import { ReactNode } from 'react';

import cx from 'classnames';
import { IconButtonComponent } from 'src/shared/components/icon-button-component';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Flex } from '@shared/components/Flex';
import { CloseIcon } from '@shared/icons/Close';

import { styles } from './ChipComponent.styles';

export type ChipVariant = 'outlined' | 'contained';

type ChipProps = AppWithStyles<typeof styles> & {
  label?: ReactNode;
  variant?: ChipVariant;
  onClick?: () => void;
  onDelete?: () => void;
  children?: ReactNode;
};

const _ChipComponent = ({
  classes,
  variant = 'outlined',
  label,
  children,
  onClick,
  onDelete,
}: ChipProps) => {
  const variantClass = {
    outlined: classes.outlinedRoot,
    contained: classes.containedRoot,
  };

  return (
    <div className={cx(classes.root, variantClass[variant])}>
      <Flex alignItems="center" classes={{ root: classes.content }} onClick={onClick}>
        {label || children}
        {onDelete && (
          <IconButtonComponent classes={{ root: classes.deleteButton }} onClick={onDelete}>
            <CloseIcon classes={{ root: classes.deleteIcon }} />
          </IconButtonComponent>
        )}
      </Flex>
    </div>
  );
};

export const ChipComponent = appWithStyles(styles)(_ChipComponent);
