import { SVGComponent, SVGProps } from '@shared/components/SVGComponent';

type MoreIconProps = Partial<SVGProps> & {
  orientation?: 'horizontal' | 'vertical';
};

export const MoreIcon = ({ orientation = 'vertical', ...otherProps }: MoreIconProps) => {
  return (
    <SVGComponent
      width={orientation === 'horizontal' ? 16 : 4}
      height={orientation === 'horizontal' ? 4 : 16}
      {...otherProps}
    >
      <path
        d={
          orientation === 'horizontal'
            ? 'M0.125 1.97656C0.125 2.14892 0.158949 2.3196 0.224908 2.47884C0.290867 2.63807 0.387546 2.78276 0.509422 2.90464C0.631299 3.02652 0.775988 3.12319 0.935228 3.18915C1.09447 3.25511 1.26514 3.28906 1.4375 3.28906C1.60986 3.28906 1.78053 3.25511 1.93977 3.18915C2.09901 3.12319 2.2437 3.02652 2.36558 2.90464C2.48745 2.78276 2.58413 2.63807 2.65009 2.47884C2.71605 2.3196 2.75 2.14892 2.75 1.97656C2.75 1.8042 2.71605 1.63353 2.65009 1.47429C2.58413 1.31505 2.48745 1.17036 2.36558 1.04848C2.2437 0.926608 2.09901 0.82993 1.93977 0.763971C1.78053 0.698012 1.60986 0.664063 1.4375 0.664063C1.26514 0.664063 1.09447 0.698012 0.935228 0.763971C0.775988 0.82993 0.631299 0.926608 0.509422 1.04848C0.387546 1.17036 0.290867 1.31505 0.224908 1.47429C0.158949 1.63353 0.125 1.8042 0.125 1.97656ZM6.6875 1.97656C6.6875 2.32466 6.82578 2.6585 7.07192 2.90464C7.31806 3.15078 7.6519 3.28906 8 3.28906C8.3481 3.28906 8.68194 3.15078 8.92808 2.90464C9.17422 2.6585 9.3125 2.32466 9.3125 1.97656C9.3125 1.62847 9.17422 1.29463 8.92808 1.04848C8.68194 0.802343 8.3481 0.664062 8 0.664063C7.6519 0.664062 7.31806 0.802343 7.07192 1.04848C6.82578 1.29463 6.6875 1.62847 6.6875 1.97656ZM13.25 1.97656C13.25 2.32466 13.3883 2.6585 13.6344 2.90464C13.8806 3.15078 14.2144 3.28906 14.5625 3.28906C14.9106 3.28906 15.2444 3.15078 15.4906 2.90464C15.7367 2.6585 15.875 2.32466 15.875 1.97656C15.875 1.62847 15.7367 1.29463 15.4906 1.04848C15.2444 0.802343 14.9106 0.664062 14.5625 0.664063C14.2144 0.664062 13.8806 0.802343 13.6344 1.04848C13.3883 1.29463 13.25 1.62847 13.25 1.97656Z'
            : 'M0.6875 1.41406C0.6875 1.76216 0.825781 2.096 1.07192 2.34214C1.31806 2.58828 1.6519 2.72656 2 2.72656C2.3481 2.72656 2.68194 2.58828 2.92808 2.34214C3.17422 2.096 3.3125 1.76216 3.3125 1.41406C3.3125 1.06597 3.17422 0.732126 2.92808 0.485985C2.68194 0.239843 2.3481 0.101563 2 0.101562C1.6519 0.101563 1.31806 0.239843 1.07192 0.485985C0.825781 0.732126 0.6875 1.06597 0.6875 1.41406ZM0.6875 7.97656C0.6875 8.32466 0.825781 8.6585 1.07192 8.90464C1.31806 9.15078 1.6519 9.28906 2 9.28906C2.3481 9.28906 2.68194 9.15078 2.92808 8.90464C3.17422 8.6585 3.3125 8.32466 3.3125 7.97656C3.3125 7.62847 3.17422 7.29463 2.92808 7.04848C2.68194 6.80234 2.3481 6.66406 2 6.66406C1.6519 6.66406 1.31806 6.80234 1.07192 7.04848C0.825781 7.29463 0.6875 7.62847 0.6875 7.97656ZM0.6875 14.5391C0.6875 14.8872 0.825781 15.221 1.07192 15.4671C1.31806 15.7133 1.6519 15.8516 2 15.8516C2.3481 15.8516 2.68194 15.7133 2.92808 15.4671C3.17422 15.221 3.3125 14.8872 3.3125 14.5391C3.3125 14.191 3.17422 13.8571 2.92808 13.611C2.68194 13.3648 2.3481 13.2266 2 13.2266C1.6519 13.2266 1.31806 13.3648 1.07192 13.611C0.825781 13.8571 0.6875 14.191 0.6875 14.5391Z'
        }
        fill="#0B0D0F"
      />
    </SVGComponent>
  );
};
