import { SelectOption } from 'src/shared/components/select';

import {
  convertToISOString,
  formatDate,
  isBefore,
  isAfter,
  getStartOfDate,
  addDate,
} from '@shared/utils/date';

export const generateTimePeriod = (format?: string, utc?: boolean) => {
  const slots = [getStartOfDate(new Date(), 'day')];
  const intervalMins = 15;
  const intervalsCountInDay = 24 * (60 / intervalMins);

  for (let i = 0; i < intervalsCountInDay - 1; i++) {
    slots.push(addDate(slots[i], intervalMins, 'minutes', false));
  }

  if (format) {
    return slots.map((slot) => {
      return formatDate(slot, { format, utc });
    });
  }

  return slots;
};

export const processOptions = (
  period: Array<SelectOption<string>>,
  minDate?: string | Date,
  maxDate?: string | Date
) => {
  const currentDate = formatDate(new Date(), { format: 'MM/DD/YYYY' });
  const minDateTime = formatDate(minDate, { format: 'HH:mm', utc: false });
  const computedMinDate = convertToISOString(`${currentDate} ${minDateTime}`);
  const maxDateTime = formatDate(maxDate, { format: 'HH:mm' });
  const computedMaxDate = convertToISOString(`${currentDate} ${maxDateTime}`);

  return period.filter((option) => {
    const computedOption = convertToISOString(`${currentDate} ${option.id}`);
    const isDateBefore = minDate ? isBefore(computedOption, computedMinDate, { utc: true }) : false;
    const isDateAfter = maxDate ? isAfter(computedOption, computedMaxDate, { utc: true }) : false;

    return !(isDateBefore || isDateAfter);
  });
};
