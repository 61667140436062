import { BaseModel } from '@core/base-model';

import { ResourceFileMetadataDTO, ResourceLinkMetadataDTO } from './metadata';
import { ResourceType } from './type';

export interface ResourceReadDTO {
  id: string;
  metadata: ResourceFileMetadataDTO | ResourceLinkMetadataDTO;
  name: string;
  resourceType: ResourceType;
  createdAt: string;
  updatedAt: string;
  pageCount: number;
  user: Record<string, string>;
  pages: Array<Record<string, string>>;
}

export class ResourceReadModel extends BaseModel<ResourceReadDTO> {
  private id: string;
  private metadata: ResourceFileMetadataDTO | ResourceLinkMetadataDTO;
  private name: string;
  private resourceType: ResourceType;
  private createdAt: string;
  private updatedAt: string;
  private pageCount: number;
  private user: Record<string, string>;
  private pages: Array<Record<string, string>>;

  constructor(dto: ResourceReadDTO) {
    super();

    this.update(dto);
  }

  get asJson(): ResourceReadDTO {
    return {
      id: this.id,
      metadata: this.metadata,
      name: this.name,
      resourceType: this.resourceType,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      pageCount: this.pageCount,
      user: this.user,
      pages: this.pages,
    };
  }
}
