import omitBy from 'lodash/omitBy';
import qs, { IParseOptions } from 'qs';

import { history } from '@shared/utils/history';

export const getQueries = <T = Queries>(options?: IParseOptions): T =>
  qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: Infinity,
    ...options,
  }) as T;

export const getQueriesAsSearch = (queries?: Queries): string =>
  qs.stringify(
    { ...(queries || getQueries()) },
    {
      addQueryPrefix: true,
      encode: true,
    }
  );

export const setQueries = (
  queries: Queries,
  replace?: boolean,
  locationArgs?: Partial<Location>
) => {
  const args = {
    search: qs.stringify(
      omitBy(queries, (value) => !value),
      { addQueryPrefix: true }
    ),
    ...locationArgs,
  };

  if (replace) {
    return history.replace(args);
  }

  return history.push(args);
};
