import { injectable } from '@core/di/di-utils';
import { inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { SubmissionListModel, SubmissionListQuery } from '@shared/models/submission/list-model';
import { SubmissionDefaultStatus } from '@shared/models/submission/status';
import { PaginatedResponse } from '@shared/types/services';
import { IInsightsActivityService } from '@shared/types/services/insights/activity';

@injectable()
export class InsightsActivityService extends BaseService implements IInsightsActivityService {
  private $http = inject<HTTPClient>(HTTPClient.diToken);

  protected urlPrefix = '/insights/activity';

  getSections: IInsightsActivityService['getSections'] = async (filters) => {
    const {
      data: { sections },
    } = await this.$http.post<{
      sections: Array<{ type: SubmissionDefaultStatus; count: number }>;
    }>(this.getUrl('forms/sections'), filters);

    return sections;
  };

  getSubmissionList: IInsightsActivityService['getSubmissionList'] = async (
    section,
    filter,
    pagination
  ) => {
    const {
      data: { submissions, total },
    } = await this.$http.post<PaginatedResponse<{ submissions: Array<SubmissionListQuery> }>>(
      this.getUrl('forms'),
      {
        section,
        ...filter,
        ...pagination,
      }
    );

    return {
      total,
      submissions: submissions.map((story) => new SubmissionListModel(story)),
    };
  };
}
