import { ChangeEvent } from 'react';

import { STYLE_TYPE } from 'baseui/checkbox';

import { Caption1, Checkbox, FlexFullContainer, FlexRowContainer } from '@shared/components';
import { FormQuestionDTO } from '@shared/models/forms/model';

type Props = {
  task: FormQuestionDTO;
  onEdit: (form: FormQuestionDTO) => void;
};

export const TaskRequired = ({ task, onEdit }: Props) => {
  const handleUpdateRequired = (value: boolean) => {
    onEdit({ ...task, config: { ...task.config, required: value } });
  };

  return (
    <FlexRowContainer>
      <FlexFullContainer />
      <Checkbox
        checkmarkType={STYLE_TYPE.toggle}
        checked={task?.config.required}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateRequired(e.target.checked)}
      >
        <Caption1>Required</Caption1>
      </Checkbox>
    </FlexRowContainer>
  );
};
