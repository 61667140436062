import { CSSProperties } from 'react';

import { palette } from '@core/theme/units/palette';

export const styles = () => {
  const commonLabelStyles: CSSProperties = {
    fontSize: '14px',
    fontWeight: 600,
    marginRight: 4,
  };

  return {
    root: {
      width: 'auto',
    },
    label: {
      ...commonLabelStyles,
      color: palette.text.secondary,
    },
    optionalLabel: {
      ...commonLabelStyles,
      color: palette.grey[400],
    },
  };
};
