import { BaseModel } from '@core/base-model';

export interface EventTypeListQuery {
  id: string;
  name: string;
  type: string;
  pageType: Record<string, string> | null;
}

export class EventTypeListModel extends BaseModel<EventTypeListQuery> {
  private id: string;
  private name: string;
  private type: string;
  private pageType: Record<string, string> | null;

  constructor(data: EventTypeListQuery) {
    super();

    this.update(data);
  }

  get asJson(): EventTypeListQuery {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      pageType: this.pageType,
    };
  }
}
