import { Icon } from '@shared/components';
import {
  FirstColumn,
  HistoryItem,
  IconContainer,
  RevertText,
  SecondColumn,
  ThirdColumn,
} from '@shared/components/PageElements/HistoryPage';
import { useThemeContext } from '@shared/hooks';

type Props = {
  index: number;
  change: any;
  changeData: any;
};

export const UserChange = ({ index, change, changeData }: Props) => {
  const theme = useThemeContext();

  return (
    <HistoryItem
      style={changeData.length - 1 !== index ? { paddingBottom: theme.sizing.scale300 } : null}
    >
      <FirstColumn>
        <IconContainer>
          <Icon size={20} icon="RegularHistory" color="mono700OnBase"></Icon>
        </IconContainer>
      </FirstColumn>
      <SecondColumn>
        {change.changeType === 'Added' && (
          <>
            {change.changeType} {change.subject} <b>{change.nextValue}</b>
          </>
        )}
        {change.changeType === 'Changed' && (
          <>
            {change.changeType} {change.subject} from <b>{change.prevValue}</b> to{' '}
            <b>{change.nextValue}</b>
          </>
        )}
      </SecondColumn>
      <ThirdColumn>
        {change.revertable && (
          <>
            <IconContainer>
              <Icon size={14} icon="SolidRedo" color="mono700OnBase"></Icon>
            </IconContainer>
            <RevertText>Revert</RevertText>
          </>
        )}
      </ThirdColumn>
    </HistoryItem>
  );
};
