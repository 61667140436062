import { injectable } from '@core/di/di-utils';
import { inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { GroupListModel, GroupListQuery } from '@shared/models/group/list-model';
import { GroupReadModel, GroupReadQuery } from '@shared/models/group/read-model';
import { GroupUserListModel, GroupUserListQuery } from '@shared/models/group/user/list-model';
import { PaginatedResponse } from '@shared/types/services';
import { IGroupsService } from '@shared/types/services/groups';

@injectable()
export class GroupsService extends BaseService implements IGroupsService {
  private $http = inject<HTTPClient>(HTTPClient.diToken);

  protected urlPrefix = '/groups';

  getCategoriesDictionary: IGroupsService['getCategoriesDictionary'] = async () => {
    const { data } = await this.$http.get<Array<string>>(this.getUrl('categories/available'));

    return data;
  };

  createAutoGroups: IGroupsService['createAutoGroups'] = async (categories) => {
    await this.$http.post(this.getUrl('auto'), { categories });
  };

  createCustomGroup: IGroupsService['createCustomGroup'] = async (group) => {
    await this.$http.post(this.getUrl(), group);
  };

  getCategories: IGroupsService['getCategories'] = async () => {
    const { data } = await this.$http.get<Array<{ name: string; count: number }>>(
      this.getUrl('categories')
    );

    return data;
  };

  getGroups: IGroupsService['getGroups'] = async (params) => {
    const { data } = await this.$http.post<Array<GroupListQuery>>(this.getUrl('list'), params);

    return data.map(
      (group) =>
        new GroupListModel({
          ...group,
          profileName: group.autoGroupped ? group.profileName : 'Custom groups',
        })
    );
  };

  getGroup: IGroupsService['getGroup'] = async (id) => {
    const { data } = await this.$http.get<GroupReadQuery>(this.getUrl(id));

    return new GroupReadModel(data);
  };

  getGroupUsers: IGroupsService['getGroupUsers'] = async (groupId, params) => {
    const normalizedParams = params
      ? {
          limit: params.pagination?.limit,
          skip: params.pagination?.skip,
          sort: params.sort,
          search: params.filters?.search,
        }
      : undefined;

    const {
      data: { total, users },
    } = await this.$http.post<PaginatedResponse<{ users: Array<GroupUserListQuery> }>>(
      this.getUrl(`${groupId}/users`),
      normalizedParams
    );

    return {
      total,
      users: users.map((user) => new GroupUserListModel(user)),
    };
  };

  changeUsersRole: IGroupsService['changeUsersRole'] = async (id, members) => {
    await this.$http.patch(this.getUrl(`${id}/members`), members);
  };

  deleteGroup: IGroupsService['deleteGroup'] = async (id) => {
    await this.$http.delete(this.getUrl(id));
  };

  updateGroup: IGroupsService['updateGroup'] = async (id, data) => {
    await this.$http.patch(this.getUrl(id), data);
  };

  updateGroupUsers: IGroupsService['updateGroupUsers'] = async (id, users) => {
    await this.$http.patch(this.getUrl(`${id}/users`), users);
  };
}
