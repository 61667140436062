import { ReactNode } from 'react';

import { FormMinMax } from '@modules/tenant/program-library/components/edit-task/tasks/components/form-min-max';
import { TaskRequired } from '@modules/tenant/program-library/components/edit-task/tasks/components/task-required';

import { Caption2, FlexColumnContainer } from '@shared/components';
import { SegmentedControl } from '@shared/components/segmented-control';
import { FormQuestionDTO, FormQuestionType } from '@shared/models/forms/model';

import { TaskButtonControl } from '../components/task-button-control';

type Props = {
  task: FormQuestionDTO;
  removable: boolean;
  onEdit: (form: FormQuestionDTO) => void;
  children: ReactNode;
};

export const MAX_LIKERT_NPS_LIMIT = 10;
export const MAX_LIKERT_CUSTOM_LIMIT = 99999;

export const LikertScaleTask = ({ task, onEdit, removable, children }: Props) => {
  const taskType = task.config.type;

  const handleChange = (index: number) => {
    if (!removable) return;

    switch (index) {
      case 0:
        handleEditTaskType(FormQuestionType.LIKERT_NPS);
        break;
      case 1:
        handleEditTaskType(FormQuestionType.LIKERT_CUSTOM);
        break;
    }
  };

  const handleEditTaskType = (newType: FormQuestionType) => {
    if (newType === FormQuestionType.LIKERT_CUSTOM) {
      delete task.config.min;
      delete task.config.max;
    }
    onEdit({
      ...task,
      config: {
        ...task.config,
        type: newType,
        ...(newType === FormQuestionType.LIKERT_NPS && {
          min: 0,
          max: MAX_LIKERT_NPS_LIMIT,
        }),
        ...(newType === FormQuestionType.LIKERT_CUSTOM && {
          min: 0,
          max: MAX_LIKERT_CUSTOM_LIMIT,
        }),
      },
    });
  };

  const isLikertScaleTask = [FormQuestionType.LIKERT_NPS, FormQuestionType.LIKERT_CUSTOM].includes(
    taskType
  );

  if (!isLikertScaleTask) return null;

  return (
    <>
      <TaskButtonControl>
        <SegmentedControl
          items={['NPS', 'Custom range']}
          disabled={!removable}
          onChange={handleChange}
          selected={
            taskType === FormQuestionType.LIKERT_NPS
              ? 0
              : taskType === FormQuestionType.LIKERT_CUSTOM
                ? 1
                : null
          }
        />
      </TaskButtonControl>

      {children}

      {taskType === FormQuestionType.LIKERT_CUSTOM && (
        <FlexColumnContainer $style={{ marginTop: '22px' }}>
          <Caption2>Options</Caption2>
          <FormMinMax
            task={task}
            onEdit={onEdit}
            isDisabled={!removable}
            maxLimit={MAX_LIKERT_CUSTOM_LIMIT}
          />
        </FlexColumnContainer>
      )}

      <TaskRequired task={task} onEdit={onEdit} />
    </>
  );
};
