import { CommentReadQuery } from '@shared/models/comment/read-model';
import { ReactionReadQuery } from '@shared/models/reaction/read-model';
import { ReactionType } from '@shared/models/reaction/type';
import { UserReadQuery } from '@shared/models/user/read-model';

export const processReactions = (
  reactions: CommentReadQuery['reactions'],
  currentUserId: UserReadQuery['id']
): Array<{ type: ReactionType; id: ReactionReadQuery['id']; count: number }> => {
  const mappedReactions = reactions.reduce((acc, reaction) => {
    acc[reaction.type] = {
      type: reaction.type,
      id: acc[reaction.type]?.id || (reaction.user.id === currentUserId ? reaction.id : undefined),
      count: acc[reaction.type]?.count ? acc[reaction.type].count + 1 : 1,
    };

    return acc;
  }, {});

  return Object.keys(mappedReactions).map((reactionKey) => {
    return mappedReactions[reactionKey];
  });
};
