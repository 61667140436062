import { AppNotification } from '@shared/components';

import { AppContextProvider } from './app-context-provider';
import { AppRouter } from './app-router';

export const App = () => {
  return (
    <AppContextProvider>
      <AppRouter />
      <AppNotification />
    </AppContextProvider>
  );
};
