import { withStyle } from 'baseui';
import {
  type ModalProps,
  Modal as BaseModal,
  ModalHeader as BaseModalHeader,
  ModalBody as BaseModalBody,
  ModalButton as BaseModalButton,
  ModalFooter as BaseModalFooter,
} from 'baseui/modal';

import { type AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { type FlexProps, Flex } from '@shared/components/Flex';

import { footerStyles } from './Modal.styles';

export const Modal = (props: ModalProps) => {
  const { overrides: propsOverrides = {} } = props;

  return (
    <BaseModal
      {...props}
      overrides={{
        Root: { style: { zIndex: 7 } },
        Dialog: {
          style: ({ $theme }: any) => ({
            paddingTop: $theme.sizing.scale800,
            paddingBottom: $theme.sizing.scale100,
          }),
        },
        ...propsOverrides,
      }}
    />
  );
};

export const ModalHeader = withStyle(BaseModalHeader, ({ $hasBorder = false }: any) =>
  $hasBorder ? {} : { marginTop: '-10px' }
);

export const ModalBody = (props: any) => {
  return <BaseModalBody {...props} />;
};

export const ModalFooter = withStyle(BaseModalFooter, ({ $theme, $hasBorder = false }: any) =>
  $hasBorder
    ? {
        marginLeft: $theme.sizing.scale600,
        marginRight: $theme.sizing.scale600,
      }
    : {
        marginLeft: $theme.sizing.scale600,
        marginRight: $theme.sizing.scale600,
        borderTopWidth: '0px',
        borderTopStyle: 'none',
        borderTopColor: 'transparent',
      }
);

type ModalFooterProps = AppWithStyles<typeof footerStyles> & Omit<FlexProps, 'classes'> & {};

const _ModalFooterComponent = (props: ModalFooterProps) => {
  return <Flex justifyContent="flex-end" alignItems="center" {...props} />;
};

export const ModalFooterComponent = appWithStyles(footerStyles)(_ModalFooterComponent);

export const ModalButton = (props: any) => {
  return (
    <BaseModalButton
      {...props}
      overrides={{
        BaseButton: {
          style: {
            fontSize: '12px',
            lineHeight: '20px',
            letterSpacing: '1px',
            fontWeight: 500,
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingRight: '18px',
            paddingLeft: '16px',
          },
        },
      }}
    />
  );
};
