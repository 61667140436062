import { BaseModel } from '@core/base-model';

import { NoteMediaType } from './note-media-type';

import { UserReadQuery } from '../user/read-model';

export interface NoteReadQuery {
  body: string;
  createdAt: string;
  id: string;
  title: string;
  type: NoteMediaType;
  updatedAt: string;
  user: UserReadQuery;
}

export class NoteReadModel extends BaseModel<NoteReadQuery> {
  private body: string;
  private createdAt: string;
  private id: string;
  private title: string;
  private type: NoteMediaType;
  private updatedAt: string;
  private user: UserReadQuery;

  constructor(data: NoteReadQuery) {
    super();

    this.update(data);
  }

  get asJson(): NoteReadQuery {
    return {
      body: this.body,
      createdAt: this.createdAt,
      id: this.id,
      title: this.title,
      type: this.type,
      updatedAt: this.updatedAt,
      user: this.user,
    };
  }
}
