import { CalendarView } from '@shared/components/calendar/calendar.types';
import { ManageMode } from '@shared/constants/common';
import { EventListQuery } from '@shared/models/event/list-model';

export type InsightRouteParams = { insightToken: string };
export type SubmissionDetailsRouteParams = { submissionId: string };
export type StoryDetailsRouteParams = { storyId: string };
export type HeadlineDetailsRouteParams = { headlineId: string };
export type CalendarRouteParams = {
  viewType: CalendarView;
  date: string;
  eventManageMode: ManageMode;
  eventId: EventListQuery['id'];
};

export const ROUTES = {
  root: '/',
  LOGIN: () => `/login`,
  FORGOT_PASSWORD: () => `/forgot-password`,
  RESET_PASSWORD: () => `/reset-password`,
  INVITE: () => `/invite`,

  TENANTS_LIST: () => `/tenants`,
  TENANT_CREATE: () => `/tenants/new`,
  TENANT_OVERVIEW: (payload: any) => `/tenants/${payload.tenantId}/overview`,
  TENANT_SETTINGS: (payload: any) => `/tenants/${payload.tenantId}/edit`,
  TENANT_THEME: (payload: any) => `/tenants/${payload.tenantId}/theme`,
  TENANT_HISTORY: (payload: any) => `/tenants/${payload.tenantId}/history`,

  USERS_LIST: () => `/users`,
  USER_CREATE: () => `/users/new`,
  USER_EDIT: (payload: any) => `/users/${payload.userId}`,
  USER_HISTORY: (payload: any) => `/users/${payload.userId}/history`,

  ADMIN: () => `/admin`,
  ADMIN_USERS: () => `/admin/users`,

  FIELD: () => '/field',
  notifications: '/notifications',
  fieldNews: '/field-news',
  calendar: {
    root: '/calendar',
    children: {
      personalView: (
        viewType = ':viewType' as CalendarView,
        date = ':date',
        eventManageMode = ':eventManageMode?' as ManageMode | string,
        eventId = ':eventId?'
      ) => {
        const baseUrl = `${ROUTES.calendar.root}/personal-view/${viewType}/${date}`;

        return `${baseUrl}${
          eventManageMode ? `/${eventManageMode}${eventId ? `/${eventId}` : ''}` : ''
        }`;
      },
    },
  },
  inventory: {
    root: '/inventory',
    children: {
      orders: () => `${ROUTES.inventory.root}/orders`,
      ordersHistory: () => `${ROUTES.inventory.root}/orders-history`,
      addressBook: () => `${ROUTES.inventory.root}/address-book`,
      create: () => `${ROUTES.inventory.root}/place-order`,
      addAddress: () => `${ROUTES.inventory.root}/add-address`,
      orderDetails: (id: string = ':id') => `${ROUTES.inventory.root}/order-details/${id}`,
      addressDetails: (id: string = ':id') => `${ROUTES.inventory.root}/address-details/${id}`,
      createAddress: () => `${ROUTES.inventory.root}/create-address`,
    },
  },
  pages: {
    root: '/pages',
    children: {
      details: (id: Id = ':pageId') => `${ROUTES.pages.root}/${id}`,
      create: () => `${ROUTES.pages.root}/${ManageMode.create}`,
    },
  },
  myAccount: {
    root: '/my-account',
    children: {
      personalDetails: () => `${ROUTES.myAccount.root}/personal-details`,
      changePassword: () => `${ROUTES.myAccount.root}/change-password`,
    },
  },
  programUsers: {
    root: '/program-users',
    children: {
      users: {
        root: () => `${ROUTES.programUsers.root}/users`,
        children: {
          list: () => `${ROUTES.programUsers.children.users.root()}/list`,
          import: () => `${ROUTES.programUsers.children.users.root()}/import`,
          create: () => `${ROUTES.programUsers.children.users.root()}/${ManageMode.create}`,
        },
      },
      roles: () => `${ROUTES.programUsers.root}/roles`,
      groups: () => `${ROUTES.programUsers.root}/groups`,
    },
  },
  insights: {
    root: '/insights',
    children: {
      activity: {
        root: (insightToken: string = ':insightToken?') =>
          `${ROUTES.insights.root}/activity/${insightToken}`,
        children: {
          submittedForm: (formId: string = ':formId') =>
            `${ROUTES.insights.root}/activity/submission-form/${formId}`,
        },
      },
      explore: {
        root: (insightToken: string = ':insightToken?') =>
          `${ROUTES.insights.root}/explore/${insightToken}`,
      },
      analyze: {
        root: (insightToken: string = ':insightToken?') =>
          `${ROUTES.insights.root}/analyze/${insightToken}`,
      },
    },
  },
  programOrganization: {
    root: '/organization',
    children: {
      eventTypes: {
        root: () => `${ROUTES.programOrganization.root}/event-types`,
      },
    },
  },
  headlineDetails: (id: Id = ':headlineId') => `/headline/${id}`,
  storyDetails: (id: Id = ':storyId') => `/story/${id}`,
  userProfile: (id: Id = ':userId') => `/user-profile/${id}`,
  submissionDetails: (id: Id = ':submissionId') => `/submission/${id}`,
  FIELD_WORK: () => '/field/work',
  FIELD_WORK_PAGES: '/field/work/pages',
  FIELD_WORK_OBJECTIVES: '/field/work/objectives',

  FIELD_SUMMARIES: '/field/summaries',
  FIELD_SUMMARIES_PROGRESS: () => `/field/summaries/progress`,
  FIELD_SUMMARIES_DETAILS: () => `/field/summaries/details`,
  FIELD_SUMMARIES_PERFORMANCE: '/field/summaries/performance',

  INSIGHTS_SHARED_INSIGHT: (payload: any) => `/insights/share/${(payload || {}).id}`,
  INSIGHTS_ACTIVITY_FORMS: '/insights/activity/forms',

  INSIGHTS_ACTIVITY: `/insights/activity`,
  INSIGHTS_EXPLORE: '/insights/explore',
  INSIGHTS_ANALYZE: '/insights/analyze',

  PROGRAM_USERS_OVERVIEW: '/program/users',

  PROGRAM_ORGANIZATION: '/organization',
  PROGRAM_ORGANIZATION_PAGES: '/organization/pages',
  PROGRAM_ORGANIZATION_GROUPS: '/organization/groups',
  PROGRAM_ORGANIZATION_PAGE_GROUPS: '/organization/page-groups',
  PROGRAM_ORGANIZATION_EVENT_TYPES: '/organization/event-types',
  PROGRAM_ORGANIZATION_TAGS: '/organization/tags',
  PROGRAM_ORGANIZATION_COLLABORATIONS: '/organization/collaborations',
  PROGRAM_ORGANIZATION_IMPORT_CSV: (pageTypeId: string = ':pageTypeId') =>
    `/organization/import-csv/${pageTypeId}`,

  PROGRAM_NEWS: '/program/news',
  PROGRAM_NEWS_HEADLINE_CREATE: () => `/program/news/headlines/new`,
  PROGRAM_NEWS_HEADLINE_EDIT: (payload: any) =>
    `/program/news/headlines/edit/${(payload || {}).headlineId}`,
  PROGRAM_NEWS_HEADLINES_MANAGE: () => `/program/news/headlines/drafts`,

  PROGRAM_LIBRARY: '/program/library',
  PROGRAM_LIBRARY_FORMS: () => `/program/library/forms`,
  PROGRAM_LIBRARY_FORM_CREATE: () => `/program/library/forms/create`,
  PROGRAM_LIBRARY_FORM_VIEW: (payload: any) => `/program/library/forms/${payload.id}`,
  PROGRAM_LIBRARY_FORM_EDIT: (payload: any) => `/program/library/forms/${payload.id}/edit`,
  PROGRAM_LIBRARY_FORM_ARCHIVED: (payload: any) => `/program/library/forms/${payload.id}/archived`,
  PROGRAM_LIBRARY_PROMPTS: () => '/program/library/prompts',
  PROGRAM_LIBRARY_PROMPT_CREATE: () => '/program/library/prompts/create',
  PROGRAM_LIBRARY_PROMPT_EDIT: (payload: any) => `/program/library/prompts/${payload.id}/edit`,
  PROGRAM_LIBRARY_RESOURCES: () => `/program/library/resources`,
  PROGRAM_LIBRARY_ARCHIVED_FORMS: () => `/program/library/archived-forms`,

  ORGANIZATION_PAGES: () => `/organization/pages`,

  FOUR_OH_FOUR: () => `/404`,
};

export const SHARED_ROUTES = [ROUTES.insights.root];

type Routes = keyof typeof ROUTES;

export const getRoute = (key: Routes, payload: any = {}): string => {
  const route = ROUTES[key] as any;

  return route(payload);
};

export const getRoutePath = (key: Routes, payload: any = null): string => {
  return getRoute(key, payload);
};
