import { useContext, useMemo, useState, createContext, ReactNode } from 'react';

import { useStyletron } from 'baseui';
import Media from 'react-media';

export interface IBreadCrumbLink {
  label: string;
  path: string | null;
}

interface IUIContext {
  drawerOpen: boolean;
  setDrawerOpen: Function;
  menuExpanded: boolean;
  setMenuExpanded: Function;
  mobile: boolean;
  breadCrumbs: IBreadCrumbLink[];
  setBreadCrumbs: Function;
}

const defaultUIContextValue: IUIContext = {
  drawerOpen: false,
  setDrawerOpen: () => null,
  menuExpanded: false,
  setMenuExpanded: () => {},
  mobile: false,
  breadCrumbs: [],
  setBreadCrumbs: () => {},
};

const UIContext = createContext(defaultUIContextValue);
export const useUIContext = () => useContext(UIContext);

type Props = {
  children: ReactNode;
};

export const UIContextProvider = ({ children }: Props) => {
  const theme = useStyletron()[1];

  let savedMenuExpanded: boolean = JSON.parse(
    window.localStorage.getItem('menuExpanded') || 'true'
  );

  const [menuExpanded, setMenuExpanded] = useState(savedMenuExpanded);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const context: IUIContext = useMemo(
    () => ({
      drawerOpen,
      setDrawerOpen: (open: boolean) => {
        setDrawerOpen(open);
      },
      menuExpanded,
      setMenuExpanded: (expanded: boolean) => {
        setMenuExpanded(expanded);
        window.localStorage.setItem('menuExpanded', JSON.stringify(expanded));
      },
      mobile: false,
      breadCrumbs,
      setBreadCrumbs,
    }),
    [menuExpanded, drawerOpen, breadCrumbs]
  );

  return (
    <Media query={{ maxWidth: theme.breakpoints.medium }}>
      {(matches) => {
        return (
          <UIContext.Provider value={{ ...context, mobile: matches }}>
            {children}
          </UIContext.Provider>
        );
      }}
    </Media>
  );
};
