import { Button } from 'baseui/button';
import {
  ButtonGroup as NativeButtonGroup,
  ButtonGroupProps as NativeButtonGroupProps,
} from 'baseui/button-group';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './button-group.styles';

type Props = Except<NativeButtonGroupProps, 'children'> &
  AppWithStyles<typeof styles> & {
    itemsEqualWidth?: boolean;
    items: Array<{ text: string }>;
  };

const ButtonGroupComponent = ({ items, itemsEqualWidth, classes, ...props }: Props) => {
  return (
    <div className={classes.root}>
      <NativeButtonGroup
        overrides={{
          Root: {
            style: {
              margin: '0px !important',
              width: '100%',
            },
          },
        }}
        {...props}
      >
        {items.map((item, i) => {
          return (
            <Button
              key={i}
              overrides={{
                BaseButton: {
                  style: {
                    width: itemsEqualWidth ? `${100 / items.length}%` : undefined,
                  },
                },
              }}
              {...{ className: classes.button }}
              {...item}
            >
              {item.text}
            </Button>
          );
        })}
      </NativeButtonGroup>
    </div>
  );
};

export const ButtonGroup = appWithStyles(styles)(ButtonGroupComponent);
