import { useState, useEffect } from 'react';

import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import cx from 'classnames';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Message } from '@shared/components/Message';
import { Spinner } from '@shared/components/Spinner';

import { styles } from './image-view.styles';

type ImageViewProps = AppWithStyles<typeof styles> & {
  src: string;
  withErrorMessage?: boolean;
  loaderSize?: number;
  onError?: () => void;
  onLoad?: () => void;
};

const ImageViewComponent = ({
  classes,
  src,
  onError,
  onLoad,
  loaderSize = 20,
  withErrorMessage = true,
}: ImageViewProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!src) {
      handleError();
    }
  }, [src]);

  const handleLoad = () => {
    setLoading(false);

    onLoad?.();
  };

  const handleError = () => {
    setLoading(false);
    setError(true);

    onError?.();
  };

  const image = () => {
    if ((error || !src) && withErrorMessage) {
      return (
        <Message
          icon={<BrokenImageIcon classes={{ root: classes.imageEmptyStateIcon }} />}
          heading="Failed to load"
          classes={{ root: classes.image, heading: classes.imageEmptyStateHeading }}
        />
      );
    }

    if (error || !src) {
      return null;
    }

    return (
      <>
        <img
          decoding="async"
          alt=""
          src={src}
          onLoad={handleLoad}
          onError={handleError}
          className={cx(classes.image, { [classes.imageHidden]: loading })}
        />
        {loading && (
          <Spinner
            size={loaderSize}
            withWrapper
            wrapperProps={{ classes: { root: classes.loader } }}
          />
        )}
      </>
    );
  };

  return <div className={classes.root}>{image()}</div>;
};

export const ImageView = appWithStyles(styles)(ImageViewComponent);
