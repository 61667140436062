import { useState } from 'react';

import { Block } from 'baseui/block';

import { DatePicker, Timepicker } from '@shared/components';
import { FlexRowContainer } from '@shared/components';

type Props = {
  value: Date;
  onChange: (d: Date) => void;
  withTime?: boolean;
};

export const DatetimeInput = ({ value, onChange, withTime = true }: Props) => {
  const [date, setDate] = useState<Date | null>(value ? new Date(value) : null);

  const handleUpdate = (d: Date) => {
    setDate(d);
    if (onChange) {
      onChange(d);
    }
  };

  return (
    <FlexRowContainer>
      <Block flex={20} marginRight="scale300">
        <DatePicker
          value={date}
          formatString="MM/dd/yyyy"
          onChange={({ date }: any) => handleUpdate(date)}
        />
      </Block>
      {withTime ? (
        <Block flex={15} width="120px">
          {date ? (
            <Timepicker creatable value={date} onChange={(time: any) => handleUpdate(time)} />
          ) : null}
        </Block>
      ) : null}
    </FlexRowContainer>
  );
};
