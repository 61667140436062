import { useMemo } from 'react';

import { Block, StatefulInput } from '@shared/components';
import { Icon } from '@shared/components/Icon';

const Before = (
  <Block display="flex" alignItems="center" paddingLeft="scale500">
    <Icon size={20} icon="RegularSearch" color="colorSecondary" />
  </Block>
);

export const SearchInput = (props: any) => {
  const overrides = useMemo(
    () => ({
      Root: {
        style: {
          maxWidth: props.width || '300px',
        },
      },
      Before: () => Before,
      ...props.overrides,
    }),
    [props]
  );

  return <StatefulInput {...props} overrides={overrides} />;
};
