import { ReactNode, Component } from 'react';

import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import cx from 'classnames';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Flex } from '@shared/components/Flex';
import { ModalButton } from '@shared/components/Modal';

import { styles } from './confirmation.styles';

type Props = AppWithStyles<typeof styles> & {};

type ButtonConfig = { text: string; className?: string };

type ConfirmationState = {
  open: boolean;
  title: ReactNode;
  question: ReactNode;
  buttonsConfig?: {
    confirm?: ButtonConfig;
    reject?: ButtonConfig;
  };
  callback: (isConfirmed?: boolean) => void;
};

let showConfirmationFn;

export function showConfirmation(
  { title, question, buttonsConfig }: Partial<ConfirmationState>,
  callback: (isConfirmed: boolean) => void
): void {
  showConfirmationFn({ title, question, buttonsConfig }, callback);
}

class ConfirmationComponent extends Component<Props, ConfirmationState> {
  readonly state: ConfirmationState = {
    open: false,
    title: 'Confirmation',
    question: '',
    callback: (): void => {},
  };

  componentDidMount() {
    showConfirmationFn = this.show;
  }

  private show = (
    { title, question, buttonsConfig }: Partial<ConfirmationState>,
    callback: (isConfirmed: boolean) => void
  ): void => {
    this.setState({ title, question, buttonsConfig, callback, open: true });
  };

  private terminate = () => {
    this.state.callback(false);
    this.hide();
  };

  private hide = () => {
    this.setState({ open: false });
  };

  private handleYes = () => {
    this.state.callback(true);
    this.hide();
  };

  render() {
    const { classes } = this.props;
    const { open, title, question, buttonsConfig } = this.state;
    const modalProps = {
      className: classes.root,
    };

    return (
      <Modal
        isOpen={open}
        size="auto"
        onClose={this.hide}
        mountNode={document.body}
        {...modalProps}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody className={classes.modalBody}>
          {question && <div className={classes.question}>{question}</div>}
        </ModalBody>
        <Flex alignItems="center" justifyContent="flex-end" className={classes.modalFooter}>
          <ModalButton
            className={cx(classes.rejectButton, buttonsConfig?.reject?.className)}
            onClick={this.terminate}
          >
            {buttonsConfig?.reject?.text || 'Cancel'}
          </ModalButton>
          <ModalButton className={buttonsConfig?.confirm?.className} onClick={this.handleYes}>
            {buttonsConfig?.confirm?.text || 'Yes'}
          </ModalButton>
        </Flex>
      </Modal>
    );
  }
}

export const Confirmation = appWithStyles(styles)(ConfirmationComponent);
