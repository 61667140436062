import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { DateTime } from 'luxon';

import { inject } from '@core/di/di-utils';
import { Config } from '@core/сonfig';

import { DI_TOKENS } from '@shared/constants/di';
import { IConfig } from '@shared/types/config';
import { IAuthService } from '@shared/types/services/auth';

const alwaysHeaders: any = () => ({
  'X-Timezone': DateTime.local().zoneName,
  'X-Timezone-Offset': DateTime.local().offset,
});

let refreshing = false;

export const createClient = () => {
  const config = inject<IConfig>(Config.diToken);
  const authService = inject<IAuthService>(DI_TOKENS.authService);
  const apiBaseURL = config.getApiBaseURL();

  const accessToken = authService.tokens.access;

  const processForbidden = async (options: RequestInit) => {
    const { operationName } = JSON.parse(options.body as string);
    const customUri = `${apiBaseURL}/api?opname=${operationName}`;

    if (!refreshing) {
      refreshing = true;
      const refreshToken = authService.tokens.refresh;

      if (refreshToken) {
        const response = await authService.refreshToken();

        if (response) {
          authService.setTokens({
            access: response.accessToken,
            refresh: response.refreshToken,
          });
        }
      }
    }

    refreshing = false;

    if (accessToken) {
      const headers = options.headers as Record<string, string>;

      headers.authorization = `Bearer ${accessToken}`;
      options.headers = headers;
    }

    return fetch(customUri, options);
  };

  const customFetch = async (uri, options) => {
    const { operationName } = JSON.parse(options.body);
    let opts = {
      ...options,
      cache: 'no-cache',
      redirect: 'follow',
      referrer: 'no-referrer',
      credentials: 'same-origin',
      headers: {
        ...options.headers,
        ...alwaysHeaders(),
      },
    };
    const customUri = `${apiBaseURL}/api?opname=${operationName}`;
    const response = await fetch(customUri, opts);

    const untouchedResponse = response.clone();

    if (response.status === 401) {
      return processForbidden(opts);
    }

    if (response.ok) {
      const json = await response.json();

      if (json?.errors?.[0]?.extensions?.exception?.status === 401) {
        return processForbidden(opts);
      } else {
        return untouchedResponse;
      }
    }

    return response;
  };

  const httpLink = new HttpLink({
    uri: `${apiBaseURL}/api`,
    fetch: customFetch,
  });

  const authLink = setContext((_, { headers }) => {
    const accessToken = authService.tokens.access;

    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });

  const sharedInsightLink = new ApolloLink((operation, forward) => {
    const { sharedInsightView } = operation.getContext();
    if (sharedInsightView) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'X-Shared-Insight-View': sharedInsightView?.sharingOptions?.id,
          'X-Shared-Insight-User': sharedInsightView?.user?.id,
        },
      }));
    }

    return forward(operation);
  });

  const cache = new InMemoryCache();

  return new ApolloClient({
    cache,
    link: ApolloLink.from([authLink, sharedInsightLink, httpLink]),
    name: 'web',
  });
};
