export const styles = () => {
  return {
    root: {
      position: 'relative',
      padding: 24,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
      borderRadius: '4px',
    },
  };
};
