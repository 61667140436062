import React from 'react';

import Tooltip, { TooltipProps as NativeTooltipProps } from '@mui/material/Tooltip';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './app-tooltip.styles';

type Props = AppWithStyles<typeof styles> & NativeTooltipProps & {};

const AppTooltipComponent: React.FC<Props> = (props) => {
  return <Tooltip {...props} />;
};

AppTooltipComponent.defaultProps = {
  arrow: true,
  placement: 'top',
};

export const AppTooltip = appWithStyles(styles)(AppTooltipComponent);
