import { useCallback } from 'react';

import { Button } from 'src/shared/components/button';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Flex } from '@shared/components/Flex';
import { ReactionStyleVariant } from '@shared/components/reaction/reaction.types';
import { useThemeContext } from '@shared/hooks';
import { ReactionReadQuery } from '@shared/models/reaction/read-model';
import { ReactionType } from '@shared/models/reaction/type';

import { styles } from './selected-reactions.styles';

type Props = AppWithStyles<typeof styles> & {
  processing: boolean;
  data: Array<{ type: ReactionType; id: ReactionReadQuery['id']; count: number }>;
  variant: ReactionStyleVariant;
  onSelect: (reaction: ReactionType) => void;
  onRemove: (reactionId: ReactionReadQuery['id']) => void;
};

const SelectedReactionsComponent = ({
  classes,
  data,
  variant,
  onSelect,
  onRemove,
  processing,
}: Props) => {
  const $theme = useThemeContext();

  const onOptionSelect = useCallback(
    (e) => {
      const { type, id } = e.currentTarget.dataset;

      if (id) {
        onRemove(id);
      } else {
        onSelect(type);
      }
    },
    [onRemove, onSelect]
  );

  return (
    <Flex alignItems="center" flexWrap="wrap" classes={{ root: classes.root }}>
      {data.map((reaction) => {
        const styleConfig: Record<ReactionStyleVariant, CSSRules> = {
          primary: {
            border: `1px solid ${reaction.id ? $theme.colors.primary : 'transparent'}`,
            backgroundColor: reaction.id ? $theme.colors.primary50 : '#F6F6F6',
            color: '#333333',
          },
          secondary: {
            backgroundColor: reaction.id ? $theme.colors.primary : 'transparent',
            color: '#FFFFFF',
          },
        };

        return (
          <Button
            disabled={processing}
            key={reaction.type}
            data-type={reaction.type}
            data-id={reaction.id}
            kind="minimal"
            size="compact"
            text={<span className={classes.reactionEmoji}>{reaction.type}</span>}
            endEnhancer={reaction.count}
            style={styleConfig[variant]}
            overrides={{
              EndEnhancer: {
                style: {
                  marginLeft: '8px',
                },
              },
            }}
            className={classes.reactionOption}
            onClick={onOptionSelect}
          />
        );
      })}
    </Flex>
  );
};

export const SelectedReactions = appWithStyles(styles)(SelectedReactionsComponent);
