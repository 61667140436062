/**
 * Activity log types for submissions
 */
export enum AssignmentSubmissionActivityLogEntryType {
  SUBMISSION_CLOSED = 'SUBMISSION_CLOSED',
  SUBMISSION_IN_REVIEW = 'SUBMISSION_IN_REVIEW',
  SUBMISSION_SENT_BACK = 'SUBMISSION_SENT_BACK',
  SUBMISSION_STARTED = 'SUBMISSION_STARTED',
  SUBMISSION_STATUS_CHANGED = 'SUBMISSION_STATUS_CHANGED',
}

/**
 * Activity log type for answers
 */

export enum HeadlinePersonTag {
  AUTHOR = 'AUTHOR',
  PUBLISHER = 'PUBLISHER',
}

export enum InsightViewType {
  ACTIVITY_FORMS = 'ACTIVITY_FORMS',
  ANALYZE_SUBMISSIONS = 'ANALYZE_SUBMISSIONS',
  EXPLORE_STORIES = 'EXPLORE_STORIES',
}

export enum ObjectiveActivityStatus {
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
}

export enum ObjectiveActivityType {
  COMPLETE_FORM = 'COMPLETE_FORM',
  COMPLETE_STORY = 'COMPLETE_STORY',
  CUSTOM = 'CUSTOM',
  VISIT_PLACE = 'VISIT_PLACE',
}

export enum ObjectiveDerivedStatus {
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
  OVERDUE = 'OVERDUE',
}

/**
 * How to handle date constraints
 */
enum ObjectiveFiltersDateConstraintType {
  ENDS_WITHIN = 'ENDS_WITHIN',
  INTERSECTS = 'INTERSECTS',
}

export enum ObjectivePriority {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MED = 'MED',
}

export enum ObjectiveStatus {
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
}

export enum PageTypeColumnType {
  BASIC = 'BASIC',
  GROUP = 'GROUP',
  LIST = 'LIST',
}

export enum ProviderSourceType {
  MATERIALOGIC = 'MATERIALOGIC',
  NAMELY = 'NAMELY',
}

export enum ProviderType {
  FULFILLMENT = 'FULFILLMENT',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
}

export enum StoryPersonTag {
  AUTHOR = 'AUTHOR',
  PUBLISHER = 'PUBLISHER',
}

export interface CreateCommentInput {
  body: string;
  user?: string | null;
}

export interface CreateReactionInput {
  type: string;
  user?: string | null;
}

export interface HeadlineInput {
  people?: HeadlinePersonInput[] | null;
  title?: string | null;
  subtitle?: string | null;
  body?: string | null;
  headerPhoto?: string | null;
  publishedAt?: any | null;
}

interface HeadlinePersonInput {
  user: string;
  tags: HeadlinePersonTag[];
}

export interface HeadlineReadReceiptInput {
  id: string;
  at: any;
}

/**
 * Input for creating an Insights "View", i.e. what data to pull and how to filter it
 */

export interface ObjectiveFilters {
  startDate?: any | null;
  endDate?: any | null;
  dateConstraint?: ObjectiveFiltersDateConstraintType | null;
  searchText?: string | null;
  name?: string | null;
  priority?: ObjectivePriority | null;
  status?: ObjectiveDerivedStatus | null;
  users?: string[] | null;
  page?: string | null;
  universalFilters?: UniversalFilters | null;
  user?: string | null;
  pageGroupFilters?: PageGroupFilter[] | null;
}

interface PageGroupFilter {
  groups?: string[] | null;
  pages?: string[] | null;
  groupPages?: string[] | null;
  users?: string[] | null;
  groupUsers?: string[] | null;
}

/**
 * Header values that may be required for using the provider API
 */
interface ProviderHeadersInput {
  apiKey?: string | null;
  authorization?: string | null;
}

/**
 * Input to create or update a third party API provider
 */
export interface ProviderInput {
  tenant: string;
  name: string;
  providerType: ProviderType;
  providerSource: ProviderSourceType;
  forProductionUse: boolean;
  headers?: ProviderHeadersInput | null;
  referenceId1?: string | null;
  referenceId2?: string | null;
}

interface UniversalFilters {
  places?: PageGroupFilter | null;
  activations?: PageGroupFilter | null;
  products?: PageGroupFilter | null;
  people?: PageGroupFilter | null;
}

export interface UpdateCommentInput {
  body: string;
}

export enum PagesAndGroupsType {
  page = 'page',
  group = 'group',
}
