import { BaseModel } from '@core/base-model';

type UserPage = {
  id: string;
  name: string;
  peoplePageUserId: string;
};

export interface UserReadQuery {
  displayName?: string;
  email: string;
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  name: string;
  permissions: Array<string>;
  profileUrl?: string;
  page: UserPage;
  lastActiveAt?: string;
  tenant: {
    id: string;
  };
  profileFields: { [key: string]: { value: string | Array<string> } };
  phone?: string;
}

export class UserReadModel extends BaseModel<UserReadQuery> {
  private displayName?: string;
  private email: string;
  private firstName: string;
  private fullName: string;
  private id: string;
  private lastName: string;
  private name: string;
  private permissions: Array<string>;
  private profileUrl?: string;
  private page: UserPage;
  private lastActiveAt?: string;
  private tenant: {
    id: string;
  };
  private profileFields: { [key: string]: { value: string | Array<string> } };
  private phone?: string;

  constructor(dto: UserReadQuery) {
    super();

    this.update(dto);
  }

  get asJson(): UserReadQuery {
    return {
      displayName: this.displayName,
      email: this.email,
      firstName: this.firstName,
      fullName: this.fullName,
      id: this.id,
      lastName: this.lastName,
      name: this.name,
      page: this.page,
      permissions: this.permissions,
      profileUrl: this.profileUrl,
      lastActiveAt: this.lastActiveAt,
      tenant: this.tenant,
      profileFields: this.profileFields,
      phone: this.phone,
    };
  }
}
