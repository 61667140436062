import { v4 } from 'uuid';

export const generateId = (): string => {
  return v4();
};

export const compareIds = (...ids: Array<Id>) => {
  const firstId = ids[0];

  return ids.every((id) => String(id) === String(firstId));
};
