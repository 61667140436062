import { ReactNode } from 'react';

import { ThemeProvider } from 'react-jss';
import { Router } from 'react-router-dom';
import { ApolloContextProvider } from 'src/shared/hocs/apollo-context';
import { ModalContextProvider } from 'src/shared/hocs/modal-context';
import { UIContextProvider } from 'src/shared/hocs/ui-context';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';

import { muiTheme } from '@core/theme';
import { MuiThemeProvider } from '@core/theme/utils/with-styles';

import { history } from '@shared/utils/history';

const engine = new Styletron();

type AppContextProviderProps = {
  children: ReactNode;
};

const jssTheme = {};

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  return (
    <ApolloContextProvider>
      <StyletronProvider value={engine}>
        <ThemeProvider theme={jssTheme}>
          <MuiThemeProvider theme={muiTheme}>
            <ModalContextProvider>
              <UIContextProvider>
                <Router history={history}>{children}</Router>
              </UIContextProvider>
            </ModalContextProvider>
          </MuiThemeProvider>
        </ThemeProvider>
      </StyletronProvider>
    </ApolloContextProvider>
  );
};
