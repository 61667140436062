import { BaseModel } from '@core/base-model';

import { UserReadQuery } from '../user/read-model';

type HeadlinePerson = Pick<UserReadQuery, 'id' | 'profileUrl' | 'firstName' | 'lastName'>;

export interface HeadlineListQuery {
  headerPhoto: string;
  id: string;
  subtitle: string;
  title: string;
  people: Array<HeadlinePerson>;
  createdAt: string;
  publishedAt?: string;
}

export class HeadlineListModel extends BaseModel<HeadlineListQuery> {
  private headerPhoto: string;
  private id: string;
  private subtitle: string;
  private title: string;
  private people: Array<HeadlinePerson>;
  private createdAt: string;
  private publishedAt?: string;

  constructor(data: HeadlineListQuery) {
    super();

    this.update(data);
  }

  get asJson(): HeadlineListQuery {
    return {
      headerPhoto: this.headerPhoto,
      id: this.id,
      subtitle: this.subtitle,
      title: this.title,
      people: this.people,
      createdAt: this.createdAt,
      publishedAt: this.publishedAt,
    };
  }
}
