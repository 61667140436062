import Color from 'color';

import { mergeOpacity } from './mergeOpacity';

/**
 * This function builds the theme object for the app.
 * It takes an input of 3 colors as strings
 * and returns a giant {Object} off all our overrided colors
 *     that are layered over the baseui theme.
 */
export const buildThemeColors = (primaryColor: string) => {
  const lighterColors = ['50', '100', '200', '300'];
  const darkerColors = ['500', '600', '700'];
  let themeColors: any = {};
  const primary = Color(primaryColor).hsl().toString();

  themeColors['white'] = '#FFFFFF';
  themeColors['black'] = '#000000';
  themeColors['mono100'] = '#FFFFFF';
  themeColors['mono200'] = '#F7F7F7';
  themeColors['mono300'] = '#F0F0F0';
  themeColors['mono400'] = '#E5E5E5';
  themeColors['mono500'] = '#CCCCCC';
  themeColors['mono600'] = '#B3B3B3';
  themeColors['mono700'] = '#999999';
  themeColors['mono800'] = '#666666';
  themeColors['mono900'] = '#333333';
  themeColors['mono1000'] = '#000000';

  themeColors['primary'] = primary;
  themeColors['primary400'] = primary;

  // we lighten colors by merging a partially transparent colors with white
  lighterColors.forEach((shade: string, index: number) => {
    const amount = 0.85 - index / 10;
    themeColors[`primary${shade}`] = mergeOpacity(Color(primary).fade(amount), Color('#FFFFFF'));
  });

  // we darken colors by merging a partially transparent colors with black
  darkerColors.forEach((shade: string, index: number) => {
    const amount = 0.25 + (index === 1 ? 0.3 : index === 2 ? 0.5 : 0);
    themeColors[`primary${shade}`] = mergeOpacity(Color(primary).fade(amount), Color('#000000'));
  });

  themeColors['inputPlaceholder'] = themeColors['mono600'];

  return themeColors;
};
