import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { CommentReadQuery } from '@shared/models/comment/read-model';

import { styles } from './content.styles';

type Props = AppWithStyles<typeof styles> & {
  data: CommentReadQuery['body'];
};

const ContentComponent = ({ classes, data }: Props) => {
  return <div className={classes.root}>{data}</div>;
};

export const Content = appWithStyles(styles)(ContentComponent);
