import { ReactNode } from 'react';

import {
  MAX_LIKERT_CUSTOM_LIMIT,
  MAX_LIKERT_NPS_LIMIT,
} from '@modules/tenant/program-library/components/edit-task/tasks/likert-scale-task/likert-scale-task';

import { FormQuestionType } from '@shared/models/forms/model';
import { SubmissionAnswerReadQuery } from '@shared/models/submission/answer/read-model';
import { formatDate, getDuration } from '@shared/utils/date';

export const getAnswerValue = (answer: SubmissionAnswerReadQuery) => {
  const stringValue = answer.value.string;
  const integerValue = answer.value.integer;
  const fileArrayValue = answer.value.fileArray;
  const dateFormat = 'lll';
  const decimalValue = answer.value.floatingPoint;

  const getItemValue = (key: string, value: string) => {
    return (
      <p>
        {key} : {value}
      </p>
    );
  };

  const answersTypeConfig: Record<FormQuestionType, ReactNode> = {
    [FormQuestionType.PAGE_SELECT]: answer.value.relations?.length
      ? answer.value.relations?.map((relation) => relation.label)
      : undefined,
    [FormQuestionType.MULTIPLE_SELECT]: answer.value.stringArray,
    [FormQuestionType.DATE]:
      Boolean(answer.value.date) && formatDate(answer.value.date, { format: dateFormat }),
    [FormQuestionType.DATE_RANGE]:
      Boolean(answer.value.dateArray?.length) &&
      answer.value.dateArray[0] &&
      `${formatDate(answer.value.dateArray[0], { format: dateFormat })} ${
        answer.value.dateArray[1] &&
        `- ${formatDate(answer.value.dateArray[1], { format: dateFormat })}`
      }`,
    [FormQuestionType.DEPENDENCY]: answer?.value?.string,
    [FormQuestionType.DURATION]: answer.value.duration && getDuration(answer.value.duration),
    [FormQuestionType.YES_NO]: stringValue,
    [FormQuestionType.LIKERT_NPS]: `${integerValue}/${
      answer.task?.config?.max || MAX_LIKERT_NPS_LIMIT
    }`,
    [FormQuestionType.LIKERT_CUSTOM]: `${integerValue}/${
      answer.task?.config?.max || MAX_LIKERT_CUSTOM_LIMIT
    }`,
    [FormQuestionType.PHOTO_UPLOAD]: fileArrayValue as ReactNode,
    [FormQuestionType.VIDEO_UPLOAD]: fileArrayValue as ReactNode,
    [FormQuestionType.PRICE]: answer.value.integer ? `$${integerValue}` : `$${decimalValue}`,
    [FormQuestionType.PERCENTAGE]: answer.value.integer ? `${integerValue}%` : `${decimalValue}%`,
    [FormQuestionType.COUNT]: answer.value.integer || answer.value.floatingPoint,
    [FormQuestionType.MULTIPLE_CHOICE]: stringValue,
    [FormQuestionType.EMAIL]: stringValue,
    [FormQuestionType.PHONE]: stringValue,
    [FormQuestionType.TEXTAREA]: stringValue,
    [FormQuestionType.SHORT_ANSWER]: stringValue,
    [FormQuestionType.MULTIPLE_ALPHABETIC]: answer.value.textMutliple?.map((textMultiple) =>
      getItemValue(textMultiple.key, textMultiple.textValue)
    ),
    [FormQuestionType.MULTIPLE_NUMERICAL]: answer.value.textMutliple?.map((textMultiple) =>
      getItemValue(textMultiple.key, textMultiple.numValue)
    ),
    [FormQuestionType.MATRIX_CHOICE]: answer?.value?.matrix?.map((item) =>
      getItemValue(item.row, item.singleValue)
    ),
    [FormQuestionType.MATRIX_SELECT]: answer?.value?.matrix?.map((item) =>
      getItemValue(item.row, item.multipleValues?.join(', '))
    ),
    [FormQuestionType.MULTIPLE_PERSENTAGE_SUMM]: answer.value.multipleSumm?.map((multipleSumm) =>
      getItemValue(multipleSumm.key, multipleSumm.value)
    ),
    [FormQuestionType.MULTIPLE_NUMERICAL_SUMM]: answer.value.multipleSumm?.map((multipleSumm) =>
      getItemValue(multipleSumm.key, multipleSumm.value)
    ),
  };

  return answer.task?.config.type && answersTypeConfig[answer.task.config.type];
};
