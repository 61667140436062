import { useMemo, useState, ReactNode } from 'react';

import GetIcon from '@mui/icons-material/GetAppSharp';
import ZoomIcon from '@mui/icons-material/ZoomInRounded';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import cx from 'classnames';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Spinner } from '@shared/components';
import { showNotification } from '@shared/components/app-notification';
import { ImageView } from '@shared/components/image-view';
import { VideoView } from '@shared/components/video-view';
import { useThemeContext } from '@shared/hooks';
import { AnswerFile } from '@shared/models/submission/answer/answer-file';
import { FileExtension } from '@shared/types/file';
import { downloadFileByUrl, presignedUrlCacheQueries } from '@shared/utils/file';
import { getQueriesAsSearch } from '@shared/utils/queries';

import { FullScreenView } from './components/full-screen-view';

import { styles } from './media-value.styles';

type Props = AppWithStyles<typeof styles> & AnswerFile & {};

const MediaValueComponent = ({ classes, type, url }: Props) => {
  const $theme = useThemeContext();

  const [downloading, setDownloading] = useState(false);
  const [isFullScreenOpen, setFullScreenOpen] = useState(false);
  const [fileLoadFailed, setFileLoadFailed] = useState(false);
  const [fileLoading, setFileLoading] = useState(true);

  const handleFullScreenStateChange = () => {
    setFullScreenOpen(!isFullScreenOpen);
  };

  const handleDownload = async () => {
    const bucketUrl = `${url}${getQueriesAsSearch(presignedUrlCacheQueries())}`;

    try {
      setDownloading(true);

      await downloadFileByUrl(bucketUrl, 'Form submission media file');
    } catch (e) {
      console.error(e);
      showNotification('Something went wrong while downloading file', { type: 'error' });
    } finally {
      setDownloading(false);
    }
  };

  const handleError = () => {
    setFileLoadFailed(true);
    setFileLoading(false);
  };

  const handleLoad = () => {
    setFileLoading(false);
  };

  const element = useMemo(() => {
    const renderImage = () => {
      return (
        <ImageView
          classes={{ image: cx(classes.sourceElement, classes.image) }}
          src={url}
          loaderSize={15}
          onError={handleError}
          onLoad={handleLoad}
        />
      );
    };

    const renderVideo = () => {
      return (
        <VideoView
          loaderSize={15}
          src={url}
          classes={{
            video: cx(classes.sourceElement, classes.video),
          }}
          onError={handleError}
          onLoad={handleLoad}
        />
      );
    };

    const config: Record<FileExtension, () => ReactNode> = {
      [FileExtension.jpg]: renderImage,
      [FileExtension.gif]: renderImage,
      [FileExtension.bmp]: renderImage,
      [FileExtension.png]: renderImage,
      [FileExtension.mp4]: renderVideo,
      [FileExtension.mov]: renderVideo,
      [FileExtension.webm]: renderVideo,
    };

    return config[type]();
  }, [type]);

  const controls = () => {
    const imageFormats = [
      FileExtension.jpg,
      FileExtension.gif,
      FileExtension.bmp,
      FileExtension.png,
    ];
    const commonIconProps = {
      style: { fill: $theme.colors.primary },
      classes: { root: classes.controlIcon },
    };

    const config = [
      {
        icon: downloading ? <Spinner withWrapper size={15} /> : <GetIcon {...commonIconProps} />,
        onClick: handleDownload,
      },
      {
        icon: <ZoomIcon {...commonIconProps} />,
        onClick: handleFullScreenStateChange,
        condition: imageFormats.includes(type),
      },
    ].filter(({ condition = true }) => condition);

    return config.map((control, i) => {
      return (
        <IconButton
          key={i}
          classes={{ root: cx(classes.control, { [classes.controlDisabled]: downloading }) }}
          onClick={control.onClick}
        >
          {control.icon}
        </IconButton>
      );
    });
  };

  return (
    <Grid item classes={{ root: classes.root }}>
      <FullScreenView isOpen={isFullScreenOpen} src={url} close={handleFullScreenStateChange} />
      {element}
      {!fileLoading && !fileLoadFailed && <div className={classes.controls}>{controls()}</div>}
    </Grid>
  );
};

export const MediaValue = appWithStyles(styles)(MediaValueComponent);
