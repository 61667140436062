import { ModalBody, ModalButton, ModalFooter, Paragraph2 } from '@shared/components';
import { useModalContext } from '@shared/hocs/modal-context';

export const ResetUsersPasswordModal = (props: any) => {
  const { data } = props;

  const modalContext = useModalContext();

  const handleReset = () => {
    modalContext.setPayload(true);
    modalContext.close();
  };

  const possessiveUsers = (count: number) => {
    return count === 1 ? "user's" : "users'";
  };

  const plural = (str: string, count: number) => {
    return count === 1 ? `${str}` : `${str}s`;
  };

  let paragraph = <></>;
  let buttonText = <>Yes, Send</>;

  if (data.length === 1) {
    const name = `${data[0].fullName}'s`;
    if (data[0].lastActiveAt) {
      paragraph = (
        <>
          Are you sure you want to send an email to <b>{name}</b> with instructions for resetting
          their password?
        </>
      );
    } else {
      buttonText = <>Yes, Resend</>;
      paragraph = (
        <>
          Are you sure you want to resend <b>{name}</b> invite?
        </>
      );
    }
  } else {
    let resets = 0;
    let resends = 0;
    data.forEach((user: any) => {
      if (!!user.lastActiveAt) {
        ++resets;
      } else {
        ++resends;
      }
    });

    if (resets && resends) {
      paragraph = (
        <>
          Are you sure you want to send emails to {resets} {possessiveUsers(resets)} with
          instructions for resetting their passwords and resend {resends} {possessiveUsers(resends)}{' '}
          {plural('invite', resends)} email?
        </>
      );
    } else if (resets) {
      paragraph = (
        <>
          Are you sure you want to send emails to {resets} {possessiveUsers(resets)} with
          instructions for resetting their passwords?
        </>
      );
    } else if (resends) {
      buttonText = <>Yes, Resend</>;
      paragraph = (
        <>
          Are you sure you want to resend {resends} {possessiveUsers(resends)}{' '}
          {plural('invite', resends)}?
        </>
      );
    }
  }

  return (
    <>
      <ModalBody>
        <Paragraph2>{paragraph}</Paragraph2>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="minimal" onClick={modalContext.close}>
          Cancel
        </ModalButton>
        <ModalButton onClick={handleReset}>{buttonText}</ModalButton>
      </ModalFooter>
    </>
  );
};
