import { withStyle } from 'baseui';
import { Block } from 'baseui/block';
import { Label2 } from 'baseui/typography';

import { Switch } from '@shared/components';
import {
  Caption1,
  FlexColumnContainer,
  FlexFullContainer,
  StandardBorder,
} from '@shared/components';

const Wrapper = withStyle(FlexColumnContainer, ({ $theme }: any) => ({
  paddingTop: $theme.sizing.scale800,
  borderBottom: StandardBorder($theme),
}));

const Description = withStyle(Caption1, () => ({}));

type Props = {
  title: string;
  description: string;
  enabled: boolean;
  disabled?: boolean;
  onChange: (event: any) => void;
};

export const RoleSection = ({ title, description, enabled, disabled, onChange }: Props) => {
  return (
    <Wrapper>
      <Block paddingBottom="scale700" display="flex" flexDirection="row">
        <Label2>{title}</Label2>
        <FlexFullContainer />
        <Switch checked={enabled} disabled={disabled} onChange={onChange} />
      </Block>
      <Block paddingBottom="scale800">
        <Description>{description}</Description>
      </Block>
    </Wrapper>
  );
};
