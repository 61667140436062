import CheckIcon from '@mui/icons-material/CheckRounded';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Flex, Ellipsis } from '@shared/components';
import { useThemeContext } from '@shared/hooks';

import { styles } from './multi-select-value.styles';

type Props = AppWithStyles<typeof styles> & {
  value: Array<string>;
};

const MultiSelectValueComponent = ({ classes, value }: Props) => {
  const $theme = useThemeContext();

  return (
    <Flex
      flexDirection="column"
      style={{
        borderColor: $theme.colors.primary,
        backgroundColor: $theme.colors.primary50,
      }}
      classes={{ root: classes.root }}
    >
      {value.map((option: string) => {
        return (
          <Flex alignItems="center" key={option}>
            <CheckIcon style={{ fill: $theme.colors.primary }} />
            <Ellipsis text={option} classes={{ root: classes.value }} />
          </Flex>
        );
      })}
    </Flex>
  );
};

export const MultiSelectValue = appWithStyles(styles)(MultiSelectValueComponent);
