import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

const textareaMargin = 12;

export const AVATAR_SIZE = 28;

export const styles = () =>
  createStyles({
    root: {},
    textarea: {
      width: '100%',
    },
    textareaCreating: {
      width: `calc(100% - ${AVATAR_SIZE + textareaMargin}px)`,
      marginLeft: `${textareaMargin}px`,
    },
    controlsWrapper: {
      marginTop: spacing(3),
    },
    button: {
      textTransform: 'none',
    },
    declineButton: {
      marginRight: spacing(4),
      backgroundColor: 'transparent',
    },
  });
