import { palette } from '@core/theme/units/palette';
import { radius } from '@core/theme/units/radius';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const AVATAR_SIZE = 16;
export const USER_NAME_MARGIN = 4;
export const METADATA_FIELD_NAME_WIDTH = 81;
export const METADATA_FIELD_NAME_MARGIN = 4;

export const styles = () =>
  createStyles({
    root: {},
    title: {
      fontWeight: 600,
      fontSize: '24px',
      color: palette.text.primary,
    },
    description: {
      fontWeight: 400,
      fontSize: '14px',
      color: palette.text.primary,
      marginTop: spacing(),
    },
    status: {
      marginLeft: spacing(4),
      padding: spacing(1, 2),
      color: palette.text.secondary,
      fontSize: '12px',
      fontWeight: 600,
      backgroundColor: palette.grey[100],
      borderRadius: radius.containers.primary,
    },
    metadata: {
      marginTop: spacing(4),
    },
    userField: {
      width: `calc(100% - ${METADATA_FIELD_NAME_WIDTH + METADATA_FIELD_NAME_MARGIN}px)`,
    },
    metadataField: {
      width: '30%',
      marginRight: spacing(4),
    },
    metadataFieldName: {
      maxWidth: `${METADATA_FIELD_NAME_WIDTH}px`,
      color: palette.grey[500],
      fontSize: '12px',
      fontWeight: 400,
      marginRight: `${METADATA_FIELD_NAME_MARGIN}px`,
    },
    metadataFieldValue: {
      fontWeight: 400,
      fontSize: '12px',
      color: palette.common.black,
    },
    userName: {
      marginLeft: `${USER_NAME_MARGIN}px`,
      fontWeight: 600,
      fontSize: '12px',
      color: palette.common.black,
    },
  });
