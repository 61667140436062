import { palette } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      color: palette.text.primary,
      fontSize: '14px',
      fontWeight: 600,
    },
  });
