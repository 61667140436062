import { SVGComponent, SVGProps } from '@shared/components/SVGComponent';

type ListIconProps = Partial<SVGProps> & {};

export const ListIcon = (props: ListIconProps) => {
  return (
    <SVGComponent width={49} height={48} {...props}>
      <g clipPath="url(#clip0_5931_701)">
        <rect
          x="1.5"
          y="1"
          width="46"
          height="46"
          rx="1"
          fill="#EEEEEE"
          stroke="#AFAFAF"
          strokeWidth="2"
        />
        <rect x="9" y="8.5" width="7" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="25" y="8.5" width="11" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="25" y="14.5" width="12" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="25" y="26.5" width="9" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="25" y="18.5" width="5" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="25" y="34.5" width="7" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="25" y="38.5" width="11" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="25" y="30.5" width="15" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="25" y="22.5" width="11" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="9" y="14.5" width="11" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="9" y="26.5" width="9" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="9" y="18.5" width="7" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="9" y="30.5" width="7" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="9" y="34.5" width="9" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="9" y="38.5" width="11" height="1" rx="0.5" stroke="#AFAFAF" />
        <rect x="9" y="22.5" width="11" height="1" rx="0.5" stroke="#AFAFAF" />
      </g>
      <defs>
        <clipPath id="clip0_5931_701">
          <rect width="48" height="48" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </SVGComponent>
  );
};
