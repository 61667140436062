import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { TenantReadQuery } from '@shared/models/tenant/read-model';
import { UserProfileConfig } from '@shared/models/tenant/user-profile-config';
import { ITenantsService } from '@shared/types/services/tenants';

@injectable()
export class TenantsService extends BaseService implements ITenantsService {
  private $http = inject<HTTPClient>(HTTPClient.diToken);
  protected urlPrefix = '/tenants';

  private _currentTenant: TenantReadQuery | null = null;

  get currentTenant() {
    return this._currentTenant;
  }

  getCurrentTenant: ITenantsService['getCurrentTenant'] = async () => {
    const { data } = await this.$http.get<TenantReadQuery>(this.getUrl('current'));

    this._currentTenant = data;

    return data;
  };

  create: ITenantsService['create'] = async (tenant) => {
    const { data } = await this.$http.post<TenantReadQuery>(this.getUrl(), tenant);

    return data;
  };

  getGuest: ITenantsService['getGuest'] = async () => {
    const { data } = await this.$http.get<TenantReadQuery>(this.getUrl('guest'));

    return data;
  };

  get: ITenantsService['get'] = async (id) => {
    const { data } = await this.$http.get<TenantReadQuery>(this.getUrl(id));

    return data;
  };

  private getProfileConfigUrl = (path?: string | number) => {
    const url = '/profile-config/column';

    if (path) {
      return `${url}/${path}`;
    }

    return url;
  };

  getUserProfileConfig: ITenantsService['getUserProfileConfig'] = async () => {
    const {
      data: { userProfileConfig },
    } = await this.$http.get<{ userProfileConfig: UserProfileConfig }>(
      this.getUrl('profile-config')
    );

    return userProfileConfig;
  };

  deleteColumn: ITenantsService['deleteColumn'] = async (columnName) => {
    await this.$http.put<{ userProfileConfig: UserProfileConfig }>('profile-config/column/delete', {
      name: columnName,
    });
  };

  createUserProfileField: ITenantsService['createUserProfileField'] = async (field) => {
    await this.$http.post(this.getProfileConfigUrl('create'), field);
  };

  updateUserProfileConfig: ITenantsService['updateUserProfileConfig'] = async (data) => {
    await this.$http.patch(
      this.getProfileConfigUrl('update/bulk'),
      Array.isArray(data) ? data : [data]
    );
  };

  updateColumnOptions: ITenantsService['updateColumnOptions'] = async (options) => {
    await this.$http.patch(this.getProfileConfigUrl('values/rename'), options);
  };

  updateTenant: ITenantsService['updateTenant'] = async (
    tenantId: TenantReadQuery['id'],
    params
  ): Promise<TenantReadQuery> => {
    const { data } = await this.$http.patch<TenantReadQuery>(this.getUrl(tenantId), params);

    return data;
  };
}
