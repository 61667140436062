import { io } from 'socket.io-client';

import { inject, injectable } from '@core/di/di-utils';
import { BaseService } from '@core/services/base-service';
import { Config } from '@core/сonfig';

import { DI_TOKENS } from '@shared/constants/di';
import { IConfig } from '@shared/types/config';
import { IAuthService } from '@shared/types/services/auth';
import { INotificationsService } from '@shared/types/services/notifications';

@injectable()
export class NotificationsService extends BaseService implements INotificationsService {
  private config = inject<IConfig>(DI_TOKENS.config);
  private authService = inject<IAuthService>(DI_TOKENS.authService);

  createSocket: INotificationsService['createSocket'] = () => {
    return io(this.config.getApiBaseURL(), {
      secure: true,
      path: '/websockets/notifications',
      transports: ['websocket', 'polling'],
      auth: {
        token: this.authService.tokens.access,
      },
    });
  };
}
