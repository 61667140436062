import { palette } from '@core/theme/units/palette';
import { radius } from '@core/theme/units/radius';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      padding: spacing(1, 3),
      display: 'inline-flex',
      alignItems: 'center',
    },
    outlinedRoot: {
      border: `1px solid ${palette.grey[200]}`,
      borderRadius: radius.containers.primary,
    },
    containedRoot: {
      backgroundColor: palette.grey[50],
      borderRadius: radius.containers.secondary,
    },
    content: {
      fontSize: '16px',
      fontWeight: 600,
      color: palette.text.primary,
    },
    deleteButton: {
      marginLeft: spacing(2),
    },
    deleteIcon: {
      fontSize: '12px',
    },
  });
