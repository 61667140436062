import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {},
    reactionOption: {
      height: '24px',
      padding: spacing(0, 1),
      margin: spacing(0, 2, 2, 0),
    },
    reactionEmoji: {
      color: '#DD2E44',
    },
  });
