import { injectable } from '@core/di/di-utils';
import { inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { CommentListModel, CommentListQuery } from '@shared/models/comment/list-model';
import { CommentReadModel, CommentReadQuery } from '@shared/models/comment/read-model';
import { PaginatedResponse } from '@shared/types/services';
import { ICommentsService } from '@shared/types/services/comments';

@injectable()
export class CommentsService extends BaseService implements ICommentsService {
  private $http = inject<HTTPClient>(HTTPClient.diToken);

  protected urlPrefix = '/comments';

  getList: ICommentsService['getList'] = async (params) => {
    const {
      data: { comments, total },
    } = await this.$http.post<PaginatedResponse<{ comments: Array<CommentListQuery> }>>(
      this.getUrl('list'),
      {
        owner: params.owner,
        parent: params.parentId,
        pagination: params.pagination,
      }
    );

    return {
      total,
      comments: comments.map((story) => new CommentListModel(story)),
    };
  };

  create: ICommentsService['create'] = async (args) => {
    const { data } = await this.$http.post<CommentReadQuery>(this.getUrl(), {
      owner: args.owner,
      parent: args.parentId,
      body: args.body,
    });

    return new CommentReadModel(data);
  };

  edit: ICommentsService['edit'] = async (id, args) => {
    const { data } = await this.$http.patch<CommentReadQuery>(this.getUrl(id), args);

    return new CommentReadModel(data);
  };

  delete: ICommentsService['delete'] = async (id) => {
    await this.$http.delete(this.getUrl(id));
  };

  addReaction: ICommentsService['addReaction'] = async (commentId, reactions) => {
    const { data } = await this.$http.post<CommentReadQuery>(
      this.getUrl(`${commentId}/reactions`),
      reactions
    );

    return new CommentReadModel(data);
  };

  deleteReaction: ICommentsService['deleteReaction'] = async (commentId, reactionId) => {
    const { data } = await this.$http.delete<CommentReadQuery>(
      this.getUrl(`${commentId}/reactions/${reactionId}`)
    );

    return new CommentReadModel(data);
  };

  getCommentsCount: ICommentsService['getCommentsCount'] = async (owner) => {
    const { data } = await this.$http.get<number>(this.getUrl('count'), {
      params: {
        ownerId: owner.id,
        ownerType: owner.type,
      },
    });

    return data;
  };
}
