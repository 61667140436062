import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Textarea, TextAreaProps } from '@shared/components/Textarea';

import { styles } from './input.styles';

type Props = AppWithStyles<typeof styles> &
  Pick<TextAreaProps, 'value' | 'onBlur' | 'onFocus' | 'onChange' | 'placeholder'> & {};

const InputComponent = ({
  classes,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder = 'Write a comment...',
}: Props) => {
  return (
    <Textarea
      autoSize
      size="mini"
      placeholder={placeholder}
      name={placeholder}
      value={value}
      classes={{ root: classes.root }}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export const Input = appWithStyles(styles)(InputComponent);
