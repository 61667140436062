import { ReactNode } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Ellipsis } from '@shared/components';

import { styles } from './error-text.styles';

type Props = AppWithStyles<typeof styles> & {
  text?: ReactNode;
};

const ErrorTextComponent = ({ classes, text }: Props) => {
  return <Ellipsis classes={{ root: classes.root }} text={text} />;
};

export const ErrorText = appWithStyles(styles)(ErrorTextComponent);
