import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { GroupListModel, GroupListQuery } from '@shared/models/group/list-model';
import { InsightListDTO } from '@shared/models/insight/list-model';
import { RoleListQuery } from '@shared/models/role/list-model';
import {
  UserCollaborationListModel,
  UserCollaborationListQuery,
} from '@shared/models/user/collaboration-model';
import { UserListQuery, UserListModel } from '@shared/models/user/list-model';
import { UserReadQuery, UserReadModel } from '@shared/models/user/read-model';
import { UserEmailListModel, UserEmailListQuery } from '@shared/models/user/user-email-list';
import { InsightViewType } from '@shared/types/globalTypes';
import { PaginatedResponse } from '@shared/types/services';
import { IUsersService, UserGetListByEmail } from '@shared/types/services/users';

@injectable()
export class UsersService extends BaseService implements IUsersService {
  private $http = inject<HTTPClient>(HTTPClient.diToken);
  protected urlPrefix = '/users';

  activateUsers: IUsersService['activateUsers'] = async (ids) => {
    const { data } = await this.$http.patch<UserReadQuery>(this.getUrl('activate'), {
      ids: Array.isArray(ids) ? ids : [ids],
    });

    return new UserReadModel(data);
  };

  deactivateUsers: IUsersService['deactivateUsers'] = async (ids) => {
    const { data } = await this.$http.patch<UserReadQuery>(this.getUrl('deactivate'), {
      ids: Array.isArray(ids) ? ids : [ids],
    });

    return new UserReadModel(data);
  };

  deleteUsers: IUsersService['deleteUsers'] = async (ids) => {
    const { data } = await this.$http.put<UserReadQuery>(this.getUrl('delete'), {
      ids: Array.isArray(ids) ? ids : [ids],
    });

    return new UserReadModel(data);
  };

  changePassword: IUsersService['changePassword'] = async (userId, passwords) => {
    await this.$http.put(this.getUrl(`${userId}/password`), passwords);
  };

  createUser: IUsersService['createUser'] = async (user) => {
    await this.$http.post(this.getUrl(), user);
  };

  createUsers: IUsersService['createUsers'] = async (users) => {
    await this.$http.post(this.getUrl('bulk'), users);
  };

  inviteUsers: IUsersService['inviteUsers'] = async (emails) => {
    const query = `
      mutation ProgramUsersCreateSendInviteMutation($input: [String!]!) {
        requestInvite(emails: $input)
      }
    `;

    await this.$http.post('api', {
      query,
      variables: {
        input: Array.isArray(emails) ? emails : [emails],
      },
    });
  };

  resetPassword: IUsersService['resetPassword'] = async (emails) => {
    const query = `
      mutation AdminUsersListOptionsPopoverRequestPasswordResetMutation($emails: [String!]!) {
        requestPasswordReset(emails: $emails)
      }
    `;

    await this.$http.post('api', {
      query,
      variables: {
        emails: Array.isArray(emails) ? emails : [emails],
      },
    });
  };

  getCurrentUser: IUsersService['getCurrentUser'] = async () => {
    const { data } = await this.$http.get<UserReadQuery>(this.getUrl('me'));

    return new UserReadModel(data);
  };

  getUser: IUsersService['getUser'] = async (id) => {
    const { data } = await this.$http.get<UserReadQuery>(this.getUrl(id));

    return new UserReadModel(data);
  };

  updateUser: IUsersService['updateUser'] = async (userId, data) => {
    await this.$http.patch(`/profile-config/${userId}/update/bulk`, data);
  };

  getUsers: IUsersService['getUsers'] = async (params) => {
    const normalizedParams = params && {
      limit: params.pagination.limit,
      skip: params.pagination.skip,
      sort: params.sort,
      search: params.filters?.search,
    };

    const {
      data: { total, users },
    } = await this.$http.post<PaginatedResponse<{ users: Array<UserListQuery> }>>(
      this.getUrl('profile-configs'),
      normalizedParams
    );

    return {
      total,
      users: users.map((user) => new UserListModel(user)),
    };
  };

  async getUsersByEmail(params: UserGetListByEmail) {
    const queryData = {
      email: params.email,
      ...(params.limit && { limit: params.limit }),
      ...(params.skip && { skip: params.skip }),
    };

    const {
      data: { items },
    } = await this.$http.get<{ items: UserEmailListQuery[] }>(this.getUrl(), {
      params: queryData,
    });

    return items.map((item) => new UserEmailListModel(item));
  }

  getUsersCollaborationDictionary: IUsersService['getUsersCollaborationDictionary'] = async (
    params
  ) => {
    const normalizedParams = {
      limit: params.pagination.limit,
      skip: params.pagination.skip,
      sort: params.sort,
      search: params.filters?.search,
    };

    const {
      data: { total, users },
    } = await this.$http.post<PaginatedResponse<{ users: Array<UserCollaborationListQuery> }>>(
      this.getUrl('collaboration-pages'),
      normalizedParams
    );

    return {
      total,
      users: users.map((user) => new UserCollaborationListModel(user)),
    };
  };

  async uploadAvatar(file: File) {
    const query = `
      mutation FileUploaderGetSignedRequestFromS3($name: String!, $type: String!, $folder: String!) {
          getSignedRequestFromS3(name: $name, type: $type, folder: $folder)
      }
    `;

    try {
      const {
        data: { getSignedRequestFromS3 },
      } = await this.$http.post<{ getSignedRequestFromS3: string }>('/api', {
        query,
        variables: {
          name: file.name,
          type: file.type,
          folder: 'pages-covers',
        },
      });

      const response = JSON.parse(getSignedRequestFromS3);

      await this.$http.createInstance().put(response.signedRequest, file);

      return response.url;
    } catch (err) {
      throw err;
    }
  }

  getRoles: IUsersService['getRoles'] = async () => {
    const query = `
      query ProgramUsersRolesPageQuery {
        roles {
          id
          name
        }
      }
    `;

    const {
      data: { roles },
    } = await this.$http.post<{ roles: Array<RoleListQuery> }>('/api', {
      query,
    });

    return roles;
  };

  getUserGroups = async (id: UserReadQuery['id']) => {
    const { data } = await this.$http.get<Array<GroupListQuery>>(this.getUrl(`${id}/groups`));

    return data.map((group) => new GroupListModel(group));
  };

  sendUserTimeZone = async (timezone: string) => {
    await this.$http.patch(this.getUrl('me/timezone'), { timezone });
  };

  getInsightViews = async (id: UserReadQuery['id'], type: InsightViewType) => {
    const { data } = await this.$http.get<Array<InsightListDTO>>(
      this.getUrl(`${id}/insight-views`),
      {
        params: {
          type,
        },
      }
    );

    return data;
  };
}
