import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      width: '24px',
      height: '24px',
      padding: spacing(),
    },
    icon: {
      width: '16px',
      height: '16px',

      '& > path': {
        fill: palette.common.black,
      },
    },
    iconDisabled: {
      '& > path': {
        fill: palette.text.disabled,
      },
    },
    actionButton: {
      width: '100%',
      display: 'block',
      borderRadius: '0px',
      color: palette.common.black,
      background: palette.background.paper,

      '&:hover': {
        background: palette.grey[100],
      },
    },
  });
