import { useState } from 'react';

import { DateTime } from 'luxon';

import {
  Block,
  FlexRowContainer,
  LoadingWrapper,
  DatePicker,
  Icon,
  Timepicker,
} from '@shared/components';
import { formatMMDDYYYYDate } from '@shared/utils/date';

const START_DATE = DateTime.local().set({ minute: 15 }).set({ second: 0 }).toJSDate();
const END_DATE = DateTime.local()
  .plus({ days: 4 })
  .set({ minute: 15 })
  .set({ second: 0 })
  .plus({ hours: 4 })
  .toJSDate();

const formatDateAtIndex = (dates: Date | Date[], index: number) => {
  if (!dates || !Array.isArray(dates)) return '';

  const date = dates[index];

  if (!date) return '';

  return formatMMDDYYYYDate(date);
};

type Props = {
  value: [Date, Date];
  onChange: (d: [Date, Date]) => void;
  withTime?: boolean;
};

export const DateRangeInput = ({ value, withTime = true, onChange }: Props) => {
  const [dates, setDates] = useState<[Date, Date]>(value || [START_DATE, END_DATE]);

  const handleUpdate = (dates: [Date, Date]) => {
    setDates(dates);
    if (onChange) {
      onChange(dates);
    }
  };

  return (
    <FlexRowContainer>
      <FlexRowContainer>
        <Block marginRight="scale300">
          <DatePicker
            value={dates}
            onChange={({ date }: any) => handleUpdate(date)}
            formatDisplayValue={(date: Date | Date[]) => formatDateAtIndex(date, 0)}
            timeSelectStart
            range
          />
        </Block>
        {withTime ? (
          <Block width="120px">
            <Timepicker value={dates[0]} onChange={(time: any) => handleUpdate([time, dates[1]])} />
          </Block>
        ) : null}
      </FlexRowContainer>
      <LoadingWrapper style={{ margin: '0 8px' }}>
        <Icon size={24} color="mono600" icon="RegularArrowAltRight" />
      </LoadingWrapper>
      <FlexRowContainer>
        <Block marginRight="scale300">
          <DatePicker
            value={dates}
            onChange={({ date }: any) => handleUpdate(date)}
            formatDisplayValue={(date: Date | Date[]) => formatDateAtIndex(date, 1)}
            timeSelectStart
            range
          />
        </Block>
        {withTime ? (
          <Block width="120px">
            <Timepicker value={dates[1]} onChange={(time: any) => handleUpdate([dates[0], time])} />
          </Block>
        ) : null}
      </FlexRowContainer>
    </FlexRowContainer>
  );
};
