type FormQuestionConfigDTO = {
  options: Array<string>;
  type: FormQuestionType;
  allowDecimals?: boolean;
  canSelectMultiple?: boolean;
  changesAreDisabled?: boolean;
  choicesAreRandomized?: boolean;
  interval?: number;
  max?: number;
  min?: number;
  pageSelections?: Array<string>;
  groupSelections?: Array<string>;
  pageType?: string;
  pageTypeColumn?: string;
  required?: boolean;
  fixedSumm?: string;
  dependency?: {
    dependencyTaskId: string;
    condition: boolean;
  };
  matrix?: {
    columns: Array<string>;
    rows: Array<string>;
  };
};

export type FormQuestionDTO = {
  id: string;
  name: string;
  required: boolean;
  description?: string;
  hasTimeTracking?: boolean;
  config?: FormQuestionConfigDTO;
};

export enum FormQuestionType {
  COUNT = 'COUNT',
  DATE_RANGE = 'DATE_RANGE',
  DEPENDENCY = 'DEPENDENCY',
  DURATION = 'DURATION',
  VIDEO_UPLOAD = 'VIDEO_UPLOAD',
  PAGE_SELECT = 'PAGE_SELECT',
  SHORT_ANSWER = 'SHORT_ANSWER',
  YES_NO = 'YES_NO',
  LIKERT_NPS = 'LIKERT_NPS',
  LIKERT_CUSTOM = 'LIKERT_CUSTOM',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
  PERCENTAGE = 'PERCENTAGE',
  PRICE = 'PRICE',
  DATE = 'DATE',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  PHOTO_UPLOAD = 'PHOTO_UPLOAD',
  TEXTAREA = 'TEXTAREA',
  MULTIPLE_ALPHABETIC = 'MULTIPLE_ALPHABETIC',
  MULTIPLE_NUMERICAL = 'MULTIPLE_NUMERICAL',
  MATRIX_CHOICE = 'MATRIX_CHOICE',
  MATRIX_SELECT = 'MATRIX_SELECT',
  MULTIPLE_PERSENTAGE_SUMM = 'MULTIPLE_PERSENTAGE_SUMM',
  MULTIPLE_NUMERICAL_SUMM = 'MULTIPLE_NUMERICAL_SUMM',
}
