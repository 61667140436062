import { DiEntityIdentifier } from '@core/di/types';

function createTokens<Key extends string>(x: Record<Key, DiEntityIdentifier>) {
  return x;
}

export const DI_TOKENS = createTokens({
  authService: Symbol('auth-service'),
  baseService: Symbol('base-service'),
  config: Symbol('config'),
  httpClient: Symbol('http-client'),
  heapService: Symbol('heap-service'),
  storageService: Symbol('storage-service'),
  scheduleService: Symbol('schedule-service'),
  usersService: Symbol('users-service'),
  tenantsService: Symbol('tenants-service'),
  groupsService: Symbol('groups-service'),
  rolesService: Symbol('roles-service'),
  insightsService: Symbol('insights-service'),
  submissionsService: Symbol('submissions-service'),
  insightsExploreService: Symbol('insights-explore-service'),
  insightsActivityService: Symbol('insights-activity-service'),
  insightsAnalyzeService: Symbol('insights-analyze-service'),
  commentsService: Symbol('comments-service'),
  notesService: Symbol('notes-service'),
  headlinesService: Symbol('headlines-service'),
  storiesService: Symbol('stories-service'),
  reactionsViewModel: Symbol('reactions-view-model'),
  eventsService: Symbol('events-service'),
  notificationsService: Symbol('notifications-service'),
  notificationsViewModel: Symbol('notifications-view-model'),
  pagesService: Symbol('pages-service'),
  pagesImportService: Symbol('pages-import-service'),
  pageTypeService: Symbol('pages-type-service'),
  formsService: Symbol('forms-service'),
  dictionariesService: Symbol('dictionaries-service'),
  promptsService: Symbol('prompts-service'),
  inventoryService: Symbol('inventory-service'),
});
