import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { AppLink } from '@shared/components/app-link';
import { Flex } from '@shared/components/Flex';
import { SUBMISSION_STATUS_LABELS } from '@shared/constants/insights-activity';
import { SubmissionActivityLog } from '@shared/models/submission/activity-log';
import { AssignmentSubmissionActivityLogEntryType } from '@shared/types/globalTypes';
import { formatDate } from '@shared/utils/date';
import { ROUTES } from '@shared/utils/getRoute';

import { styles } from './item.styles';

type ItemProps = AppWithStyles<typeof styles> & {
  data: SubmissionActivityLog;
};

const ItemComponent = ({ classes, data }: ItemProps) => {
  const content = () => {
    const config: Record<AssignmentSubmissionActivityLogEntryType, string> = {
      [AssignmentSubmissionActivityLogEntryType.SUBMISSION_STARTED]: 'started form',
      [AssignmentSubmissionActivityLogEntryType.SUBMISSION_IN_REVIEW]: 'completed form',
      [AssignmentSubmissionActivityLogEntryType.SUBMISSION_SENT_BACK]: 'sent back form',
      [AssignmentSubmissionActivityLogEntryType.SUBMISSION_STATUS_CHANGED]: `changed form status to ${
        SUBMISSION_STATUS_LABELS[data.value] || data.value
      }`,
      [AssignmentSubmissionActivityLogEntryType.SUBMISSION_CLOSED]: 'closed form',
    };

    return (
      <Flex justifyContent="space-between">
        <Flex flexWrap="wrap" className={classes.value}>
          <AppLink to={ROUTES.userProfile(data.user.id)} className={classes.userName}>
            {`${data.user.firstName} ${data.user.lastName}`}
          </AppLink>
          <h5 className={classes.message}>{config[data.type] || 'had activity'}</h5>
        </Flex>
        <h5 className={classes.date}>{formatDate(data.at, { format: 'lll' })}</h5>
      </Flex>
    );
  };

  return <div className={classes.root}>{content()}</div>;
};

export const Item = appWithStyles(styles)(ItemComponent);
