import { forwardRef, Ref, ReactNode, Component } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Input as BaseInput, InputProps, InternalState, StatefulInputProps } from 'baseui/input';
import { StatefulInput as BaseStatefulInput } from 'baseui/input';
import cx from 'classnames';
import { Field } from 'mobx-react-form';

import { appObserver } from '@core/state-management/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Field as FieldComponent } from '@shared/components/Field';
import { getFieldBindings, getFieldMaxLength } from '@shared/utils/form';

import { styles } from './Input.styles';

export { SIZE as InputSIZE } from 'baseui/input';

type IInputProps = InputProps & InternalState;

export const Input = forwardRef((props: IInputProps, ref: Ref<HTMLInputElement>) => {
  return (
    <BaseInput
      inputRef={ref}
      overrides={{
        Root: { style: { minHeight: '40px' } },
        ...(props.overrides || {}),
      }}
      {...props}
    />
  );
});

export const StatefulInput = (props: StatefulInputProps) => {
  return <BaseStatefulInput {...props} />;
};

export type TextFieldProps = InputProps &
  AppWithStyles<typeof styles> & {
    name: string;
    className?: string;
    label?: ReactNode;
    field?: Field;
    errorText?: string;
    optional?: boolean;
    withLengthCounter?: boolean;
  };

class TextFieldComponent extends Component<TextFieldProps> {
  private handleBlur = (e) => {
    const { field, onBlur } = this.props;
    const { value } = e.target;
    const normalizedValue = value.trim();

    e.target.value = normalizedValue;
    e.currentTarget.value = normalizedValue;

    if (onBlur) {
      onBlur(e);
    }

    if (field) {
      field.set('value', normalizedValue);
      field.onBlur(e);
    }
  };

  private handleChange = (e) => {
    const { field, onChange } = this.props;

    onChange?.(e);

    if (field) {
      field.onChange(e);
    }
  };

  private getVisibilityIcon = () => {
    const { classes } = this.props;

    return <VisibilityIcon className={classes.visibilityIcon} />;
  };

  private getVisibilityOffIcon = () => {
    const { classes } = this.props;

    return <VisibilityOffIcon className={classes.visibilityIcon} />;
  };

  render() {
    const {
      label,
      field,
      errorText,
      error,
      classes,
      optional,
      className,
      withLengthCounter,
      overrides,
      ...otherProps
    } = this.props;
    const inputErrorText = errorText || field?.error;
    const hasError = Boolean(error || field?.error);
    const bindings = getFieldBindings(field);
    const maxLength = field && getFieldMaxLength(field.rules);
    const inputOverrides = overrides?.Input as { props: ObjectLike };

    return (
      <FieldComponent
        label={label}
        optional={optional}
        hasError={hasError}
        errorText={inputErrorText}
        classes={{ root: cx(classes.root, className) }}
      >
        <BaseInput
          {...bindings}
          {...otherProps}
          overrides={{
            MaskToggleHideIcon: this.getVisibilityIcon,
            MaskToggleShowIcon: this.getVisibilityOffIcon,
            ...overrides,
            Input: {
              props: {
                ...inputOverrides?.props,
              },
            },
          }}
          error={hasError}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
        {withLengthCounter && (
          <span
            className={cx(classes.lengthCounter, {
              [classes.lengthCounterError]: maxLength && field.value.length > maxLength,
            })}
          >
            {field.value.length}/{maxLength}
          </span>
        )}
      </FieldComponent>
    );
  }
}

export const TextField = appWithStyles(styles)(appObserver(TextFieldComponent));
