import { BaseModel } from '@core/base-model';

import { PageQueryDTO } from '@shared/models/pages/page-query-model';

import { EventLocation } from './location';
import { EventGroup, EventParticipant } from './participant';

import { EventTypeListQuery } from '../event-type/list-model';

export interface EventReadQuery {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  notes?: string;
  eventType?: EventTypeListQuery['name'];
  location?: EventLocation;
  isFullDay: boolean;
  organizer: EventParticipant;
  users: Array<EventParticipant>;
  groups: Array<EventGroup>;
  pages?: Array<PageQueryDTO['id']>;
}

export class EventReadModel extends BaseModel<EventReadQuery> {
  private id: string;
  private name: string;
  private startDate: string;
  private endDate: string;
  private notes?: string;
  private eventType?: EventTypeListQuery['name'];
  private location?: EventLocation;
  private isFullDay: boolean;
  private organizer: EventParticipant;
  private users: Array<EventParticipant>;
  private groups: Array<EventGroup>;
  private pages?: Array<PageQueryDTO['id']>;

  constructor(data: EventReadQuery) {
    super();

    this.update(data);
  }

  get asJson(): EventReadQuery {
    return {
      id: this.id,
      name: this.name,
      startDate: this.startDate,
      endDate: this.endDate,
      eventType: this.eventType,
      notes: this.notes,
      location: this.location,
      isFullDay: this.isFullDay,
      organizer: this.organizer,
      users: this.users,
      groups: this.groups,
      pages: this.pages,
    };
  }
}
