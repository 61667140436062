import { palette } from '@core/theme/units/palette';
import { radius } from '@core/theme/units/radius';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

const sectionsMargin = 24;
const answersWrapperMargin = '16px';
const statusSelectWrapperWidth = 176;
const statusSelectWrapperMargin = 8;

export const styles = () =>
  createStyles({
    root: {},
    sectionsWrapper: {
      height: '100%',
      width: '100%',
    },
    headerTitleWrapper: {
      width: `calc(100% - ${statusSelectWrapperWidth + statusSelectWrapperMargin}px)`,
    },
    mainInfo: {
      width: `calc(100% - ${sectionsMargin * 2}px)`,
      height: '100%',
      margin: `0px ${sectionsMargin}px`,
      overflow: 'auto',
    },
    answersWrapper: {
      height: '100%',
      width: `calc(65% - ${answersWrapperMargin})`,
      marginRight: answersWrapperMargin,
      backgroundColor: palette.common.white,
      borderRadius: radius.containers.primary,
      border: `1px solid ${palette.grey[200]}`,
    },
    formMetadata: {
      padding: spacing(6),
      borderBottom: `1px solid ${palette.grey[200]}`,
    },
    answer: {
      margin: spacing(8, 6, 0),
      paddingBottom: spacing(8),
      borderBottom: `1px solid ${palette.grey[200]}`,

      '&:last-of-type': {
        borderBottom: 'none',
      },
    },
    activityLog: {
      width: '35%',
      padding: spacing(4),
    },
    loading: {
      height: '480px',
      width: '100%',
    },
    emptyState: {
      height: '480px',
      width: '100%',
    },
    emptyStateIcon: {
      width: '40px',
      height: '48px',
    },
    emptyStateHeading: {
      margin: spacing(4, 0, 0),
    },
    statusSelectWrapper: {
      marginLeft: `${statusSelectWrapperMargin}px`,
    },
    statusLabel: {
      fontSize: '12px',
      fontWeight: 400,
      color: palette.grey[500],
      marginRight: spacing(2),
    },
    statusSelect: {
      width: '128px',
      marginRight: spacing(3),
    },
  });
