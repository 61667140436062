import { palette } from '@core/theme/units/palette';
import { radius } from '@core/theme/units/radius';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const ORDER_SIZE = 24;
export const ORDER_MARGIN = 8;

export const styles = () => {
  const commentsButtonWidth = '50px';

  return createStyles({
    root: {},
    order: {
      backgroundColor: '#E2E2E2',
      color: palette.text.primary,
      borderRadius: radius.containers.round,
      width: `${ORDER_SIZE}px`,
      height: `${ORDER_SIZE}px`,
      marginRight: `${ORDER_MARGIN}px`,
      fontSize: '12px',
      fontWeight: 600,
      padding: spacing(),
    },
    header: {
      width: '100%',
      marginBottom: spacing(),
    },
    taskNameWrapper: {
      width: `calc(100% - ${commentsButtonWidth})`,
    },
    taskName: {
      color: palette.text.primary,
      fontSize: '14px',
      fontWeight: 600,
    },
    taskDescription: {
      marginLeft: `${ORDER_SIZE + ORDER_MARGIN}px`,
      fontSize: '12px',
      fontWeight: 400,
      color: palette.grey[500],
    },
    answerWrapper: {
      maxWidth: `calc(100% - ${ORDER_SIZE + ORDER_MARGIN}px)`,
      marginLeft: `${ORDER_SIZE + ORDER_MARGIN}px`,
      marginTop: spacing(3),
    },
    valueWrapper: {
      maxWidth: '100%',
      padding: spacing(1, 4),
      border: '1px solid',
      borderRadius: radius.containers.primary,
    },
    defaultValue: {
      color: palette.common.black,
      fontWeight: 600,
      fontSize: '18px',
      padding: spacing(),
    },
    mediaComponent: {
      width: '256px',
      height: '160px',
    },
    emptyState: {
      padding: spacing(1, 4),
      backgroundColor: palette.grey[50],
      border: `1px solid ${palette.grey[200]}`,
      borderRadius: radius.containers.primary,
      color: '#AFAFAF',
      fontSize: '18px',
      fontWEight: 600,
    },
    countEmptyState: {
      padding: spacing(1, 4),
      backgroundColor: 'rgba(0, 104, 179, 0.08)',
      border: `1px solid ${palette.primary.light}`,
      borderRadius: radius.containers.primary,
      color: palette.common.black,
      fontSize: '18px',
      fontWEight: 600,
    },
    commentsButtonWrapper: {
      height: '20px',
    },
    commentsButton: {
      width: commentsButtonWidth,
    },
    commentButtonDisabled: {
      backgroundColor: 'transparent !important',
    },
    commentsIcon: {
      width: '16px',
      height: '16px',
    },
    comments: {
      padding: spacing(8, 8, 0),
    },
  });
};
