import { ReactNode } from 'react';

import { Button, ButtonProps } from 'src/shared/components/button';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Flex } from '@shared/components/Flex';

import { styles } from './Message.styles';

export type MessageConfig = {
  style?: CSSRules;
  heading?: ReactNode;
  subheading?: ReactNode;
  icon?: ReactNode;
  buttonProps?: Partial<ButtonProps>;
};

export type MessageProps = AppWithStyles<typeof styles> & MessageConfig & {};

const MessageComponent = ({
  classes,
  heading,
  icon,
  subheading,
  buttonProps,
  style,
}: MessageProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      style={style}
      classes={{ root: classes.root }}
    >
      <div className={classes.content}>
        {icon && (
          <Flex justifyContent="center" classes={{ root: classes.icon }}>
            {icon}
          </Flex>
        )}
        {heading && (
          <Flex justifyContent="center">
            <h4 className={classes.heading}>{heading}</h4>
          </Flex>
        )}
        {subheading && (
          <Flex justifyContent="center">
            <h4 className={classes.subheading}>{subheading}</h4>
          </Flex>
        )}
        {buttonProps && (
          <Flex justifyContent="center">
            <div className={classes.button}>
              <Button kind="primary" {...buttonProps} />
            </div>
          </Flex>
        )}
      </div>
    </Flex>
  );
};

export const Message = appWithStyles(styles)(MessageComponent);
