import { BaseModel } from '@core/base-model';

import { TenantReadQuery } from '@shared/models/tenant/read-model';

export interface UserEmailListQuery {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  isDeactivated: boolean;
  lastActiveAt: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  nickName: string;
  fullName: string;
  profileUrl: string;
  tenant: TenantReadQuery;
  profileFields: { [key: string]: { value: string } };
}

export class UserEmailListModel extends BaseModel<UserEmailListQuery> {
  private id: string;
  private createdAt: string;
  private updatedAt: string;
  private deletedAt: string;
  private isDeactivated: boolean;
  private lastActiveAt: string;
  private email: string;
  private phone: string;
  private firstName: string;
  private lastName: string;
  private nickName: string;
  private fullName: string;
  private profileUrl: string;
  private tenant: TenantReadQuery;
  private profileFields: { [key: string]: { value: string } };

  constructor(dto: UserEmailListQuery) {
    super();

    this.update(dto);
  }

  get asJson(): UserEmailListQuery {
    return {
      id: this.id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      isDeactivated: this.isDeactivated,
      lastActiveAt: this.lastActiveAt,
      email: this.email,
      phone: this.phone,
      firstName: this.firstName,
      lastName: this.lastName,
      nickName: this.nickName,
      fullName: this.fullName,
      profileUrl: this.profileUrl,
      tenant: this.tenant,
      profileFields: this.profileFields,
    };
  }
}
