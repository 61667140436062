import { useMemo, ReactNode, SyntheticEvent, useCallback } from 'react';

import { StatefulPopover, BasePopoverProps } from 'baseui/popover';
import cx from 'classnames';
import { Button } from 'src/shared/components/button';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { MoreIcon } from '@shared/icons/more';

import { styles } from './dropdown-menu.styles';

export type DropDownMenuItem = {
  name: ReactNode;
  className?: string;
  onClick: (e: SyntheticEvent) => void;
};

type DropDownMenuIconOrientation = 'horizontal' | 'vertical';

type DropDownMenuProps = AppWithStyles<typeof styles> & {
  title?: ReactNode;
  iconOrientation?: DropDownMenuIconOrientation;
  items: Array<DropDownMenuItem>;
  icon?: ReactNode;
  placement?: BasePopoverProps['placement'];
  disabled?: boolean;
};

const DropdownMenuComponent = ({
  title,
  items,
  classes,
  icon,
  iconOrientation = 'horizontal',
  placement,
  disabled,
}: DropDownMenuProps) => {
  const content = useCallback(
    ({ close }: { close: () => void }) => {
      return (
        <div>
          {items.map(({ name, onClick, className }, i) => {
            const onButtonClick = (e) => {
              onClick(e);
              close();
            };

            return (
              <Button
                key={i}
                kind="minimal"
                className={cx(classes.actionButton, className)}
                onClick={onButtonClick}
              >
                {name}
              </Button>
            );
          })}
        </div>
      );
    },
    [classes, items]
  );

  const actionIcon = useMemo(() => {
    if (icon) {
      return icon;
    }

    return (
      <MoreIcon
        orientation={iconOrientation}
        classes={{ root: cx(classes.icon, { [classes.iconDisabled]: disabled }) }}
      />
    );
  }, [icon, iconOrientation, disabled]);

  return (
    <StatefulPopover autoFocus content={content} placement={placement}>
      <Button
        kind="minimal"
        className={classes.root}
        text={title || actionIcon}
        endEnhancer={title && actionIcon}
        disabled={disabled}
      />
    </StatefulPopover>
  );
};

export const DropdownMenu = appWithStyles(styles)(DropdownMenuComponent);
