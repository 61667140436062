import { palette } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      width: 'auto',
      color: palette.text.primary,
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '16px',
    },
    icon: {
      color: palette.text.primary,
    },
  });
