import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const footerStyles = () =>
  createStyles({
    root: {
      borderTop: `1px solid ${palette.grey[200]}`,
      padding: spacing(4, 4),
      width: '100%',

      '& > *': {
        marginLeft: spacing(3),
      },

      '& > button': {
        height: 48,
      },
    },
  });
