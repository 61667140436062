import { Form } from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs, { ValidatorStatic } from 'validatorjs';

import { isAfter, isBefore } from '@shared/utils/date';

interface Validation {
  function: (value: any, attribute?: string) => boolean;
  message: string;
}

const validationRegExpr = {
  oneLowerCase: /[a-z]/,
  oneUpperCase: /[A-Z]/,
  oneDigital: /[0-9]/,
  // eslint-disable-next-line
  specialChar: /[!@#$%^&*()+=_\-{}\[\]|:;“’?/<>,.]/,
  // eslint-disable-next-line
  specialAndDigitChar: /[0-9!@#$%^&*()+=_\-{}\[\]|:;“’?/<>,.]/,
  minimumLength: /^.{7,}$/,
  whiteSpace: /^(?![\s]).*[\S]+$/,
  // eslint-disable-next-line
  url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
};

function createValidations<ClassKey extends string>(x: Record<ClassKey, Validation>) {
  return x;
}

export const baseValidations = createValidations({
  passwordStrength: {
    function(value) {
      const formatValidators = [
        validationRegExpr.oneLowerCase,
        validationRegExpr.oneUpperCase,
        validationRegExpr.oneDigital,
        validationRegExpr.specialChar,
        validationRegExpr.minimumLength,
        validationRegExpr.whiteSpace,
      ];

      return formatValidators.every((rule) => rule.test(value));
    },
    message: 'Invalid password format.',
  },
  whiteSpace: {
    function(value) {
      return validationRegExpr.whiteSpace.test(value);
    },
    message: 'Please remove blank space',
  },
  samePass: {
    function(value, attribute) {
      if (!attribute) {
        return false;
      }

      const newValue = (this as unknown as Form<any>).validator.input[attribute];

      return newValue === value;
    },
    message: 'Passwords do not match.',
  },
  notSamePass: {
    function(newPassword, attribute) {
      if (!attribute) {
        return false;
      }

      const oldPassword = (this as unknown as Form<any>).validator.input[attribute];

      return oldPassword !== newPassword;
    },
    message: 'New password cannot match with the previous one.',
  },
  array: {
    function(value) {
      return Boolean(value.length);
    },
    message: 'This field is required.',
  },
  number: {
    function(value) {
      return !isNaN(Number(value));
    },
    message: 'Invalid number format.',
  },
  positive: {
    function(value) {
      return Number(value) > 0;
    },
    message: 'This field should be positive.',
  },
  integer: {
    function(value) {
      return Number.isInteger(Number(value));
    },
    message: 'This field should be integer.',
  },
  after: {
    function(value, attribute) {
      if (!attribute) {
        return false;
      }

      const compareToDate = (this as unknown as Form<any>).validator.input[attribute];

      if (compareToDate) {
        return isAfter(value, compareToDate, { utc: true });
      }

      return true;
    },
    message: 'This field should be later',
  },
  before: {
    function(value, attribute) {
      if (!attribute) {
        return false;
      }

      const compareToDate = (this as unknown as Form<any>).validator.input[attribute];

      if (compareToDate) {
        return isBefore(value, compareToDate, { utc: true });
      }

      return true;
    },
    message: 'This field should be before',
  },
  url: {
    function(value: string) {
      return validationRegExpr.url.test(value);
    },
    message: 'The format is invalid. (http://example.com, https://example.com)',
  },
});

export function getPlugins(customValidations?: { [key: string]: Validation }) {
  const rules = {
    ...baseValidations,
    ...customValidations,
  };

  return {
    dvr: dvr({
      package: validatorjs,
      extend: ({ validator }: { validator: ValidatorStatic }) => {
        Object.keys(rules).forEach((key) =>
          validator.register(key, rules[key].function, rules[key].message)
        );

        const messages = validatorjs.getMessages('en');

        messages.max = 'This value is too long. It should have :max characters or less.';

        validator.setMessages('en', messages);
      },
    }),
  };
}
