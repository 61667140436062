import { Component } from 'react';

import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
  STYLE_TYPE,
} from 'baseui/checkbox';
import { Field } from 'mobx-react-form';
import { ErrorText } from 'src/shared/components/error-text';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { FieldConfig, Field as FieldComponent } from '@shared/components/Field';
import { getFieldBindings } from '@shared/utils/form';

import { styles } from './Checkbox.styles';

interface CheckboxProps
  extends Omit<BaseCheckboxProps, 'value'>,
    AppWithStyles<typeof styles>,
    PickOptional<FieldConfig, 'label'> {
  field?: Field;
}

class CheckboxComponent extends Component<CheckboxProps> {
  private handleChange = (e) => {
    const { field, onChange } = this.props;

    if (onChange) {
      onChange(e);
    }

    if (field) {
      field.onChange(e);
      field.validate();
    }
  };

  render() {
    const { optional, checked, field, classes, error, errorText, label, children, ...otherProps } =
      this.props;
    const hasError = Boolean(field?.error) || error;
    const checkboxErrorText = field?.error || errorText;

    return (
      <FieldComponent
        label=""
        optional={optional}
        hasError={hasError}
        errorText={checkboxErrorText}
        classes={{ root: classes.root }}
      >
        <BaseCheckbox
          labelPlacement="right"
          {...otherProps}
          {...getFieldBindings(field)}
          checked={field ? field.value : checked}
          onChange={this.handleChange}
        >
          {label || children}
        </BaseCheckbox>
        {hasError && <ErrorText text={checkboxErrorText} />}
      </FieldComponent>
    );
  }
}

export const Checkbox = appWithStyles(styles)(appObserver(CheckboxComponent));

type SwitchProps = Omit<BaseCheckboxProps, 'value'> &
  AppWithStyles<typeof styles> &
  PickOptional<FieldConfig, 'label'> & {
    field?: Field;
  };

class SwitchComponent extends Component<SwitchProps> {
  private handleChange = (e) => {
    const { field, onChange } = this.props;

    if (onChange) {
      onChange(e);
    }

    if (field) {
      field.onChange(e);
      field.validate();
    }
  };

  render() {
    const {
      optional,
      checked,
      field,
      classes,
      error,
      errorText,
      labelPlacement,
      label,
      children,
      ...otherProps
    } = this.props;
    const hasError = Boolean(field?.error || error);
    const switchErrorText = field?.error || errorText;

    return (
      <FieldComponent
        label=""
        optional={optional}
        hasError={hasError}
        errorText={switchErrorText}
        classes={{ root: classes.root }}
      >
        <BaseCheckbox
          {...otherProps}
          labelPlacement={labelPlacement || 'right'}
          checkmarkType={STYLE_TYPE.toggle}
          overrides={{
            ToggleTrack: {
              style: ({ $checked, $theme }: any) => ({
                backgroundColor: $checked ? $theme.colors.positive : $theme.colors.mono400,
                ':hover': {
                  backgroundColor: $checked ? $theme.colors.positive : $theme.colors.mono500,
                },
              }),
            },
            ToggleInner: {
              style: ({ $checked, $theme }: any) => ({
                backgroundColor: $checked ? $theme.colors.positive : $theme.colors.mono400,
              }),
            },
            Label: {
              style: {
                fontSize: '14px',
                fontWeight: 600,
                paddingLeft: 0,
              },
            },
          }}
          checked={field ? field.value : checked}
          onChange={this.handleChange}
        >
          {label || children}
        </BaseCheckbox>
      </FieldComponent>
    );
  }
}

export const Switch = appWithStyles(styles)(appObserver(SwitchComponent));
