import { styled, withStyle } from 'baseui';

import { FlexColumnContainer } from '@shared/components';

const Wrapper = withStyle(FlexColumnContainer, ({ $theme }: any) => ({
  flexWrap: 'wrap',
  width: '288px',
  height: '288px',
  justifyContent: 'space-evenly',
  paddingTop: $theme.sizing.scale300,
  paddingRight: $theme.sizing.scale300,
  paddingBottom: $theme.sizing.scale300,
  paddingLeft: $theme.sizing.scale300,
}));

const Color = styled('div', ({ $theme, $color }: any) => ({
  height: '32px',
  width: '32px',
  borderRadius: $theme.borders.radius200,
  backgroundColor: $color,
  cursor: 'pointer',
}));

const COLORS = [
  ['#f98866', '#ff420e', '#80bd9e', '#89da59', '#90afc5', '#336b87', '#2a3132', '#763626'],
  ['#46211a', '#693d3d', '#ba5536', '#a43820', '#505160', '#68829e', '#aebd38', '#598234'],
  ['#003b46', '#07575b', '#66a5ad', '#c4dfe6', '#2e4600', '#486b00', '#a2c523', '#7d4427'],
  ['#021c1e', '#004445', '#2c7873', '#6fb98f', '#375e97', '#fb6542', '#ffbb00', '#3f681c'],
  ['#98bdc6', '#5bc8ac', '#e6d72a', '#f18d9e', '#324851', '#86ac41', '#34675c', '#7da3a1'],
  ['#4cb5f5', '#b7b8b6', '#34675c', '#b3c100', '#f4cc70', '#de7a22', '#20948b', '#6ab187'],
  ['#8d230f', '#1e434c', '#9b4f0f', '#c99e10', '#f1f1f2', '#bcbabe', '#a1d6e2', '#1995ad'],
  ['#9a9eab', '#5d535e', '#ec96a4', '#dfe166', '#011a27', '#063852', '#f0810f', '#e6df44'],
];

type Props = {
  onClick: (color: string) => void;
};
export const ColorPopover = ({ onClick }: Props) => {
  return (
    <Wrapper>
      {COLORS.map((row: string[], i: number) =>
        row.map((color: string, j: number) => (
          <Color $color={color} key={`${i}${j}`} onClick={() => onClick(color)} />
        ))
      )}
    </Wrapper>
  );
};
