import { inject } from '@core/di/di-utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';

import { DI_TOKENS } from '@shared/constants/di';
import { CommentOwner, CommentOwnerType } from '@shared/models/comment/comment-owner';
import { ICommentsService } from '@shared/types/services/comments';

export class AnswerViewModel {
  private readonly commentsService = inject<ICommentsService>(DI_TOKENS.commentsService);

  private _isOpenComments: boolean = false;
  private _loading = {
    commentCount: false,
  };
  private _commentsCount: number = 0;
  private readonly _owner: CommentOwner;

  constructor(id?: string) {
    appMakeObservable(this, {
      _isOpenComments: appObservable,
      _loading: appObservable,
      _commentsCount: appObservable,
      _owner: appObservable,
    });

    if (id) {
      this._owner = this.createOwner(id);
      this.getCommentsCount();
    }
  }

  get isOpenComments() {
    return this._isOpenComments;
  }

  get loading() {
    return this._loading;
  }

  get commentsCount() {
    return this._commentsCount;
  }

  get owner() {
    return this._owner;
  }

  updateOpenComments(isOpen: boolean) {
    this._isOpenComments = isOpen;
  }

  updateCommentsCount(count: number) {
    this._commentsCount = count;
  }

  private async getCommentsCount() {
    this._loading.commentCount = true;

    try {
      this._commentsCount = await this.commentsService.getCommentsCount(this._owner);
    } finally {
      this._loading.commentCount = false;
    }
  }

  private createOwner(id: string): CommentOwner {
    return {
      id,
      type: CommentOwnerType.submissionAnswer,
    };
  }
}
