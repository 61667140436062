import { OnChangeParams } from 'src/shared/components/select';

import { inject } from '@core/di/di-utils';
import { appMakeObservable, appObservable, appToJs } from '@core/state-management/utils';

import { showNotification } from '@shared/components';
import { DI_TOKENS } from '@shared/constants/di';
import { SUBMISSION_STATUS_LABELS } from '@shared/constants/insights-activity';
import { SubmissionActivityLog } from '@shared/models/submission/activity-log';
import { SubmissionListQuery } from '@shared/models/submission/list-model';
import { SubmissionReadQuery } from '@shared/models/submission/read-model';
import { SubmissionDefaultStatus, SubmissionStatus } from '@shared/models/submission/status';
import { IAuthService } from '@shared/types/services/auth';
import { ISubmissionsService } from '@shared/types/services/submissions';
import { showErrors } from '@shared/utils/errors';

import { createLog } from './submission.utils';

export class SubmissionViewModel {
  private readonly authService = inject<IAuthService>(DI_TOKENS.authService);
  private readonly submissionsService = inject<ISubmissionsService>(DI_TOKENS.submissionsService);

  private _submission: SubmissionReadQuery | null = null;
  private _error: boolean = false;
  private _loading: boolean = true;
  private _statusSubmitting: boolean = false;
  private _currentStatus: string = '';
  private _activityLog: SubmissionActivityLog[] = [];
  private readonly _id: SubmissionListQuery['id'] = '';

  constructor(id: SubmissionListQuery['id']) {
    this._id = id;

    appMakeObservable(this, {
      _submission: appObservable,
      _error: appObservable,
      _loading: appObservable,
      _statusSubmitting: appObservable,
      _currentStatus: appObservable,
      _activityLog: appObservable,
      _id: appObservable,
    });

    this.initialize();
  }

  get currentUser() {
    return this.authService.currentUser;
  }

  get submission() {
    return appToJs(this._submission);
  }

  get loading() {
    return this._loading;
  }

  get error() {
    return this._error;
  }

  get currentStatus() {
    return this._currentStatus;
  }

  get activityLog() {
    return this._activityLog;
  }

  get statusSubmitting() {
    return this._statusSubmitting;
  }

  get statuses() {
    const processedStatuses = !this._submission?.assignment?.statuses?.length
      ? [SubmissionDefaultStatus.open, SubmissionDefaultStatus.closed]
      : [
          SubmissionDefaultStatus.open,
          ...this._submission?.assignment?.statuses,
          SubmissionDefaultStatus.closed,
        ];

    return processedStatuses.map((status) => {
      return {
        id: status,
        label: SUBMISSION_STATUS_LABELS[status] || status,
      };
    });
  }

  private initialize = async () => {
    this._loading = true;

    try {
      await this.getData();
    } finally {
      this._loading = false;
    }
  };

  getData = async () => {
    this._error = false;

    try {
      const response = await this.getSubmission(this._id);

      this._submission = response;
      this._activityLog = response.activityLog;
      this._currentStatus = response.status;
    } catch (e) {
      this._error = true;
      console.error(e);
      showErrors(e?.response?.data?.errors, {
        notificationText: 'Something went wrong while fetching submission form',
      });
    }
  };

  handleStatusChange = async (params: OnChangeParams<SubmissionStatus>) => {
    try {
      this._statusSubmitting = true;

      await this.updateStatus(this._submission.assignment.id, this._id, {
        status: params.normalizedValue,
      });

      this._activityLog = [
        createLog(params.normalizedValue, this._currentStatus, this.currentUser),
        ...this._activityLog,
      ];

      this._currentStatus = params.normalizedValue;

      showNotification('Submission status was changed');
    } catch (err) {
      showErrors(err?.response?.data?.errors, {
        notificationText: 'Something went wrong while changing submission status',
      });
      console.error(err);
    } finally {
      this._statusSubmitting = false;
    }
  };

  getSubmission: ISubmissionsService['getSubmissionById'] = (...args) => {
    return this.submissionsService.getSubmissionById(...args);
  };

  updateStatus: ISubmissionsService['updateStatus'] = (...args) => {
    return this.submissionsService.updateStatus(...args);
  };

  deleteSubmission = async () => {
    this._error = false;
    this._loading = true;

    try {
      await this.submissionsService.deleteSubmission(this._id);
      showNotification('The submission has been deleted.', { type: 'success' });
    } catch (e) {
      this._error = true;
      console.error(e);
      showErrors(e?.response?.data?.errors, {
        notificationText: 'Something went wrong while deleting submission',
      });
    } finally {
      this._loading = false;
    }
  };
}
