import { palette } from '@core/theme/units/palette';
import { radius } from '@core/theme/units/radius';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      width: `100%`,
      padding: spacing(2, 4),
      borderRadius: radius.containers.primary,
      backgroundColor: palette.grey[100],
    },
    pageName: {
      fontWeight: 400,
      fontSize: '14px',
      color: palette.common.black,
    },
    title: {
      width: '100%',
    },
    dropdown: {
      border: `1px solid ${palette.border.main}`,
      borderRadius: radius.containers.primary,
      width: '236px',
      maxHeight: '310px',
      overflow: 'auto',
      padding: spacing(2, 0),
      backgroundColor: palette.background.paper,
    },
    option: {
      fontSize: '14px',
      padding: spacing(2, 4),
      color: palette.common.black,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: palette.background.default,
      },
    },
    icon: {
      cursor: 'pointer',
    },
    search: {
      height: '32px',
      borderRadius: radius.containers.primary,
    },
    emptyState: {
      textAlign: 'center',
    },
  });
