import { spacing } from '@core/theme/utils/spacing';

export const styles = () => {
  return {
    root: {
      position: 'relative',
    },
    label: {
      marginBottom: spacing(1),
    },
    errorText: {},
  };
};
