import { palette } from '@core/theme/units/palette';
import { appCreateStyles } from '@core/theme/utils/with-styles';

const viewStyles: Record<'primary' | 'secondary', CSSRules> = {
  primary: {},
  secondary: {
    color: palette.text.secondary,
    background: 'transparent',
    border: `1px dashed ${palette.grey[300]}`,
    textTransform: 'capitalize',
    fontSize: '16px',

    '&:hover': {
      background: 'transparent',
      borderColor: palette.grey[400],
    },
  },
};

export const styles = () =>
  appCreateStyles({
    root: {},
    ...viewStyles,
  });
