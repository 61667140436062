import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { DI_TOKENS } from '@shared/constants/di';
import { TaskListDTO } from '@shared/models/task/list-model';
import { TaskType } from '@shared/models/task/type';
import { IFormsService } from '@shared/types/services/forms';

@injectable()
export class TasksService extends BaseService {
  static diToken = Symbol('tasks-service');

  private readonly formsService = inject<IFormsService>(DI_TOKENS.formsService);
  private readonly $http = inject<HTTPClient>(HTTPClient.diToken);

  async getPromptsQuery(
    promptName: string,
    selectedTaskId: Array<string>,
    options: { limit: number }
  ) {
    const {
      data: { items },
    } = await this.$http.post<{ items: Array<TaskListDTO> }>('/prompts/query', {
      query: {
        filters: {
          name: promptName,
          exclude: selectedTaskId,
        },
      },
      pagination: {
        limit: options.limit,
      },
    });

    return items.map((prompt) => {
      return {
        ...prompt,
        type: TaskType.prompt,
      };
    });
  }

  async getTasks(
    name: string,
    excludedIds: Array<string>,
    options: { limit: number }
  ): Promise<Array<TaskListDTO>> {
    const [forms, prompts] = await Promise.all([
      this.formsService.getFormsQuery({
        name,
        excludedIds,
        options,
      }),
      this.getPromptsQuery(name, excludedIds, options),
    ]);

    return [...forms, ...prompts];
  }
}
