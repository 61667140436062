import { appObserver } from '@core/state-management/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Button, Modal, ModalBody, ModalFooter } from '@shared/components';
import { SubmissionReadQuery } from '@shared/models/submission/read-model';

import { styles } from './assignment-modal.styles';

type Props = AppWithStyles<typeof styles> & {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit?: () => Promise<void>;
  page: SubmissionReadQuery['page'];
  newPage: SubmissionReadQuery['page'];
};

const AssignmentModalComponent = ({
  isOpen,
  onClose,
  handleSubmit,
  page,
  newPage,
  classes,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeable>
      <ModalBody>
        Are you sure you want to re-assign Submission to the Form from the Page "{page.name}" to the
        Page "{newPage.name}"?
      </ModalBody>
      <div className={classes.devider}></div>
      <ModalFooter className={classes.modalFooter}>
        <Button kind="secondary" onClick={onClose}>
          No
        </Button>
        <div className={classes.deleteButton}>
          <Button onClick={handleSubmit} kind="delete">
            Yes, re-assign
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export const AssignmentModal = appWithStyles(styles)(appObserver(AssignmentModalComponent));
