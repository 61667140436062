import { useMemo, ReactNode } from 'react';

import { BaseProvider, createTheme, lightThemePrimitives } from 'baseui';

import { inject } from '@core/di/di-utils';

import { DI_TOKENS } from '@shared/constants/di';
import { IAuthService } from '@shared/types/services/auth';
import { buildThemeColors } from '@shared/utils/Theme/buildThemeColors';

type Props = {
  children: ReactNode;
};

export const ThemeContextProvider = ({ children }: Props) => {
  const { currentTenant } = inject<IAuthService>(DI_TOKENS.authService);
  const settings = currentTenant?.settings;

  const overrides = useMemo(() => {
    if (settings?.primaryColor) {
      localStorage.setItem('primaryColor', settings.primaryColor);
      localStorage.setItem('secondaryColor', settings.secondaryColor);
      localStorage.setItem('baseColor', settings.baseColor);
    }
    return buildThemeColors(
      settings?.primaryColor || localStorage.getItem('primaryColor') || '#000'
    );
  }, [currentTenant?.id]);

  const primitives = {
    ...lightThemePrimitives,
    ...overrides,
    primaryFontFamily: 'Inter',
  };

  const theme = useMemo(
    () =>
      createTheme(primitives, {
        ...overrides,
        colors: {
          background: overrides.mono200,
          buttonPrimaryHover: settings?.primaryColor,
          buttonSecondaryFill: primitives.mono300,
          buttonSecondaryText: primitives.mono1000,
          buttonSecondaryHover: primitives.mono400,
          buttonSecondaryActive: primitives.mono600,
          buttonSecondarySelectedFill: primitives.mono300,
          buttonSecondarySelectedText: primitives.mono1000,
          buttonSecondarySpinnerForeground: primitives.mono700,
          buttonSecondarySpinnerBackground: primitives.mono300,
        },
        borders: {
          cardBorderBottomWidth: '1px',
          buttonBorderRadius: '4px',
          inputBorderRadius: '4px',
          popoverBorderRadius: '4px',
          surfaceBorderRadius: '4px',
        },
      }),
    [overrides]
  );

  const providerOverrides = useMemo(() => {
    return {
      AppContainer: {
        style: {
          height: '100%',
        },
      },
    };
  }, []);

  return (
    <BaseProvider overrides={providerOverrides} zIndex={1} theme={theme}>
      {children}
    </BaseProvider>
  );
};
