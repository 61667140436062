import { injectable } from '@core/di/di-utils';

import { DI_TOKENS } from '@shared/constants/di';
import { IConfig, CommonConfig } from '@shared/types/config';

const isProduction = process.env.NODE_ENV === 'production';

@injectable()
export class Config implements IConfig {
  static diToken = DI_TOKENS.config;
  private config: CommonConfig;

  initialize: IConfig['initialize'] = async () => {
    return await this.initBaseConfig();
  };

  get: IConfig['get'] = () => {
    return this.config;
  };

  getApiBaseURL: IConfig['getApiBaseURL'] = () => {
    const { hostname, protocol } = window.location;
    const { url } = this.config.api;
    const tenant = hostname.split('.')[0];
    const productionApiUrl = `${url.protocol || protocol}://${tenant}.${url.basename}`;

    return isProduction ? productionApiUrl : 'https://boeing747.devapi.afg-dev.net';
  };

  getMapSearchServiceUrl: IConfig['getMapSearchServiceUrl'] = (coordinates, placeId) => {
    const { lat, lng } = coordinates;

    return `${this.config.mapSearchService}/search/?api=1&query=${lat}%2C${lng}&query_place_id=${placeId}`;
  };

  private async initBaseConfig() {
    try {
      this.config = await fetch(`${window.location.origin}/config.json`).then((r) => r.json());
    } catch (err) {
      throw err;
    }
  }
}
