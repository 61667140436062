import { appObserver } from '@core/state-management/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Button } from '@shared/components/button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@shared/components/Modal';
import { SubmissionReadQuery } from '@shared/models/submission/read-model';

import { styles } from './delete-submission-modal.styles';

type Props = AppWithStyles<typeof styles> & {
  isOpen: boolean;
  onClose: () => void;
  handleDelete: () => Promise<void>;
};

const DeleteSubmissionModalComponent = ({ isOpen, onClose, handleDelete, classes }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeable>
      <ModalHeader>Delete submission</ModalHeader>
      <ModalBody>
        All submitted information and media files will be permanently deleted from the system. Are
        you sure you want to delete this submission?
      </ModalBody>
      <div className={classes.devider}></div>
      <ModalFooter className={classes.modalFooter}>
        <Button kind="secondary" onClick={onClose}>
          No
        </Button>
        <div className={classes.deleteButton}>
          <Button onClick={handleDelete} kind="delete">
            Yes, delete
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export const DeleteSubmissionModal = appWithStyles(styles)(
  appObserver(DeleteSubmissionModalComponent)
);
