import { ReactNode } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { InsightsExploreSubmissionDataItem } from '@modules/tenant/insights/layouts/private/pages/explore/explore.types';

import { BackButton } from '@shared/components/BackButton';
import { Ellipsis } from '@shared/components/Ellipsis';
import { Flex } from '@shared/components/Flex';
import { ROUTES } from '@shared/utils/getRoute';

import { styles } from './header.styles';

export type HeaderProps = AppWithStyles<typeof styles> & {
  title: InsightsExploreSubmissionDataItem['title'];
  loading: boolean;
  actions: ReactNode;
  onClose: () => void;
};

const HeaderComponent = ({ classes, title, loading, actions, onClose }: HeaderProps) => {
  const history = useHistory();

  const handleBackButtonClick = () => {
    if (history.length > 1) {
      history.goBack();
      return;
    }
    history.push(ROUTES.FIELD_WORK_OBJECTIVES);
  };

  const leftControl = () => {
    if (onClose) {
      return (
        <IconButton onClick={onClose} classes={{ root: classes.iconButton }}>
          <CloseRoundedIcon classes={{ root: classes.closeIcon }} />
        </IconButton>
      );
    }

    return <BackButton kind="minimal" size="compact" text="Back" onClick={handleBackButtonClick} />;
  };

  return (
    <Flex alignItems="center" justifyContent="space-between" classes={{ root: classes.root }}>
      <Flex alignItems="center" classes={{ root: classes.titleWrapper }}>
        {leftControl()}
        {!loading && <Ellipsis classes={{ root: classes.title }} text={title} />}
      </Flex>
      {actions}
    </Flex>
  );
};

export const Header = appWithStyles(styles)(HeaderComponent);
