import {
  Popover as BasePopover,
  StatefulPopover as BaseStatefulPopover,
  StatefulPopoverProps,
} from 'baseui/popover';

type IPopoverProps = any;
export const Popover = (props: IPopoverProps) => {
  return <BasePopover {...props} />;
};

export const StatefulPopover = (props: StatefulPopoverProps) => {
  return (
    <BaseStatefulPopover
      {...props}
      overrides={{
        Body: {
          style: {
            zIndex: 20,
          },
        },
        ...props.overrides,
      }}
    />
  );
};

export { PLACEMENT as PopoverPLACEMENT, TRIGGER_TYPE as PopoverTRIGGER_TYPE } from 'baseui/popover';
