import { useEffect, useState, useRef } from 'react';

import cx from 'classnames';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { CommentsAction } from '@shared/components/commentaries/commentaries.types';
import { Management } from '@shared/components/commentaries/components/management';
import { CommentOwner } from '@shared/models/comment/comment-owner';
import { CommentReadQuery } from '@shared/models/comment/read-model';
import { UserReadQuery } from '@shared/models/user/read-model';

import { Content } from './components/content';
import { Footer } from './components/footer';
import { Header } from './components/header';

import { styles } from './comment.styles';

type CommentProps = AppWithStyles<typeof styles> &
  CommentReadQuery & {
    owner: CommentOwner;
    currentUser: UserReadQuery;
    onDeleteSuccess: () => Promise<void>;
    onUpdateSuccess: (updatedComment: CommentReadQuery) => void;
    onReplySuccess?: () => Promise<void>;
    onReactionUpdateSuccess?: (comment: CommentReadQuery) => void;
    active: boolean;
    scrollTo: (top: number) => void;
  };

const CommentComponent = ({
  classes,
  id,
  user,
  editor,
  parent,
  createdAt,
  updatedAt,
  owner,
  body,
  currentUser,
  onDeleteSuccess,
  reactions,
  onUpdateSuccess,
  onReplySuccess,
  onReactionUpdateSuccess,
  active,
  scrollTo,
}: CommentProps) => {
  const ref = useRef<HTMLDivElement>();

  const [action, setAction] = useState<CommentsAction | undefined>();

  useEffect(() => {
    if (active && ref.current) {
      const { top } = ref.current.getBoundingClientRect();
      scrollTo(top - 90);
    }
  }, []);

  const handleEditStateChange = () => {
    setAction(action ? undefined : CommentsAction.editing);
  };

  const handleReplyStateChange = () => {
    setAction(action ? undefined : CommentsAction.creatingReply);
  };

  const handleUpdateComment = (updatedComment: CommentReadQuery) => {
    handleEditStateChange();
    onUpdateSuccess(updatedComment);
  };

  const handleAddReply = async () => {
    handleReplyStateChange();

    if (onReplySuccess) {
      await onReplySuccess();
    }
  };

  const renderContent = () => {
    if (action === CommentsAction.editing) {
      return (
        <Management
          hasParent={Boolean(parent)}
          inputPlaceholder={parent ? 'Edit reply...' : 'Edit comment...'}
          id={id}
          value={body}
          currentUser={currentUser}
          action={action}
          owner={owner}
          onEditSuccess={handleUpdateComment}
          cancel={handleEditStateChange}
        />
      );
    }

    return <Content data={body} />;
  };

  const renderFooter = () => {
    return (
      <>
        <Footer
          canReply={!Boolean(parent)}
          action={action}
          id={id}
          currentUser={currentUser}
          reactions={reactions}
          classes={{ root: classes.footer }}
          onReply={handleReplyStateChange}
          onReactionUpdateSuccess={onReactionUpdateSuccess}
        />
        {action === CommentsAction.creatingReply && (
          <Management
            inputPlaceholder="Write reply..."
            id={id}
            currentUser={currentUser}
            action={action}
            owner={owner}
            onCreateSuccess={handleAddReply}
            cancel={handleReplyStateChange}
            classes={{ root: classes.replyManagement }}
          />
        )}
      </>
    );
  };

  return (
    <div ref={ref} className={cx(classes.root, active && classes.active)}>
      <Header
        action={action}
        id={id}
        user={user}
        currentUser={currentUser}
        editor={editor}
        createdAt={createdAt}
        updatedAt={updatedAt}
        classes={{ root: classes.header }}
        onEditActivate={handleEditStateChange}
        onDeleteSuccess={onDeleteSuccess}
      />
      {renderContent()}
      {renderFooter()}
    </div>
  );
};

export const Comment = appWithStyles(styles)(CommentComponent);
