import { useState } from 'react';

import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { withStyle } from 'baseui';

import { FlexRowContainer } from '@shared/components/containers';

import { FilePreview } from './FilePreview';
import { FileUpload } from './FileUpload';

const FilePreviewWrapper = withStyle(FlexRowContainer, () => ({
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
}));

export type UploadedFile = {
  url: string;
  name: string;
  size: any;
  type: string;
};

type Props = {
  width?: string;
  height?: string;
  uploaderHeight?: string;
  value?: any;
  onChange: (file: any) => void;
  uploadFolder: string;
  showDescription?: boolean;
  accept?: string;
  multiple?: boolean;
  preservePreviewRatio?: boolean;
  showUploaderWithValue?: boolean;
  onValidateFile?: (files: Array<UploadedFile>) => boolean;
};

export const FileUploader = ({
  width,
  height = '100%',
  uploaderHeight,
  value = [],
  onChange,
  uploadFolder = 'uploads',
  showDescription = true,
  accept = '',
  multiple = true,
  preservePreviewRatio = true,
  showUploaderWithValue = true,
  onValidateFile,
}: Props) => {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleDrop = async (files: Array<File>) => {
    if (onValidateFile && !onValidateFile(files as unknown as Array<UploadedFile>)) return;

    setUploading(true);

    const uploadResult = await handleUpload(files, uploadFolder);
    setUploadProgress(0);
    setUploading(false);

    if (uploadResult) {
      onChange([
        ...value,
        ...uploadResult.map((result: any) => ({
          url: result.url,
          name: result.name,
          size: result.size,
          type: result.type,
        })),
      ]);
    }
  };

  const GET_SIGNED_REQUEST_FROM_S3_MUTATION = gql`
    mutation FileUploaderGetSignedRequestFromS3($name: String!, $type: String!, $folder: String!) {
      getSignedRequestFromS3(name: $name, type: $type, folder: $folder)
    }
  `;

  const [getSignedRequest] = useMutation(GET_SIGNED_REQUEST_FROM_S3_MUTATION, {});

  const getSignedRequestFromS3 = async (name: string, type: string, folder: string) => {
    const {
      data: { getSignedRequestFromS3 },
    } = await getSignedRequest({ variables: { name: encodeURIComponent(name), type, folder } });

    return getSignedRequestFromS3;
  };

  const handleUpload = async (acceptedFiles: any, uploadFolder: string) => {
    if (!acceptedFiles[0] || (!multiple && value && value.length === 1)) {
      setUploading(false);
      onChange([]);
      return;
    }
    let all: Promise<any>[] = [];

    // get the total size for the upload
    const totalBytes = acceptedFiles
      .map((file: any) => file.size)
      .reduce((acc: number, curr: number) => acc + curr);

    const progress: number[] = [];
    const updateProgress = (i: number, individualProgress: number) => {
      progress[i] = individualProgress;
      const totalProgress = progress.reduce((acc: number, curr: number) => acc + curr);
      setUploadProgress((totalProgress / totalBytes) * 100);
    };

    for (let i = 0; i < acceptedFiles.length; ++i) {
      const file = acceptedFiles[i];
      if (i > 0 && !multiple) {
        break;
      }
      const amazonResponse = await getSignedRequestFromS3(file.path, file.type, uploadFolder);
      const amazonResult = JSON.parse(amazonResponse);
      all = [
        ...all,
        new Promise((res, rej) => {
          const xhr = new XMLHttpRequest();
          xhr.open('PUT', amazonResult.signedRequest);
          xhr.onerror = () => {
            throw Error('Error occurred uploading file');
          };
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                res({
                  url: amazonResult.url,
                  name: file.name,
                  size: file.size,
                  type: file.type,
                });
              } else {
                //log error here
                rej('An error occurred when uploading');
              }
            }
          };
          xhr.upload.addEventListener(
            'progress',
            (event) => {
              updateProgress(i, event.loaded);
            },
            false
          );
          xhr.send(file);
        }),
      ];
    }
    return Promise.all(all);
  };

  const updateImageDescription = (description: string, index: number) => {
    let _value = [...value];
    _value[index].description = description;
    onChange(_value);
  };

  const deleteImage = (index: number) => {
    let _value = [...value];
    _value.splice(index, 1);
    onChange(_value);
  };

  const hasValue = value && value.length > 0;
  const shouldShowUploader = !hasValue || (showUploaderWithValue && hasValue);

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      {shouldShowUploader && (
        <FileUpload
          accept={accept}
          multiple={multiple}
          onDrop={handleDrop}
          progressMessage={uploading && `Uploading...`}
          progressAmount={uploadProgress}
          overrides={{
            Root: {
              style: {
                textAlign: 'center',
                zIndex: 0,
                position: 'relative',
              },
            },
            FileDragAndDrop: {
              style: {
                height: hasValue ? '68px' : uploaderHeight || '148px',
                flexDirection: hasValue ? 'row' : 'column',
                paddingTop: 0,
                paddingBottom: 0,
                justifyContent: hasValue ? 'space-evenly' : 'center',
              },
            },
          }}
        />
      )}
      {hasValue ? (
        <FilePreviewWrapper>
          {value.map((image: any, index: number) => (
            <FilePreview
              key={image.url}
              url={image.url}
              description={image.description}
              onChange={(description: string) => updateImageDescription(description, index)}
              onDelete={() => deleteImage(index)}
              showDescription={showDescription}
              preservePreviewRatio={preservePreviewRatio}
              width={width}
              height={height}
            />
          ))}
        </FilePreviewWrapper>
      ) : null}
    </div>
  );
};
