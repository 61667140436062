import { useMemo } from 'react';

import { Avatar as BaseAvatar, AvatarProps as BaseAvatarProps } from 'baseui/avatar';

type Props = Except<BaseAvatarProps, 'size'> & {
  size?: string | number;
};

export const Avatar = ({ name = '', size, ...rest }: Props) => {
  const normalizedSize = useMemo(() => {
    if (typeof size === 'string') return size;

    return `${size}px`;
  }, [size]);

  const normalizedFontSize = useMemo(() => {
    if (typeof size === 'string') {
      return `${parseInt(size, 10) / 2}px`;
    }

    return `${size / 2}px`;
  }, [size]);

  return (
    <BaseAvatar
      name={name.replace(/\s+/g, ' ')}
      size={normalizedSize}
      overrides={{
        Root: {
          style: {
            width: normalizedSize,
          },
        },
        Initials: {
          style: {
            fontSize: normalizedFontSize,
          },
        },
      }}
      {...rest}
    />
  );
};
