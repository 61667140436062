import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { PageReadDTO } from '@shared/models/pages/read-model';
import { ReactionReadModel, ReactionReadQuery } from '@shared/models/reaction/read-model';
import { SubmissionListQuery } from '@shared/models/submission/list-model';
import { SubmissionReadQuery } from '@shared/models/submission/read-model';
import { SubmissionQueryResponse } from '@shared/models/submission/submission-query';
import { TagListDTO } from '@shared/models/tag/list-model';
import { ISubmissionsService } from '@shared/types/services/submissions';

@injectable()
export class SubmissionsService extends BaseService implements ISubmissionsService {
  private $http = inject<HTTPClient>(HTTPClient.diToken);

  getSubmissionById: ISubmissionsService['getSubmissionById'] = async (id: string) => {
    const { data } = await this.$http.get<SubmissionReadQuery>(`/form-submissions/${id}`);

    return data;
  };

  updateStatus: ISubmissionsService['updateStatus'] = async (
    formId: string,
    submissionId: SubmissionListQuery['id'],
    data: Record<string, SubmissionListQuery['status']>
  ) => {
    await this.$http.patch<SubmissionReadQuery>(
      `/forms/${formId}/submissions/${submissionId}`,
      data
    );
  };

  getSubmissionFileTags: ISubmissionsService['getSubmissionFileTags'] = async (
    submissionId,
    fileId
  ) => {
    const {
      data: { primaryTag, tags },
    } = await this.$http.get<{ primaryTag: TagListDTO; tags: Array<TagListDTO> }>(
      `/form-submissions/${submissionId}/files/${fileId}/tags`
    );

    return { primaryTag: primaryTag || undefined, tags };
  };

  updateSubmissionFileTags: ISubmissionsService['updateSubmissionFileTags'] = async (
    submissionId,
    fileId,
    tags
  ) => {
    await this.$http.post(`/form-submissions/${submissionId}/files/${fileId}/tags`, { tags });
  };

  removeSubmissionFileTags: ISubmissionsService['removeSubmissionFileTags'] = async (
    submissionId,
    fileId,
    tags
  ) => {
    await this.$http.delete(`/form-submissions/${submissionId}/files/${fileId}/tags`, {
      data: {
        tags,
      },
    });
  };

  addSubmissionReaction: ISubmissionsService['addSubmissionReaction'] = async (
    submissionId,
    fileId,
    data
  ) => {
    const {
      data: { reactions },
    } = await this.$http.post<{ reactions: Array<ReactionReadQuery> }>(
      `/form-submissions/${submissionId}/files/${fileId}/reactions`,
      data
    );

    return reactions.map((reaction) => new ReactionReadModel(reaction));
  };

  deleteSubmissionReaction: ISubmissionsService['deleteSubmissionReaction'] = async (
    submissionId,
    fileId,
    reactionId
  ) => {
    const {
      data: { reactions },
    } = await this.$http.delete<{ reactions: Array<ReactionReadQuery> }>(
      `/form-submissions/${submissionId}/files/${fileId}/reactions/${reactionId}`
    );

    return reactions.map((reaction) => new ReactionReadModel(reaction));
  };

  getSubmissionsQuery: ISubmissionsService['getSubmissionsQuery'] = async (params) => {
    const { data } = await this.$http.post<SubmissionQueryResponse>(
      '/form-submissions/query',
      params
    );

    return data;
  };

  reassignSubmission: ISubmissionsService['reassignSubmission'] = async (
    formId: string,
    submissionId: SubmissionListQuery['id'],
    data: Record<string, PageReadDTO['id']>
  ) => {
    await this.$http.patch<SubmissionReadQuery>(
      `/forms/${formId}/submissions/${submissionId}`,
      data
    );
  };

  deleteSubmission: ISubmissionsService['deleteSubmission'] = async (submissionId) => {
    await this.$http.delete(`/form-submissions/${submissionId}`);
  };
}
