import { ReactNode, Component } from 'react';

import { ButtonProps } from 'src/shared/components/button';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Message } from '@shared/components/Message';
import { history } from '@shared/utils/history';

import { styles } from './ErrorHandler.styles';

interface ErrorHandlerProps extends AppWithStyles<typeof styles> {
  heading?: ReactNode;
  icon?: ReactNode;
  buttonProps?: Partial<ButtonProps>;
  withButton?: boolean;
}

const handleGoToInitialPage = () => {
  history.push('/');
};

class ErrorHandlerComponent extends Component<ErrorHandlerProps> {
  static defaultProps = {
    withButton: true,
    heading: 'Something went wrong',
    buttonProps: {
      text: 'Go to home pages',
      onClick: handleGoToInitialPage,
    },
  };

  render() {
    const { classes, heading, icon, buttonProps, withButton } = this.props;

    return (
      <Message
        classes={{
          root: classes.root,
          heading: classes.heading,
        }}
        heading={heading}
        icon={icon}
        buttonProps={withButton ? buttonProps : undefined}
      />
    );
  }
}

export const ErrorHandler = appWithStyles(styles)(ErrorHandlerComponent);
