import { useState, useMemo } from 'react';

import cx from 'classnames';
import { Avatar } from 'src/shared/components/avatar';
import { ButtonProps, Button } from 'src/shared/components/button';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { CommentsAction } from '@shared/components/commentaries/commentaries.types';
import { Flex } from '@shared/components/Flex';
import { CommentOwner } from '@shared/models/comment/comment-owner';
import { CommentReadModel, CommentReadQuery } from '@shared/models/comment/read-model';
import { UserReadQuery } from '@shared/models/user/read-model';
import { showErrors } from '@shared/utils/errors';

import { Input } from './components/input';
import { ManagementViewModel } from './management.vm';

import { styles, AVATAR_SIZE } from './management.styles';

type ControlProps = Pick<ButtonProps, 'text' | 'onClick' | 'kind'>;

type Props = AppWithStyles<typeof styles> & {
  hasParent?: boolean;
  id?: CommentReadQuery['id'];
  action: CommentsAction;
  currentUser: UserReadQuery;
  value?: CommentReadModel['body'];
  inputPlaceholder?: string;
  owner: CommentOwner;
  cancel?: () => void;
  onCreateSuccess?: () => Promise<void>;
  onEditSuccess?: (updatedComment: CommentReadQuery) => void;
};

const ManagementComponent = ({
  id,
  hasParent,
  classes,
  action,
  cancel,
  owner,
  inputPlaceholder,
  value: _existingValue,
  currentUser,
  onCreateSuccess,
  onEditSuccess,
}: Props) => {
  const $vm = useMemo(() => new ManagementViewModel(), []);

  const [value, setValue] = useState(_existingValue || '');
  const [submitting, setSubmitting] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleFocusChange = () => {
    setFocused(!focused);
  };

  const onCancel = () => {
    setValue('');
    if (cancel) {
      cancel();
    }
  };

  const create = async () => {
    try {
      setSubmitting(true);
      await $vm.create({
        owner,
        parentId: id,
        body: value,
      });

      setValue('');

      if (onCreateSuccess) {
        await onCreateSuccess();
      }
    } catch (e) {
      showErrors(e?.response?.data?.errors, {
        notificationText: 'Something went wrong while creating comment',
      });
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  const edit = async () => {
    try {
      setSubmitting(true);
      const comment = await $vm.edit(id, {
        body: value,
      });
      setValue('');

      if (onEditSuccess) {
        await onEditSuccess(comment.asJson);
      }
    } catch (err) {
      showErrors(err?.response?.data?.errors, {
        notificationText: 'Something went wrong while editing comment',
      });
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const controls = useMemo(() => {
    const config: Record<CommentsAction, { confirm: ControlProps; decline: ControlProps }> = {
      [CommentsAction.creatingComment]: {
        confirm: {
          kind: 'primary',
          text: 'Add comment',
          onClick: create,
        },
        decline: {
          text: 'Cancel',
          kind: 'tertiary',
          onClick: onCancel,
        },
      },
      [CommentsAction.editing]: {
        confirm: {
          kind: 'primary',
          text: hasParent ? 'Edit reply' : 'Edit comment',
          onClick: edit,
        },
        decline: {
          text: 'Cancel',
          kind: 'tertiary',
          onClick: onCancel,
        },
      },
      [CommentsAction.creatingReply]: {
        confirm: {
          text: 'Add reply',
          kind: 'primary',
          onClick: create,
        },
        decline: {
          text: 'Cancel',
          kind: 'tertiary',
          onClick: onCancel,
        },
      },
    };

    return config[action];
  }, [create, edit, action, onCancel, hasParent]);

  const shouldShowControls = useMemo(() => {
    const config: Record<CommentsAction, boolean> = {
      [CommentsAction.creatingComment]: focused || Boolean(value),
      [CommentsAction.editing]: true,
      [CommentsAction.creatingReply]: true,
    };

    return config[action];
  }, [focused, value, action]);

  return (
    <div className={classes.root}>
      <Flex>
        {action === CommentsAction.creatingComment && (
          <Avatar size={AVATAR_SIZE} src={currentUser.profileUrl} name={currentUser.fullName} />
        )}
        <Input
          placeholder={inputPlaceholder}
          value={value}
          classes={{
            root: cx(classes.textarea, {
              [classes.textareaCreating]: action === CommentsAction.creatingComment,
            }),
          }}
          onChange={handleChange}
          onFocus={handleFocusChange}
          onBlur={handleFocusChange}
        />
      </Flex>
      {shouldShowControls && (
        <Flex justifyContent="flex-end" classes={{ root: classes.controlsWrapper }}>
          <div style={{ marginRight: '12px' }}>
            <Button {...controls.decline} disabled={submitting} kind="tertiary" size="compact" />
          </div>
          <Button
            {...controls.confirm}
            size="compact"
            kind="primary"
            isLoading={submitting}
            disabled={!value}
          />
        </Flex>
      )}
    </div>
  );
};

export const Management = appWithStyles(styles)(ManagementComponent);
