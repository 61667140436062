import { Suspense, ReactNode } from 'react';

import ErrorBoundary from '@shared/components/ErrorBoundary';
import { Spinner, SpinnerProps } from '@shared/components/Spinner';

type SuspenseBoundaryProps = {
  children: ReactNode;
  loadingProps?: SpinnerProps;
};

export const SuspenseBoundary = ({ children, loadingProps }: SuspenseBoundaryProps) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Spinner {...loadingProps} />}>{children}</Suspense>
    </ErrorBoundary>
  );
};
