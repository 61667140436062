import { ReactNode, useEffect, useState } from 'react';

import { Fade, Snackbar, Alert, AlertColor } from '@mui/material';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './app-notification.styles';

type AppNotificationProps = AppWithStyles<typeof styles> & {
  type?: AlertColor;
  autoHideDuration?: number | null;
};

const defaultProps = {
  type: 'success' as AlertColor,
  autoHideDuration: 5000,
  className: '',
};

let showNotificationFn;

export const showNotification = (
  message: ReactNode,
  notificationProps?: Partial<AppNotificationProps>
) => {
  showNotificationFn(message, notificationProps);
};

const AppNotificationComponent = ({ classes }: AppNotificationProps) => {
  const [isOpen, setOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<ReactNode>('');
  const [notificationProps, setNotificationProps] = useState(defaultProps);

  const open = (message: ReactNode, props: AppNotificationProps) => {
    setNotificationMessage(message);
    setNotificationProps({
      ...defaultProps,
      ...props,
    });
    setOpen(true);
  };

  useEffect(() => {
    showNotificationFn = open;
  }, []);

  const handleOnClose = () => {
    setNotificationProps(defaultProps);
    setOpen(false);
  };

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      TransitionComponent={Fade}
      autoHideDuration={notificationProps.autoHideDuration}
      classes={{ root: classes.root }}
      onClose={handleOnClose}
    >
      <Alert elevation={6} onClose={handleOnClose} severity={notificationProps.type}>
        {notificationMessage}
      </Alert>
    </Snackbar>
  );
};

export const AppNotification = appWithStyles(styles)(AppNotificationComponent);
