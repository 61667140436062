import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { CommentOwner } from '@shared/models/comment/comment-owner';
import { CommentListQuery } from '@shared/models/comment/list-model';
import { CommentReadQuery } from '@shared/models/comment/read-model';
import { UserReadQuery } from '@shared/models/user/read-model';

import { Thread } from './components/thread';

import { styles } from './list.styles';

type Props = AppWithStyles<typeof styles> & {
  shouldRefreshThread: boolean;
  owner: CommentOwner;
  data: Array<CommentListQuery>;
  currentUser: UserReadQuery;
  onDeleteSuccess: () => Promise<void>;
  onUpdateSuccess: (updatedComment: CommentReadQuery) => void;
  onReplySuccess: () => Promise<void>;
  openCommentParentId?: string;
  openCommentId?: string;
  scrollTo: (top: number) => void;
};

const ListComponent = ({
  classes,
  data,
  currentUser,
  owner,
  onDeleteSuccess,
  onUpdateSuccess,
  shouldRefreshThread,
  onReplySuccess,
  openCommentParentId,
  openCommentId,
  scrollTo,
}: Props) => {
  return (
    <div className={classes.root}>
      {data.map((comment) => {
        const isOpenReplaces = openCommentParentId && openCommentParentId === comment.id;

        return (
          <Thread
            key={comment.id}
            commentId={openCommentId}
            isOpenReplace={isOpenReplaces}
            scrollTo={scrollTo}
            shouldRefreshThread={shouldRefreshThread}
            owner={owner}
            currentUser={currentUser}
            parentComment={comment}
            onDeleteSuccess={onDeleteSuccess}
            onUpdateSuccess={onUpdateSuccess}
            onReplySuccess={onReplySuccess}
            classes={{ root: classes.thread }}
          />
        );
      })}
    </div>
  );
};

export const List = appWithStyles(styles)(ListComponent);
