import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { NoteListModel, NoteListQuery } from '@shared/models/notes/list-model';
import { NoteReadModel, NoteReadQuery } from '@shared/models/notes/read-model';
import { PaginatedResponse } from '@shared/types/services';
import { INotesService } from '@shared/types/services/notes';

@injectable()
export class NotesService extends BaseService implements INotesService {
  private readonly $http = inject<HTTPClient>(HTTPClient.diToken);

  protected urlPrefix = '/notes';

  getList: INotesService['getList'] = async (params) => {
    const {
      data: { notes, total },
    } = await this.$http.get<PaginatedResponse<{ notes: Array<NoteListQuery> }>>(this.getUrl(), {
      params: {
        ownerId: params.owner.id,
        ownerType: params.owner.type,
        ...params.pagination,
      },
    });

    return {
      total,
      notes: notes.map((story) => new NoteListModel(story)),
    };
  };

  create: INotesService['create'] = async (args) => {
    const { data } = await this.$http.post<NoteReadQuery>(this.getUrl(), args);

    return new NoteReadModel(data);
  };

  edit: INotesService['edit'] = async (id, args) => {
    const { data } = await this.$http.patch<NoteReadQuery>(this.getUrl(id), args);

    return new NoteReadModel(data);
  };

  delete: INotesService['delete'] = async (id) => {
    await this.$http.delete(this.getUrl(id));
  };
}
