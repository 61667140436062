import { SyntheticEvent, ChangeEvent } from 'react';

import { withStyle } from 'baseui';
import { FormControl, FormControlOverrides } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Select } from 'baseui/select';

import { FlexRowContainer, Button } from '@shared/components';
import { Icon } from '@shared/components/Icon';

const Wrapper = withStyle(FlexRowContainer, ({ $theme }: any) => ({
  width: '100%',
  backgroundColor: $theme.colors.white,
  borderBottom: `1px solid ${$theme.colors.mono400}`,
  alignItems: 'flex-start',
  paddingTop: $theme.sizing.scale100,
  paddingLeft: $theme.sizing.scale100,
}));

const ButtonWrapper = withStyle(FlexRowContainer, ({ $theme }: any) => ({
  paddingBottom: $theme.sizing.scale100,
  paddingRight: $theme.sizing.scale100,
}));

const SectionWrapper = withStyle(FlexRowContainer, ({ $theme }: any) => ({
  paddingBottom: $theme.sizing.scale100,
  paddingRight: $theme.sizing.scale100,
  width: '100%',
}));

const formControlOverrides: FormControlOverrides = {
  ControlContainer: {
    style: () => {
      return { marginBottom: 0 };
    },
  },
};

export const CreateUserRow = ({ index, user, roles, errors, onChange, onRemove }: any) => {
  const update = (key: string, value: string) => {
    onChange({ ...user, [key]: value });
  };

  const handleUpdate = (event: SyntheticEvent, key: string) => {
    update(key, (event.target as HTMLInputElement).value);
  };

  return (
    <Wrapper>
      <SectionWrapper>
        <FormControl error={errors['firstName']} overrides={formControlOverrides}>
          <Input
            id={`firstName_${index}`}
            value={user.firstName}
            autoFocus={true}
            autoComplete="off"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdate(e, 'firstName')}
            placeholder="First Name"
            error={!!errors['firstName']}
          />
        </FormControl>
      </SectionWrapper>
      <SectionWrapper>
        <FormControl error={errors['lastName']} overrides={formControlOverrides}>
          <Input
            id={`lastName_${index}`}
            value={user.lastName}
            autoComplete="off"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdate(e, 'lastName')}
            placeholder="Last Name"
            error={!!errors['lastName']}
          />
        </FormControl>
      </SectionWrapper>
      <SectionWrapper>
        <FormControl error={errors['email']} overrides={formControlOverrides}>
          <Input
            id={`email_${index}`}
            value={user.email}
            autoComplete="off"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdate(e, 'email')}
            placeholder="Email"
            error={!!errors['email']}
          />
        </FormControl>
      </SectionWrapper>
      <SectionWrapper>
        <FormControl error={errors['role']} overrides={formControlOverrides}>
          <Select
            id={`role_${index}`}
            options={roles}
            labelKey="name"
            valueKey="id"
            value={roles.filter((role: any) => role.id === user.role)}
            clearable={false}
            deleteRemoves={false}
            escapeClearsValue={false}
            onChange={(value: any) => update('role', value.value[0].id)}
            placeholder="Role"
            overrides={{
              Root: {
                style: {
                  width: '100%',
                },
              },
            }}
          />
        </FormControl>
      </SectionWrapper>
      <ButtonWrapper>
        <Button kind="minimal" onClick={onRemove}>
          <Icon size={20} icon="SolidTrashAlt" color="primary" />
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
