import { palette } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    image: {
      width: 'inherit',
      height: 'inherit',
    },
    imageHidden: {
      display: 'none',
    },
    loader: {
      width: 'inherit',
      height: 'inherit',
      position: 'absolute',
    },
    imageEmptyStateIcon: {
      color: palette.grey[400],
    },
    imageEmptyStateHeading: {
      color: palette.grey[400],
      fontSize: '14px',
    },
  });
