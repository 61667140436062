import { Button } from 'baseui/button';

export const IconButton = (props) => {
  return (
    <Button
      overrides={{
        BaseButton: {
          style: {
            paddingLeft: '0px',
            paddingRight: '0px',
            paddingTop: '0px',
            paddingBottom: '0px',
            width: '40px',
            height: '40px',
          },
        },
      }}
      {...props}
      kind="minimal"
      shape="round"
    />
  );
};
