import { CSSProperties, HTMLAttributes, RefObject, ComponentType, forwardRef } from 'react';

import { AlignItems, FlexDirection, JustifyContent } from 'baseui/block';
import cx from 'classnames';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Flex.styles';

export type FlexWrap = 'wrap' | 'nowrap';

export type FlexProps = AppWithStyles<typeof styles> &
  HTMLAttributes<HTMLDivElement> & {
    reference?: RefObject<HTMLDivElement>;
    style?: CSSProperties;
    alignItems?: AlignItems;
    flexDirection?: FlexDirection;
    justifyContent?: JustifyContent;
    flexWrap?: FlexWrap;
    component?: ComponentType<any> | string;
    componentProps?: { [key: string]: any };
    name?: string;
  };

const FlexComponent = forwardRef<HTMLDivElement, FlexProps>(
  (
    {
      style,
      classes,
      className,
      children,
      alignItems,
      flexDirection,
      justifyContent,
      flexWrap,
      component: Component = 'div',
      componentProps,
      reference,
      name,
      ...props
    },
    ref
  ) => {
    return (
      <Component
        ref={reference || ref}
        name={name}
        className={cx(
          classes.root,
          className,
          classes[`alignItems-${alignItems}`],
          classes[`flexDirection-${flexDirection}`],
          classes[`justifyContent-${justifyContent}`],
          classes[`flexWrap-${flexWrap}`]
        )}
        style={style}
        {...componentProps}
        {...props}
      >
        {children}
      </Component>
    );
  }
);

export const Flex = appWithStyles(styles)(FlexComponent);
