import { styled, withStyle } from 'baseui';

import { inject } from '@core/di/di-utils';

import { FlexColumnContainer, FlexFullContainer, Paragraph1, Icon } from '@shared/components';
import { DI_TOKENS } from '@shared/constants/di';
import { IAuthService } from '@shared/types/services/auth';
import { getRoute, ROUTES } from '@shared/utils/getRoute';
import { history } from '@shared/utils/history';

const Wrapper = withStyle(FlexColumnContainer, ({ $theme }: any) => ({
  width: '164px',
  backgroundColor: $theme.colors.white,
  borderRadius: $theme.borders.radius200,
}));

const Item = styled('div', ({ $theme }: any) => ({
  display: 'flex',
  padding: $theme.sizing.scale600,
  cursor: 'pointer',
  color: $theme.colors.mono800,
  textDecoration: 'none',
  ':hover': {
    backgroundColor: $theme.colors.mono200,
  },
}));

export const AccountPopover = () => {
  const authService = inject<IAuthService>(DI_TOKENS.authService);

  const showAccount = authService.currentUser.tenant.id === authService.currentTenant.id;

  const handleLogout = () => {
    authService.logout();
    history.push(getRoute('LOGIN'));
  };

  const handleGoToProfile = () => {
    history.push(ROUTES.myAccount.root);
  };

  return (
    <Wrapper>
      {showAccount ? (
        <Item onClick={handleGoToProfile}>
          <Paragraph1>My Account</Paragraph1>
        </Item>
      ) : null}
      <Item onClick={handleLogout}>
        <Paragraph1>Log out</Paragraph1>
        <FlexFullContainer />
        <Icon size={20} color="colorSecondary" icon="SolidSignOutAlt" />
      </Item>
    </Wrapper>
  );
};
