import { Component } from 'react';

import { Block } from 'baseui/block';
import { ParagraphMedium } from 'baseui/typography';

class ErrorBoundary extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  render() {
    if (this.state.error !== null) {
      return (
        <Block>
          <ParagraphMedium>Error: {this.state.error?.message}</ParagraphMedium>
        </Block>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
