import { radius } from '@core/theme/units/radius';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {},
    button: {
      marginRight: '0px',
      marginLeft: '0px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',

      '&:first-of-type': {
        borderTopLeftRadius: radius.containers.primary,
        borderBottomLeftRadius: radius.containers.primary,
      },

      '&:last-of-type': {
        borderTopRightRadius: radius.containers.primary,
        borderBottomRightRadius: radius.containers.primary,
      },
    },
  });
