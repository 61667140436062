import cx from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './app-link.styles';

export type AppLinkProps = AppWithStyles<typeof styles> & Partial<LinkProps> & {};

const AppLinkComponent = ({
  classes,
  className,
  children,
  href,
  to,
  ...otherProps
}: AppLinkProps) => {
  if (to) {
    return (
      <Link className={cx(classes.root, className)} to={to} {...otherProps}>
        {children}
      </Link>
    );
  }

  return (
    <a className={cx(classes.root, className)} href={href} {...otherProps}>
      {children}
    </a>
  );
};

export const AppLink = appWithStyles(styles)(AppLinkComponent);
