import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {},
    iconWrapper: {
      position: 'relative',
    },
    content: {
      padding: spacing(4),
      backgroundColor: palette.common.white,
    },
    notificationsLabel: {
      fontSize: '16px',
      fontWeight: 600,
      color: palette.text.primary,
    },
    list: {
      margin: spacing(4, 0),
    },
  });
