import { RefObject, ReactNode } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './FormContentWrapper.styles';

type Props = AppWithStyles<typeof styles> & {
  reference?: RefObject<HTMLDivElement>;
  children: ReactNode;
};

const FormContentWrapperComponent = ({ classes, children, reference }: Props) => {
  return (
    <div className={classes.root} ref={reference}>
      {children}
    </div>
  );
};

export const FormContentWrapper = appWithStyles(styles)(FormContentWrapperComponent);
