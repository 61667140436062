import {
  ReactNode,
  ComponentType,
  CSSProperties,
  MouseEventHandler,
  useRef,
  MouseEvent,
} from 'react';

import cx from 'classnames';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { AppTooltip } from '@shared/components';

import { styles } from './Ellipsis.styles';

export const ellipsisStyles: CSSRules = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export type EllipsisProps = AppWithStyles<typeof styles> & {
  className?: string;
  text?: string | number | ReactNode;
  tooltipText?: string;
  component?: ComponentType<any> | string;
  componentProps?: { [key: string]: any };
  width?: string | number;
  maxWidth?: string | number;
  withTooltip?: boolean;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLElement>;
  children?: ReactNode;
};

const EllipsisComponent = ({
  className,
  classes,
  width,
  maxWidth,
  children,
  text,
  tooltipText,
  withTooltip = true,
  component: Component,
  componentProps,
  style,
  ...otherProps
}: EllipsisProps) => {
  const contentStyles = Component ? { maxWidth: '100%' } : { width, maxWidth };
  const tooltipContent = tooltipText || String(text) || '';
  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = useRef(null);
  const areaRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  const getAnchorEl = () => {
    return new DOMRect(
      areaRef.current!.getBoundingClientRect().x +
        areaRef.current!.getBoundingClientRect().width / 2,
      areaRef.current!.getBoundingClientRect().y,
      0,
      0
    );
  };

  const content = (
    <>
      {withTooltip ? (
        <AppTooltip
          title={tooltipContent}
          PopperProps={{
            popperRef,
            anchorEl: {
              getBoundingClientRect: () => getAnchorEl(),
            },
          }}
        >
          <div
            ref={areaRef}
            onMouseOver={handleMouseMove}
            style={{ ...contentStyles, ...style }}
            className={cx(classes.root, className)}
            {...otherProps}
          >
            {text || children}
          </div>
        </AppTooltip>
      ) : (
        <div style={{ ...contentStyles, ...style }} className={classes.root} {...otherProps}>
          {text || children}
        </div>
      )}
    </>
  );

  if (Component) {
    return (
      <Component style={{ width, maxWidth: maxWidth || '100%', ...style }} {...componentProps}>
        {content}
      </Component>
    );
  }

  return content;
};

export const Ellipsis = appWithStyles(styles)(EllipsisComponent);
