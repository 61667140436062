import { Redirect, Route } from 'react-router-dom';

import { ROUTES } from '@shared/utils/getRoute';

type Props = {
  allowedIf: boolean;
  redirect?: string;
  [elemName: string]: any;
};

export const ProtectedRoute = (props: Props) => {
  const { component: Component, render, allowedIf, redirect, ...rest } = props;

  let innerRender = render;

  if (!innerRender) {
    innerRender = (innerProps) => {
      return allowedIf ? <Component {...innerProps} /> : <Redirect to={redirect || ROUTES.root} />;
    };
  } else {
    if (!allowedIf) {
      innerRender = () => <Redirect to={redirect || ROUTES.root} />;
    }
  }
  return <Route {...rest} component={innerRender} />;
};
