import { BaseModel } from '@core/base-model';

import { GroupListQuery } from '@shared/models/group/list-model';

import { EventUserStatus } from './user-status';

type BelongUser = {
  userId: string;
  userStatus: EventUserStatus;
  fullName: string;
};

type BelongToGroup = {
  groupId: GroupListQuery['id'];
  groupName: GroupListQuery['name'];
  groupProfileName: GroupListQuery['profileName'];
  participants: Array<BelongUser>;
};

export interface EventListQuery {
  id: string;
  name: string;
  isFullDay: boolean;
  startDate: string;
  endDate: string;
  notes?: string;
  userStatus: EventUserStatus;
  eventColor?: string;
  belongsToUsers?: Array<BelongUser>;
  belongsToGroups?: Array<BelongToGroup>;
}

export class EventListModel extends BaseModel<EventListQuery> {
  private id: string;
  private name: string;
  private startDate: string;
  private isFullDay: boolean;
  private endDate: string;
  private notes?: string;
  private userStatus: EventUserStatus;
  private eventColor?: string;
  private belongsToUsers: Array<BelongUser>;
  private belongsToGroups?: Array<BelongToGroup>;

  constructor(data: EventListQuery) {
    super();

    this.update(data);
  }

  get asJson(): EventListQuery {
    return {
      id: this.id,
      name: this.name,
      startDate: this.startDate,
      isFullDay: this.isFullDay,
      endDate: this.endDate,
      notes: this.notes,
      userStatus: this.userStatus,
      eventColor: this.eventColor,
      belongsToUsers: this.belongsToUsers,
      belongsToGroups: this.belongsToGroups,
    };
  }
}
