import { inject } from '@core/di/di-utils';
import { appMakeObservable, appObservable, appToJs } from '@core/state-management/utils';

import { showNotification } from '@shared/components/app-notification';
import { DI_TOKENS } from '@shared/constants/di';
import { PageQueryDTO } from '@shared/models/pages/page-query-model';
import { SubmissionReadQuery } from '@shared/models/submission/read-model';
import { IPageService } from '@shared/types/services/pages';
import { ISubmissionsService } from '@shared/types/services/submissions';
import { showErrors } from '@shared/utils/errors';

export class AssignmentDropdownViewModel {
  private readonly pagesService = inject<IPageService>(DI_TOKENS.pagesService);
  private readonly submissionsService = inject<ISubmissionsService>(DI_TOKENS.submissionsService);

  private readonly _submissionId: SubmissionReadQuery['id'] = null;
  private readonly _activePage: SubmissionReadQuery['page'] = null;

  private _assignment: SubmissionReadQuery['assignment'] = null;
  private _pagesList: PageQueryDTO[] = [];
  private _pageTypeId: string = null;
  private _newPage: SubmissionReadQuery['page'] = null;
  private _loading: boolean = false;
  private _searchLoading: boolean = false;
  private _searchError: boolean = false;
  private _isModalOpen: boolean = false;
  private _searchValue: string = '';

  constructor(submission: SubmissionReadQuery) {
    this._activePage = submission.page;
    this._assignment = submission.assignment;
    this._submissionId = submission.id;

    this.getPagesList();

    appMakeObservable(this, {
      _activePage: appObservable,
      _assignment: appObservable,
      _pagesList: appObservable,
      _newPage: appObservable,
      _submissionId: appObservable,
      _loading: appObservable,
      _searchLoading: appObservable,
      _isModalOpen: appObservable,
      _searchValue: appObservable,
    });
  }

  get pagesList() {
    return appToJs(this._pagesList);
  }

  get newPage() {
    return this._newPage;
  }

  get activePage() {
    return this._activePage;
  }

  get loading() {
    return this._loading;
  }

  get searchLoading() {
    return this._searchLoading;
  }

  get searchError() {
    return this._searchError;
  }

  get isModalOpen() {
    return this._isModalOpen;
  }

  get searchValue() {
    return this._searchValue;
  }

  set searchValue(value: string) {
    this._searchValue = value;
  }

  handleOpenAssignModal = (page: SubmissionReadQuery['page']) => {
    if (page.id === this._activePage.id) return;

    this.updateNewPage(page);
    this._isModalOpen = true;
  };

  handleCloseAssignModal = () => {
    this._isModalOpen = false;
  };

  updateNewPage(page: SubmissionReadQuery['page']) {
    this._newPage = page;
  }

  getPagesList = async () => {
    this._loading = true;
    try {
      const page = await this.pagesService.getPageById(this._activePage.id);
      this._pageTypeId = page.pageType.id;
      const pagesList = await this.pagesService.getBulkPages({
        ids: this._assignment.pages,
        pageTypeId: this._pageTypeId,
      });

      this._pagesList = pagesList.items.sort((a, b) => a.name.localeCompare(b.name));
    } catch (err) {
      showErrors(err?.response?.data?.errors, {
        notificationText: 'Something went wrong while fetching pages',
      });
    } finally {
      this._loading = false;
    }
  };

  handleReassign = async () => {
    try {
      await this.submissionsService.reassignSubmission(this._assignment.id, this._submissionId, {
        page: this._newPage.id,
      });
      showNotification('The submission was successfully reassigned.');
    } catch (err) {
      showErrors(err?.response?.data?.errors, {
        notificationText: 'Something went wrong while re-assigning submission',
      });
    }
  };

  handleSearchPages = async () => {
    this._searchLoading = true;
    this._searchError = false;
    try {
      const pages = await this.pagesService.getBulkPages({
        ids: this._assignment.pages,
        pageTypeId: this._pageTypeId,
      });

      const filteredPages = pages.items
        .filter((page) => page.name.toLowerCase().includes(this._searchValue.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));

      if (filteredPages.length === 0) {
        this._searchError = true;
        return;
      }

      this._pagesList = filteredPages;
    } catch (err) {
      showErrors(err?.response?.data?.errors, {
        notificationText: 'Something went wrong while searching pages',
      });
    } finally {
      this._searchLoading = false;
    }
  };
}
