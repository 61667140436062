import { injectable } from '@core/di/di-utils';
import { inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import {
  PageGroupAssignments,
  QuerySearchPageAndGroups,
} from '@shared/models/dictionaries/query-search-page-and-groups';
import { QuerySearchPageGroups } from '@shared/models/dictionaries/query-search-page-groups';
import { SearchPageGroupsDTO } from '@shared/models/dictionaries/search-page-groups-dto';
import { GroupListQuery } from '@shared/models/group/list-model';
import { UserHeaderModel, UserHeaderQuery } from '@shared/models/user/header-model';
import { IDictionariesService } from '@shared/types/services/dictionaries';

@injectable()
export class DictionariesService extends BaseService implements IDictionariesService {
  static diToken = Symbol('dictionaries-service');

  private $http = inject<HTTPClient>(HTTPClient.diToken);
  protected urlPrefix = '/dictionaries';

  getGroupsDictionary = async (name, excludeIds) => {
    const { data } = await this.$http.post<Array<GroupListQuery>>(`${this.urlPrefix}/groups`, {
      name,
      excludeIds,
    });

    return data.map((group) => {
      return {
        ...group,
        profileName: group.autoGroupped ? group.profileName : 'Custom groups',
      };
    });
  };

  getUsersDictionary = async (fullName: string, excludeIds: Array<string>) => {
    const { data } = await this.$http.post<Array<UserHeaderQuery>>(`${this.urlPrefix}/users`, {
      fullName,
      excludeIds,
    });

    return data.map((user) => new UserHeaderModel(user));
  };

  getReactionList = async () => {
    const { data } = await this.$http.get<Array<string>>(`${this.urlPrefix}/reactions`);

    return data;
  };

  getPageAndGroups = async (query: QuerySearchPageAndGroups) => {
    const { data } = await this.$http.post<Array<SearchPageGroupsDTO>>(
      `${this.urlPrefix}/pages-and-groups`,
      {
        ...query,
        // DECIDED SET LIMIT FOR ALL QUERIES
        limit: 50,
      }
    );

    return data;
  };

  getPageGroups = async (query: QuerySearchPageGroups) => {
    const { data } = await this.$http.post<Array<SearchPageGroupsDTO>>(
      `${this.urlPrefix}/page-groups`,
      query
    );

    return data;
  };

  getPageGroupsAssignment = async (ids: Array<string>) => {
    const { data } = await this.$http.post<PageGroupAssignments>(
      `${this.urlPrefix}/page-groups-assignments`,
      { ids }
    );

    return data;
  };
}
