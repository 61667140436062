import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {},
    content: {
      marginTop: spacing(4),
    },
    contentHidden: {
      display: 'none',
    },
  });
