import { inject } from '@core/di/di-utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';

import { ActivityType } from '@shared/components/submission/components/activity/activity.types';
import { DI_TOKENS } from '@shared/constants/di';
import { CommentOwner, CommentOwnerType } from '@shared/models/comment/comment-owner';
import { ICommentsService } from '@shared/types/services/comments';
import { showErrors } from '@shared/utils/errors';

export class ActivityViewModel {
  private readonly commentsService = inject<ICommentsService>(DI_TOKENS.commentsService);

  private _commentCount: number = 0;
  private _countLoading: boolean = false;
  private _currentTab: ActivityType = ActivityType.log;

  private readonly _owner: CommentOwner;

  constructor(submissionId: string) {
    this._owner = this.createOwner(submissionId);

    appMakeObservable(this, {
      _countLoading: appObservable,
      _commentCount: appObservable,
      _owner: appObservable,
      _currentTab: appObservable,
    });
  }

  async initialize(openCommentId?: string) {
    await this.getCommentsCount();
    if (openCommentId) {
      this.updateCurrentTab(ActivityType.comments);
    }
  }

  get commentCount() {
    return this._commentCount;
  }

  get countLoading() {
    return this._countLoading;
  }

  get owner() {
    return this._owner;
  }

  get currentTab() {
    return this._currentTab;
  }

  updateCurrentTab(tab: ActivityType) {
    this._currentTab = tab;
  }

  private createOwner(id: string) {
    return {
      id,
      type: CommentOwnerType.submission,
    };
  }

  updateCommentCount(count: number) {
    this._commentCount = count;
  }

  async getCommentsCount() {
    this._countLoading = true;
    try {
      this._commentCount = await this.commentsService.getCommentsCount(this._owner);
    } catch (e) {
      console.error(e);
      showErrors(e?.response?.data?.errors, {
        notificationText: 'Something went wrong while loading comments count',
      });
    } finally {
      this._countLoading = false;
    }
  }
}
