export const SPACING_GAP = 4;

export const spacing = (...gaps: ReadonlyArray<number | string>): string => {
  if (process.env.NODE_ENV !== 'production') {
    if (!(gaps.length <= 4)) {
      throw new Error(
        `Theme: Too many arguments provided, expected between 0 and 4, got ${gaps.length}`
      );
    }
  }

  const transform = (value: string | number) => {
    return typeof value === 'number' ? `${value * SPACING_GAP}px` : value;
  };

  if (gaps.length === 0) {
    const defaultValue = 1;

    return transform(defaultValue);
  }

  return gaps.map(transform).join(' ');
};
