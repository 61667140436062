import { inject } from '@core/di/di-utils';
import { appMakeObservable, appObservable, appToJs } from '@core/state-management/utils';

import { SelectOption } from '@shared/components';
import { TagsService } from '@shared/services/tags';
import { ITagsService } from '@shared/types/services/tags';
import { showErrors } from '@shared/utils/errors';

export class TagSelectViewModel {
  private readonly tagsService = inject<ITagsService>(TagsService.diToken);

  private _loading: boolean = false;
  private _options: Array<SelectOption> = [];

  constructor() {
    appMakeObservable(this, {
      _loading: appObservable,
      _options: appObservable,
    });

    this.getTagList();
  }

  get loading() {
    return this._loading;
  }

  get options() {
    return appToJs(this._options);
  }

  async getTagList() {
    try {
      this._loading = true;

      const { tags } = await this.tagsService.getTagList();

      this._options = tags.map(({ id, name }) => ({
        id,
        label: name,
      }));
    } catch (e) {
      console.error(e);
      showErrors(e?.response?.data?.errors, {
        notificationText: 'Something went wrong while fetching tags',
      });
    } finally {
      this._loading = false;
    }
  }
}
