import { useEffect, useMemo, useState } from 'react';

import Divider from '@mui/material/Divider';
import cx from 'classnames';
import { Button } from 'src/shared/components/button';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Flex } from '@shared/components/Flex';
import { Spinner } from '@shared/components/Spinner';
import { CommentOwner } from '@shared/models/comment/comment-owner';
import { CommentListQuery } from '@shared/models/comment/list-model';
import { CommentReadQuery } from '@shared/models/comment/read-model';
import { UserReadQuery } from '@shared/models/user/read-model';
import { Pagination } from '@shared/types/services';
import { showErrors } from '@shared/utils/errors';

import { ThreadViewModel } from './thread.vm';

import { Comment } from '../comment';

import { styles } from './thread.styles';

type Props = AppWithStyles<typeof styles> & {
  owner: CommentOwner;
  parentComment: CommentReadQuery;
  currentUser: UserReadQuery;
  shouldRefreshThread: boolean;
  onDeleteSuccess: () => Promise<void>;
  onUpdateSuccess: (updatedComment: CommentReadQuery) => void;
  onReplySuccess: () => Promise<void>;
  scrollTo: (top: number) => void;
  commentId?: string;
  isOpenReplace: boolean;
};

const ThreadComponent = ({
  classes,
  parentComment,
  owner,
  currentUser,
  shouldRefreshThread,
  onDeleteSuccess,
  onUpdateSuccess,
  onReplySuccess,
  scrollTo,
  commentId,
  isOpenReplace,
}: Props) => {
  const DEFAULT_LIMIT = 10;

  const $vm = useMemo(() => new ThreadViewModel(), []);

  const [data, setData] = useState<Array<CommentListQuery>>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [total, setTotal] = useState(parentComment.childrenCount);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [shouldShowReplies, setShowReplies] = useState(false);

  useEffect(() => {
    if (isOpenReplace) {
      setShowReplies(true);
      getReplies();
    }
  }, [isOpenReplace]);

  const hideReplies = () => {
    setShowReplies(false);
  };

  const showReplies = () => {
    setShowReplies(true);
  };

  const getList = async (skip: Pagination['skip']) => {
    const response = await $vm.getList({
      parentId: parentComment.id,
      owner,
      pagination: {
        skip,
        limit: DEFAULT_LIMIT,
      },
    });

    return {
      comments: response.comments.map(({ asJson }) => asJson),
      total: response.total,
    };
  };

  const refreshList = async () => {
    const updatedData = [];
    let updatedTotal = 0;

    for (let iteration = 0; iteration < skip / DEFAULT_LIMIT + 1; iteration++) {
      const response = await getList(iteration * DEFAULT_LIMIT);

      updatedTotal = response.total;
      updatedData.push(...response.comments);
    }

    setData(updatedData);
    setTotal(updatedTotal);
  };

  const getReplies = () => {
    showReplies();

    const loadMore = async () => {
      try {
        setLoading(true);
        const updatedSkip = data.length ? skip + DEFAULT_LIMIT : 0;
        const response = await getList(updatedSkip);

        if (response.total === total || total === 0) {
          setData([...data, ...response.comments]);
          setTotal(response.total);
        } else {
          await refreshList();
        }

        setSkip(updatedSkip);
      } catch (e) {
        showErrors(e?.response?.data?.errors, {
          notificationText: `Something went wrong while loading${
            !data.length ? ' ' : ' more '
          }replies`,
        });
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    if (!data.length) {
      loadMore();
    }
    if (shouldShowReplies) {
      return loadMore();
    }
  };

  const updateReply = (updatedComment: CommentReadQuery) => {
    setData(
      data.map((comment) => {
        return comment.id === updatedComment.id ? updatedComment : comment;
      })
    );
  };

  const handleReplySuccess = async () => {
    await refreshList();
    await onReplySuccess();
  };

  useEffect(() => {
    const refreshManually = async () => {
      try {
        setRefreshing(true);
        await refreshList();
      } catch (err) {
        showErrors(err?.response?.data?.errors, {
          notificationText: 'Something went wrong while refreshing replies',
        });
        console.error(err);
      } finally {
        setRefreshing(false);
      }
    };

    if (shouldRefreshThread) {
      refreshManually();
    }
  }, [shouldRefreshThread]);

  const renderReplies = () => {
    if (total) {
      const shouldShowTotal = !data.length || !shouldShowReplies;
      const allItemsVisible = total <= DEFAULT_LIMIT;
      const leftItemsAmount = total - data.length;
      const repliesCount = shouldShowTotal || allItemsVisible ? total : leftItemsAmount;
      const buttonConfig =
        total !== data.length || !shouldShowReplies
          ? {
              text: `Show replies (${repliesCount})`,
              onClick: getReplies,
            }
          : {
              text: 'Hide replies',
              onClick: hideReplies,
            };

      return (
        <>
          {loading && !data.length && <Spinner size={20} withWrapper minHeight="100px" />}
          {shouldShowReplies && (
            <div className={cx(classes.list, { [classes.disabledList]: refreshing })}>
              {data.map((comment) => {
                return (
                  <Comment
                    key={comment.id}
                    scrollTo={scrollTo}
                    active={comment.id === commentId}
                    owner={owner}
                    currentUser={currentUser}
                    onDeleteSuccess={handleReplySuccess}
                    onUpdateSuccess={updateReply}
                    classes={{
                      root: cx(classes.reply),
                    }}
                    onReactionUpdateSuccess={updateReply}
                    {...comment}
                  />
                );
              })}
            </div>
          )}
          <Divider classes={{ root: classes.divider }} />
          <Flex justifyContent="center">
            <Button
              isLoading={Boolean(loading && data.length) || refreshing}
              disabled={loading && !data.length}
              kind="minimal"
              size="compact"
              {...buttonConfig}
              className={classes.showMoreButton}
            />
          </Flex>
        </>
      );
    }
  };

  return (
    <>
      <Comment
        owner={owner}
        scrollTo={scrollTo}
        active={parentComment.id === commentId}
        currentUser={currentUser}
        onDeleteSuccess={onDeleteSuccess}
        onUpdateSuccess={onUpdateSuccess}
        onReplySuccess={handleReplySuccess}
        {...parentComment}
      />
      {renderReplies()}
    </>
  );
};

export const Thread = appWithStyles(styles)(ThreadComponent);
