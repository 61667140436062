import { useMemo, ReactNode } from 'react';

import cx from 'classnames';
import { Avatar } from 'src/shared/components/avatar';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { AppLink, Flex, Ellipsis } from '@shared/components';
import { AssignmentSubmissionPersonTag } from '@shared/models/assignments/assignment-model';
import { SubmissionReadQuery } from '@shared/models/submission/read-model';
import { UserReadQuery } from '@shared/models/user/read-model';
import { formatDate } from '@shared/utils/date';
import { ROUTES } from '@shared/utils/getRoute';
import { getStatusLabel } from '@shared/utils/insights-activity';

import { AssignmentDropdown } from './components/assignment-dropdown';

import {
  styles,
  AVATAR_SIZE,
  USER_NAME_MARGIN,
  METADATA_FIELD_NAME_MARGIN,
  METADATA_FIELD_NAME_WIDTH,
} from './main-info.styles';

type Props = AppWithStyles<typeof styles> & {
  currentUser: UserReadQuery;
  status: string;
  onUpdateSubmission: () => Promise<void>;
  submission: SubmissionReadQuery;
};

const MainInfoComponent = ({
  classes,
  submission,
  currentUser,
  status,
  onUpdateSubmission,
}: Props) => {
  const creator = useMemo(() => {
    return submission.people.find(({ tags }) =>
      tags.includes(AssignmentSubmissionPersonTag.CREATOR)
    )?.user;
  }, [submission.people]);

  const hasPermission = currentUser.permissions.includes('managePages');

  const metadata = () => {
    const config: Array<{
      name: string;
      component?: ReactNode;
      value?: unknown;
      className?: string;
    }> = [
      {
        name: 'Creation date',
        value: formatDate(submission.createdAt, { format: 'lll' }),
      },
      {
        name: 'Creator',
        component: (
          <AppLink to={ROUTES.userProfile(creator.id)} className={classes.userField}>
            <Flex alignItems="center">
              <Avatar size={AVATAR_SIZE} src={creator.profileUrl} name={`${creator.fullName}`} />
              <Ellipsis
                maxWidth={`calc(100% - ${AVATAR_SIZE + USER_NAME_MARGIN}px)`}
                text={`${creator.fullName}`}
                classes={{ root: classes.userName }}
              />
            </Flex>
          </AppLink>
        ),
      },
      {
        name: '',
        component: (
          <AssignmentDropdown
            submission={submission}
            hasPermission={hasPermission}
            onUpdateSubmission={onUpdateSubmission}
          />
        ),
      },
    ];

    return (
      <Flex
        alignItems="baseline"
        justifyContent="space-between"
        flexWrap="wrap"
        classes={{ root: classes.metadata }}
      >
        {config.map((field) => {
          return (
            <Flex alignItems="baseline" key={field.name} classes={{ root: classes.metadataField }}>
              {field.name ? <h5 className={classes.metadataFieldName}>{field.name}:</h5> : null}
              {field.value && (
                <Ellipsis
                  maxWidth={`calc(100% - ${
                    METADATA_FIELD_NAME_WIDTH + METADATA_FIELD_NAME_MARGIN
                  }px)`}
                  text={field.value as string}
                  classes={{ root: cx(classes.metadataFieldValue, field?.className) }}
                />
              )}
              {field.component}
            </Flex>
          );
        })}
      </Flex>
    );
  };

  return (
    <div className={classes.root}>
      <Flex alignItems="center">
        <Ellipsis text={submission.assignment.name} classes={{ root: classes.title }} />
        <div className={classes.status}>{getStatusLabel(status)}</div>
      </Flex>
      <Ellipsis text={submission.assignment.description} classes={{ root: classes.description }} />
      {metadata()}
    </div>
  );
};

export const MainInfo = appWithStyles(styles)(appObserver(MainInfoComponent));
