import { v4 as uuidv4 } from 'uuid';

import { generateId } from '@shared/utils/id';

export const parseBytes = (
  bytes: number,
  decimals: number = 0
): { amount: number; unit: string } => {
  if (bytes === 0) return { amount: 0, unit: 'Bytes' };

  const k = 1024;
  const dm = Math.max(0, decimals);
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    amount: Number((bytes / Math.pow(k, i)).toFixed(dm)),
    unit: sizes[i],
  };
};

export const getBase64FromFile = (file: File): Promise<string> => {
  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.onload = ({ target }) => {
      if (target) {
        resolve(String(target.result));
      }
    };

    reader.readAsDataURL(file);
  });
};

export const base64ToFile = (base64: string) => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);

  let n = bstr.length;

  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], generateId(), { type: mime });
};

export const extractFileExtensionFromFullName = (fileName: string) => {
  return fileName.split('.').pop();
};

export const extractFileNameFromFullName = (fileName: string) => {
  return fileName.replace(/\.[^/.]+$/, '');
};

export const getFileExtensionLabel = (extension: string): string => {
  const labels = {
    'image/png': 'PNG',
    'image/jpeg': 'JPEG',
  };

  return (labels[extension] || extension).toUpperCase();
};

export const downloadFile = (
  { blobParts, url }: { blobParts?: BlobPart[]; url?: string },
  filename?: string
) => {
  let href = url;

  if (blobParts) {
    const blob = new Blob(blobParts);
    href = window.URL.createObjectURL(blob);
  }

  const a = document.createElement('a');

  a.href = href;

  if (filename) {
    a.download = filename;
  }

  a.click();
};

export const presignedUrlCacheQueries = () => {
  return {
    downloadId: uuidv4(),
  };
};

export const secondsToHumanReadableFormat = (seconds: number) =>
  new Date(seconds * 1000).toISOString().substr(11, 8).replace('00:', '');

export const downloadFileByUrl = async (url: string, filename: string) => {
  const file = await fetch(url);
  const fileBlob = await file.blob();
  const fileUrl = URL.createObjectURL(fileBlob);

  downloadFile({ url: fileUrl }, filename);
};
