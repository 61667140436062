import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { ResourceDictionaryDTO } from '@shared/models/resource/dictionary-model';
import { ResourceFileCreateDTO } from '@shared/models/resource/file-create-model';
import { ResourceFileUpdateDTO } from '@shared/models/resource/file-update-model';
import { ResourceLinkCreateDTO } from '@shared/models/resource/link-create-model';
import { ResourceLinkUpdateDTO } from '@shared/models/resource/link-update-model';
import { ResourceReadModel, ResourceReadDTO } from '@shared/models/resource/read-model';
import { FilesService } from '@shared/services/files';

@injectable()
export class ResourcesService extends BaseService {
  static diToken = Symbol('resources-service');

  protected urlPrefix = '/resources';
  private filesService = inject<FilesService>(FilesService.diToken);
  private $http = inject<HTTPClient>(HTTPClient.diToken);

  async createResourceLink(data: ResourceLinkCreateDTO) {
    const res = await this.$http.post<ResourceReadDTO>(this.getUrl('link'), data);

    return new ResourceReadModel(res.data);
  }

  async createResourceFile(data: ResourceFileCreateDTO) {
    const res = await this.$http.post<ResourceReadDTO>(this.getUrl('file'), data);

    return new ResourceReadModel(res.data);
  }

  editResourceLink(id: Id, data: ResourceLinkUpdateDTO) {
    return this.$http.put<ResourceReadDTO>(this.getUrl(`link/${id}`), data);
  }

  editResourceFile(id: Id, data: ResourceFileUpdateDTO) {
    return this.$http.put<ResourceReadDTO>(this.getUrl(`file/${id}`), data);
  }

  getFile: FilesService['get'] = (...params) => {
    return this.filesService.get(...params);
  };

  uploadFile: FilesService['upload'] = (...params) => {
    return this.filesService.upload(...params);
  };

  assignToPage(resourceId: ResourceReadDTO['id'], pages: Array<Id>) {
    return this.$http.post(this.getUrl(`${resourceId}/pages`), { pages });
  }

  async getResourcesDictionary(
    resourceName: ResourceReadDTO['name'],
    selectedResourcesId: Array<string>,
    options: { limit: number }
  ) {
    const {
      data: { items },
    } = await this.$http.post<{ items: Array<ResourceDictionaryDTO> }>(this.getUrl('query'), {
      query: {
        pagination: {
          limit: options.limit,
        },
        filters: {
          name: resourceName,
          exclude: selectedResourcesId,
        },
      },
    });

    return items;
  }

  async getResourcesList() {
    const { data } = await this.$http.get<Array<ResourceReadDTO>>(this.getUrl('list'));
    return data;
  }

  async archiveResource(resourceId: ResourceReadDTO['id']) {
    await this.$http.post(this.getUrl(`${resourceId}/archivation`));
  }
}
