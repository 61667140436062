import { useState } from 'react';

import { inject } from '@core/di/di-utils';
import { appObserver } from '@core/state-management/utils';
import { palette } from '@core/theme/units/palette';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Button, Badge, Flex, StatefulPopover } from '@shared/components';
import { DI_TOKENS } from '@shared/constants/di';
import { useThemeContext } from '@shared/hooks';
import { BellIcon } from '@shared/icons/bell';
import { INotificationsViewModel } from '@shared/types/view-models/notifications';
import { ROUTES } from '@shared/utils/getRoute';
import { history } from '@shared/utils/history';

import { List } from './components/list';

import { ErrorHandler } from '../ErrorHandler';

import { styles } from './notification-bell.styles';

type Props = AppWithStyles<typeof styles> & {};

const NotificationBellComponent = ({ classes }: Props) => {
  const $theme = useThemeContext();

  const $vm = inject<INotificationsViewModel>(DI_TOKENS.notificationsViewModel);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('Something went wrong');

  const handleError = (errorText?: string) => {
    if (errorText) {
      setErrorText(errorText);
    }

    setError(true);
  };

  const onShowAllClick = () => {
    history.push(ROUTES.notifications);
  };

  const markAllAsRead = () => {
    $vm.markNotificationAsRead();
  };

  const shouldDisable = history.location.pathname === ROUTES.notifications;

  const renderContent = () => {
    if (error) {
      return (
        <div className={classes.root}>
          <ErrorHandler heading={errorText} />
        </div>
      );
    }

    return (
      <div className={classes.content}>
        <Flex alignItems="center" justifyContent="space-between">
          <h5 className={classes.notificationsLabel}>Notifications</h5>
          <Button kind="minimal" text="Mark all as read" onClick={markAllAsRead} />
        </Flex>
        <List
          data={$vm.list.map(({ asJson }) => asJson).slice(0, 3)}
          classes={{ root: classes.list }}
          handleError={handleError}
        />
        <Button
          text="View all"
          kind="secondary"
          style={{ width: '100%' }}
          onClick={onShowAllClick}
        />
      </div>
    );
  };

  const renderBellIcon = () => {
    return (
      <Badge
        count={$vm.list.filter(({ asJson }) => !asJson.isRead).length}
        style={{ backgroundColor: $theme.colors.primary }}
      >
        <BellIcon />
      </Badge>
    );
  };

  return (
    <StatefulPopover
      showArrow
      content={renderContent}
      overrides={{
        Arrow: { style: { backgroundColor: palette.common.white } },
        Body: { style: { width: '356px' } },
      }}
      onClose={() => setError(false)}
    >
      <Button
        disabled={shouldDisable}
        name="Notifications icon"
        kind="minimal"
        isLoading={$vm.loading}
        text={renderBellIcon()}
      />
    </StatefulPopover>
  );
};

export const NotificationBell = appWithStyles(styles)(appObserver(NotificationBellComponent));
