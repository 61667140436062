import { appCreateTheme } from '@core/theme/utils/with-styles';

import { AppThemeOptions } from './IMaterial';
import { initializeBreakpoints } from './units/breakpoints';
import { initializeDirection } from './units/direction';
import { initializeMixins } from './units/mixins';
import { initializePalette } from './units/palette';
import { initializeSpacing } from './units/spacing';
import { initializeTransitions } from './units/transitions';
import { initializeTypography } from './units/typography';

export function createTheme(options?: AppThemeOptions) {
  return appCreateTheme({
    breakpoints: initializeBreakpoints(options?.breakpoints),
    direction: initializeDirection(options?.direction),
    mixins: initializeMixins(options?.mixins),
    spacing: initializeSpacing(options?.spacing),
    transitions: initializeTransitions(options?.transitions),
    typography: initializeTypography(options?.typography),
    palette: initializePalette(options?.palette),
  });
}
