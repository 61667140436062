import { withStyle } from 'baseui';

import { FlexColumnContainer, FlexRowContainer, StandardBorder } from './';

export const Sidebar = withStyle(FlexColumnContainer, ({ $theme, $topBarCount = 1 }: any) => ({
  background: $theme.colors.white,
  borderLeft: StandardBorder($theme),
  width: '300px',
  height: `calc(100vh - ${56 * $topBarCount}px)`,
  paddingTop: $theme.sizing.scale800,
  paddingRight: $theme.sizing.scale800,
  paddingBottom: 0,
  paddingLeft: $theme.sizing.scale800,
}));

export const SidebarRow = withStyle(FlexRowContainer, ({ $theme }: any) => ({
  marginBottom: $theme.sizing.scale800,
  color: $theme.colors.mono600,
}));
