import { palette } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/styles';

export const styles = createStyles({
  root: {
    position: 'relative',
  },
  lengthCounter: {
    position: 'absolute',
    right: '0px',
    fontSize: '12px',
    color: palette.text.primary,
  },
  lengthCounterError: {
    color: palette.error.main,
  },
  visibilityIcon: {
    color: palette.grey[300],
  },
});
