import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {},
    value: {
      marginRight: spacing(2),
    },
    userName: {
      fontWeight: 600,
      color: palette.text.primary,
      fontSize: '14px',
      marginRight: spacing(),

      '&:hover': {
        color: palette.text.primary,
      },
    },
    message: {
      fontWeight: 400,
      fontSize: '14px',
      color: palette.text.primary,
    },
    date: {
      color: palette.grey[500],
      fontSize: '12px',
      fontWeight: 400,
      width: '140px',
      minWidth: '140px',
    },
  });
