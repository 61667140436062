import { palette } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    video: {
      width: '100%',
      height: '100%',
      backgroundColor: palette.grey[100],
    },
    videoHidden: {
      display: 'none',
    },
    loader: {
      width: 'inherit',
      height: 'inherit',
      position: 'absolute',
    },
    videoEmptyStateIcon: {
      color: palette.grey[400],
    },
    videoEmptyStateHeading: {
      color: palette.grey[400],
      fontSize: '14px',
    },
  });
