import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    deleteButton: {
      marginLeft: spacing(3),
      display: 'inline-block',
    },
    devider: {
      height: '1px',
      backgroundColor: palette.grey[200],
      margin: spacing(0, 4, 0, 6),
    },
    modalFooter: {
      marginTop: spacing(0),
    },
  });
