import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {},
    item: {
      padding: spacing(4),
      borderBottom: `1px solid ${palette.grey[200]}`,

      '&:last-of-type': {
        borderBottom: 'none',
      },
    },
  });
