import { Container } from 'inversify';

import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';
import { StorageService } from '@core/services/storage';
import { Config } from '@core/сonfig';

import { DI_TOKENS } from '@shared/constants/di';
import { services } from '@shared/services';
import { viewModels } from '@shared/view-models';

import { DiEntityIdentifier } from './types';

const diContainer = new Container();

const entities: Array<{ diToken: DiEntityIdentifier; entity: any }> = [
  { diToken: DI_TOKENS.httpClient, entity: HTTPClient },
  { diToken: DI_TOKENS.config, entity: Config },
  { diToken: DI_TOKENS.baseService, entity: BaseService },
  { diToken: DI_TOKENS.storageService, entity: StorageService },
  ...services,
  ...viewModels,
];

entities.forEach(({ diToken, entity }) => {
  diContainer.bind(diToken).to(entity).inSingletonScope();
});

export { diContainer };
