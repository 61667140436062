import { useCallback, useState } from 'react';

import cx from 'classnames';
import { Avatar } from 'src/shared/components/avatar';

import { inject } from '@core/di/di-utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Ellipsis } from '@shared/components/Ellipsis';
import { Flex } from '@shared/components/Flex';
import { TimeAgo } from '@shared/components/time-ago';
import { DI_TOKENS } from '@shared/constants/di';
import { useThemeContext } from '@shared/hooks';
import { NotificationListQuery } from '@shared/models/notification/list-model';
import { NotificationEvent } from '@shared/models/notification/type';
import { IInventoryService } from '@shared/types/services/inventory';
import { INotificationsViewModel } from '@shared/types/view-models/notifications';
import { ROUTES } from '@shared/utils/getRoute';
import { history } from '@shared/utils/history';

import {
  styles,
  AVATAR_SIZE,
  FULL_NAME_MARGIN,
  TIMESTAMP_WIDTH,
  TIMESTAMP_MARGIN,
} from './item.styles';

type Props = AppWithStyles<typeof styles> & {
  data: NotificationListQuery;
  handleError: (errorText?: string) => void;
};

const ItemComponent = ({ classes, data, handleError }: Props) => {
  const $theme = useThemeContext();

  const $vm = inject<INotificationsViewModel>(DI_TOKENS.notificationsViewModel);
  const inventoryService = inject<IInventoryService>(DI_TOKENS.inventoryService);

  const createdByFullName = `${data.createdBy?.firstName || 'Unknown'} ${
    data.createdBy?.lastName || 'Unknown'
  }`;

  const handleGoToUserDetails = useCallback(() => {
    $vm.markNotificationAsRead(data.id);

    history.push(ROUTES.userProfile(data.createdBy.id));
  }, [data]);

  const handleGoToEntity = async () => {
    $vm.markNotificationAsRead(data.id);

    const searchParams = new URLSearchParams({
      commentId: data.commentId,
      type: data.eventType,
      ...(data.commentParentId && { commentParentId: data.commentParentId }),
    }).toString();

    const routesConfig: Record<NotificationEvent, string> = {
      [NotificationEvent.HEADLINE_NEW]: ROUTES.headlineDetails(data.entityId),
      [NotificationEvent.TODO_NEW]: ROUTES.FIELD_WORK_OBJECTIVES,
      [NotificationEvent.TODO_OVERDUE]: ROUTES.FIELD_WORK_OBJECTIVES,
      [NotificationEvent.PAGE_ASSIGNMENT]: ROUTES.pages.children.details(data.entityId),
      [NotificationEvent.SUBMISSION_ANSWER]: `${ROUTES.insights.children.activity.children.submittedForm(
        data.entityId
      )}?${searchParams}`,
      [NotificationEvent.SUBMISSION]: `${ROUTES.insights.children.activity.children.submittedForm(
        data.entityId
      )}?${searchParams}`,
      [NotificationEvent.INVENTORY_ORDER]: ROUTES.inventory.children.orderDetails(data.entityId),
    };

    if (data.eventType === NotificationEvent.INVENTORY_ORDER) {
      const response = await inventoryService.getOrder(data.entityId).catch(() => {
        handleError();
      });

      if (!response) {
        return handleError('The order is no longer available');
      }
    }

    history.push(routesConfig[data.eventType]);
  };

  return (
    <div className={classes.root}>
      <Flex>
        <Avatar size={AVATAR_SIZE} src={data.createdBy?.profileUrl} name={createdByFullName} />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          classes={{ root: classes.userInfoWrapper }}
        >
          <Ellipsis
            maxWidth={`calc(100% - ${
              AVATAR_SIZE + FULL_NAME_MARGIN + TIMESTAMP_WIDTH + TIMESTAMP_MARGIN
            }px)`}
            text={createdByFullName}
            classes={{
              root: cx(classes.fullName, { [classes.fullNameDisabled]: !data.createdBy }),
            }}
            onClick={data.createdBy ? handleGoToUserDetails : undefined}
          />
          <TimeAgo date={data.createdAt} classes={{ root: classes.createdAt }} />
        </Flex>
      </Flex>
      <Flex classes={{ root: classes.body }}>
        {!data.isRead && (
          <div className={classes.marker} style={{ backgroundColor: $theme.colors.primary }} />
        )}
        <h5
          className={classes.notificationText}
          style={{
            color: $theme.colors.primary,
          }}
          onClick={handleGoToEntity}
        >
          {data.title}
        </h5>
      </Flex>
    </div>
  );
};

export const Item = appWithStyles(styles)(ItemComponent);
