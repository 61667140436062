import { withStyle } from 'baseui';
import { Block } from 'baseui/block';
import { LabelXSmall } from 'baseui/typography';

import { useThemeContext } from '@shared/hooks';

import { FlexContainer, StandardBorder } from '../';

const Line = withStyle(FlexContainer, ({ $theme }: any) => ({
  flex: 1,
  width: '100%',
  height: '0px',
  borderBottom: StandardBorder($theme),
  marginTop: $theme.sizing.scale200,
  marginBottom: $theme.sizing.scale200,
}));

export const Divider = ({ label = '', $style = {}, ...props }) => {
  const theme = useThemeContext();

  return (
    <Block display="flex" flexDirection="row" alignItems="center" $style={$style} {...props}>
      <Line />
      {label && (
        <LabelXSmall
          $style={{
            marginLeft: theme.sizing.scale400,
            marginRight: theme.sizing.scale400,
            color: theme.colors.mono800,
          }}
        >
          {label}
        </LabelXSmall>
      )}
      <Line />
    </Block>
  );
};
