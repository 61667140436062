import { Field, Form } from 'mobx-react-form';

import { FORM_VALIDATIONS } from '@core/forms/constants';

export const getFieldBindings = (field?: Field) => {
  if (field) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, ...otherBinds } = field.bind();

    return otherBinds;
  }

  return {};
};

export const getFieldRules = (field?: Field) => {
  const rules = field?.rules ? field.rules.split('|') : [];

  return {
    required: rules.includes(FORM_VALIDATIONS.required),
  };
};

export const resetFieldsValidation = (form: Form<any>) => {
  form.fields.forEach((field) => {
    field.resetValidation();
  });
};

export const removeRules = (rulesToDelete: Array<string>, currentRules: string | null) => {
  let normalizedRules = currentRules || '';

  rulesToDelete.forEach((rule) => {
    const isFirstRule = normalizedRules.search(rule) === 0;

    const regExpr = isFirstRule ? new RegExp(`${rule}\\|?`) : new RegExp(`\\|${rule}`);

    normalizedRules = normalizedRules.replace(regExpr, '');
  });

  return normalizedRules;
};

export const addRules = (rulesToAdd: Array<string>, currentRules: string | null) => {
  let normalizedRules = currentRules || '';

  rulesToAdd
    .filter((rule) => !normalizedRules.includes(rule))
    .forEach((rule) => {
      if (!normalizedRules) {
        normalizedRules = rule;

        return;
      }

      normalizedRules = `${normalizedRules}|${rule}`;
    });

  return normalizedRules;
};

export const joinRules = (...args: Array<string>) => {
  return args.join('|');
};

export const getFieldMaxLength = (rules?: string) => {
  if (!rules) {
    return undefined;
  }

  const regExp = /max:(\d+)/;
  const match = rules.match(regExp);

  return match ? Number(match[1]) : undefined;
};
