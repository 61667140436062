import { ModalHeader, ModalBody, ModalFooter, ModalButton, Paragraph2 } from '@shared/components';
import { useModalContext } from '@shared/hocs/modal-context';
import { useThemeContext } from '@shared/hooks';

type Props = {
  error: string;
  title?: string;
};

export const GenericErrorModal = ({ error, title }: Props) => {
  const theme = useThemeContext();
  const modalContext = useModalContext();

  return (
    <>
      {title ? <ModalHeader>{title}</ModalHeader> : null}
      <ModalBody>
        <Paragraph2>{error}</Paragraph2>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          style={{ backgroundColor: theme.colors.negative }}
          onClick={modalContext.close}
        >
          OK
        </ModalButton>
      </ModalFooter>
    </>
  );
};
