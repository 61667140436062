import { BaseModel } from '@core/base-model';

import { AssignmentSubmissionPersonTag } from '@shared/models/assignments/assignment-model';

import { AnswerFile } from './answer/answer-file';
import { SubmissionAnswer, SubmissionAnswerReadQuery } from './answer/read-model';
import { SubmissionStatus } from './status';

import { BookmarkReadQuery } from '../bookmark/read-model';
import { FormReadDTO } from '../forms/read-model';
import { PageReadDTO } from '../pages/read-model';
import { UserReadQuery } from '../user/read-model';

export interface SubmissionListQuery {
  id: string;
  status: SubmissionStatus;
  assignment: FormReadDTO;
  people?: Array<{
    user: UserReadQuery;
    tags: Array<AssignmentSubmissionPersonTag>;
  }>;
  user: Pick<UserReadQuery, 'firstName' | 'lastName' | 'id' | 'email' | 'profileUrl'>;
  page: {
    id: PageReadDTO['id'];
    name: PageReadDTO['name'];
  };
  answers: Array<SubmissionAnswerReadQuery>;
  createdAt: string;
  updatedAt: string;
  archivedAt?: string;
  bookmarks: Array<BookmarkReadQuery<AnswerFile['id']>>;
}

export type SubmissionListItem = Omit<SubmissionListQuery, 'answers'> & {
  answers: Array<SubmissionAnswer>;
};

export class SubmissionListModel extends BaseModel<SubmissionListQuery> {
  private id: string;
  private status: SubmissionStatus;
  private assignment: FormReadDTO;
  private people?: Array<{
    user: UserReadQuery;
    tags: Array<AssignmentSubmissionPersonTag>;
  }>;
  private user: Pick<UserReadQuery, 'firstName' | 'lastName' | 'id' | 'email' | 'profileUrl'>;
  private page: {
    id: PageReadDTO['id'];
    name: PageReadDTO['name'];
  };
  private answers: Array<SubmissionAnswerReadQuery>;
  private createdAt: string;
  private updatedAt: string;
  private bookmarks: Array<BookmarkReadQuery<AnswerFile['id']>>;
  private archivedAt: string;

  constructor(data: SubmissionListQuery) {
    super();

    this.update(data);
  }

  get asJson(): SubmissionListQuery {
    return {
      id: this.id,
      assignment: this.assignment,
      status: this.status,
      user: this.user,
      page: this.page,
      people: this.people,
      answers: this.answers,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      archivedAt: this.archivedAt,
      bookmarks: this.bookmarks,
    };
  }
}
