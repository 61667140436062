import { useEffect, useMemo, ReactNode } from 'react';

import Grid from '@mui/material/Grid';
import cx from 'classnames';
import { Button } from 'src/shared/components/button';

import { appObserver } from '@core/state-management/utils';
import { palette } from '@core/theme/units/palette';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { AppTooltip } from '@shared/components/app-tooltip';
import { Commentaries } from '@shared/components/commentaries';
import { Ellipsis } from '@shared/components/Ellipsis';
import { Flex } from '@shared/components/Flex';
import { useThemeContext } from '@shared/hooks';
import { CommentIcon } from '@shared/icons/comment';
import { FormQuestionDTO, FormQuestionType } from '@shared/models/forms/model';
import { AnswerFile } from '@shared/models/submission/answer/answer-file';
import { SubmissionAnswerReadQuery } from '@shared/models/submission/answer/read-model';

import { getAnswerValue } from './answer.utils';
import { AnswerViewModel } from './answer.vm';
import { DefaultValue } from './components/default-value';
import { MediaValue } from './components/media-value';
import { MultiSelectValue } from './components/multi-select-value';

import { ORDER_MARGIN, ORDER_SIZE, styles } from './answer.styles';

type AnswerProps = AppWithStyles<typeof styles> & {
  data?: SubmissionAnswerReadQuery;
  task: FormQuestionDTO;
  order: number;
  openCommentParentId?: string;
  openCommentId?: string;
  scrollTo: (top: number) => void;
  isOpen: boolean;
};

const AnswerComponent = ({
  classes,
  data,
  task,
  order,
  openCommentParentId,
  openCommentId,
  scrollTo,
  isOpen,
}: AnswerProps) => {
  const $theme = useThemeContext();

  const $vm = useMemo(() => new AnswerViewModel(data?.id), [data?.id]);

  useEffect(() => {
    if (isOpen) {
      $vm.updateOpenComments(true);
    }
  }, [isOpen]);

  const value = useMemo(() => data?.value && getAnswerValue(data), [data]);

  const updateCommentsCount = (count: number) => {
    $vm.updateCommentsCount(count);
  };

  const handleCommentsVisibilityStateChange = () => {
    $vm.updateOpenComments(!$vm.isOpenComments);
  };

  const defaultAnswerComponent = useMemo(() => {
    if (value) {
      return (
        <div
          className={classes.valueWrapper}
          style={{
            borderColor: $theme.colors.primary,
            backgroundColor: $theme.colors.primary50,
          }}
        >
          <DefaultValue value={value as string} />
        </div>
      );
    }
  }, [value]);

  const multipleChoiceComponent = useMemo(() => {
    const multipleQuestionTypes = [FormQuestionType.PAGE_SELECT, FormQuestionType.MULTIPLE_SELECT];

    if (
      Array.isArray(value) &&
      multipleQuestionTypes.includes(task.config.type) &&
      value.length > 0
    ) {
      return (
        <MultiSelectValue value={value as Array<string>} classes={{ root: classes.valueWrapper }} />
      );
    }
    return <div className={classes.emptyState}>No answer</div>;
  }, [$theme, classes, task, value]);

  const mediaComponent = useMemo(() => {
    const mediaQuestionTypes = [FormQuestionType.PHOTO_UPLOAD, FormQuestionType.VIDEO_UPLOAD];

    if (Array.isArray(value) && mediaQuestionTypes.includes(task.config.type)) {
      return (
        <Grid container spacing={4} classes={{ root: classes.answerWrapper }}>
          {value.map((file: AnswerFile) => {
            return (
              <MediaValue
                key={file.url}
                {...file}
                classes={{ sourceElement: classes.mediaComponent }}
              />
            );
          })}
        </Grid>
      );
    }
  }, [classes, task, value]);

  const answer = () => {
    const answersTypeConfig: Record<FormQuestionType, ReactNode> = {
      [FormQuestionType.PAGE_SELECT]: Array.isArray(value)
        ? multipleChoiceComponent
        : defaultAnswerComponent,
      [FormQuestionType.MULTIPLE_SELECT]: multipleChoiceComponent,
      [FormQuestionType.DATE]: defaultAnswerComponent,
      [FormQuestionType.DATE_RANGE]: defaultAnswerComponent,
      [FormQuestionType.DEPENDENCY]: defaultAnswerComponent,
      [FormQuestionType.DURATION]: defaultAnswerComponent,
      [FormQuestionType.YES_NO]: defaultAnswerComponent,
      [FormQuestionType.LIKERT_NPS]: defaultAnswerComponent,
      [FormQuestionType.LIKERT_CUSTOM]: defaultAnswerComponent,
      [FormQuestionType.PHOTO_UPLOAD]: mediaComponent,
      [FormQuestionType.VIDEO_UPLOAD]: mediaComponent,
      [FormQuestionType.PRICE]: defaultAnswerComponent,
      [FormQuestionType.PERCENTAGE]: defaultAnswerComponent,
      [FormQuestionType.COUNT]: defaultAnswerComponent,
      [FormQuestionType.MULTIPLE_CHOICE]: defaultAnswerComponent,
      [FormQuestionType.EMAIL]: defaultAnswerComponent,
      [FormQuestionType.PHONE]: defaultAnswerComponent,
      [FormQuestionType.TEXTAREA]: defaultAnswerComponent,
      [FormQuestionType.SHORT_ANSWER]: defaultAnswerComponent,
      [FormQuestionType.MULTIPLE_ALPHABETIC]: defaultAnswerComponent,
      [FormQuestionType.MULTIPLE_NUMERICAL]: defaultAnswerComponent,
      [FormQuestionType.MATRIX_CHOICE]: defaultAnswerComponent,
      [FormQuestionType.MATRIX_SELECT]: defaultAnswerComponent,
      [FormQuestionType.MULTIPLE_PERSENTAGE_SUMM]: defaultAnswerComponent,
      [FormQuestionType.MULTIPLE_NUMERICAL_SUMM]: defaultAnswerComponent,
    };

    return answersTypeConfig[task.config.type];
  };

  const commentButton = () => {
    const control = (
      <Button
        isLoading={$vm.loading.commentCount}
        disabled={!value && value !== 0}
        kind="minimal"
        startEnhancer={<CommentIcon classes={{ root: classes.commentsIcon }} />}
        text={`(${$vm.commentsCount})`}
        className={cx(classes.commentsButton, { [classes.commentButtonDisabled]: !value })}
        style={{ color: value || value === 0 ? $theme.colors.primary : palette.grey[500] }}
        onClick={handleCommentsVisibilityStateChange}
      />
    );

    if (!value && value !== 0) {
      return (
        // button has pointer events: 'none' so we have to add an addition wrapper to trigger tooltip
        <AppTooltip title="You cannot add comment(s) to a question without an answer">
          <span className={classes.commentsButtonWrapper}>{control}</span>
        </AppTooltip>
      );
    }

    return control;
  };

  const numberQuestionTypes = [
    FormQuestionType.COUNT,
    FormQuestionType.LIKERT_NPS,
    FormQuestionType.LIKERT_CUSTOM,
  ];

  return (
    <div className={classes.root}>
      <Flex alignItems="center" justifyContent="space-between" classes={{ root: classes.header }}>
        <Flex alignItems="center" classes={{ root: classes.taskNameWrapper }}>
          <Flex alignItems="center" justifyContent="center" classes={{ root: classes.order }}>
            {order}
          </Flex>
          <Ellipsis
            maxWidth={`calc(100% - ${ORDER_SIZE + ORDER_MARGIN}px)`}
            text={task.name}
            classes={{ root: classes.taskName }}
          />
        </Flex>
        {commentButton()}
      </Flex>
      {task.description && (
        <Ellipsis
          maxWidth={`calc(100% - ${ORDER_SIZE + ORDER_MARGIN}px)`}
          text={task.description}
          classes={{ root: classes.taskDescription }}
        />
      )}
      <Flex classes={{ root: classes.answerWrapper }}>
        {value ? (
          answer()
        ) : numberQuestionTypes.includes(task.config.type) && value === 0 ? (
          <div className={classes.countEmptyState}>{value}</div>
        ) : (
          <div className={classes.emptyState}>No answer</div>
        )}
      </Flex>
      {$vm.isOpenComments && (
        <Commentaries
          scrollTo={scrollTo}
          openCommentParentId={openCommentParentId}
          openCommentId={openCommentId}
          hidden={false}
          withCount={false}
          owner={$vm.owner}
          classes={{ root: classes.comments }}
          onChange={updateCommentsCount}
        />
      )}
    </div>
  );
};

export const Answer = appWithStyles(styles)(appObserver(AnswerComponent));
