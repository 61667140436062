import { ReactNode } from 'react';

import {
  Caption2,
  FlexColumnContainer,
  FlexFullContainer,
  FlexRowContainer,
} from '@shared/components';

import { TaskDivider } from '../task-divider';

type Props = {
  children: ReactNode;
};

export const TaskButtonControl = ({ children }: Props) => {
  return (
    <FlexColumnContainer>
      <TaskDivider />
      <FlexRowContainer $style={{ alignItems: 'flex-start' }}>
        <Caption2>Type</Caption2>
        <FlexFullContainer />
        {children}
      </FlexRowContainer>
    </FlexColumnContainer>
  );
};
