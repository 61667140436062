import { useMemo, ReactNode } from 'react';

import { ApolloProvider } from '@apollo/client';

import { createClient } from './CreateClient';

type Props = {
  children: ReactNode;
};

export const ApolloContextProvider = ({ children }: Props) => {
  const client = useMemo(() => createClient(), []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
