import { injectable, inject } from '@core/di/di-utils';
import { appObservable, appMakeObservable } from '@core/state-management/utils';

import { DI_TOKENS } from '@shared/constants/di';
import { ReactionType } from '@shared/models/reaction/type';
import { IDictionariesService } from '@shared/types/services/dictionaries';

@injectable()
export class ReactionsViewModel {
  private readonly dictionariesService = inject<IDictionariesService>(
    DI_TOKENS.dictionariesService
  );
  private _list: Array<ReactionType> = [];

  constructor() {
    appMakeObservable(this, {
      _list: appObservable,
    });
  }

  get list() {
    return this._list;
  }

  getList = async () => {
    this._list = await this.dictionariesService.getReactionList();
  };
}
