import { injectable } from '@core/di/di-utils';
import { inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { FormQuestionDTO } from '@shared/models/forms/model';
import { FormReadDTO } from '@shared/models/forms/read-model';
import { CalculationType } from '@shared/models/insight/analyze/submission-calculated-answer-type';
import {
  SubmissionListItem,
  SubmissionListModel,
  SubmissionListQuery,
} from '@shared/models/submission/list-model';
import { PaginatedResponse } from '@shared/types/services';
import { IInsightsAnalyzeService } from '@shared/types/services/insights/analyze';
import { SortingType } from '@shared/types/sortingType';
import { downloadFile } from '@shared/utils/file';

@injectable()
export class InsightsAnalyzeService extends BaseService implements IInsightsAnalyzeService {
  private readonly $http = inject<HTTPClient>(HTTPClient.diToken);

  exportXlsx: IInsightsAnalyzeService['exportXlsx'] = async (params) => {
    const { data } = await this.$http.post<BlobPart>(
      '/export/form-submissions',
      { format: 'XLSX', ...params },
      { responseType: 'blob' }
    );

    downloadFile({ blobParts: [data] }, 'report.xlsx');
  };

  getSharedSubmissions: IInsightsAnalyzeService['getSharedSubmissions'] = async (token, params) => {
    const { data } = await this.$http.post<
      PaginatedResponse<{
        submissions: Array<SubmissionListQuery>;
        calculations: Array<{
          type: CalculationType;
          task: FormQuestionDTO['id'];
          value: number;
        }>;
      }>
    >(
      '/sharing/form-submissions',
      {
        ...params,
        sort: {
          ...params.sort,
          direction: params.sort?.direction ? params.sort.direction === SortingType.asc : undefined,
        },
      },
      { headers: { 'Insight-Token': token } }
    );

    return {
      submissions: data.submissions.map((submission) => new SubmissionListModel(submission)),
      total: data.total,
      calculations: data.calculations,
    };
  };

  exportSharedSubmissions: IInsightsAnalyzeService['exportSharedSubmissions'] = async (
    token,
    columns,
    tasks,
    calculations,
    params
  ) => {
    const { data } = await this.$http.post<BlobPart>(
      '/sharing/form-submissions/export',
      {
        format: 'XLSX',
        columns,
        tasks,
        calculations,
        filter: params.filter,
        sort: params.sort,
      },
      {
        responseType: 'blob',
        headers: { 'Insight-Token': token },
      }
    );

    downloadFile({ blobParts: [data] }, 'report.xlsx');
  };

  getSharedAssignment: IInsightsAnalyzeService['getSharedAssignment'] = async (token) => {
    const { data } = await this.$http.get<FormReadDTO>('/sharing/assignment', {
      headers: {
        'Insight-Token': token,
      },
    });
    return data;
  };

  getCalculations: IInsightsAnalyzeService['getCalculations'] = async (params) => {
    const { data } = await this.$http.post<number>(
      '/insights/analyze/submissions/calculate',
      params
    );
    return data;
  };

  getSubmissions: IInsightsAnalyzeService['getSubmissions'] = async (params) => {
    const { data } = await this.$http.post<{
      submissions: Array<SubmissionListItem>;
      total: number;
    }>('/insights/analyze/submissions', params);
    return data;
  };
}
