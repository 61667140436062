export const ROUTES = {
  login: '/login',
  fieldNewsHeadlines: '/field/news/headlines',
  fieldWorkPages: '/field/work/pages',
  fieldSummaries: '/field/summaries',
  insightsActivity: '/insights/activity',
  insightsExplore: '/insights/explore',
  insightsAnalyze: '/insights/analyze',
  programOrganization: '/organization',
  programLibrary: '/program/library',
  programNews: '/program/news',
  programUsers: '/program/users',
};
