import { BaseModel } from '@core/base-model';

import { ReactionType } from './type';

import { UserReadQuery } from '../user/read-model';

export interface ReactionReadQuery {
  id: string;
  type: ReactionType;
  user: UserReadQuery;
}

export class ReactionReadModel extends BaseModel<ReactionReadQuery> {
  private id: string;
  private type: ReactionType;
  private user: UserReadQuery;

  constructor(data: ReactionReadQuery) {
    super();

    this.update(data);
  }

  get asJson(): ReactionReadQuery {
    return {
      id: this.id,
      type: this.type,
      user: this.user,
    };
  }
}
