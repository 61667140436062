import { styled, withStyle } from 'baseui';

export const Caption1 = styled('span', ({ $theme }: any) => ({
  ...$theme.typography.font100,
  color: $theme.colors.mono800,
}));

export const Caption2 = styled('span', ({ $theme }: any) => ({
  ...$theme.typography.font150,
  fontWeight: 'bold',
  color: $theme.colors.mono800,
}));

export const H4 = styled('span', ({ $theme }: any) => ({
  ...$theme.typography.font750,
  color: $theme.colors.mono900,
}));

export const H5 = styled('span', ({ $theme }: any) => ({
  ...$theme.typography.font650,
  color: $theme.colors.mono900,
}));

export const H6 = styled('span', ({ $theme }: any) => ({
  ...$theme.typography.font550,
  color: $theme.colors.mono900,
}));

export const Label1 = styled('span', ({ $theme }: any) => ({
  ...$theme.typography.font250,
  color: $theme.colors.mono900,
}));

export const Label2 = styled('span', ({ $theme }: any) => ({
  ...$theme.typography.font350,
  fontWeight: 'bold',
  color: $theme.colors.mono900,
}));

export const Paragraph1 = styled('span', ({ $theme }: any) => ({
  ...$theme.typography.font200,
  color: $theme.colors.mono800,
}));

export const Paragraph2 = styled('span', ({ $theme }: any) => ({
  ...$theme.typography.font300,
  color: $theme.colors.mono900,
}));

export const Micro1 = styled('span', ({ $theme }: any) => ({
  ...$theme.typography.font100,
  letterSpacing: '0.75px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}));

export const Micro2 = styled('span', ({ $theme }: any) => ({
  fontSize: '12px',
  lineHeight: $theme.sizing.scale600,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  letterSpacing: '1.25px',
  color: $theme.colors.mono700,
}));

export const Micro4 = styled('span', ({ $theme }: any) => ({
  ...$theme.typography.font100,
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '0.75px',
}));

export const Micro5 = styled('span', ({ $theme }: any) => ({
  ...$theme.typography.font100,
  fontSize: '12px',
  lineHeight: '20px',
  color: $theme.colors.mono600,
}));

export const FieldLabel = withStyle(Micro2, ({ $theme }: any) => ({
  paddingBottom: $theme.sizing.scale400,
}));

export const ErrorLabel = withStyle(Caption1, ({ $theme }: any) => ({
  color: $theme.colors.negative,
}));
