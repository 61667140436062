import { useMemo } from 'react';

import { styled } from 'baseui';

import { useThemeContext } from '@shared/hooks';

import { IconLibrary, IconLibraryIcons } from './icon-library';

type IStyledIconProps = {
  $size?: string | number;
  $color?: string;
  $style?: any;
  size?: string | number;
  color?: string;
  onClick?: Function;
};

const makeIcon = (iconComponent: any) => {
  return styled<IStyledIconProps, typeof iconComponent>(
    iconComponent,
    ({ $theme, $size, $color }: { $theme; $size; $color }) => {
      return {
        color: $color || 'inherit',
        width: $size || $theme.sizing.scale700,
        height: $size || $theme.sizing.scale700,
        fill: 'currentColor',
        path: {
          stroke: 'white',
          strokeWidth: '1px',
        },
      };
    }
  );
};

type Props = IStyledIconProps & {
  icon: IconLibraryIcons;
  title?: string;
  className?: string;
};

export const Icon = (props: Props) => {
  const { icon, color, size, ...rest } = props;
  const theme = useThemeContext();

  rest.$color = useMemo(() => {
    const rvalue = rest.$color || color;
    return (theme.colors as any)[rvalue] || rvalue;
  }, [color, rest.$color]);

  rest.$size = useMemo(() => {
    const rvalue = rest.$size || size;
    return typeof rvalue === 'number' ? `${rvalue}px` : rvalue;
  }, [size, rest.$size]);

  const IconComponent = useMemo(() => {
    return makeIcon(IconLibrary[icon]);
  }, [icon]);

  return <IconComponent {...rest} />;
};
