import { BaseModel } from '@core/base-model';

import { UserReadQuery } from '../user/read-model';

export interface GroupReadQuery {
  autoGroupped: boolean;
  createdAt: string;
  id: string;
  profileName: string;
  name: string;
  users: Array<UserReadQuery>;
}

export class GroupReadModel extends BaseModel<GroupReadQuery> {
  private autoGroupped: boolean;
  private createdAt: string;
  private id: string;
  private profileName: string;
  private name: string;
  private users: Array<UserReadQuery>;

  constructor(data: GroupReadQuery) {
    super();

    this.update(data);
  }

  get asJson(): GroupReadQuery {
    return {
      autoGroupped: this.autoGroupped,
      createdAt: this.createdAt,
      id: this.id,
      profileName: this.profileName,
      name: this.name,
      users: this.users,
    };
  }
}
