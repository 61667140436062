import * as path from 'path';

import { SyntheticEvent } from 'react';

import { styled, withStyle } from 'baseui';

import { Textarea } from '@shared/components';
import { FlexColumnContainer } from '@shared/components/containers';
import { Icon } from '@shared/components/Icon';

const Wrapper = withStyle(
  FlexColumnContainer,
  ({ $theme, $width = '100%', $height = '100%' }: any) => ({
    width: $width,
    height: $height,
    position: 'relative',
    borderRadius: $theme.borders.radius200,
    border: `1px solid ${$theme.colors.mono500}`,
    overflow: 'hidden',
  })
);

const ImageWrapper = withStyle(FlexColumnContainer, ({ $preserveRatio, $width = '100%' }: any) => ({
  width: $width,
  position: 'relative',
  height: '0px',
  paddingTop: $preserveRatio ? '62%' : '100%',
  overflow: 'hidden',
}));

const Inner = withStyle(FlexColumnContainer, ({ $theme }: any) => ({
  position: 'absolute',
  backgroundColor: $theme.colors.white,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}));

const ImageOverlay = styled('div', () => ({
  position: 'absolute',
  background: 'linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 47%,rgba(0,0,0,0.5) 100%)',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}));

const Close = styled('button', ({ $theme }: any) => ({
  backgroundColor: 'transparent',
  border: 'none',
  position: 'absolute',
  right: $theme.sizing.scale500,
  top: $theme.sizing.scale500,
  cursor: 'pointer',
}));

const Image = styled('img', ({ $width = '100%', $height = '100%' }: any) => ({
  objectFit: 'cover',
  objectPosition: 'center center',
  height: $height,
  maxWidth: $width,
  border: 'none',
}));

const DocumentPreview = withStyle(FlexColumnContainer, ({ $theme }: any) => ({
  backgroundColor: $theme.colors.mono600,
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));

const DescriptionWrapper = styled('div', ({ $theme }: any) => ({
  padding: $theme.sizing.scale300,
  backgroundColor: $theme.colors.white,
}));

export const FilePreview = (props: any) => {
  let {
    url,
    description,
    showDescription,
    onChange,
    onDelete,
    width = '100%',
    height = '100%',
    preservePreviewRatio = true,
  } = props;

  const renderFilePreview = (fileExtension: string) => {
    switch (fileExtension.toLowerCase()) {
      case '.jpg':
      case '.jpeg':
      case '.png':
      case '.gif':
      case '.tiff':
      case '.svg':
        return <Image $width={width} $height={height} src={url} />;
      case '.pdf':
        return (
          <DocumentPreview>
            <Icon size="60px" color="white" icon="LightFilePdf" />
          </DocumentPreview>
        );
      case '.zip':
      case '.rar':
        return (
          <DocumentPreview>
            <Icon size="60px" color="white" icon="LightFileArchive" />
          </DocumentPreview>
        );
      default:
        return (
          <DocumentPreview>
            <Icon size="60px" color="white" icon="LightFileAlt" />
          </DocumentPreview>
        );
    }
  };

  return (
    <Wrapper $width={width} $height={height}>
      <ImageWrapper $width={width} $preserveRatio={preservePreviewRatio}>
        <Inner>
          <ImageOverlay>
            <Close onClick={onDelete}>
              <Icon size={24} color="white" icon="RegularTimes" />
            </Close>
          </ImageOverlay>
          {renderFilePreview(path.extname(url))}
        </Inner>
      </ImageWrapper>
      {showDescription ? (
        <DescriptionWrapper>
          <Textarea
            placeholder="Add Description..."
            name="Preview Description"
            value={description}
            onChange={(e: SyntheticEvent) => onChange((e.target as HTMLTextAreaElement).value)}
            overrides={{
              Input: {
                style: {
                  height: '48px',
                  resize: 'none',
                },
              },
            }}
          />
        </DescriptionWrapper>
      ) : null}
    </Wrapper>
  );
};
