import { useMemo } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import debounce from 'lodash/debounce';

import { appObserver } from '@core/state-management/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { AppLink, Ellipsis, Flex, Icon, Input, Spinner, StatefulPopover } from '@shared/components';
import { ChevronDownIcon } from '@shared/icons/chevron-down';
import { SubmissionReadQuery } from '@shared/models/submission/read-model';
import { ROUTES } from '@shared/utils/getRoute';

import { AssignmentDropdownViewModel } from './assignment-dropdown.vm';

import { AssignmentModal } from '../assignment-modal';

import { styles } from './assignment-dropdown.styles';

type Props = AppWithStyles<typeof styles> & {
  submission: SubmissionReadQuery;
  hasPermission: boolean;
  onUpdateSubmission: () => Promise<void>;
};

const AssignmentDropdownComponent = ({
  submission,
  hasPermission,
  onUpdateSubmission,
  classes,
}: Props) => {
  const $vm = useMemo(() => new AssignmentDropdownViewModel(submission), [submission]);

  const handleReassign = async () => {
    await $vm.handleReassign();
    $vm.handleCloseAssignModal();
    onUpdateSubmission();
  };

  const debouncedSearch = useMemo(() => {
    return debounce(() => {
      $vm.handleSearchPages();
    }, 500);
  }, [$vm.getPagesList]);

  const handleSearch = (e) => {
    $vm.searchValue = e.target.value;
    return debouncedSearch();
  };

  return (
    <Flex flexDirection="column" className={classes.root}>
      {$vm.loading && <Spinner size={20} withWrapper />}

      {!$vm.loading && (
        <Flex alignItems="center" justifyContent="space-between" className={classes.title}>
          <Ellipsis
            component={AppLink}
            componentProps={{
              to: ROUTES.pages.children.details($vm.activePage.id),
              style: { width: '180px' },
            }}
            text={$vm.activePage.name}
            classes={{ root: classes.pageName }}
          />
          {hasPermission && (
            <StatefulPopover
              placement="bottomRight"
              overrides={{
                Body: {
                  style: {
                    left: '17px',
                  },
                },
              }}
              content={
                <div className={classes.dropdown}>
                  <Input
                    name="Search page"
                    placeholder="Search page..."
                    startEnhancer={<Icon size={20} icon="RegularSearch" color="colorSecondary" />}
                    overrides={{
                      Root: {
                        style: () => ({
                          paddingLeft: '0px',
                          margin: '8px 16px',
                          width: 'calc(100% - 32px)',
                        }),
                      },
                      Input: {
                        style: () => ({
                          height: '32px',
                        }),
                      },
                    }}
                    value={$vm.searchValue}
                    onChange={(e) => handleSearch(e)}
                  />
                  {$vm.searchLoading && <Spinner size={20} withWrapper />}

                  {$vm.searchError && <p className={classes.emptyState}>No results</p>}

                  {!$vm.searchLoading &&
                    !$vm.searchError &&
                    $vm.pagesList.map((page) => (
                      <Flex
                        alignItems="center"
                        className={classes.option}
                        onClick={() => $vm.handleOpenAssignModal(page)}
                      >
                        {page.id === $vm.activePage.id && (
                          <CheckIcon fontSize="small" style={{ marginRight: '12px' }} />
                        )}
                        <Ellipsis key={page.id} text={page.name} />
                      </Flex>
                    ))}
                </div>
              }
            >
              <div>
                <ChevronDownIcon className={classes.icon} />
              </div>
            </StatefulPopover>
          )}
        </Flex>
      )}
      {$vm.isModalOpen && (
        <AssignmentModal
          isOpen={$vm.isModalOpen}
          onClose={$vm.handleCloseAssignModal}
          page={$vm.activePage}
          newPage={$vm.newPage}
          handleSubmit={handleReassign}
        />
      )}
    </Flex>
  );
};

export const AssignmentDropdown = appWithStyles(styles)(appObserver(AssignmentDropdownComponent));
