import { BaseModel } from '@core/base-model';

export interface RoleListQuery {
  id: string;
  name: string;
}

export class RoleListModel extends BaseModel<RoleListQuery> {
  private id: string;
  private name: string;

  constructor(data: RoleListQuery) {
    super();

    this.update(data);
  }

  get asJson(): RoleListQuery {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
