import {
  ButtonSHAPE,
  ButtonSIZE,
  FlexFullContainer,
  FlexRowContainer,
  Icon,
  Label2,
  Caption1,
  StandardCard,
  IconButton,
} from '@shared/components';
import { useThemeContext } from '@shared/hooks';

type Props = {
  title: string;
  userCount: number;
  locked: boolean;
  selected: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
};

export const RoleCard = ({
  title,
  userCount,
  locked,
  selected,
  onEditClick,
  onDeleteClick,
}: Props) => {
  const $theme = useThemeContext();

  const selectedStyle = () => {
    return selected
      ? {
          borderLeftWidth: '2px',
          borderLeftColor: $theme.colors.primary,
        }
      : {};
  };

  return (
    <StandardCard
      $style={{
        ...selectedStyle(),
        marginBottom: $theme.sizing.scale300,
      }}
    >
      <FlexRowContainer style={{ position: 'relative', marginBottom: $theme.sizing.scale300 }}>
        <Label2>{title}</Label2>
        {locked && (
          <Icon
            icon="SolidLockAlt"
            size={20}
            color="mono600"
            $style={{ marginLeft: $theme.sizing.scale300 }}
          />
        )}
        <FlexFullContainer />
        <IconButton
          kind="minimal"
          shape={ButtonSHAPE.round}
          size={ButtonSIZE.compact}
          onClick={onEditClick}
          $style={{
            position: 'absolute',
            top: '-8px',
            right: '-20px',
          }}
        >
          <Icon icon="SolidPen" size={16} color="mono600" />
        </IconButton>
        {userCount <= 0 && (
          <IconButton
            kind="minimal"
            shape={ButtonSHAPE.round}
            size={ButtonSIZE.compact}
            onClick={onDeleteClick}
            $style={{
              position: 'absolute',
              top: '-8px',
              right: '-20px',
            }}
          >
            <Icon icon="SolidTrash" size={16} color="mono600" />
          </IconButton>
        )}
      </FlexRowContainer>
      <Caption1>
        {userCount} {userCount === 1 ? 'user' : 'users'}
      </Caption1>
    </StandardCard>
  );
};
