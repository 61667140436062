import { SubmissionActivityLog } from '@shared/models/submission/activity-log';
import { SubmissionStatus, SubmissionDefaultStatus } from '@shared/models/submission/status';
import { UserReadQuery } from '@shared/models/user/read-model';
import { AssignmentSubmissionActivityLogEntryType } from '@shared/types/globalTypes';

export const createLog = (
  currentStatus: SubmissionStatus,
  previousStatus: SubmissionStatus,
  user: UserReadQuery
): SubmissionActivityLog => {
  const defaultStatuses = [SubmissionDefaultStatus.open, SubmissionDefaultStatus.closed];
  const config = [
    {
      condition:
        previousStatus === SubmissionDefaultStatus.open &&
        currentStatus !== SubmissionDefaultStatus.closed,
      type: AssignmentSubmissionActivityLogEntryType.SUBMISSION_IN_REVIEW,
    },
    {
      condition:
        currentStatus !== previousStatus &&
        !defaultStatuses.includes(currentStatus as SubmissionDefaultStatus),
      type: AssignmentSubmissionActivityLogEntryType.SUBMISSION_STATUS_CHANGED,
    },
    {
      condition: currentStatus === SubmissionDefaultStatus.closed,
      type: AssignmentSubmissionActivityLogEntryType.SUBMISSION_CLOSED,
    },
    {
      condition: currentStatus === SubmissionDefaultStatus.open,
      type: AssignmentSubmissionActivityLogEntryType.SUBMISSION_SENT_BACK,
    },
  ].filter(({ condition }) => condition);
  const type = config[0]?.type || AssignmentSubmissionActivityLogEntryType.SUBMISSION_STARTED;

  return {
    user,
    type,
    at: new Date().toUTCString(),
    value: currentStatus,
  };
};
