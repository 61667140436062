import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '400px',
      maxHeight: '80vh',
    },
    heading: {},
  });
