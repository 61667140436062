import Axios, { InternalAxiosRequestConfig } from 'axios';

import { showNotification } from '@shared/components';
import { TokenRefreshStatus } from '@shared/constants/auth';

import {
  HttpRestInstance,
  HttpRequestConfig,
  HttpConfig,
  HttpResponse,
  HttpFailResponse,
} from './http.types';

export class Http {
  private readonly _client: HttpRestInstance;
  private readonly getAccessToken: HttpConfig['getAccessToken'];
  private readonly getTokenRefreshStatus: HttpConfig['getTokenRefreshStatus'];
  private readonly processUnauthenticated: HttpConfig['processUnauthenticated'];

  constructor(config: HttpConfig) {
    this._client = this.createHTTPClient();

    this.setClientConfig(config.defaults);

    this.getAccessToken = config.getAccessToken;
    this.getTokenRefreshStatus = config.getTokenRefreshStatus;
    this.processUnauthenticated = config.processUnauthenticated;
  }

  createHTTPClient = () => {
    return Axios.create();
  };

  private get authHeader() {
    const accessToken = this.getAccessToken();

    if (accessToken) {
      return `Bearer ${accessToken}`;
    }
  }

  private setClientConfig(defaults: HttpConfig['defaults']) {
    this.setDefaults(defaults);
    this.setClientResponseInterceptor();
    this.setClientRequestInterceptor();
  }

  private setDefaults(defaults: HttpRequestConfig) {
    this._client.defaults.baseURL = defaults.baseURL;
  }

  private handleUnauthenticated = async (response: HttpResponse<any>) => {
    await this.processUnauthenticated(response);

    if (this.authHeader) {
      response.config.headers.Authorization = this.authHeader;

      return this._client(response.config);
    }
  };

  private setClientResponseInterceptor() {
    this._client.interceptors.response.use(
      async (response: HttpResponse<any>): Promise<any> => {
        const unauthenticatedException =
          response?.data?.errors?.[0]?.extensions?.exception?.status === 401;

        if (unauthenticatedException) {
          const handledResponse = await this.handleUnauthenticated(response);

          return handledResponse;
        }

        if (response?.data?.errors) {
          const errors = response?.data?.errors.map((error) => {
            return {
              messages: error.extensions?.exception?.response?.errors?.[0]?.messages,
            };
          });
          // eslint-disable-next-line
          throw {
            response: {
              data: { errors },
            },
          };
        }

        return { ...response, data: response.data.data ?? response.data };
      },
      async (error: HttpFailResponse<unknown>) => {
        const response = error ? error.response : undefined;

        if (!response) {
          return;
        }

        if (response.status === 401) {
          try {
            return await this.handleUnauthenticated(response);
          } catch (err) {
            throw err;
          }
        }

        if (response.status >= 500) {
          showNotification('Failed to execute operation', { type: 'error' });
        }

        throw error;
      }
    );
  }

  private setClientRequestInterceptor() {
    this._client.interceptors.request.use(
      (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        const accessToken = this.getAccessToken();

        if (!accessToken || this.getTokenRefreshStatus() === TokenRefreshStatus.refreshing) {
          return config;
        }

        config.headers.Authorization = this.authHeader;

        return config;
      },
      (error: Error): Error => {
        throw error;
      }
    );
  }

  get client() {
    return this._client;
  }
}
