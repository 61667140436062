import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

import { FORM_UNIT_LABEL_WIDTH, FORM_UNIT_LABEL_MARGIN } from './Form';

export const styles = () =>
  createStyles({
    root: {
      position: 'relative',
    },
    form: {},
    loading: {},
    controls: {
      marginTop: spacing(4),
    },
    control: {
      marginRight: spacing(2),

      '&:last-of-type': {
        marginRight: '0px',
      },
    },
    submitButtonPositionPrimary: {},
    submitButtonPositionSecondary: {
      marginLeft: `calc(${FORM_UNIT_LABEL_WIDTH} + ${FORM_UNIT_LABEL_MARGIN})`,
    },
  });
