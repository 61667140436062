import Cookie from 'js-cookie';

import { injectable } from '@core/di/di-utils';

import {
  IStorageService,
  StorageType,
  StorageKey,
  StorageSetOptions,
} from '@shared/types/services/storage';

type Methods = {
  get: (key: StorageKey) => any | undefined;
  set: (key: StorageKey, value: any, options?: StorageSetOptions) => void;
  remove: (key: StorageKey) => void;
};

@injectable()
export class StorageService implements IStorageService {
  private get methods(): { [type in StorageType]: Methods } {
    return {
      cookie: {
        get: Cookie.get,
        set: Cookie.set,
        remove: (key) => Cookie.remove(key),
      },
      localStorage: {
        get: this.getLocalStorageValue,
        set: this.setLocalStorageValue,
        remove: window.localStorage.removeItem.bind(window.localStorage),
      },
    };
  }

  get: IStorageService['get'] = (type, key) => {
    const value = this.methods[type].get(this.getStorageKey(key));

    try {
      return value ? JSON.parse(value) : value;
    } catch {
      return value;
    }
  };

  set: IStorageService['set'] = (type, key, value, options) => {
    const defaultOptions: Partial<Record<StorageType, StorageSetOptions>> = {
      cookie: { sameSite: 'Lax' },
    };

    this.methods[type].set(this.getStorageKey(key), JSON.stringify(value), {
      ...defaultOptions[type],
      ...options,
    });
  };

  remove: IStorageService['remove'] = (type, key) => {
    this.methods[type].remove(this.getStorageKey(key));
  };

  private getStorageKey(key: StorageKey) {
    return `${window.location.origin}_${key}`;
  }

  private setLocalStorageValue<T>(key: StorageKey, value: T): void {
    const stringValue = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, stringValue);
  }

  private getLocalStorageValue<T>(key: StorageKey): T | undefined {
    const stringValue = localStorage.getItem(key);
    if (stringValue === null) {
      return undefined;
    }
    try {
      const parsedValue = JSON.parse(stringValue);
      return parsedValue as T;
    } catch (error) {
      return stringValue as unknown as T;
    }
  }
}
