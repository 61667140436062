import { useMemo } from 'react';

import { StatefulPopover } from 'baseui/popover';
import cx from 'classnames';

import { inject } from '@core/di/di-utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Button, Flex } from '@shared/components';
import { DI_TOKENS } from '@shared/constants/di';
import { useThemeContext } from '@shared/hooks';
import { AddReactionIcon } from '@shared/icons/add-reaction';
import { ReactionReadQuery } from '@shared/models/reaction/read-model';
import { ReactionType } from '@shared/models/reaction/type';
import { IReactionsViewModel } from '@shared/types/view-models/reactions';

import { SelectedReactions } from './components/selected-reactions';
import { ReactionStyleVariant } from './reaction.types';

import { styles } from './reaction.styles';

type Props = AppWithStyles<typeof styles> & {
  disabled?: boolean;
  processing: boolean;
  variant?: ReactionStyleVariant;
  renderDirection?: 'row' | 'row-reverse';
  selectedReactions: Array<{ type: ReactionType; id: ReactionReadQuery['id']; count: number }>;
  onSelect: (reaction: ReactionType) => void;
  onRemove: (reactionId: ReactionReadQuery['id']) => void;
};

const ReactionComponent = ({
  classes,
  onSelect,
  onRemove,
  processing,
  selectedReactions,
  disabled,
  renderDirection = 'row',
  variant = ReactionStyleVariant.primary,
}: Props) => {
  const $theme = useThemeContext();

  const $vm = inject<IReactionsViewModel>(DI_TOKENS.reactionsViewModel);

  const onOptionSelect = (e) => {
    const { type, id } = e.currentTarget.dataset;

    if (id) {
      onRemove(id);
    } else {
      onSelect(type);
    }
  };

  const reactionOptionsComponent = ({ close }: { close: () => void }) => {
    return (
      <Flex alignItems="center" classes={{ root: classes.reactionPopoverOptionsWrapper }}>
        {$vm.list.map((option) => {
          const selectedReaction = selectedReactions.find((reaction) => reaction.type === option);
          const onButtonClick = (e) => {
            onOptionSelect(e);
            close();
          };

          return (
            <Button
              disabled={processing}
              key={option}
              data-type={option}
              data-id={selectedReaction?.id}
              kind="minimal"
              size="compact"
              text={<span className={classes.reactionEmoji}>{option}</span>}
              style={{
                border: `1px solid ${selectedReaction?.id ? $theme.colors.primary : 'transparent'}`,
                backgroundColor: selectedReaction?.id ? $theme.colors.primary50 : '#F6F6F6',
              }}
              className={classes.reactionPopoverOption}
              onClick={onButtonClick}
            />
          );
        })}
      </Flex>
    );
  };

  const triggerButtonClasses = useMemo(() => {
    const config: Record<ReactionStyleVariant, string> = {
      [ReactionStyleVariant.primary]: classes.triggerButtonPrimary,
      [ReactionStyleVariant.secondary]: classes.triggerButtonSecondary,
    };

    return cx(classes.triggerButton, config[variant]);
  }, [classes, variant]);

  const triggerButtonIconClasses = useMemo(() => {
    const config: Record<ReactionStyleVariant, string> = {
      [ReactionStyleVariant.primary]: classes.triggerButtonIconPrimary,
      [ReactionStyleVariant.secondary]: classes.triggerButtonIconSecondary,
    };

    return cx(classes.triggerButtonIcon, config[variant]);
  }, [classes, variant]);

  return (
    <Flex alignItems="flex-start" flexDirection={renderDirection} classes={{ root: classes.root }}>
      <StatefulPopover
        showArrow
        content={reactionOptionsComponent}
        overrides={{
          Arrow: {
            style: {
              backgroundColor: '#fff',
            },
          },
          Inner: {
            style: {
              backgroundColor: '#fff',
            },
          },
        }}
      >
        <Button
          disabled={disabled}
          kind="minimal"
          startEnhancer={<AddReactionIcon classes={{ root: triggerButtonIconClasses }} />}
          className={triggerButtonClasses}
        />
      </StatefulPopover>
      <SelectedReactions
        variant={variant}
        processing={processing}
        data={selectedReactions}
        onSelect={onSelect}
        onRemove={onRemove}
        classes={{ root: classes.selectedReactions }}
      />
    </Flex>
  );
};

export const Reaction = appWithStyles(styles)(ReactionComponent);
