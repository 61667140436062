import { BaseModel } from '@core/base-model';

export interface UserCollaborationListQuery {
  id: string;
  fullName: string;
  pages: Array<{ id: string; name: string }>;
}

export class UserCollaborationListModel extends BaseModel<UserCollaborationListQuery> {
  private id: string;
  private fullName: string;
  private pages: Array<{ id: string; name: string }>;

  constructor(data: UserCollaborationListQuery) {
    super();

    this.update(data);
  }

  get asJson(): UserCollaborationListQuery {
    return {
      id: this.id,
      fullName: this.fullName,
      pages: this.pages,
    };
  }
}
