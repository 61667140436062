import { BaseModel } from '@core/base-model';

import { UserReadQuery } from '../user/read-model';

export interface GroupListQuery {
  autoGroupped: boolean;
  createdAt: string;
  id: string;
  profileName: string;
  name: string;
  users: Array<{
    isManager: boolean;
    user: UserReadQuery;
  }>;
}

export class GroupListModel extends BaseModel<GroupListQuery> {
  private autoGroupped: boolean;
  private createdAt: string;
  private id: string;
  private profileName: string;
  private name: string;
  private users: Array<{
    isManager: boolean;
    user: UserReadQuery;
  }>;

  constructor(data: GroupListQuery) {
    super();

    this.update(data);
  }

  get asJson(): GroupListQuery {
    return {
      autoGroupped: this.autoGroupped,
      createdAt: this.createdAt,
      id: this.id,
      profileName: this.profileName,
      name: this.name,
      users: this.users,
    };
  }
}
