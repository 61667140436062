import { inject } from '@core/di/di-utils';

import { DI_TOKENS } from '@shared/constants/di';
import { TenantFeatures } from '@shared/models/tenant/read-model';
import { IAuthService } from '@shared/types/services/auth';

export const useCheckFeature = () => {
  const authService = inject<IAuthService>(DI_TOKENS.authService);

  return (...features: (keyof TenantFeatures)[]): boolean => {
    return Boolean(features.find((name) => authService.currentTenant?.features?.[name]));
  };
};
