import { inject } from '@core/di/di-utils';

import { DI_TOKENS } from '@shared/constants/di';
import { ICommentsService } from '@shared/types/services/comments';

export class ManagementViewModel {
  private commentsService = inject<ICommentsService>(DI_TOKENS.commentsService);

  create: ICommentsService['create'] = (...args) => {
    return this.commentsService.create(...args);
  };

  edit: ICommentsService['edit'] = (...args) => {
    return this.commentsService.edit(...args);
  };
}
