import { BaseModel } from '@core/base-model';

type UserPage = {
  id: string;
  name: string;
  peoplePageUserId: string;
};

export interface UserListQuery {
  displayName?: string;
  email: string;
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  name: string;
  permissions: Array<string>;
  profileUrl?: string;
  page: UserPage;
  lastActiveAt?: string;
  profileFields: { [key: string]: { value: string } };
}

export class UserListModel extends BaseModel<UserListQuery> {
  private displayName?: string;
  private email: string;
  private firstName: string;
  private fullName: string;
  private id: string;
  private lastName: string;
  private name: string;
  private permissions: Array<string>;
  private profileUrl?: string;
  private page: UserPage;
  private lastActiveAt?: string;
  private profileFields: { [key: string]: { value: string } };

  constructor(dto: UserListQuery) {
    super();

    this.update(dto);
  }

  get asJson(): UserListQuery {
    return {
      displayName: this.displayName,
      email: this.email,
      firstName: this.firstName,
      fullName: this.fullName,
      id: this.id,
      lastName: this.lastName,
      name: this.name,
      page: this.page,
      permissions: this.permissions,
      profileUrl: this.profileUrl,
      lastActiveAt: this.lastActiveAt,
      profileFields: this.profileFields,
    };
  }
}
