import { inject, injectable } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import {
  AccountCode,
  CreateCustomerAddressDTO,
  CustomerAddressesDTO,
  CustomerAddress,
  InventoryItem,
  LineOfBusinesses,
  Order,
  OrderCreateDTO,
  OrderDTO,
  GetAddressesParams,
} from '@shared/models/inventory/list-model';
import { IInventoryService } from '@shared/types/services/inventory';

@injectable()
export class InventoryService extends BaseService implements IInventoryService {
  static diToken = Symbol('inventory-service');

  private readonly $http = inject<HTTPClient>(HTTPClient.diToken);

  protected urlPrefix = '/inventory';

  getOrdersList: IInventoryService['getOrdersList'] = async (params) => {
    const { skip, limit, include, search } = params;

    const { data } = await this.$http.get<OrderDTO>(
      this.getUrl(`orders?skip=${skip}&limit=${limit}&include=${include}&search=${search}`)
    );
    return data;
  };

  createOrder: IInventoryService['createOrder'] = async (params: OrderCreateDTO) => {
    const { data } = await this.$http.post<Order>(this.getUrl('orders'), params);
    return data;
  };

  getOrder: IInventoryService['getOrder'] = async (id: Order['id']) => {
    const { data } = await this.$http.get<Order>(this.getUrl(`orders/${id}`));
    return data;
  };

  deleteOrder: IInventoryService['deleteOrder'] = async (id: Order['id']) => {
    await this.$http.delete<OrderDTO>(this.getUrl(`orders/${id}`));
  };

  updateOrder: IInventoryService['updateOrder'] = async (
    id: Order['id'],
    params: OrderCreateDTO
  ) => {
    const { data } = await this.$http.patch<Order>(this.getUrl(`orders/${id}`), params);
    return data;
  };

  updateOrderStatus: IInventoryService['updateOrderStatus'] = async (
    id: Order['id'],
    params: Record<string, string>
  ) => {
    const { data } = await this.$http.patch<Order>(this.getUrl(`orders/${id}/status`), params);
    return data;
  };

  getInventoryItems: IInventoryService['getInventoryItems'] = async (params) => {
    const { field, order, page, limit, search } = params;
    const { data } = await this.$http.get<Array<InventoryItem>>(
      this.getUrl(
        `items?field=${field}&order=${order}&page=${page}&limit=${limit}&search=${search}`
      )
    );
    return data;
  };

  getLineOfBusinesses: IInventoryService['getLineOfBusinesses'] = async (params) => {
    const { page, limit } = params;
    const { data } = await this.$http.get<Array<LineOfBusinesses>>(
      this.getUrl(`profile-details/line-of-businesses?page=${page}&limit=${limit}`)
    );

    return data;
  };

  getAccountCodes = async (id: LineOfBusinesses['id'], params) => {
    const { page, limit } = params;
    const { data } = await this.$http.get<Array<AccountCode>>(
      this.getUrl(`profile-details/item-account-codes?lobId=${id}&page=${page}&limit=${limit}`)
    );

    return data;
  };

  createAddress = async (params: CreateCustomerAddressDTO) => {
    const { data } = await this.$http.post<CustomerAddress>(this.getUrl('address-book'), params);
    return data;
  };

  getAddresses = async (params) => {
    const { skip, limit, search } = params;

    const { data } = await this.$http.get<CustomerAddressesDTO>(
      this.getUrl(`address-book?skip=${skip}&limit=${limit}&search=${search}`),
      params
    );

    return data;
  };

  deleteAddress = async (id: CustomerAddress['id']) => {
    await this.$http.delete(this.getUrl(`address-book/${id}`));
  };

  getAddressById = async (id: string) => {
    const { data } = await this.$http.get<CustomerAddress>(this.getUrl(`address-book/${id}`));

    return data;
  };

  editAddress = async (id: string, params: CreateCustomerAddressDTO) => {
    const { data } = await this.$http.patch<CustomerAddress>(
      this.getUrl(`address-book/${id}`),
      params
    );

    return data;
  };
}
