export enum CommentOwnerType {
  post = 'POST',
  submission = 'SUBMISSION',
  submissionAnswer = 'SUBMISSION_ANSWER',
}

export type CommentOwner = {
  id: string;
  type: CommentOwnerType;
};
