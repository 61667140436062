import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';

import { TagListDTO } from '@shared/models/tag/list-model';
import { ITagsService, SearchTagByNameQuery } from '@shared/types/services/tags';

@injectable()
export class TagsService implements ITagsService {
  static diToken = Symbol('tags-service');

  private readonly $http = inject<HTTPClient>(HTTPClient.diToken);

  async getTagList() {
    const { data } = await this.$http.get<{
      tags: Array<TagListDTO>;
      archivedTags: Array<TagListDTO>;
    }>('/tags');

    return { tags: data.tags, archivedTags: data.archivedTags };
  }

  searchTags = async (query: SearchTagByNameQuery) => {
    const { data } = await this.$http.post<Array<TagListDTO>>('/search/tags', query);

    return data;
  };

  async createTag(name: TagListDTO['name']) {
    const { data } = await this.$http.post<TagListDTO>('/tags', {
      name,
    });

    return data;
  }

  archiveTag = async (id: TagListDTO['id']) => {
    const { data } = await this.$http.patch<TagListDTO>(`/tags/${id}/archive`);

    return data;
  };

  unarchiveTag = async (id: TagListDTO['id']) => {
    const { data } = await this.$http.patch<TagListDTO>(`/tags/${id}/unarchive`);

    return data;
  };
}
