import { useEffect, useImperativeHandle, useRef, ChangeEvent, ReactNode, forwardRef } from 'react';

import { Textarea, TextareaProps } from 'baseui/textarea';

type Props = TextareaProps & {
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  children?: ReactNode;
  $ref?: any;
};

export const AutosizeTextarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { children, onChange: onChangeProp, ...rest } = props;

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useImperativeHandle<HTMLTextAreaElement, any>(ref, () => inputRef.current);

  const autosize = () => {
    const el = inputRef.current;
    if (el) {
      el.rows = 1;
      const style = getComputedStyle(el);
      const lineHeight = style['line-height'];
      el.rows = ~~Math.ceil(el.scrollHeight / parseInt(lineHeight, 10));
    }
  };

  autosize();

  useEffect(() => {
    autosize();
  }, [props.value]);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeProp(e);
  };

  return (
    // @ts-ignore
    <Textarea inputRef={inputRef} onChange={onChange} rows={1} {...rest}>
      {children}
    </Textarea>
  );
});
