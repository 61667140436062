import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { SubmissionReadQuery } from '@shared/models/submission/read-model';

import { Item } from './components/item';

import { styles } from './log.styles';

type LogProps = AppWithStyles<typeof styles> & {
  data: SubmissionReadQuery['activityLog'];
};

const LogComponent = ({ classes, data }: LogProps) => {
  if (data.length === 0) return null;

  return (
    <div className={classes.root}>
      {[...data]
        .sort((a, b) => +new Date(b.at) - +new Date(a.at))
        .map((logItem) => (
          <Item key={logItem.at} data={logItem} classes={{ root: classes.item }} />
        ))}
    </div>
  );
};

export const Log = appWithStyles(styles)(LogComponent);
