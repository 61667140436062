import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { ReactionReadModel, ReactionReadQuery } from '@shared/models/reaction/read-model';
import { StoryListDTO } from '@shared/models/story/list-model';
import { StoryReadQuery } from '@shared/models/story/read-model';
import { TagListDTO } from '@shared/models/tag/list-model';
import { PaginatedResponse } from '@shared/types/services';
import { IStoriesService } from '@shared/types/services/stories';

@injectable()
export class StoriesService extends BaseService implements IStoriesService {
  private readonly $http = inject<HTTPClient>(HTTPClient.diToken);

  getStory: IStoriesService['getStory'] = async (id: StoryListDTO['id']) => {
    const { data } = await this.$http.get<StoryReadQuery>(`/stories/${id}`);

    return data;
  };

  getStoryList: IStoriesService['getStoryList'] = async (filter, pagination) => {
    const {
      data: { stories, total },
    } = await this.$http.get<PaginatedResponse<{ stories: Array<StoryListDTO> }>>('/stories', {
      params: {
        ...filter,
        ...pagination,
      },
    });

    return { total, stories };
  };

  getPostTags: IStoriesService['getPostTags'] = async (id) => {
    const {
      data: { primaryTag, tags },
    } = await this.$http.get<{ primaryTag?: TagListDTO; tags: Array<TagListDTO> }>(
      `/posts/${id}/tags`
    );

    return { primaryTag, tags };
  };

  updatePostTags: IStoriesService['updatePostTags'] = async (id, tags) => {
    await this.$http.post(`/posts/${id}/tags`, { tags });
  };

  removePostTags: IStoriesService['removePostTags'] = async (id, tags) => {
    await this.$http.delete(`/posts/${id}/tags`, {
      data: {
        tags,
      },
    });
  };

  getPostReaction: IStoriesService['getPostReaction'] = async (postId) => {
    const {
      data: { reactions },
    } = await this.$http.get<{ reactions: Array<ReactionReadQuery> }>(`/posts/${postId}/reactions`);

    return reactions.map((reaction) => new ReactionReadModel(reaction));
  };

  addPostReaction: IStoriesService['addPostReaction'] = async (postId, data) => {
    const {
      data: { reactions },
    } = await this.$http.post<{ reactions: Array<ReactionReadQuery> }>(
      `/posts/${postId}/reactions`,
      data
    );

    return reactions.map((reaction) => new ReactionReadModel(reaction));
  };

  deletePostReaction: IStoriesService['deletePostReaction'] = async (postId, reactionId) => {
    const {
      data: { reactions },
    } = await this.$http.delete<{ reactions: Array<ReactionReadQuery> }>(
      `/posts/${postId}/reactions/${reactionId}`
    );

    return reactions.map((reaction) => new ReactionReadModel(reaction));
  };
}
