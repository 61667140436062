import { useMemo } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Message } from '@shared/components/Message';
import { NotificationListQuery } from '@shared/models/notification/list-model';

import { Item } from './components/item';

import { styles } from './list.styles';

type Props = AppWithStyles<typeof styles> & {
  data: Array<NotificationListQuery>;
  handleError: (errorText?: string) => void;
};

const ListComponent = ({ classes, data, handleError }: Props) => {
  const content = useMemo(() => {
    if (!data.length) {
      return (
        <Message
          heading="There are no notifications"
          classes={{
            root: classes.emptyState,
            heading: classes.heading,
          }}
        />
      );
    }

    return data.map((item) => {
      return (
        <Item
          key={item.id}
          data={item}
          classes={{ root: classes.notification }}
          handleError={handleError}
        />
      );
    });
  }, [classes, data]);

  return <div className={classes.root}>{content}</div>;
};

export const List = appWithStyles(styles)(ListComponent);
