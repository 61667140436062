import { RefObject, useState } from 'react';

import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import cx from 'classnames';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Message } from '@shared/components/Message';
import { Spinner } from '@shared/components/Spinner';

import { styles } from './video-view.styles';

type VideoViewProps = AppWithStyles<typeof styles> & {
  autoPlay?: boolean;
  src: string;
  muted?: boolean;
  controls?: boolean;
  reference?: RefObject<HTMLVideoElement>;
  loaderSize?: number;
  onError?: () => void;
  onLoad?: () => void;
  onEnded?: () => void;
};

const VideoViewComponent = ({
  classes,
  reference,
  src,
  loaderSize,
  onError,
  onLoad,
  onEnded,
  muted,
  autoPlay,
  controls = true,
}: VideoViewProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleLoad = () => {
    setLoading(false);

    if (onLoad) {
      onLoad();
    }
  };

  const handleError = () => {
    setLoading(false);
    setError(true);

    if (onError) {
      onError();
    }
  };

  const renderVideo = () => {
    if (error) {
      return (
        <Message
          icon={<BrokenImageIcon classes={{ root: classes.videoEmptyStateIcon }} />}
          heading="Failed to load"
          classes={{ root: classes.video, heading: classes.videoEmptyStateHeading }}
        />
      );
    }

    return (
      <>
        <video
          autoPlay={autoPlay}
          muted={muted}
          controls={controls}
          ref={reference}
          src={src}
          controlsList="nodownload"
          className={cx(classes.video, { [classes.videoHidden]: loading })}
          onError={handleError}
          onCanPlay={handleLoad}
          onEnded={onEnded}
        />
        {loading && (
          <Spinner
            size={loaderSize}
            withWrapper
            wrapperProps={{ classes: { root: classes.loader } }}
          />
        )}
      </>
    );
  };

  return <div className={classes.root}>{renderVideo()}</div>;
};

export const VideoView = appWithStyles(styles)(VideoViewComponent);
