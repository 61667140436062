import { useEffect, useMemo, useState } from 'react';

import { Button } from 'src/shared/components/button';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Reaction } from '@shared/components';
import { CommentsAction } from '@shared/components/commentaries/commentaries.types';
import { Flex } from '@shared/components/Flex';
import { CommentListQuery } from '@shared/models/comment/list-model';
import { CommentReadQuery } from '@shared/models/comment/read-model';
import { ReactionReadQuery } from '@shared/models/reaction/read-model';
import { ReactionType } from '@shared/models/reaction/type';
import { UserReadQuery } from '@shared/models/user/read-model';
import { showErrors } from '@shared/utils/errors';

import { processReactions } from './footer.utils';
import { FooterViewModel } from './footer.vm';

import { styles } from './footer.styles';

type Props = AppWithStyles<typeof styles> & {
  canReply: boolean;
  action?: CommentsAction;
  currentUser: UserReadQuery;
  reactions: CommentReadQuery['reactions'];
  id: CommentReadQuery['id'];
  onReply: () => void;
  onReactionUpdateSuccess?: (comment: CommentListQuery) => void;
};

const FooterComponent = ({
  classes,
  reactions: _reactions,
  id,
  currentUser,
  action,
  onReply,
  canReply,
  onReactionUpdateSuccess,
}: Props) => {
  const $vm = useMemo(() => new FooterViewModel(), []);

  const [reactions, setReactions] = useState<CommentReadQuery['reactions']>(_reactions);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setReactions(_reactions);
  }, [_reactions]);

  const onReactionAdd = async (reaction: ReactionType) => {
    try {
      setProcessing(true);

      const comment = await $vm.addReaction(id, { type: reaction });

      setReactions(comment.asJson.reactions);

      if (onReactionUpdateSuccess) {
        onReactionUpdateSuccess(comment.asJson);
      }
    } catch (err) {
      showErrors(err?.response?.data?.errors, {
        notificationText: 'Something went wrong while adding reaction',
      });

      console.error(err);
    } finally {
      setProcessing(false);
    }
  };

  const onReactionRemove = async (reactionId: ReactionReadQuery['id']) => {
    try {
      setProcessing(true);

      const comment = await $vm.deleteReaction(id, reactionId);

      setReactions(comment.asJson.reactions);

      if (onReactionUpdateSuccess) {
        onReactionUpdateSuccess(comment.asJson);
      }
    } catch (err) {
      showErrors(err?.response?.data?.errors, {
        notificationText: 'Something went wrong while removing reaction',
      });

      console.error(err);
    } finally {
      setProcessing(false);
    }
  };

  const processedReactions = useMemo(() => {
    return processReactions(reactions, currentUser.id);
  }, [reactions, currentUser]);

  return (
    <Flex justifyContent="space-between" classes={{ root: classes.root }}>
      <Reaction
        disabled={Boolean(action)}
        processing={processing}
        selectedReactions={processedReactions}
        onSelect={onReactionAdd}
        onRemove={onReactionRemove}
      />
      {canReply && (
        <Button
          disabled={Boolean(action)}
          kind="minimal"
          size="compact"
          text="Reply"
          onClick={onReply}
        />
      )}
    </Flex>
  );
};

export const Footer = appWithStyles(styles)(FooterComponent);
