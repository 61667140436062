import { BaseModel } from '@core/base-model';

export type UserHeaderQuery = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  isDeactivated: boolean;
  profileUrl?: string;
};

export class UserHeaderModel extends BaseModel<UserHeaderQuery> {
  private id: string;
  private name: string;
  private firstName: string;
  private lastName: string;
  private email: string;
  private isDeactivated: boolean;
  private profileUrl?: string;

  constructor(dto: UserHeaderQuery) {
    super();

    this.update(dto);
  }

  get asJson(): UserHeaderQuery {
    return {
      id: this.id,
      name: this.name,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      isDeactivated: this.isDeactivated,
      profileUrl: this.profileUrl,
    };
  }
}
