import { DI_TOKENS } from '@shared/constants/di';

import { NotificationsViewModel } from './notifications';
import { ReactionsViewModel } from './reactions';

export const viewModels = [
  {
    diToken: DI_TOKENS.reactionsViewModel,
    entity: ReactionsViewModel,
  },
  {
    diToken: DI_TOKENS.notificationsViewModel,
    entity: NotificationsViewModel,
  },
];
