import { ReactNode } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { ErrorText, Label } from '@shared/components';

import { styles } from './Field.styles';

export type FieldConfig = {
  label?: ReactNode;
  optional?: boolean;
  hasError?: boolean;
  errorText?: ReactNode;
};

export type FieldProps = AppWithStyles<typeof styles> &
  FieldConfig & {
    children: ReactNode;
  };

const FieldComponent = ({
  classes,
  children,
  label,
  optional,
  errorText,
  hasError,
  ...otherProps
}: FieldProps) => {
  return (
    <div className={classes.root} {...otherProps}>
      {label && <Label text={label} optional={optional} classes={{ label: classes.label }} />}
      {children}
      {hasError && <ErrorText text={errorText} classes={{ root: classes.errorText }} />}
    </div>
  );
};

export const Field = appWithStyles(styles)(FieldComponent);
