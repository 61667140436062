import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { RoleListModel, RoleListQuery } from '@shared/models/role/list-model';

@injectable()
export class RolesService extends BaseService {
  private $http = inject<HTTPClient>(HTTPClient.diToken);

  async getList() {
    const query = `
      query ProgramUsersRolesPageQuery {
        roles {
          id
          name
          userCount
          permissions {
            news {
              canManageHeadlines
            }
            teamwork {
              canManageTeamwork
              canReviewSubmissions
              canManageSubmissions
            }
            admin {
              canManageUsers
              canManageRoles
            }
          }
        }
      }
    `;

    const {
      data: { roles },
    } = await this.$http.post<{ roles: Array<RoleListQuery> }>('/api', { query });

    return roles.map((tag) => new RoleListModel(tag));
  }
}
