import { BaseModel } from '@core/base-model';

import { NotificationEvent } from './type';

import { HeadlineListQuery } from '../headline/list-model';
import { TodoReadQuery } from '../todo/read-model';
import { UserReadQuery } from '../user/read-model';

type NotificationCreator = Pick<UserReadQuery, 'id' | 'firstName' | 'lastName' | 'profileUrl'>;

export interface NotificationListQuery {
  eventType: NotificationEvent;
  id: string;
  title: string;
  entityId: TodoReadQuery['id'] | HeadlineListQuery['id'];
  isRead: boolean;
  createdAt: string;
  createdBy?: NotificationCreator;
  body: string;
  commentId: string;
  commentParentId?: string;
}

export class NotificationListModel extends BaseModel<NotificationListQuery> {
  private eventType: NotificationEvent;
  private id: string;
  private title: string;
  private entityId: TodoReadQuery['id'] | HeadlineListQuery['id'];
  private isRead: boolean;
  private createdAt: string;
  private createdBy?: NotificationCreator;
  private body: string;
  private commentId: string;
  private commentParentId: string;

  constructor(data: NotificationListQuery) {
    super();

    this.update(data);
  }

  get asJson(): NotificationListQuery {
    return {
      eventType: this.eventType,
      id: this.id,
      commentId: this.commentId,
      commentParentId: this.commentParentId,
      title: this.title,
      entityId: this.entityId,
      isRead: this.isRead,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      body: this.body,
    };
  }

  markAsRead = () => {
    this.isRead = true;
  };
}
