import { palette } from '@core/theme/units/palette';
import { radius } from '@core/theme/units/radius';
import { shadows } from '@core/theme/units/shadows';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
    },
    sourceElement: {
      width: '100%',
      height: '100%',
      borderRadius: radius.containers.primary,
      backgroundColor: palette.grey[100],
    },
    video: {},
    image: {
      objectFit: 'contain',
    },
    controls: {
      marginLeft: spacing(2),
    },
    control: {
      marginBottom: `${spacing(2)} !important`,
      width: '32px',
      height: '32px',
      boxShadow: `${shadows[2]} !important`,
      borderRadius: `${radius.containers.primary} !important`,
      backgroundColor: palette.common.white,
    },
    controlDisabled: {
      pointerEvents: 'none',
      opacity: 0.6,
    },
    controlIcon: {
      width: '20px',
      height: '20px',
    },
    imageEmptyState: {
      height: '100%',
      cursor: 'pointer',
    },
    imageEmptyStateIcon: {
      color: palette.grey[400],
    },
    imageEmptyStateHeading: {
      color: palette.grey[400],
      fontSize: '14px',
    },
  });
