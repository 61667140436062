import { palette } from '@core/theme/units/palette';
import { radius } from '@core/theme/units/radius';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const AVATAR_SIZE = 24;
export const FULL_NAME_MARGIN = 12;
export const TIMESTAMP_WIDTH = 100;
export const TIMESTAMP_MARGIN = 12;
const UNREAD_MARKER_SIZE = 6;
const UNREAD_MARKER_MARGIN = 8;

export const styles = () =>
  createStyles({
    root: {},
    userInfoWrapper: {
      width: `calc(100% - ${AVATAR_SIZE + FULL_NAME_MARGIN}px)`,
    },
    fullName: {
      marginLeft: `${FULL_NAME_MARGIN}px`,
      fontSize: '14px',
      fontWeight: 600,
      color: palette.common.black,
      cursor: 'pointer',
    },
    fullNameDisabled: {
      cursor: 'default',
    },
    createdAt: {
      marginLeft: `${TIMESTAMP_MARGIN}px`,
      width: `${TIMESTAMP_WIDTH}px`,
      color: palette.grey[500],
      fontSize: '12px',
      fontWeight: 400,
    },
    body: {
      marginTop: spacing(),
      marginRight: `${TIMESTAMP_MARGIN + TIMESTAMP_WIDTH}px`,
      marginLeft: `${AVATAR_SIZE + FULL_NAME_MARGIN}px`,
    },
    marker: {
      marginTop: spacing(2),
      width: `${UNREAD_MARKER_SIZE}px`,
      height: `${UNREAD_MARKER_SIZE}px`,
      borderRadius: radius.containers.round,
      marginRight: `${UNREAD_MARKER_MARGIN}px`,
    },
    notificationText: {
      maxWidth: `calc(100% - ${UNREAD_MARKER_SIZE + UNREAD_MARKER_MARGIN}px)`,
      wordBreak: 'break-word',
      cursor: 'pointer',
    },
  });
