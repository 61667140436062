import { ReactNode } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Header, HeaderProps } from '../header';

import { styles } from './wrapper-header.styles';

type Props = AppWithStyles<typeof styles> &
  Omit<HeaderProps, 'classes'> & {
    children: ReactNode;
  };

const WrapperHeaderComponent = ({ classes, title, children, actions, onClose, loading }: Props) => {
  return (
    <div className={classes.root}>
      <Header
        classes={{
          root: classes.header,
          title: classes.headerTitle,
          titleWrapper: classes.headerTitleWrapper,
        }}
        loading={loading}
        title={title}
        actions={actions}
        onClose={onClose}
      />
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export const WrapperHeader = appWithStyles(styles)(WrapperHeaderComponent);
