import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { InsightListDTO } from '@shared/models/insight/list-model';
import { IInsightsService } from '@shared/types/services/insights';

@injectable()
export class InsightsService extends BaseService implements IInsightsService {
  private readonly $http = inject<HTTPClient>(HTTPClient.diToken);

  createInsight: IInsightsService['createInsight'] = async (params) => {
    const { data } = await this.$http.post<InsightListDTO>('/insight-views', params);
    return data;
  };

  updateInsight: IInsightsService['updateInsight'] = async (id, data) => {
    await this.$http.patch<InsightListDTO>(`/insight-views/${id}`, data);
  };

  deleteInsight: IInsightsService['deleteInsight'] = async (id) => {
    await this.$http.delete<InsightListDTO>(`/insight-views/${id}`);
  };

  changeSharingAccessType: IInsightsService['changeSharingAccessType'] = async (id, state) => {
    await this.$http.put('/sharing/insight-view/access', {
      insightView: id,
      isPublic: state,
    });
  };

  getSharedInsightFilterExternal: IInsightsService['getSharedInsightFilterExternal'] = async (
    token
  ) => {
    const { data } = await this.$http.get<InsightListDTO>('/sharing/insight-view/public', {
      headers: {
        'Insight-Token': token,
      },
    });

    return data;
  };

  getSharedInsightFilterInternal: IInsightsService['getSharedInsightFilterInternal'] = async (
    token
  ) => {
    const { data } = await this.$http.get<InsightListDTO>('/sharing/insight-view/restricted', {
      headers: {
        'Insight-Token': token,
      },
    });

    return data;
  };

  getSharedFilterAccess: IInsightsService['getSharedFilterAccess'] = async (token) => {
    const { data } = await this.$http.get<{ isPublic: boolean }>('/sharing/insight-view/access', {
      headers: {
        'Insight-Token': token,
      },
    });

    return data.isPublic;
  };
}
