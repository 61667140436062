import { ReactNode } from 'react';

import { Button } from '@shared/components';
import { Icon } from '@shared/components/Icon';

type Props = {
  full?: boolean;
  onClick?: Function;
  children: ReactNode;
};

export const OpenButton = ({ children, onClick, full = false }: Props) => {
  return (
    <Button
      kind="primary"
      onClick={() => onClick && onClick()}
      startEnhancer={<Icon size={20} icon="RegularArrowSquareRight" />}
      overrides={{
        BaseButton: {
          style: {
            flex: full ? 1 : 'unset',
            textTransform: 'uppercase',
          },
        },
      }}
    >
      {children}
    </Button>
  );
};
