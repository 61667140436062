import { useCallback } from 'react';

import { inject } from '@core/di/di-utils';

import { DI_TOKENS } from '@shared/constants/di';
import { TenantReadQuery } from '@shared/models/tenant/read-model';
import { IAuthService } from '@shared/types/services/auth';
import { getRoute } from '@shared/utils/getRoute';
import { getQueriesAsSearch } from '@shared/utils/queries';

export const useCrossTenantLink = (tenantRoute: string) => {
  const authService = inject<IAuthService>(DI_TOKENS.authService);
  const accessToken = authService.tokens.access;
  const refreshToken = authService.tokens.refresh;

  return useCallback(
    (tenant: TenantReadQuery) => {
      const hostPieces = location.host.split('.');
      hostPieces.shift();
      const url = `${tenant.slug}.${hostPieces.join('.')}${
        tenant.isAdminTenant ? getRoute('TENANTS_LIST') : tenantRoute
      }`;

      window.location.replace(
        `${location.protocol}//${url}${getQueriesAsSearch({ accessToken, refreshToken })}`
      );
    },
    [tenantRoute, accessToken, refreshToken]
  );
};
