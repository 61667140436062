import { palette } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      color: palette.link.primary,
      cursor: 'pointer',

      '&:hover': {
        color: palette.link.primaryHover,
      },
    },
  });
