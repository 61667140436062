import { AlignItems, FlexDirection, JustifyContent } from 'baseui/block';

import { FlexWrap } from './Flex';

const alignItems: { [K in `alignItems-${AlignItems}`]?: CSSRules } = {
  'alignItems-start': {
    alignItems: 'start',
  },
  'alignItems-center': {
    alignItems: 'center',
  },
  'alignItems-end': {
    alignItems: 'end',
  },
  'alignItems-flex-start': {
    alignItems: 'flex-start',
  },
  'alignItems-flex-end': {
    alignItems: 'flex-end',
  },
  'alignItems-baseline': {
    alignItems: 'baseline',
  },
  'alignItems-inherit': {
    alignItems: 'inherit',
  },
};

const flexDirection: { [K in `flexDirection-${FlexDirection}`]?: CSSRules } = {
  'flexDirection-row': {
    flexDirection: 'row',
  },
  'flexDirection-row-reverse': {
    flexDirection: 'row-reverse',
  },
  'flexDirection-column': {
    flexDirection: 'column',
  },
  'flexDirection-column-reverse': {
    flexDirection: 'column-reverse',
  },
  'flexDirection-inherit': {
    flexDirection: 'inherit',
  },
};

const justifyContent: { [K in `justifyContent-${JustifyContent}`]?: CSSRules } = {
  'justifyContent-center': {
    justifyContent: 'center',
  },
  'justifyContent-start': {
    justifyContent: 'start',
  },
  'justifyContent-end': {
    justifyContent: 'end',
  },
  'justifyContent-flex-start': {
    justifyContent: 'flex-start',
  },
  'justifyContent-flex-end': {
    justifyContent: 'flex-end',
  },
  'justifyContent-space-between': {
    justifyContent: 'space-between',
  },
  'justifyContent-space-around': {
    justifyContent: 'space-around',
  },
  'justifyContent-space-evenly': {
    justifyContent: 'space-evenly',
  },
};

const flexWrap: { [K in `flexWrap-${FlexWrap}`]?: CSSRules } = {
  'flexWrap-wrap': {
    flexWrap: 'wrap',
  },
  'flexWrap-nowrap': {
    flexWrap: 'nowrap',
  },
};

export const styles: Styles = {
  root: {
    display: 'flex',
  },
  ...alignItems,
  ...flexDirection,
  ...justifyContent,
  ...flexWrap,
};
