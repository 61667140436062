import { appCreatePalette } from '@core/theme/utils/with-styles';

import { AppPaletteOptions } from '../IMaterial';

export const palette = {
  common: {
    black: '#000',
    white: '#fff',
  },
  primary: {
    light: '#0068B3',
    main: '#0068B3',
    dark: '#0068B3',
  },
  border: {
    main: '#CCCCCC',
  },
  error: {
    main: '#D44333',
    border: '#f1998e',
  },
  grey: {
    50: '#F6F6F6',
    100: '#EEEEEE',
    200: '#E2E2E2',
    300: '#CBCBCB',
    400: '#AFAFAF',
    500: '#757575',
  },
  text: {
    primary: '#333333',
    secondary: '#545454',
    disabled: '#b3b3b3',
  },
  background: {
    paper: '#fff',
    default: '#E5E5E5',
    input: '#f0f0f0',
    error: '#ffefed',
  },
  link: {
    primary: '#0000EE',
    primaryHover: '#551A8B',
  },
};

export const initializePalette = (config?: AppPaletteOptions) => {
  return appCreatePalette({
    ...config,
  });
};
