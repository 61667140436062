import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {},
    triggerButton: {
      width: '24px',
      height: '24px',
      padding: spacing(0, 1),
      margin: spacing(0, 2, 2, 0),
    },
    triggerButtonPrimary: {
      backgroundColor: palette.grey[50],
    },
    triggerButtonSecondary: {
      backgroundColor: 'transparent',
    },
    triggerButtonIcon: {
      width: '32px',
      height: '32px',
    },
    triggerButtonIconPrimary: {
      '& > path': {
        fill: '#595959',
      },
    },
    triggerButtonIconSecondary: {
      '& > path': {
        fill: palette.common.white,
      },
    },
    reactionPopoverOptionsWrapper: {
      padding: spacing(),
    },
    reactionPopoverOption: {
      backgroundColor: 'transparent',
      fontSize: '24px',
      marginLeft: spacing(),
    },
    selectedReactions: {
      marginRight: spacing(),
    },
    reactionEmoji: {
      color: '#DD2E44',
    },
  });
