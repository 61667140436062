import 'reflect-metadata';

import ReactDOM from 'react-dom/client';

import { inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { setUp } from '@core/state-management/utils';
import { Config } from '@core/сonfig';

import { DI_TOKENS } from '@shared/constants/di';
import { IConfig } from '@shared/types/config';
import { IAuthService } from '@shared/types/services/auth';

import { App } from './app';

import './index.css';

async function initializeApp() {
  try {
    setUp();

    const config = inject<IConfig>(Config.diToken);

    await config.initialize();

    const httpClient = inject<HTTPClient>(HTTPClient.diToken);
    const authService = inject<IAuthService>(DI_TOKENS.authService);

    httpClient.initialize({
      defaults: {
        baseURL: config.getApiBaseURL(),
      },
      getTokenRefreshStatus: () => authService.tokenRefreshStatus,
      getAccessToken: () => authService.tokens.access,
      processUnauthenticated: authService.processUnauthenticated,
    });

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

    root.render(<App />);
  } catch (err) {
    alert('Sorry, we are running a problem while loading the App');

    throw err;
  }
}

initializeApp();
