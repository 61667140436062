import { useEffect, useState, ChangeEvent } from 'react';

import { styled } from 'baseui';

import { Input, PopoverPLACEMENT, StatefulPopover } from '@shared/components';

import { ColorPopover } from '../Popovers';

const ColorBlock = styled('div', ({ $color }: any) => ({
  width: '44px',
  height: '42px',
  backgroundColor: $color,
  borderRadius: '4px 0 0 4px',
  cursor: 'pointer',
}));

type Props = {
  value?: string;
  error?: boolean;
  placeholder?: string;
  onChange?: Function;
  onBlur?: Function;
};

export const ColorInput = (props: Props) => {
  const {
    value = '',
    error = false,
    placeholder = 'Enter a Color',
    onChange = () => null,
    onBlur = () => null,
  } = props;

  const [color, setColor] = useState(value);

  useEffect(() => {
    setColor(value);
  }, [value]);

  return (
    <Input
      value={color}
      error={error}
      name={placeholder}
      placeholder={placeholder}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setColor(e.target.value);
        onChange(e.target.value);
      }}
      onBlur={(e: ChangeEvent<HTMLInputElement>) => onBlur(e)}
      startEnhancer={
        <StatefulPopover
          showArrow
          placement={PopoverPLACEMENT.bottom}
          content={({ close }) => {
            return (
              <ColorPopover
                onClick={(color: string) => {
                  onBlur({
                    target: { value: color },
                  });
                  close();
                }}
              />
            );
          }}
        >
          <ColorBlock $color={value} />
        </StatefulPopover>
      }
      overrides={{
        StartEnhancer: {
          style: {
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
          },
        },
        Root: {
          style: {
            width: '200px',
          },
        },
      }}
    />
  );
};
