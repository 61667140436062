import { useEffect, useRef, useState } from 'react';

export const useIntersect = ({
  root = null,
  rootMargin,
  threshold = 0,
}: any): [any, IntersectionObserverEntry] => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry | any>({});
  const [node, setNode] = useState();

  const observer = useRef(
    new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold,
    })
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    node && currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node]);

  const result: [any, IntersectionObserverEntry] = [setNode, entry];

  return result;
};
