import { BaseModel } from '@core/base-model';

import { ReactionReadQuery } from '../reaction/read-model';
import { UserReadQuery } from '../user/read-model';

export interface CommentReadQuery {
  body: string;
  createdAt: string;
  editor: UserReadQuery;
  id: string;
  parent: CommentReadQuery['id'];
  reactions: Array<ReactionReadQuery>;
  updatedAt: string;
  user: UserReadQuery;
  childrenCount: number;
}

export class CommentReadModel extends BaseModel<CommentReadQuery> {
  private body: string;
  private createdAt: string;
  private editor: UserReadQuery;
  private id: string;
  private parent: CommentReadQuery['id'];
  private reactions: Array<ReactionReadQuery>;
  private updatedAt: string;
  private user: UserReadQuery;
  private childrenCount: number;

  constructor(data: CommentReadQuery) {
    super();

    this.update(data);
  }

  get asJson(): CommentReadQuery {
    return {
      body: this.body,
      createdAt: this.createdAt,
      editor: this.editor,
      id: this.id,
      parent: this.parent,
      reactions: this.reactions,
      updatedAt: this.updatedAt,
      user: this.user,
      childrenCount: this.childrenCount,
    };
  }
}
