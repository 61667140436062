import { inject } from '@core/di/di-utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';

import { DI_TOKENS } from '@shared/constants/di';
import { CommentOwner } from '@shared/models/comment/comment-owner';
import { CommentListQuery } from '@shared/models/comment/list-model';
import { CommentReadQuery } from '@shared/models/comment/read-model';
import { IAuthService } from '@shared/types/services/auth';
import { ICommentsService } from '@shared/types/services/comments';
import { showErrors } from '@shared/utils/errors';

export class CommentariesViewModel {
  private readonly authService = inject<IAuthService>(DI_TOKENS.authService);
  private readonly commentsService = inject<ICommentsService>(DI_TOKENS.commentsService);

  private readonly DEFAULT_LIMIT: number = 10;

  private _loading = {
    initialize: false,
    list: false,
    commentsCount: false,
  };
  private _commentCount: number = 0;

  private readonly _owner: CommentOwner;

  private _commentsList: Array<CommentListQuery> = [];
  private _commentsTotal: number = 0;

  constructor(owner: CommentOwner) {
    this._owner = owner;

    appMakeObservable(this, {
      _loading: appObservable,
      _owner: appObservable,
      _commentCount: appObservable,
      _commentsList: appObservable,
      _commentsTotal: appObservable,
    });
  }

  get loading() {
    return this._loading;
  }

  get commentCount() {
    return this._commentCount;
  }

  get currentUser() {
    return this.authService.currentUser;
  }

  get commentsList() {
    return this._commentsList;
  }

  get commentsTotal() {
    return this._commentsTotal;
  }

  updateComment(updatedComment: CommentReadQuery) {
    this._commentsList = this._commentsList.map((comment) => {
      return comment.id === updatedComment.id ? updatedComment : comment;
    });
  }

  async initializeComments(openCommentId?: string) {
    try {
      const { total, comments } = await this.fetchCommentList(0);
      const processComments = comments.map(({ asJson }) => asJson);

      this._commentsList = processComments;
      this._commentsTotal = total;

      if (!openCommentId || total === 0) return;

      const hasCurrentComment = processComments.find(({ id }) => id === openCommentId);

      if (hasCurrentComment) return;

      const nextResponse = await this.fetchCommentList(0, total);

      this._commentsList = nextResponse.comments.map(({ asJson }) => asJson);
      this._commentsTotal = nextResponse.total;
    } catch (e) {
      console.error(e);
    } finally {
      this._loading.initialize = false;
    }
  }

  async refreshList() {
    try {
      await this.getList(0, this._commentsList.length + 1);
    } catch (e) {
      showErrors(e?.response?.data?.errors, {
        notificationText: 'Something went wrong while refreshing list',
      });
      console.error(e);
    }
  }

  async loadMore() {
    const { comments, total } = await this.fetchCommentList(this._commentsList.length);

    this._commentsList = [...this._commentsList, ...comments.map(({ asJson }) => asJson)];
    this._commentsTotal = total;
  }

  async getList(skip: number, limit: number = this.DEFAULT_LIMIT) {
    try {
      this._loading.list = true;

      const { comments, total } = await this.fetchCommentList(skip, limit);

      this._commentsList = comments.map(({ asJson }) => asJson);
      this._commentsTotal = total;
    } catch (e) {
      showErrors(e?.response?.data?.errors, {
        notificationText: 'Something went wrong while loading comments',
      });
      console.error(e);
    } finally {
      this._loading.list = false;
    }
  }

  private fetchCommentList(skip: number, limit?: number) {
    return this.commentsService.getList({
      owner: this._owner,
      pagination: {
        skip,
        limit: limit || this.DEFAULT_LIMIT,
      },
    });
  }

  async getCommentsCount() {
    this._loading.commentsCount = true;

    try {
      const response = await this.commentsService.getCommentsCount(this._owner);
      this._commentCount = response;

      return response;
    } catch (e) {
      showErrors(e?.response?.data?.errors, {
        notificationText: 'Something went wrong while loading count',
      });
      console.error(e);
    } finally {
      this._loading.commentsCount = false;
    }
  }
}
