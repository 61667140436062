import { Modal } from 'baseui/modal';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { ImageView } from '@shared/components/image-view';
import { AnswerFile } from '@shared/models/submission/answer/answer-file';

import { styles, MODAL_MARGIN } from './full-screen-view.styles';

type Props = AppWithStyles<typeof styles> & {
  isOpen: boolean;
  src: AnswerFile['url'];
  close: () => void;
};

const FullScreenViewComponent = ({ classes, src, isOpen, close }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      size="full"
      overrides={{
        Root: {
          style: {
            overflow: 'hidden',
            height: '100%',
            width: '100%',
          },
        },
        Dialog: {
          style: {
            height: `calc(100% - ${MODAL_MARGIN * 2}px)`,
            width: `calc(100% - ${MODAL_MARGIN * 2}px)`,
          },
        },
        DialogContainer: {
          style: {
            height: '100%',
            width: '100%',
          },
        },
      }}
      onClose={close}
    >
      <ImageView
        src={src}
        loaderSize={25}
        classes={{
          root: classes.imageWrapper,
          image: classes.image,
        }}
      />
    </Modal>
  );
};

export const FullScreenView = appWithStyles(styles)(FullScreenViewComponent);
