import { palette } from '@core/theme/units/palette';
import { radius } from '@core/theme/units/radius';
import { shadows } from '@core/theme/units/shadows';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {},
    rootHidden: {
      display: 'none',
    },
    headerWrapper: {
      marginBottom: spacing(3),
    },
    count: {
      color: palette.text.secondary,
      fontSize: '14px',
      fontWeight: 600,
    },
    disabledList: {
      opacity: 0.6,
      pointerEvents: 'none',
    },
    management: {
      padding: spacing(4),
      backgroundColor: palette.common.white,
      boxShadow: shadows[2],
      borderRadius: radius.containers.primary,
    },
    emptyState: {
      marginTop: spacing(3),
      height: '100px',
      borderRadius: radius.containers.primary,
      border: `1px solid ${palette.grey[200]}`,
    },
    emptyStateIcon: {
      fill: `${palette.grey[400]} !important`,
    },
    emptyStateHeading: {
      color: palette.grey[500],
      fontSize: '14px',
      fontWeight: 600,
    },
    loadMoreButtonWrapper: {
      marginTop: spacing(6),
    },
    refreshIcon: {
      width: '12px',
      height: '12px',

      '& > path': {
        fill: palette.grey[500],
      },
    },
  });
