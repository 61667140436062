import { ReactNode } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Flex } from '@shared/components';

import { styles } from './Label.styles';

type Props = AppWithStyles<typeof styles> & {
  text: ReactNode;
  optional?: boolean;
};

const LabelComponent = ({ classes, text, optional }: Props) => {
  return (
    <Flex alignItems="center" classes={{ root: classes.root }}>
      <span className={classes.label}>{text}</span>
      {optional && <span className={classes.optionalLabel}>(optional)</span>}
    </Flex>
  );
};

export const Label = appWithStyles(styles)(LabelComponent);
