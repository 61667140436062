import { ReactNode, forwardRef } from 'react';

import cx from 'classnames';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Button, Icon, ButtonProps, ButtonSIZE } from '@shared/components';

import { styles } from './add-button.styles';

type Props = ButtonProps &
  AppWithStyles<typeof styles> & {
    view?: 'primary' | 'secondary';
    children: ReactNode;
  };

const AddButtonComponent = forwardRef<HTMLButtonElement, Props>(
  ({ classes, view = 'primary', className, ...otherProps }: Props, ref) => {
    return (
      <Button
        ref={ref}
        {...otherProps}
        kind="primary"
        size={ButtonSIZE.compact}
        startEnhancer={<Icon icon="SolidPlus" />}
        className={cx(classes.root, classes[view], className)}
      />
    );
  }
);

export const AddButton = appWithStyles(styles)(AddButtonComponent);
