import { useMemo } from 'react';

import { Field } from 'mobx-react-form';

import { appObserver } from '@core/state-management/utils';

import { SelectProps, Select } from '@shared/components/select';
import { TagListDTO } from '@shared/models/tag/list-model';

import { TagSelectViewModel } from './tag-select.vm';

type Props = Partial<Omit<SelectProps, 'onChange' | 'value'>> & {
  onChange: (tags: Array<TagListDTO>) => void;
  field: Field;
};

const TagSelectComponent = ({ onChange, field }: Props) => {
  const $vm = useMemo(() => new TagSelectViewModel(), []);

  const handleSelectChange = (e) => {
    onChange(e.value);
  };

  return (
    <Select
      placeholder="Select tag..."
      isLoading={$vm.loading}
      options={$vm.options}
      onChange={handleSelectChange}
      field={field}
    />
  );
};

export const TagSelect = appObserver(TagSelectComponent);
