import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import {
  ProcessingColumn,
  ValidatedPagesDTO,
} from '@modules/tenant/program-organization/pages/import-csv/components/types';

import { PageTypeDTO } from '@shared/models/page-type/page-type-model';
import { IPagesImportService } from '@shared/types/services/pages-import';

@injectable()
export class PagesImportService extends BaseService implements IPagesImportService {
  static diToken = Symbol('pages-import-service');

  protected urlPrefix = '/pages-import';
  private readonly $http = inject<HTTPClient>(HTTPClient.diToken);

  validatePages = async (pageTypeId: PageTypeDTO['id'], filePath: string) => {
    const { data } = await this.$http.post<ValidatedPagesDTO>(
      this.getUrl(`${pageTypeId}/validate`),
      { filePath }
    );
    return data;
  };

  importPages = async (
    pageTypeId: PageTypeDTO['id'],
    params: {
      records: Array<Record<string, string | Array<string>>>;
      forceOverride?: boolean;
      newColumns: Array<ProcessingColumn>;
    }
  ) => {
    await this.$http.post(this.getUrl(`${pageTypeId}/import`), params);
  };

  downloadExample = async (pageTypeId: PageTypeDTO['id']) => {
    const { data } = await this.$http.get<string>(this.getUrl(`${pageTypeId}/example`));

    return data;
  };
}
