import { createBrowserHistory } from 'history';

import { showConfirmation } from '@shared/components/confirmation';

export const history = createBrowserHistory<{ from?: string }>({
  getUserConfirmation: (message, callback): void => {
    showConfirmation(
      {
        question: message,
        buttonsConfig: {
          confirm: {
            text: 'Discard changes',
          },
        },
      },
      callback
    );
  },
});
