import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {},
    modalBody: {
      marginTop: '0px',
      minWidth: '300px',
    },
    question: {
      fontSize: '16px',
      wordBreak: 'break-all',
    },
    rejectButton: {
      backgroundColor: palette.grey[100],
      color: palette.text.primary,

      '&:hover': {
        color: palette.common.white,
      },
    },
    modalFooter: {
      borderTop: `1px solid ${palette.grey[200]}`,
      padding: spacing(4, 0),
      margin: spacing(0, 4),
    },
  });
