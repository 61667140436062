import { useEffect, useMemo, RefObject } from 'react';

import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import cx from 'classnames';
import { Button } from 'src/shared/components/button';

import { appObserver } from '@core/state-management/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Flex } from '@shared/components/Flex';
import { Message } from '@shared/components/Message';
import { Spinner } from '@shared/components/Spinner';
import { RefreshIcon } from '@shared/icons/refresh';
import { CommentOwner } from '@shared/models/comment/comment-owner';
import { CommentReadQuery } from '@shared/models/comment/read-model';

import { CommentsAction } from './commentaries.types';
import { CommentariesViewModel } from './commentaries.vm';
import { List } from './components/list';
import { Management } from './components/management';

import { styles } from './commentaries.styles';

type Props = AppWithStyles<typeof styles> & {
  hidden?: boolean;
  reference?: RefObject<HTMLDivElement>;
  owner: CommentOwner;
  withCount?: boolean;
  onChange?: (count: number) => void;
  openCommentParentId?: string;
  openCommentId?: string;
  scrollTo?: (top: number) => void;
};

const CommentariesComponent = ({
  classes,
  owner,
  withCount = true,
  onChange,
  reference,
  hidden,
  openCommentParentId,
  openCommentId,
  scrollTo,
}: Props) => {
  const $vm = useMemo(() => new CommentariesViewModel(owner), [owner]);

  useEffect(() => {
    const initialize = async () => {
      if (withCount) await getCommentsCount();
      await $vm.initializeComments(openCommentId);
    };
    if (!hidden) {
      initialize();
    }
  }, [hidden, owner]);

  const getCommentsCount = async () => {
    const response = await $vm.getCommentsCount();
    onChange && onChange(response);
  };

  const refreshList = async () => {
    getCommentsCount();
    await $vm.refreshList();
  };

  const updateComment = (updatedComment: CommentReadQuery) => {
    $vm.updateComment(updatedComment);
  };

  const isEmptyCommentList = $vm.commentsList.length === 0;
  const isShowContentSpinner = ($vm.loading.initialize || $vm.loading.list) && isEmptyCommentList;
  const isShowEmpty = !$vm.loading.initialize && !$vm.loading.list && isEmptyCommentList;

  return (
    <div ref={reference} className={cx(classes.root, { [classes.rootHidden]: hidden })}>
      {$vm.commentsList.length > 0 && !$vm.loading.initialize && (
        <Flex
          alignItems="center"
          justifyContent={withCount ? 'space-between' : 'flex-end'}
          classes={{ root: classes.headerWrapper }}
        >
          {withCount && <h5 className={classes.count}>Comments ({$vm.commentCount})</h5>}
          <Button
            isLoading={$vm.loading.list}
            startEnhancer={<RefreshIcon classes={{ root: classes.refreshIcon }} />}
            size="compact"
            kind="secondary"
            text="Refresh comments"
            onClick={refreshList}
          />
        </Flex>
      )}
      {!$vm.loading.initialize && (
        <Management
          currentUser={$vm.currentUser}
          action={CommentsAction.creatingComment}
          owner={owner}
          onCreateSuccess={refreshList}
          classes={{ root: classes.management }}
        />
      )}
      {isShowContentSpinner && <Spinner withWrapper size={26} minHeight="100px" />}
      {isShowEmpty && (
        <Message
          icon={<TextsmsRoundedIcon classes={{ root: classes.emptyStateIcon }} />}
          heading="No comments"
          classes={{
            root: classes.emptyState,
            heading: classes.emptyStateHeading,
          }}
        />
      )}
      <List
        scrollTo={scrollTo}
        openCommentParentId={openCommentParentId}
        openCommentId={openCommentId}
        shouldRefreshThread={$vm.loading.list}
        owner={owner}
        currentUser={$vm.currentUser}
        data={$vm.commentsList}
        classes={{ root: cx({ [classes.disabledList]: $vm.loading.list }) }}
        onDeleteSuccess={refreshList}
        onReplySuccess={getCommentsCount}
        onUpdateSuccess={updateComment}
      />
      {$vm.commentsList.length > 0 && $vm.commentsList.length < $vm.commentsTotal && (
        <Flex
          alignItems="center"
          justifyContent="center"
          classes={{ root: classes.loadMoreButtonWrapper }}
        >
          <Button
            isLoading={$vm.loading.list || $vm.loading.commentsCount}
            text={`Show comments (${$vm.commentsTotal - $vm.commentsList.length})`}
            onClick={() => $vm.loadMore()}
            kind="secondary"
            size="compact"
          />
        </Flex>
      )}
    </div>
  );
};

export const Commentaries = appWithStyles(styles)(appObserver(CommentariesComponent));
