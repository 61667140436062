import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { GroupListQuery } from '@shared/models/group/list-model';
import { PageCreateDTO } from '@shared/models/pages/create-model';
import { PageFieldWorkDTO } from '@shared/models/pages/page-field-work-model';
import { PageFormDTO } from '@shared/models/pages/page-form';
import { PageListDTO } from '@shared/models/pages/page-list-model';
import { PagePromptDTO } from '@shared/models/pages/page-prompt';
import { PageQueryDTO } from '@shared/models/pages/page-query-model';
import { PageReadDTO } from '@shared/models/pages/read-model';
import { PageTaskReadDTO } from '@shared/models/pages/task/read-model';
import { PageUpdateDTO } from '@shared/models/pages/update-model';
import { TaskType } from '@shared/models/task/type';
import { UserHeaderQuery } from '@shared/models/user/header-model';
import { ListQueryParams, Pagination } from '@shared/types/services';
import { IPageService } from '@shared/types/services/pages';

export type PagesListQueryFilters = {
  name?: string;
  searchText?: string;
  pageType?: Array<Id>;
  exclude?: Array<Id>;
};

@injectable()
export class PagesService extends BaseService implements IPageService {
  static diToken = Symbol('pages-service');

  protected urlPrefix = '/pages';
  private $http = inject<HTTPClient>(HTTPClient.diToken);

  async createPage(data: PageCreateDTO) {
    const { data: page } = await this.$http.post<PageReadDTO>(this.getUrl(), data);

    return page;
  }

  async getPageById(id: PageReadDTO['id']) {
    const { data } = await this.$http.get<PageReadDTO>(this.getUrl(id));

    return data;
  }

  getBulkPages = async (
    params: { ids: Array<PageQueryDTO['id']>; pageTypeId: string },
    pagination: Pagination
  ) => {
    const {
      data: { items, meta },
    } = await this.$http.post<{
      items: Array<PageQueryDTO>;
      meta: { limit: number; total: number; skip: number };
    }>(this.getUrl('getBulkPages'), params, {
      params: {
        ...pagination,
      },
    });

    return {
      items,
      total: meta.total,
    };
  };

  async getPagesSearchList(
    name: string,
    excludePageIds: Array<PageQueryDTO['id']>,
    options: { limit: number; pageType?: string; skip?: number }
  ) {
    const query: ListQueryParams<PagesListQueryFilters> = {
      filters: {
        name,
        pageType: options?.pageType ? [options.pageType] : undefined,
        ...(excludePageIds.length > 0 && { exclude: excludePageIds }),
      },
      pagination: {
        limit: options.limit,
        skip: options.skip ?? 0,
      },
    };

    const { items } = await this.getPagesQuery(query);

    return items;
  }

  async getPagesQuery(query: ListQueryParams<PagesListQueryFilters>) {
    const {
      data: {
        items,
        meta: { total },
      },
    } = await this.$http.post<{ items: Array<PageQueryDTO>; meta: { total: number } }>(
      this.getUrl('query'),
      { query }
    );

    return { items, total };
  }

  async getPagesList(query: ListQueryParams<PagesListQueryFilters>) {
    const {
      data: {
        items,
        meta: { total },
      },
    } = await this.$http.post<{ items: Array<PageListDTO>; meta: { total: number } }>(
      this.getUrl('list'),
      { query }
    );

    return { items, total };
  }

  async getQueryFieldWorkPages(query: ListQueryParams<PagesListQueryFilters>) {
    const {
      data: {
        items,
        meta: { total },
      },
    } = await this.$http.post<{ items: Array<PageFieldWorkDTO>; meta: { total: number } }>(
      this.getUrl('queryFieldWorkPages'),
      { query }
    );

    return { items, total };
  }

  async uploadPageCover(file: File) {
    const query = `
      mutation FileUploaderGetSignedRequestFromS3($name: String!, $type: String!, $folder: String!) {
          getSignedRequestFromS3(name: $name, type: $type, folder: $folder)
      }
    `;

    const { data } = await this.$http.post<{ getSignedRequestFromS3: string }>('/api', {
      query,
      variables: {
        name: file.name,
        type: file.type,
        folder: 'pages-covers',
      },
    });

    const response = JSON.parse(data.getSignedRequestFromS3);
    await this.$http.createInstance().put(response.signedRequest, file);

    return response.url;
  }

  async uploadDescriptionImage(file: File) {
    const query = `
      mutation FileUploaderGetSignedRequestFromS3($name: String!, $type: String!, $folder: String!) {
          getSignedRequestFromS3(name: $name, type: $type, folder: $folder)
      }
    `;

    const {
      data: { getSignedRequestFromS3 },
    } = await this.$http.post<{ getSignedRequestFromS3: string }>('/api', {
      query,
      variables: {
        name: file.name,
        type: file.type,
        folder: 'pages-description',
      },
    });

    const response = JSON.parse(getSignedRequestFromS3);

    await this.$http.createInstance().put(response.signedRequest, file);

    return response.url;
  }

  async editPage(id: PageReadDTO['id'], data: Partial<PageUpdateDTO>) {
    const { data: page } = await this.$http.patch<PageReadDTO>(this.getUrl(id), data);

    return page;
  }

  async assignTasks(
    pageId: string,
    tasks: Array<PageTaskReadDTO>
  ): Promise<Array<PageTaskReadDTO>> {
    const forms: Array<Id> = [];
    const prompts: Array<Id> = [];

    tasks.forEach((task) => {
      const sourceConfig: { [type in TaskType]: Array<Id> } = {
        [TaskType.form]: forms,
        [TaskType.prompt]: prompts,
      };

      sourceConfig[task.type].push(task.id);
    });

    await this.$http.put<PageReadDTO>(this.getUrl(`${pageId}/forms`), { forms });

    const { data } = await this.$http.put<PageReadDTO>(this.getUrl(`${pageId}/prompts`), {
      prompts,
    });

    const normalizedForms = data.forms.map((form) => {
      return {
        ...form,
        type: TaskType.form,
      };
    });

    const normalizedPrompts = data.prompts.map((prompt) => {
      return {
        ...prompt,
        type: TaskType.prompt,
      };
    });

    return [...normalizedForms, ...normalizedPrompts];
  }

  async getAssignees(pageId: string) {
    const { data } = await this.$http.get<{
      users: Array<UserHeaderQuery>;
      groups: Array<GroupListQuery>;
    }>(this.getUrl(`${pageId}/assigns`));

    return data;
  }

  async assign(
    pageId: string,
    assignees: {
      users?:
        | {
            added?: Array<UserHeaderQuery['id']>;
            removed: Array<UserHeaderQuery['id']>;
          }
        | {
            added: Array<UserHeaderQuery['id']>;
            removed?: Array<UserHeaderQuery['id']>;
          };
      groups?:
        | {
            added?: Array<GroupListQuery['id']>;
            removed: Array<GroupListQuery['id']>;
          }
        | {
            added: Array<GroupListQuery['id']>;
            removed?: Array<GroupListQuery['id']>;
          };
    }
  ) {
    const { data } = await this.$http.put<{
      users: Array<UserHeaderQuery>;
      groups: Array<GroupListQuery>;
    }>(this.getUrl(`${pageId}/assigns`), assignees);

    return data;
  }

  deletePage = async (pageId: string) => {
    await this.$http.delete(this.getUrl(pageId));
  };

  downloadFile = async (url: string) => {
    const { data } = await this.$http.get<string>(url);

    return data;
  };

  getPagePrompts = async (id: PageReadDTO['id']) => {
    const { data } = await this.$http.get<Array<PagePromptDTO>>(`${this.getUrl(id)}/prompts`);

    return data;
  };

  getPageForms = async (id: PageReadDTO['id']) => {
    const { data } = await this.$http.get<Array<PageFormDTO>>(`${this.getUrl(id)}/forms`);

    return data;
  };
}
