import { ReactNode, Component } from 'react';

import {
  DatePicker as BaseDatepicker,
  DatepickerProps as BaseDatepickerProps,
} from 'baseui/datepicker';
import { Field } from 'mobx-react-form';
import moment from 'moment';

import { appObserver } from '@core/state-management/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Field as FieldComponent } from '@shared/components/Field';
import { Flex } from '@shared/components/Flex';
import { TimePickerComponent } from '@shared/components/Timepicker';
import { formatDate, convertToISOString } from '@shared/utils/date';
import { getFieldBindings } from '@shared/utils/form';

import { styles } from './date-picker.styles';

export type DatepickerOnChangeData = {
  date: Date;
  dates: Array<Date>;
  isoDate: string;
  isoDates: Array<string>;
};

type Props = Omit<BaseDatepickerProps, 'onChange'> &
  AppWithStyles<typeof styles> & {
    errorText?: string;
    field?: Field;
    label?: ReactNode;
    timePickerLabel?: ReactNode;
    optional?: boolean;
    withTimePicker?: boolean;
    withTimeField?: Field;
    onChange?: (data: DatepickerOnChangeData) => void;
  };

const toISODate = (date?: Date) => {
  return date ? formatDate(date, { utc: true }) : null;
};

class DatepickerComponent extends Component<Props> {
  private handleChange: BaseDatepickerProps['onChange'] = ({ date }) => {
    const normalizedValue = Array.isArray(date) ? date : [date];

    const data: DatepickerOnChangeData = {
      date: normalizedValue[0],
      dates: normalizedValue,
      isoDate: toISODate(normalizedValue[0]),
      isoDates: normalizedValue.map(toISODate),
    };
    const { field, onChange } = this.props;

    if (onChange) {
      onChange(data);
    }

    if (field) {
      field.set('value', data.isoDate);
      this.validate();
    }
  };

  private validate = () => {
    const { field } = this.props;

    field?.validate();
  };

  private get value() {
    const { field } = this.props;

    if (field) {
      if (field.value) {
        return new Date(field.value);
      }

      return;
    }

    return this.props.value;
  }

  private handleChangeTime = (data: { date: Date }) => {
    const { withTimeField } = this.props;
    const { date } = data;
    const normalizedValue = Array.isArray(this.value) ? this.value[0] : this.value;

    if (!date) {
      withTimeField?.set('value', false);

      this.handleChange({ date: moment(normalizedValue).startOf('day').toDate() });

      return;
    }

    const currentDate = formatDate(normalizedValue, { format: 'DD-MM-YYYY' });

    const currentTime = formatDate(date, { format: 'HH:mm' });
    const dateWithTime = moment(`${currentDate} ${currentTime}`, 'DD-MM-YYYY HH:mm').toISOString();

    this.handleChange({ date: new Date(dateWithTime) });

    withTimeField?.set('value', true);
  };

  render() {
    const {
      label,
      timePickerLabel,
      field,
      error,
      errorText,
      optional,
      classes,
      withTimePicker,
      withTimeField,
      placeholder,
      ...otherProps
    } = this.props;
    const selectErrorText = errorText || field?.error;
    const hasError = Boolean(error || field?.error);
    const normalizedDatePickerValue = convertToISOString(
      Array.isArray(this.value) ? this.value[0] : this.value
    );

    return (
      <Flex classes={{ root: classes.root }}>
        <FieldComponent
          label={label}
          optional={optional}
          hasError={hasError}
          errorText={selectErrorText}
          classes={{ root: classes.field }}
        >
          <BaseDatepicker
            clearable
            mask={null}
            formatString="MM/dd/yyyy"
            {...otherProps}
            {...getFieldBindings(field)}
            error={hasError}
            value={this.value}
            mountNode={document.body}
            overrides={{
              Input: {
                props: {
                  placeholder: placeholder || 'MM/DD/YYYY',
                  overrides: {
                    Input: {
                      props: {
                        readOnly: 'readonly',
                      },
                    },
                    ClearIcon: {
                      style: {
                        width: '16px !important',
                        height: '16px !important',
                      },
                    },
                  },
                },
              },
            }}
            onChange={this.handleChange}
          />
        </FieldComponent>
        {withTimePicker && (
          <TimePickerComponent
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            clearable
            label={timePickerLabel}
            placeholder="HH:MM"
            value={withTimeField.value ? normalizedDatePickerValue : null}
            disabled={Array.isArray(this.value) ? !this.value?.length : !this.value}
            onChange={this.handleChangeTime}
          />
        )}
      </Flex>
    );
  }
}

export const DatePicker = appWithStyles(styles)(appObserver(DatepickerComponent));
