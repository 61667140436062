import { ReactNode, Component } from 'react';

import { Field } from 'mobx-react-form';
import { Select, OnChangeParams } from 'src/shared/components/select';

import { appObserver } from '@core/state-management/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Field as FieldComponent } from '@shared/components/Field';
import { convertToISOString, formatDate } from '@shared/utils/date';
import { getFieldBindings } from '@shared/utils/form';

import { generateTimePeriod, processOptions } from './TimePickerComponent.utils';

import { styles } from './TimePickerComponent.styles';

export type TimePickerOnChangeData = {
  date: Date;
  isoDate: string;
};

type TimePickerProps = AppWithStyles<typeof styles> & {
  value?: string | Date;
  minDate?: string | Date;
  maxDate?: string | Date;
  disabled?: boolean;
  placeholder?: string;
  error?: boolean;
  errorText?: string;
  clearable?: boolean;
  field?: Field;
  label?: ReactNode;
  optional?: boolean;
  onChange?: (data: TimePickerOnChangeData) => void;
};

class _TimePickerComponent extends Component<TimePickerProps> {
  static defaultProps = {
    clearable: false,
  };

  private handleChange = ({ normalizedValue }: OnChangeParams<string>) => {
    const currentDate = formatDate(new Date(), { format: 'MM/DD/YYYY' });
    const computedDate = convertToISOString(`${currentDate} ${normalizedValue} `);
    const data: TimePickerOnChangeData = {
      date: normalizedValue ? new Date(computedDate) : undefined,
      isoDate: normalizedValue ? computedDate : undefined,
    };

    const { field, onChange } = this.props;

    if (onChange) {
      onChange(data);
    }

    if (field) {
      field.set('value', data.isoDate);
      this.validate();
    }
  };

  private validate = () => {
    const { field } = this.props;

    field?.validate();
  };

  private get value() {
    const { field, value } = this.props;

    if (!field?.value && !value) {
      return undefined;
    }

    return [formatDate(field?.value || new Date(value), { format: 'HH:mm' })];
  }

  private get options() {
    const { minDate, maxDate } = this.props;

    const dates = generateTimePeriod('HH:mm', false);
    const labels = generateTimePeriod('h:mm A');

    const options = labels.map((label, i) => {
      return {
        label,
        id: dates[i],
      };
    });

    return processOptions(options, minDate, maxDate);
  }

  render() {
    const { label, field, error, errorText, optional, classes, placeholder, disabled, clearable } =
      this.props;
    const timePickerErrorText = errorText || field?.error;
    const hasError = Boolean(error || field?.error);

    return (
      <FieldComponent
        label={label}
        optional={optional}
        hasError={hasError}
        errorText={timePickerErrorText}
        classes={{ root: classes.root }}
      >
        <Select
          {...getFieldBindings(field)}
          clearable={clearable}
          placeholder={placeholder}
          searchable={false}
          migratingToValue={this.value}
          error={hasError}
          disabled={disabled}
          options={this.options}
          onChange={this.handleChange}
          overrides={{
            Popover: {
              props: {
                mountNode: document.body,
              },
            },
          }}
        />
      </FieldComponent>
    );
  }
}

export const TimePickerComponent = appWithStyles(styles)(appObserver(_TimePickerComponent));
