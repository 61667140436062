import { ReactNode, Component, createRef } from 'react';

import { Textarea as BaseTextarea, TextareaProps as BaseTextareaProps } from 'baseui/textarea';
import cx from 'classnames';
import { Field } from 'mobx-react-form';

import { appObservable, appObserver, appMakeObservable } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Field as FieldComponent } from '@shared/components/Field';
import { getFieldBindings, getFieldMaxLength } from '@shared/utils/form';

import { styles } from './Textarea.styles';

export type TextAreaProps = BaseTextareaProps &
  AppWithStyles<typeof styles> & {
    label?: ReactNode;
    field?: Field;
    errorText?: string;
    optional?: boolean;
    withLengthCounter?: boolean;
    autoSize?: boolean;
  };

export class TextareaComponent extends Component<TextAreaProps> {
  private inputRef = createRef<HTMLTextAreaElement>();
  private rows = 1;

  constructor(props: TextAreaProps) {
    super(props);

    appMakeObservable(this, {
      rows: appObservable,
    });
  }

  private adjustHeight = () => {
    const textarea = this.inputRef.current;

    if (textarea) {
      textarea.rows = 1;

      if (this.props.value) {
        const style = getComputedStyle(textarea);
        const lineHeight = style['line-height'];

        textarea.rows = Math.floor(textarea.scrollHeight / parseInt(lineHeight, 10));
      }
    }
  };

  componentDidMount() {
    if (this.props.autoSize) {
      this.adjustHeight();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.autoSize) {
      this.adjustHeight();
    }
  }

  private handleBlur = (e) => {
    e.preventDefault();

    const { field, onBlur } = this.props;
    const { value } = e.target;
    const normalizedValue = value.trim();

    e.target.value = normalizedValue;
    e.currentTarget.value = normalizedValue;

    if (onBlur) {
      onBlur(e);
    }

    if (field) {
      field.set('value', normalizedValue);
      field.onBlur(e);
    }
  };

  private handleChange = (e) => {
    const { field, onChange } = this.props;

    if (onChange) {
      onChange(e);
    }

    if (field) {
      field.onChange(e);
    }
  };

  render() {
    const {
      label,
      field,
      autoSize,
      errorText,
      rows,
      error,
      classes,
      optional,
      value,
      overrides,
      withLengthCounter,
      ...otherProps
    } = this.props;
    const inputErrorText = errorText || field?.error;
    const hasError = Boolean(error || field?.error);
    const bindings = getFieldBindings(field);
    const maxLength = field && getFieldMaxLength(field.rules);
    const inputOverrides = overrides?.Input as { props: ObjectLike };

    return (
      <FieldComponent
        label={label}
        optional={optional}
        hasError={hasError}
        errorText={inputErrorText}
        classes={{ root: classes.root }}
      >
        <BaseTextarea
          overrides={{
            ...overrides,
            Input: {
              props: {
                ...inputOverrides?.props,
              },
            },
          }}
          inputRef={this.inputRef}
          rows={autoSize ? this.rows : rows}
          {...bindings}
          {...otherProps}
          value={field ? field.value : value}
          error={hasError}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
        />
        {withLengthCounter && (
          <span
            className={cx(classes.lengthCounter, {
              [classes.lengthCounterError]: maxLength && field.value.length > maxLength,
            })}
          >
            {field.value.length}/{maxLength}
          </span>
        )}
      </FieldComponent>
    );
  }
}

export const Textarea = appWithStyles(styles)(appObserver(TextareaComponent));
