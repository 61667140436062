import { injectable } from '@core/di/di-utils';

import { DI_TOKENS } from '@shared/constants/di';

import { Http } from './http';
import { HttpConfig, HttpRequestConfig, HttpResponse } from './http.types';

export interface HTTPClientConfig {}

@injectable()
export class HTTPClient {
  static diToken = DI_TOKENS.httpClient;

  private http: Http;

  initialize = (config: HttpConfig) => {
    this.http = new Http(config);
  };

  createInstance() {
    return this.http.createHTTPClient();
  }

  get<T = unknown>(url: string, config?: HttpRequestConfig): Promise<HttpResponse<T>> {
    return this.http.client.get<T>(url, config);
  }

  post<T = unknown>(
    url: string,
    body?: unknown,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    return this.http.client.post<T>(url, body, config);
  }

  put<T = unknown>(
    url: string,
    body?: unknown,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    return this.http.client.put<T>(url, body, config);
  }

  patch<T = unknown>(
    url: string,
    body?: unknown,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    return this.http.client.patch<T>(url, body, config);
  }

  delete<T = unknown>(url: string, config?: HttpRequestConfig): Promise<HttpResponse<T>> {
    return this.http.client.delete<T>(url, config);
  }
}
