import { ReactComponent as SolidCamera } from '@fortawesome/fontawesome-free/svgs/solid/camera.svg';
import { ReactComponent as SolidCaretDown } from '@fortawesome/fontawesome-free/svgs/solid/caret-down.svg';
import { ReactComponent as SolidCaretUp } from '@fortawesome/fontawesome-free/svgs/solid/caret-up.svg';
import { ReactComponent as SolidCheckCircle } from '@fortawesome/fontawesome-free/svgs/solid/check-circle.svg';
import { ReactComponent as SolidCheck } from '@fortawesome/fontawesome-free/svgs/solid/check.svg';
import { ReactComponent as SolidCogs } from '@fortawesome/fontawesome-free/svgs/solid/cogs.svg';
import { ReactComponent as SolidGripVertical } from '@fortawesome/fontawesome-free/svgs/solid/grip-vertical.svg';
import { ReactComponent as SolidPen } from '@fortawesome/fontawesome-free/svgs/solid/pen.svg';
import { ReactComponent as SolidPlus } from '@fortawesome/fontawesome-free/svgs/solid/plus.svg';
import { ReactComponent as SolidRedo } from '@fortawesome/fontawesome-free/svgs/solid/redo.svg';
import { ReactComponent as SolidSave } from '@fortawesome/fontawesome-free/svgs/solid/save.svg';
import { ReactComponent as SolidSignOutAlt } from '@fortawesome/fontawesome-free/svgs/solid/sign-out-alt.svg';
import { ReactComponent as SolidTimesCircle } from '@fortawesome/fontawesome-free/svgs/solid/times-circle.svg';
import { ReactComponent as SolidTimes } from '@fortawesome/fontawesome-free/svgs/solid/times.svg';
import { ReactComponent as SolidTrashAlt } from '@fortawesome/fontawesome-free/svgs/solid/trash-alt.svg';
import { ReactComponent as SolidTrash } from '@fortawesome/fontawesome-free/svgs/solid/trash.svg';
import { ReactComponent as SolidUsers } from '@fortawesome/fontawesome-free/svgs/solid/users.svg';

import { ReactComponent as LightFileAlt } from '@shared/components/Icon/svg/light/file-alt.svg';
import { ReactComponent as LightFileArchive } from '@shared/components/Icon/svg/light/file-archive.svg';
import { ReactComponent as LightFilePdf } from '@shared/components/Icon/svg/light/file-pdf.svg';
import { ReactComponent as InfoCircle } from '@shared/components/Icon/svg/light/info-circle.svg';
import { ReactComponent as LightMinus } from '@shared/components/Icon/svg/light/minus.svg';
import { ReactComponent as LightPlus } from '@shared/components/Icon/svg/light/plus.svg';
import { ReactComponent as RegularAngleLeft } from '@shared/components/Icon/svg/regular/angle-left.svg';
import { ReactComponent as RegularAngleRight } from '@shared/components/Icon/svg/regular/angle-right.svg';
import { ReactComponent as RegularArrowAltRight } from '@shared/components/Icon/svg/regular/arrow-alt-right.svg';
import { ReactComponent as RegularArrowDown } from '@shared/components/Icon/svg/regular/arrow-down.svg';
import { ReactComponent as RegularArrowLeft } from '@shared/components/Icon/svg/regular/arrow-left.svg';
import { ReactComponent as RegularArrowRight } from '@shared/components/Icon/svg/regular/arrow-right.svg';
import { ReactComponent as RegularArrowSquareRight } from '@shared/components/Icon/svg/regular/arrow-square-right.svg';
import { ReactComponent as RegularArrowUp } from '@shared/components/Icon/svg/regular/arrow-up.svg';
import { ReactComponent as RegularBan } from '@shared/components/Icon/svg/regular/ban.svg';
import { ReactComponent as RegularBars } from '@shared/components/Icon/svg/regular/bars.svg';
import { ReactComponent as RegularCheck } from '@shared/components/Icon/svg/regular/check.svg';
import { ReactComponent as RegularChevronLeft } from '@shared/components/Icon/svg/regular/chevron-left.svg';
import { ReactComponent as RegularChevronRight } from '@shared/components/Icon/svg/regular/chevron-right.svg';
import { ReactComponent as RegularCircle } from '@shared/components/Icon/svg/regular/circle.svg';
import { ReactComponent as RegularColumns } from '@shared/components/Icon/svg/regular/columns.svg';
import { ReactComponent as RegularEllipsisV } from '@shared/components/Icon/svg/regular/ellipsis-v.svg';
import { ReactComponent as RegularExclamationCircle } from '@shared/components/Icon/svg/regular/exclamation-circle.svg';
import { ReactComponent as RegularExternalLink } from '@shared/components/Icon/svg/regular/external-link.svg';
import { ReactComponent as RegularEyeSlash } from '@shared/components/Icon/svg/regular/eye-slash.svg';
import { ReactComponent as RegularEye } from '@shared/components/Icon/svg/regular/eye.svg';
import { ReactComponent as RegularHistory } from '@shared/components/Icon/svg/regular/history.svg';
import { ReactComponent as RegularPaperPlane } from '@shared/components/Icon/svg/regular/paper-plane.svg';
import { ReactComponent as RegularPen } from '@shared/components/Icon/svg/regular/pen.svg';
import { ReactComponent as RegularPlusCircle } from '@shared/components/Icon/svg/regular/plus-circle.svg';
import { ReactComponent as RegularPlus } from '@shared/components/Icon/svg/regular/plus.svg';
import { ReactComponent as RegularSearch } from '@shared/components/Icon/svg/regular/search.svg';
import { ReactComponent as RegularSmilePlus } from '@shared/components/Icon/svg/regular/smile-plus.svg';
import { ReactComponent as RegularTextCopy } from '@shared/components/Icon/svg/regular/text-copy.svg';
import { ReactComponent as RegularThList } from '@shared/components/Icon/svg/regular/th-list.svg';
import { ReactComponent as RegularTimesCircle } from '@shared/components/Icon/svg/regular/times-circle.svg';
import { ReactComponent as RegularTimes } from '@shared/components/Icon/svg/regular/times.svg';
import { ReactComponent as SolidExclamationTriangle } from '@shared/components/Icon/svg/solid/exclamation-triangle.svg';
import { ReactComponent as SolidLockAlt } from '@shared/components/Icon/svg/solid/lock-alt.svg';
import { ReactComponent as SolidReply } from '@shared/components/Icon/svg/solid/reply.svg';

import { ReactComponent as LightQuestionCircle } from './svg/light/question-circle.svg';

export const IconLibrary = {
  InfoCircle,
  LightFileAlt,
  LightQuestionCircle,
  LightFileArchive,
  LightFilePdf,
  LightMinus,
  LightPlus,
  RegularAngleLeft,
  RegularAngleRight,
  RegularArrowAltRight,
  RegularArrowDown,
  RegularArrowLeft,
  RegularArrowRight,
  RegularArrowSquareRight,
  RegularArrowUp,
  RegularBan,
  RegularBars,
  RegularCheck,
  RegularChevronLeft,
  RegularChevronRight,
  RegularCircle,
  RegularColumns,
  RegularEllipsisV,
  RegularExclamationCircle,
  RegularExternalLink,
  RegularEye,
  RegularEyeSlash,
  RegularHistory,
  RegularPaperPlane,
  RegularPen,
  RegularPlus,
  RegularPlusCircle,
  RegularSearch,
  RegularSmilePlus,
  RegularTextCopy,
  RegularThList,
  RegularTimes,
  RegularTimesCircle,
  SolidExclamationTriangle,
  SolidLockAlt,
  SolidReply,
  SolidCamera,
  SolidCaretDown,
  SolidCaretUp,
  SolidCheck,
  SolidCheckCircle,
  SolidCogs,
  SolidGripVertical,
  SolidPen,
  SolidPlus,
  SolidRedo,
  SolidSave,
  SolidSignOutAlt,
  SolidTimes,
  SolidTimesCircle,
  SolidTrash,
  SolidTrashAlt,
  SolidUsers,
};

export type IconLibraryIcons = keyof typeof IconLibrary;
