import { injectable } from '@core/di/di-utils';
import { inject } from '@core/di/di-utils';
import { HTTPClientConfig } from '@core/http-client';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { FileReadDTO } from '@shared/models/file/read-model';

@injectable()
export class FilesService extends BaseService {
  static diToken = Symbol('files-service');
  private $http = inject<HTTPClient>(HTTPClient.diToken);

  protected urlPrefix = '/files';

  async upload(file: File, config: Partial<HTTPClientConfig>) {
    const {
      data: { signedRequestLink, key },
    } = await this.$http.get<{ key: string; signedRequestLink: string }>(
      this.getUrl('signed-request'),
      {
        params: {
          originalFileName: file.name,
        },
      }
    );

    await this.$http.createInstance().put(signedRequestLink, file, config);

    const {
      data: { id },
    } = await this.$http.post<{ id: string }>(this.getUrl(), {
      fileKey: key,
    });

    return id;
  }

  async get(id: Id) {
    const res = await this.$http.get<FileReadDTO>(`files/${id}`);

    return res.data;
  }
}
