import { palette } from '@core/theme/units/palette';
import { shadows } from '@core/theme/units/shadows';
import { spacing } from '@core/theme/utils/spacing';

export const styles = () => ({
  root: {
    boxShadow: shadows[1],
    backgroundColor: palette.common.white,
    padding: spacing(0, 5),
  },
  titleWrapper: {},
  title: {
    fontSize: '16px',
    fontWeight: 600,
    color: palette.text.primary,
    marginLeft: spacing(5),
  },
  iconButton: {
    padding: `${spacing()} !important`,
  },
  closeIcon: {
    fontSize: '2rem !important',

    '& > path': {
      fill: palette.grey[500],
    },
  },
});
