import { styled, withStyle } from 'baseui';

import { FlexColumnContainer, FlexRowContainer, FlexContainer } from '../containers';

export const SearchRow = withStyle(FlexRowContainer, ({ $theme }: any) => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: $theme.sizing.scale800,
}));

export const History = withStyle(FlexColumnContainer, () => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  display: 'block',
  maxWidth: '526px',
  margin: '0 auto',
  position: 'relative',
}));

export const Date = styled('div', ({ $theme }: any) => ({
  backgroundColor: 'white',
  width: '200px',
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'center',
  marginTop: $theme.sizing.scale800,
  paddingTop: $theme.sizing.scale0,
  paddingBottom: $theme.sizing.scale200,
  paddingRight: $theme.sizing.scale500,
  paddingLeft: $theme.sizing.scale600,
  borderRadius: $theme.sizing.scale800,
  marginBottom: $theme.sizing.scale800,
}));

export const HistoryCard = styled('div', ({ $theme }: any) => ({
  maxWidth: '526px',
  position: 'relative',
  marginBottom: $theme.sizing.scale500,
  borderRadius: $theme.sizing.scale200,
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'rgba(0, 0, 0, .12)',
  backgroundColor: 'white',
  padding: $theme.sizing.scale600,
  color: /** $theme.colors.mono800OnBase */ $theme.colors.mono800,
  fontSize: '14px',
}));

export const HistoryItem = withStyle(FlexContainer, () => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
}));

export const SearchContainer = styled('div', () => ({
  width: '526px',
  height: '40px',
}));

export const IconContainer = withStyle(FlexRowContainer, () => ({
  background: 'white',
  alignItems: 'center',
  border: '1px solid white',
}));

export const RevertText = styled('div', ({ $theme }: any) => ({
  paddingLeft: $theme.sizing.scale300,
  fontWeight: 500,
}));

export const FirstColumn = withStyle(FlexColumnContainer, () => ({
  width: '70px',
  alignItems: 'center',
  background: 'white',
  border: '3px solid white',
}));

export const SecondColumn = styled('div', () => ({
  width: '366px',
}));

export const ThirdColumn = withStyle(FlexRowContainer, () => ({
  alignItems: 'center',
  width: '90px',
}));

export const Line = styled('div', ({ $theme }: any) => ({
  width: '1px',
  height: 'inherit',
  borderRight: '1px solid ' + /** $theme.colors.mono400OnBase */ $theme.colors.mono400,
  margin: '0 auto',
}));

export const LineContainer = styled('div', () => ({
  position: 'absolute',
  width: '66px',
  top: '30px',
  bottom: '30px',
  display: 'flex',
}));
