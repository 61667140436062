import { injectable } from '@core/di/di-utils';
import { inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { DI_TOKENS } from '@shared/constants/di';
import { FormCreateDTO } from '@shared/models/forms/form-model';
import { FormPublishType } from '@shared/models/forms/publish-type';
import { FormReadDTO } from '@shared/models/forms/read-model';
import { QuantityByStatus } from '@shared/models/forms/submission-quantity';
import { UpdateFormDTO } from '@shared/models/forms/update-form';
import { TaskListDTO } from '@shared/models/task/list-model';
import { TaskType } from '@shared/models/task/type';
import { FormPagination, IFormsService } from '@shared/types/services/forms';

@injectable()
export class FormsService extends BaseService implements IFormsService {
  static diToken = DI_TOKENS.formsService;

  private readonly $http = inject<HTTPClient>(HTTPClient.diToken);

  protected urlPrefix = 'forms';

  async getFormById(id: FormReadDTO['id']): Promise<FormReadDTO> {
    const { data } = await this.$http.get<FormReadDTO>(`${this.urlPrefix}/${id}`);

    return data;
  }

  async createForm(createData: FormCreateDTO): Promise<FormReadDTO> {
    const { data } = await this.$http.post<FormReadDTO>(this.urlPrefix, createData);

    return data;
  }

  async updateForm(id: FormReadDTO['id'], updatedDate: FormCreateDTO): Promise<FormReadDTO> {
    const { data } = await this.$http.patch<FormReadDTO>(`${this.urlPrefix}/${id}`, updatedDate);

    return data;
  }

  getFormsList = async (
    params?: FormPagination
  ): Promise<{
    items: Array<FormReadDTO>;
    meta: { total: number; skip: number; limit: number };
  }> => {
    const { data } = await this.$http.get<{
      items: Array<FormReadDTO>;
      meta: { total: number; skip: number; limit: number };
    }>(this.urlPrefix, {
      params,
    });

    return data;
  };

  async getFormsQuery(params: {
    name: string;
    excludedIds?: Array<string>;
    include?: string;
    withArchived?: boolean;
    options: { limit: number };
  }) {
    const { data } = await this.$http.post<Array<TaskListDTO>>(`${this.urlPrefix}/query`, {
      query: {
        filters: {
          name: params.name,
          exclude: params.excludedIds,
          type: FormPublishType.published,
          withArchived: params.withArchived,
        },
        include: params.include,
        pagination: {
          limit: params.options.limit,
        },
      },
    });

    return data.map((form) => ({
      ...form,
      type: TaskType.form,
    }));
  }

  assignToForm = async (id: FormReadDTO['id'], assignData: UpdateFormDTO): Promise<FormReadDTO> => {
    const { data } = await this.$http.put<FormReadDTO>(
      `${this.urlPrefix}/${id}/pages-groups`,
      assignData
    );

    return data;
  };

  archiveForm = async (formId: FormReadDTO['id'], archived: boolean) => {
    await this.$http.patch<FormReadDTO>(`${this.urlPrefix}/${formId}/archivation`, {
      archived,
    });
  };

  scheduleFormArchiving = async (formId: FormReadDTO['id'], date: Date) => {
    await this.$http.patch<FormReadDTO>(`${this.urlPrefix}/${formId}/schedule-archivation`, {
      archived: true,
      scheduledArchivationAt: date,
    });
  };

  cancelScheduleFormArchiving = async (formId: FormReadDTO['id']) => {
    await this.$http.patch<FormReadDTO>(`${this.urlPrefix}/${formId}/schedule-archivation`);
  };

  deleteForm = async (formId: FormReadDTO['id']) => {
    await this.$http.delete<FormReadDTO>(`${this.urlPrefix}/${formId}`, {});
  };

  getSubmissionQuantity = async (formId: FormReadDTO['id']) => {
    const { data } = await this.$http.get<{ quantityByStatus: QuantityByStatus }>(
      `${this.urlPrefix}/${formId}/submissions-quantity`
    );
    return data.quantityByStatus;
  };
}
