import moment, { DurationInputArg1, Moment, unitOfTime } from 'moment';
import momentTimezone from 'moment-timezone';

import { MaxUnitType } from '@shared/constants/date';

export const convertToISOString = (date: string | Date) => {
  return moment(date).toISOString();
};

export const concatDateAndTime = (time: string | Date, date?: string | Date) => {
  const currentDate = formatDate(date || new Date(), {
    format: 'MM/DD/YYYY',
  });
  const processedTime = formatDate(time, { format: 'HH:mm' });

  return convertToISOString(`${currentDate} ${processedTime}`);
};

export const getDateMs = (date: string | Date) => {
  return new Date(date).getTime();
};

export const getDateMins = (date: string) => {
  const dateObject = getDateObject(date);

  return dateObject.hours * 60 + dateObject.minutes;
};

export const getDateObject = (date?: string) => {
  return moment(date).toObject();
};

export const generateTimePeriod = (date?: string, format?: string) => {
  const slots = [
    moment(date || new Date())
      .startOf('day')
      .toLocaleString(),
  ];

  for (const i of Array(23).keys()) {
    slots.push(moment(slots[i]).add(1, 'hour').toLocaleString());
  }

  if (format) {
    return slots.map((slot) => {
      return formatDate(slot, { format });
    });
  }

  return slots;
};

export const generateDaysPeriod = (params: {
  startDate: string;
  endDate?: string;
  unit?: unitOfTime.StartOf;
}) => {
  const { unit, startDate: initialStartDate, endDate: initialEndDate } = params;

  const startDate = moment(initialStartDate, true).startOf(unit);
  const endDate = moment(initialEndDate || initialStartDate, true).endOf(unit);

  const offset = endDate.diff(startDate, 'days');
  const sameDate = offset === 0;

  if (sameDate) {
    return startDate.toISOString();
  }

  const dates = [startDate.toISOString()];

  for (const i of Array(offset).keys()) {
    const computedDate = moment(dates[i]).add(1, 'day').toISOString();

    dates.push(computedDate);
  }
  return dates;
};

export const isSameDay = (startDate: string | Date, endDate?: string | Date) => {
  return moment(endDate).isSame(startDate, 'day');
};

export const isBetweenDates = (
  currentDate: string | Date | Moment,
  startDate: string | Date | Moment,
  endDate: string | Date | Moment
): boolean => {
  return moment(currentDate).isBetween(startDate, endDate);
};

export const convertToDate = (date: string | Date) => {
  return moment(date).toDate();
};

export const fromNow = (date: string | Date) => moment(date).fromNow();

export const addDate = (
  date: string | Date | Moment,
  amount: DurationInputArg1,
  unit: unitOfTime.DurationConstructor,
  utc = true
) => {
  if (utc) {
    return moment(date).add(amount, unit).toISOString();
  }

  return moment(date).add(amount, unit).toLocaleString();
};

export const subtractDate = (
  date: string | Date | Moment,
  amount: DurationInputArg1,
  unit: unitOfTime.DurationConstructor,
  utc: boolean = false
) => {
  return utc
    ? moment.utc(date).subtract(amount, unit).toISOString()
    : moment(date).subtract(amount, unit).toISOString();
};

export const getStartOfDate = (
  date: string | Date,
  unit: unitOfTime.StartOf,
  utc: boolean = false
): string => {
  return utc
    ? moment.utc(date).startOf(unit).toISOString()
    : moment(date).startOf(unit).toISOString();
};

export const getEndOfDate = (
  date: string | Date,
  unit: unitOfTime.StartOf,
  utc: boolean = false
) => {
  return utc ? moment.utc(date).endOf(unit).toISOString() : moment(date).endOf(unit).toISOString();
};

export const compareDates = (
  initialDate: string | Date,
  comparisonDate: string | Date,
  unit: unitOfTime.All
) => {
  return moment(initialDate).get(unit) === moment(comparisonDate).get(unit);
};

export const formatDate = (date: string | Date, options?: { format?: string; utc?: boolean }) => {
  return options?.utc
    ? moment.utc(date).format(options?.format)
    : moment(date).format(options?.format);
};

export const formatMMDDYYYYDate = (date: string | Date): string => {
  return formatDate(date, { format: 'MM/DD/YYYY' });
};

export const formatDDMMMYYYYDate = (date: string | Date): string => {
  return formatDate(date, { format: 'DD MMM YYYY' });
};

export const isAfter = (
  comparableDate: string,
  compareToDate: string,
  options?: { compareType?: unitOfTime.StartOf; utc?: boolean }
) => {
  return options?.utc
    ? moment.utc(comparableDate).isAfter(compareToDate, options?.compareType)
    : moment(comparableDate).isAfter(compareToDate, options?.compareType);
};

export const isBefore = (
  comparableDate: string,
  compareToDate: string,
  options?: { compareType?: unitOfTime.StartOf; utc?: boolean }
) => {
  return options?.utc
    ? moment.utc(comparableDate).isBefore(compareToDate, options?.compareType)
    : moment(comparableDate).isBefore(compareToDate, options?.compareType);
};

export const getDuration = (milliseconds: number, maxUnit: MaxUnitType = MaxUnitType.allUnits) => {
  const seconds = Math.floor(milliseconds / 1000);
  const secondsDiff = seconds % 60;
  const minutes = Math.floor(seconds / 60);
  const minutesDiff = minutes % 60;
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const daysDiff = days % 30;
  const months = Math.floor(days / 30);
  const monthDiff = months % 12;
  const years = Math.floor(months / 12);
  const hoursValues = [
    {
      value: secondsDiff,
      label: 'sec',
    },
    {
      value: minutesDiff,
      label: 'min',
    },
    {
      value: hours,
      label: 'h',
    },
  ];
  const dateParts: Record<MaxUnitType, Array<{ value: number; label: string }>> = {
    hours: [...hoursValues],
    all: [
      ...hoursValues,
      {
        value: daysDiff,
        label: 'd',
      },
      {
        value: monthDiff,
        label: 'm',
      },
      {
        value: years,
        label: 'y',
      },
    ],
  };
  return (dateParts[maxUnit] || dateParts[MaxUnitType.allUnits])
    .reduce((acc, part) => {
      if (!part.value) {
        return acc;
      }
      return `${part.value}${part.label} ${acc}`;
    }, '')
    .trim();
};

export const getWeekDayNumber = (date: string | Date | Moment): number => {
  return moment(date).day();
};

export const getDayNumber = (date: string | Date | Moment): number => {
  return Number(moment(date).format('D'));
};

export const getDaysInMonth = (date: string | Date | Moment): number => {
  return moment(date).daysInMonth();
};

export const getCustomTimeOfDate = (
  date: string,
  time: { hours?: number; minutes?: number; seconds?: number; milliseconds?: number }
): string => {
  const { hours, seconds, minutes, milliseconds } = time;

  const d = moment(date);

  if (hours || hours === 0) {
    d.hour(hours);
  }
  if (minutes || minutes === 0) {
    d.minute(minutes);
  }
  if (seconds || seconds === 0) {
    d.second(seconds);
  }
  if (milliseconds || milliseconds === 0) {
    d.millisecond(milliseconds);
  }

  return d.toISOString();
};

export const getCurrentTimezone = (): string => {
  // e.g. Europe/Kiev

  return momentTimezone.tz.guess();
};

export const getDateYYYYMMDD = (date: string): string => {
  // e.g. 2022-12-24
  return moment(date).format(moment.HTML5_FMT.DATE);
};

export const getHoursByDate = (date: Date | string, utc?: boolean): number => {
  return utc ? moment.utc(date).hours() : moment(date).hours();
};

export const isSummerTime = (): boolean => {
  return moment().isDST();
};
