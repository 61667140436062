import { gql } from '@apollo/client';
import { withStyle } from 'baseui';
import { Button as BaseButton } from 'baseui/button';

import { inject } from '@core/di/di-utils';

import { Button, ButtonSIZE, PopoverPLACEMENT, StatefulPopover } from '@shared/components';
import { FlexRowContainer } from '@shared/components';
import { Icon } from '@shared/components/Icon';
import { DI_TOKENS } from '@shared/constants/di';
import { IAuthService } from '@shared/types/services/auth';
import groupBy from '@shared/utils/Arrays/groupBy';

interface IGroupedReaction {
  type: string;
  items: any[];
  byCurrentUser: boolean;
}

const defaultReactions = ['👍', '👎', '🔥', '🎉', '👀', '✅', '😂', '🤔', '😢', '❤️']; //['\u{2764}\u{FE0F}', '\u{1F603}', '\u{1F44D}', '\u{1F64C}', '\u{1F525}']

const Wrapper = withStyle(FlexRowContainer, () => ({
  flexWrap: 'wrap',
}));

const ReactionSelector = withStyle(FlexRowContainer, ({ $theme }: any) => ({
  justifyContent: 'center',
  paddingTop: $theme.sizing.scale100,
  paddingRight: $theme.sizing.scale200,
  paddingBottom: $theme.sizing.scale100,
  paddingLeft: $theme.sizing.scale200,
}));

const ReactionOption = (props: any) => {
  const { type, group, onClick, close } = props;

  const authService = inject<IAuthService>(DI_TOKENS.authService);

  return (
    <Button
      kind="minimal"
      size={ButtonSIZE.compact}
      style={{ fontSize: '24px' }}
      onClick={(e) => {
        e.stopPropagation();

        const currentReaction = group?.byCurrentUser
          ? group.items.find((item) => item.user.id === authService.currentUser.id)
          : null;

        if (!currentReaction) {
          onClick({ type: type, action: 'add' });
          return;
        }

        onClick({
          type: group.type,
          remove: true,
          id: currentReaction.id,
        });

        close();
      }}
    >
      {type}
    </Button>
  );
};

const SavedReactions = (props: any) => {
  const { groups, onClick, transparent } = props;

  const authService = inject<IAuthService>(DI_TOKENS.authService);

  return groups.map((group: IGroupedReaction, idx: number) => {
    const btnKind = group.byCurrentUser ? 'tertiary' : 'minimal';

    const currentReaction = group.byCurrentUser
      ? group.items.find((item) => item.user.id === authService.currentUser.id)
      : null;

    return (
      <BaseButton
        key={idx}
        kind={btnKind}
        size={ButtonSIZE.compact}
        overrides={{
          BaseButton: {
            style: ({ $theme }) => {
              return {
                position: 'relative',
                paddingTop: $theme.sizing.scale100,
                paddingRight: $theme.sizing.scale200,
                paddingBottom: $theme.sizing.scale100,
                paddingLeft: $theme.sizing.scale200,
                fontSize: '14px',
                lineHeight: '1',
                marginRight: $theme.sizing.scale100,
                ...$theme.borders.border400,
                backgroundColor: transparent ? 'unset' : 'white',
              };
            },
          },
        }}
        onClick={() => {
          return onClick({
            type: group.type,
            remove: !!currentReaction,
            id: currentReaction && currentReaction.id,
          });
        }}
      >
        {group.type} {group.items.length}
      </BaseButton>
    );
  });
};

export const Reactions = (props: any) => {
  const { collection, onReactionOptionClick, options, transparent = true } = props;

  const reactionOptions: string[] = options || defaultReactions;

  const groups = _getGroupedReactions(collection);

  return (
    <Wrapper>
      <SavedReactions transparent={transparent} groups={groups} onClick={onReactionOptionClick} />
      <StatefulPopover
        showArrow
        placement={PopoverPLACEMENT.top}
        animateOutTime={20}
        content={({ close }: any) => (
          <ReactionSelector>
            {reactionOptions.map((option, idx) => {
              const group = groups.find((g) => g.type === option);
              return (
                <ReactionOption
                  key={idx}
                  type={option}
                  group={group}
                  onClick={onReactionOptionClick}
                  close={close}
                />
              );
            })}
          </ReactionSelector>
        )}
      >
        <BaseButton
          kind="minimal"
          overrides={{
            BaseButton: {
              style: ({ $theme }) => {
                return {
                  position: 'relative',
                  paddingTop: $theme.sizing.scale100,
                  paddingRight: $theme.sizing.scale200,
                  paddingBottom: $theme.sizing.scale100,
                  paddingLeft: $theme.sizing.scale200,
                  ...$theme.borders.border400,
                  backgroundColor: transparent ? 'unset' : 'white',
                };
              },
            },
          }}
        >
          <Icon size={16} icon="RegularSmilePlus" color="mono700" />
        </BaseButton>
      </StatefulPopover>
    </Wrapper>
  );
};

const _getGroupedReactions = (reactions: any[] = [], by: string = 'type'): IGroupedReaction[] => {
  const authService = inject<IAuthService>(DI_TOKENS.authService);

  const groupedItems = groupBy(reactions, by);

  return Object.keys(groupedItems).map((key: string) => {
    return {
      type: key,
      items: groupedItems[key],
      // if one of the reactions in this group was made by the current user
      byCurrentUser: !!groupedItems[key].find((item: any) => {
        return item.user && item.user.id === authService.currentUser.id;
      }),
    };
  });
};

Reactions.fragments = {
  reaction: gql`
    fragment ReactionFragment on Reaction {
      id
      type
      user {
        id
        firstName
        lastName
        fullName
      }
    }
  `,
};
