import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      color: palette.common.black,
      fontWeight: 600,
      fontSize: '18px',
      wordBreak: 'break-all',
      padding: spacing(),
    },
  });
