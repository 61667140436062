import { useState, ChangeEvent } from 'react';

import { Block } from 'baseui/block';
import { FormControl } from 'baseui/form-control';

import {
  FlexColumnContainer,
  FlexFullContainer,
  FlexRowContainer,
  Input,
} from '@shared/components';
import { FormQuestionDTO } from '@shared/models/forms/model';

type Props = {
  task: FormQuestionDTO;
  isDisabled: boolean;
  maxLimit?: number;
  onEdit: (form: FormQuestionDTO) => void;
};

export const FormMinMax = ({ onEdit, isDisabled, task, maxLimit }: Props) => {
  const [isMinError, setIsMinError] = useState(false);
  const [isMaxError, setIsMaxError] = useState(false);

  const handleUpdateMin = (value: string) => {
    const minValue = value === '' ? undefined : Number(value);

    const min = task.config?.min;
    const max = task.config?.max;

    if (minValue >= max && minValue >= min) return;

    onEdit({ ...task, config: { ...task.config, min: minValue } });
  };

  const handleUpdateMax = (value: string) => {
    const maxValue = value === '' ? undefined : Number(value);

    if (maxLimit && maxValue > maxLimit) return;

    onEdit({ ...task, config: { ...task.config, max: maxValue } });
  };

  const handleOnBlurMin = () => {
    const min = task.config?.min;
    const hasMinError = min === undefined;

    setIsMinError(hasMinError);
    handleOnBlurMax();
  };

  const handleOnBlurMax = () => {
    const min = task.config?.min;
    const max = task.config?.max;
    const hasMaxError = max === undefined || min >= max;

    setIsMaxError(hasMaxError);
  };

  return (
    <Block>
      <FlexRowContainer>
        <FlexFullContainer />
        <FlexColumnContainer $style={{ width: '144px', paddingRight: '12px' }}>
          <FormControl label="Min">
            <Input
              name="Min"
              type="number"
              min={0}
              error={isMinError}
              disabled={isDisabled}
              value={task.config?.min}
              onBlur={handleOnBlurMin}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleUpdateMin(e.target.value);
              }}
            />
          </FormControl>
        </FlexColumnContainer>
        <FlexColumnContainer $style={{ width: '144px' }}>
          <FormControl label="Max">
            <Input
              name="Max"
              type="number"
              min={0}
              error={isMaxError}
              max={maxLimit}
              disabled={isDisabled}
              value={task.config?.max}
              onBlur={handleOnBlurMax}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleUpdateMax(e.target.value);
              }}
            />
          </FormControl>
        </FlexColumnContainer>
      </FlexRowContainer>
    </Block>
  );
};
