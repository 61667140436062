import { injectable, inject } from '@core/di/di-utils';
import { appObservable, appMakeObservable } from '@core/state-management/utils';

import { showNotification } from '@shared/components/app-notification';
import { DI_TOKENS } from '@shared/constants/di';
import {
  NotificationListModel,
  NotificationListQuery,
} from '@shared/models/notification/list-model';
import { INotificationsService, Socket } from '@shared/types/services/notifications';
import { INotificationsViewModel } from '@shared/types/view-models/notifications';

@injectable()
export class NotificationsViewModel implements INotificationsViewModel {
  private readonly notificationsService = inject<INotificationsService>(
    DI_TOKENS.notificationsService
  );

  private _list: Array<NotificationListModel> = [];
  private _socket: Socket = null;
  private _loading = true;

  constructor() {
    appMakeObservable(this, {
      _list: appObservable,
      _loading: appObservable,
    });
  }

  get loading() {
    return this._loading;
  }

  get list() {
    return this._list;
  }

  private updateNotificationsList = (
    updatedNotifications: Array<NotificationListQuery> | NotificationListQuery
  ) => {
    if (Array.isArray(updatedNotifications)) {
      this._list = updatedNotifications.map(
        (notification) => new NotificationListModel(notification)
      );
      this._loading = false;

      return;
    }

    this._list = [new NotificationListModel(updatedNotifications), ...this._list];

    showNotification(updatedNotifications.title, { type: 'info' });
  };

  subscribe = () => {
    this._socket = this.notificationsService.createSocket();

    this._socket.on('notifications', this.updateNotificationsList);
    this._socket.on('update-notifications', (data) => this.updateNotifications(data.data));
  };

  markNotificationAsRead = (id?: NotificationListQuery['id']) => {
    this._socket.emit('notifications', id || 'ALL');

    this._list = this._list.map((notification) => {
      if (!id || id === notification.asJson.id) {
        notification.markAsRead();

        return notification;
      }
      return notification;
    });
  };

  updateNotifications = (notification: NotificationListQuery) => {
    this._list = this._list.map((n) => {
      if (notification.id === n.asJson.id) {
        return new NotificationListModel(notification);
      }
      return n;
    });
  };

  get isConnected() {
    return Boolean(this._socket?.connected);
  }

  unsubscribe = () => {
    this._socket.close();
    this._loading = true;
  };
}
