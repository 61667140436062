import { ReactNode, Component } from 'react';

import {
  Select as BaseSelect,
  SelectProps as BaseSelectProps,
  OnChangeParams as BaseOnChangeParams,
  Option as BaseOption,
} from 'baseui/select';
import { observer } from 'mobx-react';
import { Field } from 'mobx-react-form';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Ellipsis } from '@shared/components/Ellipsis';
import { Field as FieldComponent } from '@shared/components/Field';
import { getFieldBindings } from '@shared/utils/form';

import { styles } from './select.styles';

export type SelectOption<T = Id> = { label: string | number; id: T };

export type OnChangeParams<T = Array<Id> | Id> = BaseOnChangeParams & {
  option: SelectOption;
  normalizedValue: T;
};

export type LabelOption = BaseOption;

export type SelectProps = Omit<BaseSelectProps, 'onChange' | 'getOptionLabel'> &
  AppWithStyles<typeof styles> & {
    options?: Array<SelectOption | unknown>;
    label?: ReactNode;
    migratingToValue?: Id | Array<Id>;
    field?: Field;
    errorText?: string;
    optional?: boolean;
    renderOptionLabel?: (option: BaseOption) => ReactNode;
    onChange?: (params: OnChangeParams) => unknown;
  };

class SelectComponent extends Component<SelectProps> {
  private renderItem = (args: { option?: BaseOption }) => {
    const { renderOptionLabel } = this.props;

    if (renderOptionLabel) {
      return renderOptionLabel(args.option);
    }

    return <Ellipsis text={args.option?.label} />;
  };

  private handleBlur = (e) => {
    const { field, onBlur } = this.props;

    onBlur?.(e);

    if (field) {
      field.onBlur(e);
    }
  };

  private handleChange = (params: OnChangeParams) => {
    const { field, multi, onChange } = this.props;
    const { value } = params;
    const fieldValue = multi ? value.map(({ id }) => id) : value[0]?.id || '';

    onChange?.({ ...params, normalizedValue: fieldValue });

    if (field) {
      field.set('value', fieldValue);
    }
  };

  private get value() {
    const { field, value, migratingToValue, options, multi } = this.props;

    if (field) {
      const selected = multi
        ? options.filter(({ id }) => field.value.includes(id))
        : options.find(({ id }) => id === field.value);

      if (Array.isArray(selected)) {
        return selected;
      }
      if (selected) {
        return [selected];
      }
      return null;
    }

    if (migratingToValue) {
      if (Array.isArray(migratingToValue)) {
        return options.filter(({ id }) => migratingToValue.includes(id)) || null;
      }

      const selected = options.find(({ id }) => id === migratingToValue);

      return selected ? [selected] : null;
    }

    return value;
  }

  render() {
    const { label, field, errorText, error, classes, optional, overrides, ...otherProps } =
      this.props;
    const selectErrorText = errorText || field?.error;
    const hasError = Boolean(error || field?.error);

    const rootOverrides = overrides?.Root as { props: ObjectLike };

    return (
      <FieldComponent
        label={label}
        optional={optional}
        hasError={hasError}
        errorText={selectErrorText}
        classes={{ root: classes.root }}
      >
        <BaseSelect
          {...getFieldBindings(field)}
          {...otherProps}
          overrides={{
            Root: { props: { ...rootOverrides?.props } },
            Dropdown: { style: { maxHeight: '200px' } },
            ...overrides,
          }}
          value={this.value}
          error={hasError}
          getOptionLabel={this.renderItem}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      </FieldComponent>
    );
  }
}

export const Select = appWithStyles(styles)(appObserver(SelectComponent));
