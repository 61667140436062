import { ReactNode, Component, Fragment } from 'react';

import cx from 'classnames';
import { Form as BaseForm } from 'mobx-react-form';
import { Button } from 'src/shared/components/button';

import { appObservable, appObserver, appMakeObservable } from '@core/state-management/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { showNotification } from '@shared/components/app-notification';
import { Flex } from '@shared/components/Flex';
import { Spinner } from '@shared/components/Spinner';
import { ManageMode } from '@shared/constants/common';
import { showErrors } from '@shared/utils/errors';

import { FormContentWrapper } from './components/FormContentWrapper';
import { FormConfig, FormButtonLayoutVariant, FormButtonType } from './form.types';

import { styles } from './Form.styles';

export const FORM_UNIT_LABEL_WIDTH = '20%';
export const FORM_UNIT_LABEL_MARGIN = '16px';

export type FormProps = AppWithStyles<typeof styles> & {
  formInstance?: BaseForm<any>;
  manageMode: ManageMode;
  notificationText?: string;
  loading?: boolean;
  loadingHeight?: number;
  buttons?: Array<FormButtonType>;
  config?: FormConfig;
  renderControls?: () => ReactNode;
  onSubmit: (data?: Item) => void;
  onSuccess?: (data?: Item) => void;
  onCancel?: () => void;
  children?: ReactNode;
};

class FormComponent extends Component<FormProps> {
  static defaultProps = {
    buttons: ['submit'] as Array<FormButtonType>,
  };

  private submitting = false;

  constructor(props) {
    super(props);

    appMakeObservable(this, {
      submitting: appObservable,
    });
  }

  private handleSubmit = async (e) => {
    e.preventDefault();

    const { formInstance, notificationText } = this.props;

    formInstance?.validate();

    if (formInstance?.hasError) {
      formInstance?.showErrors();

      return;
    }

    const data = formInstance?.values();
    const { onSubmit, onSuccess } = this.props;

    try {
      this.submitting = true;

      await onSubmit(data);

      if (notificationText) {
        showNotification(notificationText);
      }

      if (onSuccess) {
        onSuccess(data);
      }
    } catch (e) {
      showErrors(e?.response?.data?.errors, {
        form: formInstance,
        notificationText: 'Something went wrong',
      });
    } finally {
      this.submitting = false;
    }
  };

  private get config() {
    const { manageMode } = this.props;
    const cancel = {
      text: 'Cancel',
      kind: 'tertiary',
    };
    const config = {
      [ManageMode.create]: {
        buttons: {
          cancel,
          submit: {
            text: 'Create',
          },
        },
      },
      [ManageMode.edit]: {
        buttons: {
          cancel,
          submit: {
            text: 'Save',
          },
        },
      },
    };

    return config[manageMode];
  }

  private get buttons() {
    const { config, onCancel, classes } = this.props;
    const commonProps = {
      className: classes.control,
    };

    const buttons: Record<FormButtonType, ReactNode> = {
      submit: (
        <Button
          isLoading={this.submitting}
          type="submit"
          kind="primary"
          disabled={config?.submitButton?.disabled}
          {...commonProps}
        >
          {config?.submitButton?.text || this.config.buttons.submit.text}
        </Button>
      ),
      cancel: (
        <Button
          disabled={this.submitting || config?.submitButton?.disabled}
          type="button"
          kind="tertiary"
          onClick={onCancel}
          {...commonProps}
        >
          {config?.cancelButton?.text || this.config.buttons.cancel.text}
        </Button>
      ),
    };

    return buttons;
  }

  render() {
    const { classes, children, renderControls, config, loading, loadingHeight, buttons } =
      this.props;
    const customControls = renderControls ? renderControls() : null;
    const submitButtonPositionVariant = config?.submitButton?.layoutVariant || 'primary';
    const submitButtonLayoutClasses: { [type in FormButtonLayoutVariant]: string } = {
      primary: classes.submitButtonPositionPrimary,
      secondary: classes.submitButtonPositionSecondary,
    };
    const submitButtonLayoutClass = submitButtonLayoutClasses[submitButtonPositionVariant];

    return (
      <FormContentWrapper classes={{ root: classes.root }}>
        <form onSubmit={this.handleSubmit} className={classes.form}>
          {loading && (
            <Spinner
              withWrapper
              wrapperProps={{
                classes: {
                  root: classes.loading,
                },
                style: {
                  height: loadingHeight,
                },
              }}
            />
          )}
          {!loading && children}
          {!loading && Boolean(buttons.length) && (
            <Flex
              justifyContent="flex-start"
              classes={{ root: cx(classes.controls, submitButtonLayoutClass) }}
            >
              {buttons.map((buttonType) => (
                <Fragment key={buttonType}>{this.buttons[buttonType]}</Fragment>
              ))}
            </Flex>
          )}
          {customControls && (
            <Flex
              justifyContent="flex-start"
              alignItems="center"
              classes={{ root: classes.controls }}
            >
              {customControls}
            </Flex>
          )}
        </form>
      </FormContentWrapper>
    );
  }
}

export const Form = appWithStyles(styles)(appObserver(FormComponent));
