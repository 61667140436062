import { spacing } from '@core/theme/utils/spacing';

import { buildThemeColors } from '@shared/utils/Theme/buildThemeColors';

export const baseButtonStyles = {
  whiteSpace: 'nowrap',
  textTransform: 'capitalize',
  letterSpacing: '1px',
  fontWeight: 400,
};

export const primaryStyles = (colorPrimary: string, textColor: string) => {
  const colors = buildThemeColors(colorPrimary);

  return {
    borderTopWidth: '1px',
    borderLeftWidth: '1px',
    borderRightWidth: '1px',
    borderBottomWidth: '1px',
    borderRightStyle: 'solid',
    borderBottomStyle: 'solid',
    borderLeftStyle: 'solid',
    borderTopStyle: 'solid',
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderLeftColor: 'transparent',
    borderBottomColor: 'transparent',
    backgroundColor: colorPrimary,
    color: textColor,
    ':hover': {
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
    },
    ':active': {
      backgroundColor: colorPrimary,
      borderTopColor: colors.primary500,
      borderRightColor: colors.primary500,
      borderLeftColor: colors.primary500,
      borderBottomColor: colors.primary500,
    },
    ':disabled': {
      ':active': {
        backgroundColor: colors.mono200,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
      },
    },
  };
};

export const secondaryStyles = (background: string, colorPrimary: string) => {
  const colors = buildThemeColors(colorPrimary);

  return {
    borderTopWidth: '1px',
    borderLeftWidth: '1px',
    borderRightWidth: '1px',
    borderBottomWidth: '1px',
    borderRightStyle: 'solid',
    borderBottomStyle: 'solid',
    borderLeftStyle: 'solid',
    borderTopStyle: 'solid',
    borderTopColor: background,
    borderRightColor: background,
    borderLeftColor: background,
    borderBottomColor: background,
    backgroundColor: background,
    color: '#545454',
    ':hover': {
      backgroundColor: background,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
    },
    ':active': {
      backgroundColor: background,
      borderTopColor: colors.primary500,
      borderRightColor: colors.primary500,
      borderLeftColor: colors.primary500,
      borderBottomColor: colors.primary500,
    },
  };
};

export const tertiaryStyles = (background: string, colorPrimary: string) => {
  const colors = buildThemeColors(colorPrimary);

  return {
    borderTopWidth: '1px',
    borderLeftWidth: '1px',
    borderRightWidth: '1px',
    borderBottomWidth: '1px',
    borderRightStyle: 'solid',
    borderBottomStyle: 'solid',
    borderLeftStyle: 'solid',
    borderTopStyle: 'solid',
    borderTopColor: '#CBCBCB',
    borderRightColor: '#CBCBCB',
    borderLeftColor: '#CBCBCB',
    borderBottomColor: '#CBCBCB',
    backgroundColor: 'transparent',
    color: '#545454',
    ':hover': {
      background: background,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
    },
    ':active': {
      backgroundColor: 'transparent',
      borderTopColor: colors.primary500,
      borderRightColor: colors.primary500,
      borderLeftColor: colors.primary500,
      borderBottomColor: colors.primary500,
    },
  };
};

export const minimalStyles = (colorPrimary: string) => ({
  backgroundColor: 'transparent',
  color: colorPrimary,
  ':hover': {
    backgroundColor: 'transparent',
  },
  ':active': {
    backgroundColor: 'transparent',
  },
  ':disabled': {
    backgroundColor: 'transparent',
  },
});

export const deleteStyles = (textColor) => ({
  borderTopWidth: '1px',
  borderLeftWidth: '1px',
  borderRightWidth: '1px',
  borderBottomWidth: '1px',
  borderRightStyle: 'solid',
  borderBottomStyle: 'solid',
  borderLeftStyle: 'solid',
  borderTopStyle: 'solid',
  borderTopColor: 'transparent',
  borderRightColor: 'transparent',
  borderLeftColor: 'transparent',
  borderBottomColor: 'transparent',
  backgroundColor: '#D44333',
  color: textColor,
  ':hover': {
    backgroundColor: '#D44333',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
  },
});

export const sizeCompactStyles = {
  paddingTop: spacing(1.75),
  paddingBottom: spacing(1.75),
  paddingLeft: spacing(3),
  paddingRight: spacing(3),
  fontSize: '14px',
  lineHeight: '20px',
};

export const sizeDefaultStyles = {
  paddingTop: spacing(3),
  paddingBottom: spacing(3),
  paddingLeft: spacing(4),
  paddingRight: spacing(4),
  fontSize: '16px',
  lineHeight: '24px',
};

export const sizeLargeStyles = {
  paddingTop: spacing(3.5),
  paddingBottom: spacing(3.5),
  paddingLeft: spacing(5),
  paddingRight: spacing(5),
  fontSize: '18px',
  lineHeight: '28px',
};

export const sizeMiniStyles = {
  padding: '0',
  fontSize: '14px',
  lineHeight: '20px',
};
