import { useEffect, useState } from 'react';

import { IBreadCrumbLink, useUIContext } from 'src/shared/hocs/ui-context';

export const useBreadCrumbs = (breadCrumbs: (string | IBreadCrumbLink)[]) => {
  const { setBreadCrumbs } = useUIContext();

  const [crumbs, setCrumbs] = useState();

  if (crumbs) {
    setBreadCrumbs(crumbs);
  }

  // this stringify basically forces a unique check for the use effect
  const breadCrumbsJson = JSON.stringify(breadCrumbs);

  useEffect(() => {
    const links = JSON.parse(breadCrumbsJson).map((link: string | IBreadCrumbLink) => {
      if (typeof link === 'string') {
        return {
          label: link,
          path: null,
        };
      }
      return link;
    });
    setCrumbs(links);
  }, [breadCrumbsJson]);
};
