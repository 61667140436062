import { ThemeProvider } from 'baseui';
import { Button } from 'baseui/button';
import { ButtonGroup } from 'baseui/button-group';

import { ManageMode } from '@shared/constants/common';
import { useThemeContext } from '@shared/hooks';

type Props = {
  items: Array<string>;
  selected: number | number[];
  onChange: (index: number) => void;
  manageMode?: ManageMode;
  disabled?: boolean;
};

export const SegmentedControl = ({
  items,
  selected,
  onChange,
  manageMode,
  disabled = false,
}: Props) => {
  const theme = useThemeContext();

  return (
    <ThemeProvider
      theme={{
        ...theme,
        colors: {
          ...theme.colors,
          buttonSecondarySelectedFill: theme.colors.buttonPrimaryFill,
          buttonSecondarySelectedText: theme.colors.buttonPrimaryText,
        },
      }}
    >
      <ButtonGroup selected={selected}>
        {items?.map((item, index) => {
          const control = (
            <Button
              key={item}
              type="button"
              disabled={index !== selected && disabled}
              $style={{ backgroundColor: 'red' }}
              kind={index === selected ? 'primary' : 'tertiary'}
              onClick={() => onChange(index)}
            >
              {item}
            </Button>
          );

          if (manageMode === ManageMode.edit) {
            if (index === selected) return control;
            return null;
          }
          return control;
        })}
      </ButtonGroup>
    </ThemeProvider>
  );
};
