import { palette } from '@core/theme/units/palette';

export const styles = () => {
  return {
    root: {
      position: 'absolute',
      color: palette.error.main,
      fontSize: 12,
    },
  };
};
