import { inject, injectable } from '@core/di/di-utils';
import { Config } from '@core/сonfig';

import { DI_TOKENS } from '@shared/constants/di';
import { IConfig } from '@shared/types/config';
import { IHeapService } from '@shared/types/services/heap';
import { ITenantsService } from '@shared/types/services/tenants';

// https://developers.heap.io/reference/client-side-apis-overview

enum HeapStatus {
  INITIALIZE = 'INITIALIZE',
  INIT_SUCCESS = 'INIT_SUCCESS',
  ERROR = 'ERROR',
}

@injectable()
export class HeapService implements IHeapService {
  private readonly configService = inject<IConfig>(Config.diToken);
  private readonly tenantsService = inject<ITenantsService>(DI_TOKENS.tenantsService);

  private _heapStatus: HeapStatus = HeapStatus.INITIALIZE;

  private initHeap() {
    if (process.env.NODE_ENV === 'development') return;

    const heapAppId = this.getHeapAppId();

    if (heapAppId) {
      this.heapLoad(heapAppId);
      this.setTenantSlug();
    } else {
      this._heapStatus = HeapStatus.ERROR;
      console.info('Heap APP_ID not found');
    }
  }

  private heapLoad(appHeapId: string) {
    if (heap.load) {
      heap.load(appHeapId);
    }

    this._heapStatus = HeapStatus.INIT_SUCCESS;
  }

  private setTenantSlug() {
    heap.addEventProperties({
      'Tenant Slug': this.getTenantSlug(),
    });
  }

  private getHeapAppId(): string {
    return this.configService.get().heapAppId;
  }

  private getTenantSlug(): string | '' {
    const tenantSlug = this.tenantsService?.currentTenant?.slug;

    return tenantSlug || '';
  }

  private isAdminTenant(): boolean {
    const { isAdminTenant } = this.tenantsService?.currentTenant;

    return isAdminTenant;
  }

  public identifyUser({ email, lastLogin }: { email: string; lastLogin: string }) {
    // First step
    this.initHeap();

    if (this._heapStatus === HeapStatus.INIT_SUCCESS) {
      heap.identify(email);
      this.addUserProperties({
        email,
        lastLogin,
        isAdmin: this.isAdminTenant(),
        tenantSlug: this.getTenantSlug(),
      });
    }
  }

  private addUserProperties({
    email,
    lastLogin,
    isAdmin,
    tenantSlug,
  }: {
    email: string;
    lastLogin: string;
    isAdmin: boolean;
    tenantSlug: string;
  }) {
    heap.addUserProperties({
      Email: email,
      'Tenant Slug': tenantSlug,
      'Last Login': lastLogin,
      'User Role': isAdmin ? 'Admin' : 'User',
    });
  }
}
