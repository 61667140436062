import { inject, injectable } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import {
  CreateCustomGroupDTO,
  CreateAutoGroupDTO,
} from '@shared/models/page-type/create-group-model';
import { CreatePageTypeColumnDTO } from '@shared/models/page-type/create-page-type-column-model';
import { CreatePageTypeDTO } from '@shared/models/page-type/create-page-type-model';
import { EditPageTypeColumnDTO } from '@shared/models/page-type/edit-page-type-column-model';
import {
  PageTypeAutoGroupDTO,
  PageTypeCustomGroupDTO,
  AutoGroupOption,
  CustomGroupOption,
} from '@shared/models/page-type/page-type-group-model';
import { PageTypeColumnDTO, PageTypeDTO } from '@shared/models/page-type/page-type-model';
import { PageQueryDTO } from '@shared/models/pages/page-query-model';
import { PaginatedResponse } from '@shared/types/services';
import { IPageTypeService, PageGroupPagesParams } from '@shared/types/services/page-type';

@injectable()
export class PageTypeService extends BaseService implements IPageTypeService {
  static diToken = Symbol('pages-type-service');

  protected urlPrefix = '/page-types';
  private $http = inject<HTTPClient>(HTTPClient.diToken);

  async getPageTypeList() {
    const { data } = await this.$http.get<Array<PageTypeDTO>>(this.getUrl(''));

    return data;
  }

  async createPageType(data: CreatePageTypeDTO) {
    await this.$http.post(this.getUrl(''), data);
  }

  async editPageType(pageTypeId: PageTypeDTO['id'], name: PageTypeDTO['name']) {
    await this.$http.put(this.getUrl(pageTypeId), { name });
  }

  async deletePageType(pageTypeId: PageTypeDTO['id']) {
    await this.$http.delete(this.getUrl(pageTypeId));
  }

  async createPageTypeColumn(pageTypeId: PageTypeDTO['id'], data: CreatePageTypeColumnDTO) {
    await this.$http.post(this.getUrl(`${pageTypeId}/columns`), data);
  }

  async editPageTypeColumn(
    pageTypeId: PageTypeDTO['id'],
    columnId: PageTypeColumnDTO['id'],
    data: EditPageTypeColumnDTO
  ) {
    await this.$http.put(this.getUrl(`${pageTypeId}/columns/${columnId}`), data);
  }

  async deletePageTypColumn(pageTypeId: PageTypeDTO['id'], columnId: PageTypeColumnDTO['id']) {
    await this.$http.delete(this.getUrl(`${pageTypeId}/columns/${columnId}`));
  }

  async sortPageTypeColumn(
    pageTypeId: PageTypeDTO['id'],
    columnIds: Array<PageTypeColumnDTO['id']>
  ) {
    await this.$http.put(this.getUrl(`${pageTypeId}/columns`), {
      columnIds,
    });
  }

  async getPageTypeGroups(pageTypeId: PageTypeDTO['id']) {
    const { data } = await this.$http.get<Array<PageTypeCustomGroupDTO | PageTypeAutoGroupDTO>>(
      `${this.getUrl(pageTypeId)}/group-categories`
    );
    return data;
  }

  async createCustomGroup(pageTypeId: PageTypeDTO['id'], params: CreateCustomGroupDTO) {
    await this.$http.post(`${this.getUrl(pageTypeId)}/group-categories/custom/groups`, params);
  }

  async createAutoGroup(pageTypeId: PageTypeDTO['id'], params: CreateAutoGroupDTO) {
    await this.$http.post(`${this.getUrl(pageTypeId)}/group-categories`, params);
  }

  async updateCustomGroup(
    pageTypeId: string,
    groupId: string,
    params: {
      name: string;
      pages: Array<PageQueryDTO['id']>;
    }
  ) {
    await this.$http.put(
      `${this.getUrl(pageTypeId)}/group-categories/custom/groups/${groupId}`,
      params
    );
  }

  async updateAutoGroup(
    pageTypeId: PageTypeDTO['id'],
    categoryId: PageTypeAutoGroupDTO['id'],
    params: {
      pageTypeColumn: PageTypeColumnDTO['id'];
    }
  ) {
    await this.$http.put(`${this.getUrl(pageTypeId)}/group-categories/${categoryId}`, params);
  }

  async deleteCustomGroup(pageTypeId: PageTypeDTO['id'], groupId: PageTypeCustomGroupDTO['id']) {
    await this.$http.delete(this.getUrl(`${pageTypeId}/group-categories/custom/groups/${groupId}`));
  }

  async deleteAutoGroup(pageTypeId: PageTypeDTO['id'], categoryId: PageTypeAutoGroupDTO['id']) {
    await this.$http.delete(this.getUrl(`${pageTypeId}/group-categories/${categoryId}`));
  }

  async getGroupPages(
    pageTypeId: PageTypeDTO['id'],
    categoryId: PageTypeAutoGroupDTO['id'] | PageTypeCustomGroupDTO['id'],
    groupId: AutoGroupOption['id'] | CustomGroupOption['id'],
    params: PageGroupPagesParams
  ) {
    const normalizedParams = params && {
      limit: params.pagination.limit,
      offset: params.pagination.skip,
      optionId: params?.optionId,
    };
    const { data } = await this.$http.get<PaginatedResponse<Array<PageQueryDTO>>>(
      `${this.getUrl(pageTypeId)}/group-categories/${categoryId}/groups/${groupId}/pages`,
      { params: normalizedParams }
    );
    return data;
  }
}
