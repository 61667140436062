import { palette } from '@core/theme/units/palette';
import { radius } from '@core/theme/units/radius';
import { shadows } from '@core/theme/units/shadows';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      padding: spacing(4),
      boxShadow: shadows[2],
      borderRadius: radius.containers.primary,
      backgroundColor: palette.common.white,
    },
    active: {
      backgroundColor: palette.grey['50'],
      boxShadow: shadows[1],
    },
    header: {
      marginBottom: spacing(3),
    },
    footer: {
      marginTop: spacing(3),
    },
    replyManagement: {
      marginTop: spacing(7),
    },
  });
