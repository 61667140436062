import { DateTime } from 'luxon';
import { when } from 'mobx';

import { inject, injectable } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { HttpResponse } from '@core/http-client/http.types';
import { BaseService } from '@core/services/base-service';
import { appObservable, appMakeObservable } from '@core/state-management/utils';

import { TokenRefreshStatus } from '@shared/constants/auth';
import { DI_TOKENS } from '@shared/constants/di';
import { ROUTES } from '@shared/constants/routes';
import { TenantReadQuery } from '@shared/models/tenant/read-model';
import { UserReadQuery } from '@shared/models/user/read-model';
import { IAuthService } from '@shared/types/services/auth';
import { IHeapService } from '@shared/types/services/heap';
import { IStorageService } from '@shared/types/services/storage';
import { history } from '@shared/utils/history';

const STORAGE_KEYS = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  currentUser: 'currentUser',
  currentUserFilter: 'currentUserFilter',
  currentTenant: 'currentTenant',
};

@injectable()
export class AuthService extends BaseService implements IAuthService {
  static diToken = DI_TOKENS.authService;

  private refreshTokenUrl = '/token/refresh';
  private heapService = inject<IHeapService>(DI_TOKENS.heapService);
  private storageService = inject<IStorageService>(DI_TOKENS.storageService);
  private $http = inject<HTTPClient>(HTTPClient.diToken);
  private _tokenRefreshStatus = TokenRefreshStatus.initial;
  private _currentUser = this.storageService.get<UserReadQuery>('cookie', STORAGE_KEYS.currentUser);
  private _tokens = {
    access: this.storageService.get('cookie', STORAGE_KEYS.accessToken),
    refresh: this.storageService.get('cookie', STORAGE_KEYS.refreshToken),
  };

  constructor() {
    super();

    appMakeObservable(this, {
      _tokenRefreshStatus: appObservable,
      _currentUser: appObservable,
      _tokens: appObservable,
    });
  }

  get tokenRefreshStatus() {
    return this._tokenRefreshStatus;
  }

  get tokens() {
    return this._tokens;
  }

  setCurrentTenant = (tenant: TenantReadQuery) => {
    this.storageService.set('cookie', STORAGE_KEYS.currentTenant, tenant);
  };

  setCurrentUser = (user: UserReadQuery) => {
    this._currentUser = user;

    this.storageService.set('cookie', STORAGE_KEYS.currentUser, user);

    this.heapService.identifyUser({
      email: user.email,
      lastLogin: user.lastActiveAt,
    });
  };

  get currentTenant() {
    return (
      this.storageService.get('cookie', STORAGE_KEYS.currentTenant) ||
      window?.['CURRENT_TENANT'] ||
      null
    );
  }

  get currentUser() {
    return this._currentUser;
  }

  get loggedIn() {
    return Boolean(this.currentUser);
  }

  hasPermission: IAuthService['hasPermission'] = (permissions, operator = 'or') => {
    if (!this.currentUser) {
      return false;
    }

    if (Array.isArray(permissions)) {
      const computations: { [type in typeof operator]: () => boolean } = {
        or: () =>
          permissions.some((permission) => this.currentUser.permissions.includes(permission)),
        and: () =>
          permissions.every((permission) => this.currentUser.permissions.includes(permission)),
      };

      return computations[operator]();
    }

    return this.currentUser.permissions.includes(permissions);
  };

  processUnauthenticated = async (response: HttpResponse<any>) => {
    const { url } = response.config;
    const failedToRefreshToken = this.refreshTokenUrl === url;

    if (failedToRefreshToken) {
      throw response;
    }

    await this.refreshToken();
    await when(() => this._tokenRefreshStatus === TokenRefreshStatus.refreshed);
  };

  refreshToken = async () => {
    if (this._tokenRefreshStatus === TokenRefreshStatus.refreshing) {
      return;
    }

    if (!this.tokens.refresh) {
      this.logout();

      return;
    }

    this._tokenRefreshStatus = TokenRefreshStatus.refreshing;

    try {
      const {
        data: { accessToken, refreshToken },
      } = await this.$http.get<{ accessToken: string; refreshToken: string }>(
        this.refreshTokenUrl,
        {
          headers: {
            'X-Timezone': DateTime.local().zoneName,
            'X-Timezone-Offset': DateTime.local().offset,
            Accept: 'application/json',
            'X-Refresh-Token': `Bearer ${this.tokens.refresh}`,
          },
        }
      );

      this.setTokens({ access: accessToken, refresh: refreshToken });

      return { accessToken, refreshToken };
    } catch (err) {
      this._tokenRefreshStatus = TokenRefreshStatus.initial;
      this.logout();

      throw err;
    } finally {
      this._tokenRefreshStatus = TokenRefreshStatus.refreshed;
    }
  };

  setTokens: IAuthService['setTokens'] = (tokens) => {
    this._tokens = tokens;
    this.storageService.set('cookie', STORAGE_KEYS.accessToken, tokens.access);
    this.storageService.set('cookie', STORAGE_KEYS.refreshToken, tokens.refresh);
  };

  clearUserData() {
    const cookieKeys = Object.values(STORAGE_KEYS);
    cookieKeys.forEach((key) => this.storageService.remove('cookie', key));
    window.localStorage.removeItem(STORAGE_KEYS.currentUserFilter);
    this._tokens = {
      access: null,
      refresh: null,
    };
    this._currentUser = null;
  }

  logout() {
    this.clearUserData();

    history.push(ROUTES.login);
  }
}
