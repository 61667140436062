import { colorLighten } from '@core/theme/utils/color-lighten';

export const styles: Styles = {
  root: {
    width: '40px',
    height: '40px',
    animation: 'rotate 1.5s linear infinite',
  },
  bluePath: {
    fill: '#276EF1',
  },
  whitePath: {
    fill: '#ddd',
  },
  wrapperAbsolute: {
    height: '100%',
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    zIndex: 2,
    backgroundColor: colorLighten('#fff', 0.5),
  },
};
