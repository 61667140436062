import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {},
    list: {
      marginTop: spacing(4),
    },
    disabledList: {
      opacity: 0.6,
      pointerEvents: 'none',
    },
    reply: {
      borderLeft: `2px solid ${palette.grey[200]}`,
      margin: spacing(0, 3),
      padding: spacing(4),
    },
    divider: {
      margin: `${spacing(4, 0, 3)} !important`,
      backgroundColor: `${palette.grey[200]} !important`,
    },
    showMoreButton: {
      textTransform: 'none',
      color: palette.common.black,
    },
  });
