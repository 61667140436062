import { SVGComponent, SVGProps } from '@shared/components/SVGComponent';

type ChevronDownIconProps = Partial<SVGProps> & {};

export const ChevronDownIcon = (props: ChevronDownIconProps) => {
  return (
    <SVGComponent width={24} height={24} {...props}>
      <path d="M8 11L12 15L16 11H8Z" fill="#545454" />
    </SVGComponent>
  );
};
