import { FileUploader as BaseFileUploader, FileUploaderProps } from 'baseui/file-uploader';

import { Button } from '..';

export const FileUpload = (props: FileUploaderProps) => {
  return (
    <BaseFileUploader
      {...props}
      overrides={{
        ButtonComponent: {
          component: ({ onClick }: any) => (
            <Button type={'button'} onClick={onClick} kind="minimal">
              Browse Files
            </Button>
          ),
        },
        ...props.overrides,
      }}
    />
  );
};
