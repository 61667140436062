import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      padding: spacing(3, 0),
      minHeight: '100px',
    },
    icon: {
      color: palette.grey[400],
    },
    content: {},
    heading: {
      fontSize: '20px',
      margin: spacing(2, 0, 1),
      fontWeight: 600,
      color: palette.text.primary,
    },
    subheading: {
      margin: '0px',
      fontSize: '14px',
      color: palette.grey[500],
    },
    button: {
      marginTop: spacing(2),
    },
  });
