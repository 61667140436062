import { DI_TOKENS } from '@shared/constants/di';
import { DictionariesService } from '@shared/services/dictionaries';
import { PageTypeService } from '@shared/services/page-type';

import { AuthService } from './auth';
import { CommentsService } from './comments';
import { EventsService } from './events';
import { FilesService } from './files';
import { FormsService } from './forms';
import { GroupsService } from './groups';
import { HeadlinesService } from './headlines';
import { HeapService } from './heap';
import { InsightsService } from './insights';
import { InsightsActivityService } from './insights-activity';
import { InsightsAnalyzeService } from './insights-analyze';
import { InsightsExploreService } from './insights-explore';
import { InventoryService } from './inventory';
import { NotesService } from './notes';
import { NotificationsService } from './notifications';
import { PagesService } from './pages';
import { PagesImportService } from './pages-import';
import { PromptsService } from './prompts';
import { ResourcesService } from './resources';
import { RolesService } from './roles';
import { StoriesService } from './stories';
import { SubmissionsService } from './submissions';
import { TagsService } from './tags';
import { TasksService } from './tasks';
import { TenantsService } from './tenants';
import { UsersService } from './users';

export const services = [
  {
    diToken: AuthService.diToken,
    entity: AuthService,
  },
  {
    diToken: DI_TOKENS.heapService,
    entity: HeapService,
  },
  {
    diToken: DI_TOKENS.pagesService,
    entity: PagesService,
  },
  {
    diToken: DI_TOKENS.pageTypeService,
    entity: PageTypeService,
  },
  {
    diToken: FilesService.diToken,
    entity: FilesService,
  },
  {
    diToken: DI_TOKENS.formsService,
    entity: FormsService,
  },
  {
    diToken: PromptsService.diToken,
    entity: PromptsService,
  },
  {
    diToken: ResourcesService.diToken,
    entity: ResourcesService,
  },
  {
    diToken: DI_TOKENS.storiesService,
    entity: StoriesService,
  },
  {
    diToken: TagsService.diToken,
    entity: TagsService,
  },
  {
    diToken: TasksService.diToken,
    entity: TasksService,
  },
  {
    diToken: DI_TOKENS.usersService,
    entity: UsersService,
  },
  {
    diToken: DI_TOKENS.tenantsService,
    entity: TenantsService,
  },
  {
    diToken: DI_TOKENS.groupsService,
    entity: GroupsService,
  },
  {
    diToken: DI_TOKENS.rolesService,
    entity: RolesService,
  },
  {
    diToken: DI_TOKENS.insightsService,
    entity: InsightsService,
  },
  {
    diToken: DI_TOKENS.submissionsService,
    entity: SubmissionsService,
  },
  {
    diToken: DI_TOKENS.headlinesService,
    entity: HeadlinesService,
  },
  {
    diToken: DI_TOKENS.insightsExploreService,
    entity: InsightsExploreService,
  },
  {
    diToken: DI_TOKENS.insightsActivityService,
    entity: InsightsActivityService,
  },
  {
    diToken: DI_TOKENS.commentsService,
    entity: CommentsService,
  },
  {
    diToken: DI_TOKENS.notesService,
    entity: NotesService,
  },
  {
    diToken: DI_TOKENS.insightsAnalyzeService,
    entity: InsightsAnalyzeService,
  },
  {
    diToken: DI_TOKENS.eventsService,
    entity: EventsService,
  },
  {
    diToken: DI_TOKENS.notificationsService,
    entity: NotificationsService,
  },
  {
    diToken: DI_TOKENS.dictionariesService,
    entity: DictionariesService,
  },
  {
    diToken: DI_TOKENS.promptsService,
    entity: PromptsService,
  },
  {
    diToken: DI_TOKENS.pagesImportService,
    entity: PagesImportService,
  },
  {
    diToken: DI_TOKENS.inventoryService,
    entity: InventoryService,
  },
];
