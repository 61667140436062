import { useEffect, useMemo } from 'react';

import cx from 'classnames';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { ButtonGroup } from '@shared/components/button-group';
import { Commentaries } from '@shared/components/commentaries';
import { SubmissionReadQuery } from '@shared/models/submission/read-model';

import { ActivityType } from './activity.types';
import { ActivityViewModel } from './activity.vm';
import { Log } from './components/log';

import { styles } from './activity.styles';

type Props = AppWithStyles<typeof styles> & {
  log: SubmissionReadQuery['activityLog'];
  submissionId: SubmissionReadQuery['id'];
  openCommentParentId?: string;
  openCommentId?: string;
  scrollTo: (top: number) => void;
};

const ActivityComponent = ({
  classes,
  log,
  submissionId,
  openCommentParentId,
  openCommentId,
  scrollTo,
}: Props) => {
  const $vm = useMemo(() => new ActivityViewModel(submissionId), [submissionId]);

  useEffect(() => {
    $vm.initialize();

    if (openCommentId) {
      $vm.updateCurrentTab(ActivityType.comments);
    }
  }, []);

  const activityTypeControlsConfig = [
    {
      text: `Activities (${log.length})`,
      type: ActivityType.log,
    },
    {
      text: `Comments (${$vm.commentCount})`,
      isLoading: $vm.countLoading,
      type: ActivityType.comments,
    },
  ];

  const handleActivityTypeChange = (e, selectedIndex: number) => {
    const selectedItem = activityTypeControlsConfig[selectedIndex];
    if (selectedItem) {
      $vm.updateCurrentTab(selectedItem.type);
    }
  };

  return (
    <div className={classes.root}>
      <ButtonGroup
        itemsEqualWidth
        size="compact"
        selected={activityTypeControlsConfig.findIndex(({ type }) => type === $vm.currentTab)}
        items={activityTypeControlsConfig.map(({ text, isLoading }) => ({ text, isLoading }))}
        onClick={handleActivityTypeChange}
      />
      <Log
        data={log}
        classes={{
          root: cx(classes.content, {
            [classes.contentHidden]: $vm.currentTab !== ActivityType.log,
          }),
        }}
      />
      <Commentaries
        scrollTo={scrollTo}
        openCommentId={openCommentId}
        openCommentParentId={openCommentParentId}
        hidden={$vm.currentTab !== ActivityType.comments}
        withCount={false}
        owner={$vm.owner}
        classes={{ root: classes.content }}
        onChange={(count: number) => $vm.updateCommentCount(count)}
      />
    </div>
  );
};

export const Activity = appWithStyles(styles)(appObserver(ActivityComponent));
