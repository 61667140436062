import { ModalBody, ModalFooter, ModalButton, Paragraph2 } from '@shared/components';
import { useModalContext } from '@shared/hocs/modal-context';
import { useThemeContext } from '@shared/hooks';

export const DeactivateUsersModal = (props: any) => {
  const { data } = props;
  const theme = useThemeContext();
  const modalContext = useModalContext();

  const handleConfirm = () => {
    modalContext.setPayload(true);
    modalContext.close();
  };

  const handleCancel = () => {
    modalContext.setPayload(false);
    modalContext.close();
  };

  return (
    <>
      <ModalBody>
        <Paragraph2>
          {data.length === 1 && (
            <>
              Are you sure you want to deactivate <b>{`${data[0].fullName}`}</b>?
            </>
          )}
          {data.length > 1 && (
            <>
              Are you sure you want to deactivate <b>{data.length} users</b>?
            </>
          )}
          <br />
          They will be immediately signed out.
        </Paragraph2>
      </ModalBody>
      <ModalFooter>
        <ModalButton style={{ color: theme.colors.mono800 }} kind="minimal" onClick={handleCancel}>
          Cancel
        </ModalButton>
        <ModalButton style={{ backgroundColor: theme.colors.negative }} onClick={handleConfirm}>
          Yes, deactivate
        </ModalButton>
      </ModalFooter>
    </>
  );
};
