import { useCallback, useEffect, useState } from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { Ellipsis, EllipsisProps } from '@shared/components/Ellipsis';
import { addDate, fromNow } from '@shared/utils/date';

import { styles } from './time-ago.styles';

type TimeAgoProps = Pick<EllipsisProps, 'maxWidth' | 'width'> &
  AppWithStyles<typeof styles> & {
    date: string | Date;
  };

const TimeAgoComponent = ({ date: initialDate, classes }: TimeAgoProps) => {
  const [date, setDate] = useState(initialDate);

  const updateTime = useCallback(() => {
    const updatedDate = addDate(date, 1, 'second');

    setDate(updatedDate);
  }, [date]);

  useEffect(() => {
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return <Ellipsis text={fromNow(date)} classes={{ root: classes.root }} />;
};

export const TimeAgo = appWithStyles(styles)(TimeAgoComponent);
