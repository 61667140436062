import { useMemo, ReactNode } from 'react';

import cx from 'classnames';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './badge.styles';

type Props = AppWithStyles<typeof styles> & {
  count: number;
  max?: number;
  showZero?: boolean;
  style?: CSSRules;
  children: ReactNode;
};

const BadgeComponent = ({ classes, style, count, showZero, max = 99, children }: Props) => {
  const countLabel = count > max ? `${max}+` : count;

  const shouldRenderCount = useMemo(() => {
    const conditions = [showZero, !showZero && count > 0];

    return conditions.some((condition) => condition);
  }, [showZero, count]);

  return (
    <div className={classes.root}>
      {shouldRenderCount && (
        <h5
          className={cx(classes.counter, { [classes.maxCounter]: String(count).length >= 2 })}
          style={style}
        >
          {countLabel}
        </h5>
      )}
      {children}
    </div>
  );
};

export const Badge = appWithStyles(styles)(BadgeComponent);
