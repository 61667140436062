import { useContext, useReducer, createContext, ReactNode } from 'react';

import { Modal } from '@shared/components';

export type IModalContextValue = {
  isOpen: boolean;
  setContents: Function;
  setCloseable: Function;
  hideClose: Function;
  setOnClose: Function;
  setPayload: Function;
  open: Function;
  close: Function;
  set: Function;
};

type IModalContextState = {
  isOpen: boolean;
  onClose: any;
  contents: any;
  payload: any;
  closeable: boolean;
  hideClose: boolean;
};

const STATE: IModalContextState = {
  isOpen: false,
  onClose: null,
  contents: null,
  payload: null,
  closeable: true,
  hideClose: false,
};

const reducer = (state: IModalContextState, action: any) => {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        ...action.payload,
      };
    case 'open':
      return {
        ...state,
        isOpen: true,
      };
    case 'close':
      if (state.onClose) {
        state.onClose(state.payload);
      }
      return {
        ...state,
        isOpen: false,
      };
    // when contents are set, other parts of the state are "reset"
    case 'set-contents':
      return {
        ...action,
        onClose: null,
        payload: null,
        closeable: true,
        contents: action.payload,
      };
    case 'set-closeable':
      return {
        ...state,
        closeable: action.payload,
      };
    case 'set-payload':
      return {
        ...state,
        payload: action.payload,
      };
    case 'set-onclose':
      return {
        ...state,
        onClose: action.payload,
      };
    case 'hide-close':
      return {
        ...state,
        hideClose: action.payload,
      };
  }
};

const defaultModalContextValue: IModalContextValue = {
  isOpen: false,
  setContents: () => {},
  setCloseable: () => {},
  hideClose: () => {},
  setOnClose: () => {},
  setPayload: () => {},
  open: () => {},
  close: () => {},
  set: () => {},
};

const ModalContext = createContext(defaultModalContextValue);
export const useModalContext = () => useContext(ModalContext);

type Props = {
  children: ReactNode;
};

export const ModalContextProvider = ({ children }: Props) => {
  const [state, dispatch]: [IModalContextState, any] = useReducer(reducer, STATE);

  const { isOpen, contents, closeable, hideClose } = state;
  const d = (type: string, payload: any = null) => dispatch({ type, payload });

  const context: IModalContextValue = {
    isOpen: state.isOpen,
    setContents: (contents: any) => d('set-contents', contents),
    setCloseable: (closeable: boolean) => d('set-closeable', closeable),
    hideClose: (hide: boolean) => d('hide-close', hide),
    setOnClose: (onClose: any) => d('set-onclose', onClose),
    setPayload: (payload: any) => d('set-payload', payload),
    open: () => d('open'),
    close: () => d('close'),
    set: (payload: any) => d('set', payload),
  };

  const overrides = {
    ...(hideClose && {
      Close: {
        style: {
          display: 'none',
        },
      },
    }),
  };

  return (
    <ModalContext.Provider value={context}>
      {children}
      <Modal
        overrides={overrides}
        onClose={() => d('close')}
        isOpen={isOpen}
        closeable={closeable}
        autoFocus={false}
      >
        {contents}
      </Modal>
    </ModalContext.Provider>
  );
};
