import { inject } from '@core/di/di-utils';

import { DI_TOKENS } from '@shared/constants/di';
import { ICommentsService } from '@shared/types/services/comments';

export class FooterViewModel {
  private commentsService = inject<ICommentsService>(DI_TOKENS.commentsService);

  addReaction: ICommentsService['addReaction'] = (...args) => {
    return this.commentsService.addReaction(...args);
  };

  deleteReaction: ICommentsService['deleteReaction'] = (...args) => {
    return this.commentsService.deleteReaction(...args);
  };
}
