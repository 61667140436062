import { ButtonHTMLAttributes, ReactNode, RefObject, forwardRef } from 'react';

import { Button as BaseButton, ButtonProps as BaseProps, KIND } from 'baseui/button';
export { SHAPE as ButtonSHAPE, SIZE as ButtonSIZE } from 'baseui/button';

import { useThemeContext } from '@shared/hooks';

import {
  primaryStyles,
  baseButtonStyles,
  minimalStyles,
  tertiaryStyles,
  secondaryStyles,
  deleteStyles,
  sizeCompactStyles,
  sizeDefaultStyles,
  sizeLargeStyles,
  sizeMiniStyles,
} from './button.styles';

interface ButtonKIND extends KIND {
  delete: 'delete';
}

export interface ButtonProps
  extends Omit<BaseProps, 'kind'>,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  download?: boolean;
  text?: ReactNode;
  ref?: RefObject<HTMLButtonElement>;
  kind: ButtonKIND[keyof ButtonKIND];
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
  const { overrides, children, size = 'default', kind, text, ...rest } = props;

  const $theme = useThemeContext();

  const isCompact = size === 'compact';
  const isDefault = size === 'default';
  const isLarge = size === 'large';
  const isMini = size === 'mini';

  const isPrimary = kind === 'primary';
  const isSecondary = kind === 'secondary';
  const isTertiary = kind === 'tertiary';
  const isMinimal = kind === 'minimal';
  const isDelete = kind === 'delete';

  return (
    <BaseButton
      ref={ref}
      overrides={{
        // @ts-ignore
        BaseButton: {
          style: () => {
            return {
              ...baseButtonStyles,
              ...(isMinimal && minimalStyles($theme.colors.primary)),
              ...(isTertiary &&
                tertiaryStyles($theme.colors.backgroundTertiary, $theme.colors.primary)),
              ...(isSecondary &&
                secondaryStyles($theme.colors.backgroundTertiary, $theme.colors.primary)),
              ...(isPrimary &&
                primaryStyles($theme.colors.primary, $theme.colors.buttonPrimaryText)),
              ...(isDelete && deleteStyles($theme.colors.buttonPrimaryText)),
              ...(isCompact && sizeCompactStyles),
              ...(isDefault && sizeDefaultStyles),
              ...(isLarge && sizeLargeStyles),
              ...(isMini && sizeMiniStyles),
            };
          },
        },
        StartEnhancer: {
          style: () => ({
            marginRight: '4px',
          }),
        },
        ...overrides,
      }}
      {...rest}
    >
      {text || children}
    </BaseButton>
  );
});
