import { SVGComponent, SVGProps } from '@shared/components/SVGComponent';

type RefreshIconProps = Partial<SVGProps> & {};

export const RefreshIcon = (props: RefreshIconProps) => {
  return (
    <SVGComponent width={13} height={12} {...props}>
      <path
        d="M0.125 6.62488H1.11875C1.1875 6.62488 1.24375 6.56863 1.24375 6.49988V2.51082H9.50313V3.64519C9.50313 3.67488 9.5125 3.70301 9.53125 3.72644C9.54178 3.73994 9.55487 3.75122 9.56978 3.75963C9.58468 3.76804 9.60111 3.77342 9.6181 3.77545C9.6351 3.77749 9.65232 3.77613 9.66879 3.77148C9.68526 3.76682 9.70065 3.75895 9.71406 3.74832L11.9531 1.99051C12.0203 1.91238 12.0094 1.82957 11.9531 1.78426L9.71406 0.0280072C9.69102 0.00957613 9.66232 -0.000355731 9.63281 -0.000117896C9.56094 -0.000117896 9.50156 0.059257 9.50156 0.131132V1.26551H1.12031C0.503125 1.26551 0 1.76863 0 2.38738V6.49988C0 6.56863 0.05625 6.62488 0.125 6.62488ZM11.875 5.37488H10.8813C10.8125 5.37488 10.7563 5.43113 10.7563 5.49988V9.48894H2.49687V8.35457C2.49687 8.32488 2.4875 8.29676 2.46875 8.27332C2.45822 8.25982 2.44513 8.24855 2.43022 8.24013C2.41532 8.23172 2.39889 8.22635 2.3819 8.22431C2.3649 8.22228 2.34768 8.22363 2.33121 8.22829C2.31474 8.23294 2.29935 8.24081 2.28594 8.25144L0.046875 10.0093C-0.0203125 10.0874 -0.009375 10.1702 0.046875 10.2155L2.28594 11.9718C2.30937 11.9905 2.3375 11.9999 2.36719 11.9999C2.43906 11.9999 2.49844 11.9405 2.49844 11.8686V10.7343H10.8828C11.5 10.7343 12.0031 10.2311 12.0031 9.61238V5.49988C12 5.43113 11.9438 5.37488 11.875 5.37488Z"
        fill="#757575"
      />
    </SVGComponent>
  );
};
