import { useEffect, useState } from 'react';

import { gql } from '@apollo/client';

import { inject } from '@core/di/di-utils';

import { DI_TOKENS } from '@shared/constants/di';
import { TenantReadQuery } from '@shared/models/tenant/read-model';
import { IAuthService } from '@shared/types/services/auth';
import { ITenantsService } from '@shared/types/services/tenants';
import { IUsersService } from '@shared/types/services/users';
import { showErrors } from '@shared/utils/errors';

export const TENANT_FEATURES_FRAGMENT = gql`
  fragment TenantFeatures on Tenant {
    features {
      field_news
      field_news_feed
      field_news_announcements
      field_work
      field_work_pages
      field_work_objectives
      field_work_submissions
      field_summaries
      field_summaries_forms
      field_summaries_fulfillment
      insights_activity
      insights_explore
      insights_analyze
      program_organization
      program_organization_pages
      program_library
      program_newsroom
      program_data
      program_users
      program_news

      feed
      news
      news_headlines
      teamwork
      teamwork_pages
      teamwork_pages_requirements
      teamwork_pages_schedule
      teamwork_submissions
      reports
      reports_progress
      reports_summary
      reports_details
      fulfillment
      fulfillment_orders
      library
      library_assignments
      library_resources
      insights
      announcements
      stories
    }
  }
`;

export const useCurrentContext = () => {
  const [loading, setLoading] = useState(true);

  const authService = inject<IAuthService>(DI_TOKENS.authService);
  const usersService = inject<IUsersService>(DI_TOKENS.usersService);
  const tenantsService = inject<ITenantsService>(DI_TOKENS.tenantsService);

  const { tokens, currentUser, currentTenant, setCurrentUser, setCurrentTenant } = authService;
  const accessToken = tokens.access;
  const refreshToken = tokens.refresh;

  const getCurrentUser = async () => {
    if (accessToken || refreshToken) {
      try {
        const { asJson } = await usersService.getCurrentUser();

        setCurrentUser(asJson);
      } catch (err) {
        if (err?.response?.status !== 401) {
          showErrors(err?.response?.data?.errors, {
            notificationText: 'Something went wrong while fetching current user',
          });
        }
        console.error(err);
      }
    }
  };

  const getCurrentTenant = async () => {
    try {
      let tenant: TenantReadQuery;

      if (accessToken || refreshToken) {
        tenant = await tenantsService.getCurrentTenant();
      } else {
        tenant = await tenantsService.getGuest();
      }

      setCurrentTenant(tenant);
    } catch (err) {
      if (err?.response?.status !== 401) {
        showErrors(err?.response?.data?.errors, {
          notificationText: 'Something went wrong while fetching current tenant',
        });
      }

      console.error(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([getCurrentTenant(), getCurrentUser()]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken]);

  return { loading, currentUser, currentTenant };
};
