import { styled, withStyle } from 'baseui';
import { useUIContext } from 'src/shared/hocs/ui-context';

import { StandardBorder } from './standard-styles';

export const FlexContainer = styled('div', () => ({
  display: 'flex',
}));

export const FlexRowContainer = withStyle(FlexContainer, () => ({
  flexDirection: 'row',
}));

export const FlexColumnContainer = withStyle(FlexContainer, () => ({
  flexDirection: 'column',
}));

export const FlexFullContainer = withStyle(FlexContainer, ({ $flexDirection = 'row' }: any) => ({
  flex: 1,
  flexDirection: $flexDirection,
}));

const StandardBodyContainer = withStyle(
  FlexContainer,
  ({ $theme, $flexDirection = 'column', $mobile = false }: any) => ({
    flexDirection: $flexDirection,
    flex: 1,
    padding: $mobile ? $theme.sizing.scale600 : $theme.sizing.scale800,
    overflow: 'auto',
    maxHeight: '100%',
  })
);

export const StandardBody = ({ children, $flexDirection, ...rest }: any) => {
  const uiContext = useUIContext();
  return (
    <StandardBodyContainer $mobile={uiContext.mobile} $flexDirection={$flexDirection} {...rest}>
      {children}
    </StandardBodyContainer>
  );
};

export const FullWrapper = withStyle(FlexContainer, ({ $flexDirection = 'column' }: any) => ({
  flexDirection: $flexDirection,
  width: '100%',
  height: '100%',
}));

export const FieldWrapper = withStyle(FlexFullContainer, ({ $theme }: any) => ({
  flexDirection: 'column',
  position: 'relative',
  marginBottom: $theme.sizing.scale600,
}));

export const StandardCard = withStyle(FlexColumnContainer, ({ $theme }: any) => ({
  paddingTop: $theme.sizing.scale800,
  paddingRight: $theme.sizing.scale800,
  paddingBottom: $theme.sizing.scale800,
  paddingLeft: $theme.sizing.scale800,
  background: $theme.colors.white,
  border: StandardBorder($theme),
  borderRadius: $theme.borders.radius200,
}));

export const LoadingWrapper = withStyle(FlexFullContainer, ({ $height }: any) => ({
  justifyContent: 'center',
  alignItems: 'center',
  height: $height ? $height : 'unset',
}));

export const SubPageWrapper = styled('div', ({ $mobile }: any) => ({
  paddingTop: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: 0,
  flex: 1,
  height: $mobile ? 'calc(100vh - 167px)' : 'calc(100vh - 112px)',
  position: 'relative',
}));

export const CardWrapper = withStyle(FlexFullContainer, ({ $theme, $index }: any) => ({
  marginLeft: $index === 0 ? 0 : $theme.sizing.scale800,
  marginBottom: $theme.sizing.scale800,
}));
