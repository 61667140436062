import { useCallback, useMemo } from 'react';

import { Avatar } from 'src/shared/components/avatar';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { AppLink } from '@shared/components/app-link';
import { CommentsAction } from '@shared/components/commentaries/commentaries.types';
import { showConfirmation } from '@shared/components/confirmation';
import { DropdownMenu } from '@shared/components/dropdown-menu';
import { Ellipsis } from '@shared/components/Ellipsis';
import { Flex } from '@shared/components/Flex';
import { CommentReadQuery } from '@shared/models/comment/read-model';
import { UserReadQuery } from '@shared/models/user/read-model';
import { formatDate } from '@shared/utils/date';
import { showErrors } from '@shared/utils/errors';
import { ROUTES } from '@shared/utils/getRoute';

import { HeaderViewModel } from './header.vm';

import { styles, AVATAR_SIZE } from './header.styles';

type HeaderProps = AppWithStyles<typeof styles> &
  Required<Pick<CommentReadQuery, 'user' | 'createdAt' | 'updatedAt' | 'id' | 'editor'>> & {
    currentUser: UserReadQuery;
    action?: CommentsAction;
    onEditActivate: () => void;
    onDeleteSuccess: () => Promise<void>;
  };

const HeaderComponent = ({
  classes,
  user,
  action,
  currentUser,
  id,
  createdAt,
  updatedAt,
  onDeleteSuccess,
  onEditActivate,
}: HeaderProps) => {
  const $vm = useMemo(() => new HeaderViewModel(), []);

  const handleDelete = useCallback(() => {
    const callback = async (isConfirmed: boolean) => {
      if (isConfirmed) {
        try {
          await $vm.delete(id);

          await onDeleteSuccess();
        } catch (err) {
          showErrors(err?.response?.data?.errors, {
            notificationText: 'Something went wrong while deleting comment',
          });

          console.error(err);
        }
      }
    };

    return showConfirmation(
      {
        title: 'Delete comment',
        question: 'Are you sure you want to delete this comment?',

        buttonsConfig: { confirm: { text: 'Delete', className: classes.confirmDeleteButton } },
      },
      callback
    );
  }, [onDeleteSuccess]);

  const menuItems = useMemo(() => {
    return [
      {
        name: 'Edit',
        onClick: onEditActivate,
      },
      {
        name: 'Delete',
        className: classes.deleteMenuItem,
        onClick: handleDelete,
      },
    ];
  }, [classes, onEditActivate, handleDelete]);

  return (
    <Flex justifyContent="space-between" classes={{ root: classes.root }}>
      <Flex classes={{ root: classes.userInfo }}>
        <AppLink to={ROUTES.userProfile(user.id)}>
          <Avatar
            size={AVATAR_SIZE}
            src={user.profileUrl}
            name={`${user.firstName} ${user.lastName}`}
          />
        </AppLink>
        <div className={classes.fullNameWrapper}>
          <Ellipsis
            component={AppLink}
            componentProps={{
              to: ROUTES.userProfile(user.id),
            }}
            text={user.fullName}
            classes={{ root: classes.fullName }}
          />
          <Ellipsis
            classes={{ root: classes.createdAt }}
            text={`${formatDate(updatedAt, { format: 'lll' })} ${
              createdAt === updatedAt ? '' : '(Edited)'
            }`}
          />
        </div>
      </Flex>
      {user.id === currentUser.id && (
        <DropdownMenu
          disabled={Boolean(action)}
          iconOrientation="vertical"
          items={menuItems}
          classes={{
            root: classes.dropdownMenu,
            icon: classes.dropdownMenuIcon,
          }}
        />
      )}
    </Flex>
  );
};

export const Header = appWithStyles(styles)(HeaderComponent);
