import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {},
    emptyState: {
      height: '88px',
    },
    heading: {
      color: palette.grey[500],
      fontSize: '14px',
      fontWeight: 400,
      margin: '0px',
    },
    notification: {
      marginBottom: spacing(4),
    },
  });
