import {
  Table as BaseTable,
  StyledTable as BaseStyledTable,
  StyledHead as BaseStyledHead,
  StyledHeadCell as BaseStyledHeadCell,
  StyledBody as BaseStyledBody,
  StyledRow as BaseStyledRow,
  StyledCell as BaseStyledCell,
} from 'baseui/table';

type ITableProps = any;
export const Table = (props: ITableProps) => {
  return <BaseTable {...props} />;
};

type IStyledTableProps = any;
export const StyledTable = (props: IStyledTableProps) => {
  return <BaseStyledTable {...props} />;
};

type IStyledHeadProps = any;
export const StyledHead = (props: IStyledHeadProps) => {
  return <BaseStyledHead {...props} />;
};

type IStyledHeadCellProps = any;
export const StyledHeadCell = (props: IStyledHeadCellProps) => {
  return <BaseStyledHeadCell {...props} />;
};

type IStyledBodyProps = any;
export const StyledBody = (props: IStyledBodyProps) => {
  return <BaseStyledBody {...props} />;
};

type IStyledRowProps = any;
export const StyledRow = (props: IStyledRowProps) => {
  return <BaseStyledRow {...props} />;
};

type IStyledCellProps = any;
export const StyledCell = (props: IStyledCellProps) => {
  return <BaseStyledCell {...props} />;
};
