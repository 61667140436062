import { palette } from '@core/theme/units/palette';
import { radius } from '@core/theme/units/radius';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

export const styles = () =>
  createStyles({
    root: {
      position: 'relative',
      margin: spacing(2),
    },
    counter: {
      borderRadius: radius.containers.round,
      position: 'absolute',
      minWidth: '20px',
      top: '0px',
      right: '0px',
      transform: 'scale(1) translate(50%, -50%)',
      transformOrigin: '100% 0%',
      color: palette.common.white,
      fontSize: '10px',
      fontWeight: 500,
    },
    maxCounter: {
      borderRadius: radius.containers.secondary,
      padding: spacing(0, 1),
    },
  });
