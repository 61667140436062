import { matchPath } from 'react-router-dom';

import { getRoutePath } from './getRoute';

const publicPaths = new Set([
  getRoutePath('LOGIN'),
  getRoutePath('FORGOT_PASSWORD'),
  getRoutePath('RESET_PASSWORD'),
  getRoutePath('INVITE'),
  getRoutePath('INSIGHTS_SHARED_INSIGHT', { id: ':id' }),
]);

export const isPublicPath = (path: string) => {
  return Array.from(publicPaths)
    .map((publicPath) => {
      const match = matchPath(path, {
        path: publicPath,
        exact: true,
        strict: false,
      });
      return !!match;
    })
    .reduce((prev, curr) => {
      return prev || curr;
    }, false);
};
