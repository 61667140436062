import { injectable } from '@core/di/di-utils';
import { inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { HeadlineListModel, HeadlineListQuery } from '@shared/models/headline/list-model';
import { PaginatedResponse } from '@shared/types/services';
import { IHeadlinesService } from '@shared/types/services/headlines';

@injectable()
export class HeadlinesService extends BaseService implements IHeadlinesService {
  private $http = inject<HTTPClient>(HTTPClient.diToken);

  uploadImage: IHeadlinesService['uploadImage'] = async (file) => {
    const query = `
      mutation FileUploaderGetSignedRequestFromS3($name: String!, $type: String!, $folder: String!) {
          getSignedRequestFromS3(name: $name, type: $type, folder: $folder)
      }
    `;

    const {
      data: { getSignedRequestFromS3 },
    } = await this.$http.post<{ getSignedRequestFromS3: string }>('/api', {
      query,
      variables: {
        name: file.name,
        type: file.type,
        folder: 'headlines-description',
      },
    });

    const response = JSON.parse(getSignedRequestFromS3);

    await this.$http.createInstance().put(response.signedRequest, file);

    return response.url;
  };

  getHeadlines: IHeadlinesService['getHeadlines'] = async (pagination) => {
    const {
      data: { headlines, total },
    } = await this.$http.get<PaginatedResponse<{ headlines: Array<HeadlineListQuery> }>>(
      '/headlines',
      {
        params: { ...pagination },
      }
    );

    return {
      total,
      headlines: headlines.map((headline) => new HeadlineListModel(headline)),
    };
  };
}
