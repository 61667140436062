import { withStyle } from 'baseui';
import { SELECT, OPTIONS } from 'src/shared/constants/data-table';

import { FlexColumnContainer, FlexRowContainer } from '@shared/components';
import { Checkbox } from '@shared/components';
import { IDataTableColumn } from '@shared/types';

const Wrapper = withStyle(FlexColumnContainer, ({ $theme }: any) => ({
  paddingTop: $theme.sizing.scale600,
  paddingRight: $theme.sizing.scale600,
  paddingBottom: $theme.sizing.scale600,
  paddingLeft: $theme.sizing.scale600,
}));

type Props = {
  columns: IDataTableColumn[];
  onChange: (columns: IDataTableColumn[]) => void;
};

export const TableColumnSelectPopover = ({ columns, onChange }: Props) => {
  const handleChange = (column: IDataTableColumn) => {
    if (!onChange) return;

    onChange(
      columns.map((c: IDataTableColumn) =>
        c.key === column.key ? { ...column, active: !column.active } : c
      )
    );
  };

  return (
    <Wrapper>
      {columns
        .filter((c: IDataTableColumn) => c.key !== SELECT && c.key !== OPTIONS)
        .map((column: IDataTableColumn) => (
          <FlexRowContainer key={column.key}>
            <Checkbox checked={column.active} onChange={() => handleChange(column)} />
            {column.label}
          </FlexRowContainer>
        ))}
    </Wrapper>
  );
};
