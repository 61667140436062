import { palette } from '@core/theme/units/palette';
import { spacing } from '@core/theme/utils/spacing';
import { createStyles } from '@core/theme/utils/styles';

const headerHeight = 56;

export const styles = () =>
  createStyles({
    root: {
      backgroundColor: palette.grey[50],
      width: '100%',
      height: '100%',
    },
    header: {
      height: `${headerHeight}px`,
    },
    headerTitleWrapper: {},
    headerTitle: {},
    content: {
      padding: spacing(6, 4),
      height: `calc(100% - ${headerHeight}px)`,
    },
  });
