import { ReactNode } from 'react';

import cx from 'classnames';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './SVGComponent.styles';

export type SVGProps = AppWithStyles<typeof styles> & {
  className?: string;
  width: number;
  height: number;
  children: ReactNode;
};

const SVG = ({ classes, children, className, height, width }: SVGProps) => {
  return (
    <svg
      className={cx(classes.root, className)}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      {children}
    </svg>
  );
};

export const SVGComponent = appWithStyles(styles)(SVG);
