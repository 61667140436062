import { ButtonHTMLAttributes } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './icon-button.styles';

type Props = AppWithStyles<typeof styles> & ButtonHTMLAttributes<HTMLButtonElement> & {};

const _IconButtonComponent = ({ classes, ...otherProps }: Props) => {
  return <button type="button" className={classes.root} {...otherProps} />;
};

export const IconButtonComponent = appWithStyles(styles)(_IconButtonComponent);
