import { injectable, inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { PromptCreateDTO } from '@shared/models/prompt/create-model';
import { PromptReadDTO } from '@shared/models/prompt/read-model';
import { UpdatePromptDTO, UpdatePromptPagesDTO } from '@shared/models/prompt/update-prompt';
import { IPromptsService } from '@shared/types/services/prompts';

@injectable()
export class PromptsService extends BaseService implements IPromptsService {
  static diToken = Symbol('prompts-service');

  private $http = inject<HTTPClient>(HTTPClient.diToken);
  protected urlPrefix = '/prompts';

  async createPrompt(params: PromptCreateDTO) {
    const { data } = await this.$http.post<PromptReadDTO>(this.urlPrefix, params);

    return data.id;
  }

  async getPrompt(id: PromptReadDTO['id']) {
    const { data } = await this.$http.get<PromptReadDTO>(`${this.urlPrefix}/${id}`);

    return data;
  }

  getPrompts = async () => {
    const { data } = await this.$http.get<Array<PromptReadDTO>>(this.urlPrefix);

    return data;
  };

  updatePrompt = async (id: PromptReadDTO['id'], updateData: UpdatePromptDTO) => {
    const { data } = await this.$http.patch<Array<PromptReadDTO>>(
      `${this.urlPrefix}/${id}`,
      updateData
    );

    return data;
  };

  updatePromptPages = async (id: PromptReadDTO['id'], updateData: UpdatePromptPagesDTO) => {
    const { data } = await this.$http.patch<Array<PromptReadDTO>>(
      `${this.urlPrefix}/${id}/pages-and-groups`,
      updateData
    );

    return data;
  };
}
