import { SVGComponent, SVGProps } from '@shared/components/SVGComponent';

type AddReactionIconProps = Partial<SVGProps> & {};

export const AddReactionIcon = (props: AddReactionIconProps) => {
  return (
    <SVGComponent width={24} height={24} {...props}>
      <path
        d="M22 7C22 7.31559 21.7442 7.57143 21.4286 7.57143H20.2857C19.3389 7.57143 18.5714 8.33894 18.5714 9.28572V10.4286C18.5714 10.7442 18.3156 11 18 11C17.6844 11 17.4286 10.7442 17.4286 10.4286V9.28571C17.4286 8.33894 16.6611 7.57143 15.7143 7.57143H14.5714C14.2558 7.57143 14 7.31559 14 7C14 6.68441 14.2558 6.42857 14.5714 6.42857H15.7143C16.6611 6.42857 17.4286 5.66106 17.4286 4.71429V3.57143C17.4286 3.25584 17.6844 3 18 3C18.3156 3 18.5714 3.25584 18.5714 3.57143V4.71429C18.5714 5.66106 19.3389 6.42857 20.2857 6.42857H21.4286C21.7442 6.42857 22 6.68441 22 7Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4537 5.35276C14.1808 4.49838 12.6488 4 11 4C6.58065 4 3 7.58065 3 12C3 16.4194 6.58065 20 11 20C15.4194 20 19 16.4194 19 12C19 11.8745 18.9971 11.7497 18.9914 11.6257C18.7271 11.8587 18.38 12 18 12C17.8061 12 17.6209 11.9632 17.4508 11.8963C17.4513 11.9308 17.4516 11.9653 17.4516 12C17.4516 15.558 14.5581 18.4516 11 18.4516C7.44193 18.4516 4.54838 15.558 4.54838 12C4.54838 8.44192 7.44193 5.54837 11 5.54837C11.84 5.54837 12.6429 5.70963 13.3794 6.00281C13.6542 5.69431 14.0544 5.5 14.5 5.5H14.75C15.0003 5.5 15.2384 5.44744 15.4537 5.35276ZM8.41935 11.4839C8.99032 11.4839 9.45161 11.0226 9.45161 10.4516C9.45161 9.88066 8.99032 9.41937 8.41935 9.41937C7.84839 9.41937 7.3871 9.88066 7.3871 10.4516C7.3871 11.0226 7.84839 11.4839 8.41935 11.4839ZM14.6129 10.4516C14.6129 11.0226 14.1516 11.4839 13.5806 11.4839C13.0097 11.4839 12.5484 11.0226 12.5484 10.4516C12.5484 9.88066 13.0097 9.41937 13.5806 9.41937C14.1516 9.41937 14.6129 9.88066 14.6129 10.4516ZM13.7097 13.8258C13.0387 14.6323 12.0484 15.0968 11 15.0968C9.95161 15.0968 8.96129 14.6355 8.29032 13.8258C8.01613 13.4968 7.52581 13.4548 7.2 13.7258C6.87097 14 6.82903 14.4871 7.1 14.8161C8.06774 15.9774 9.49032 16.6419 11 16.6419C12.5097 16.6419 13.9323 15.9774 14.9 14.8161C15.1742 14.4871 15.129 14 14.8 13.7258C14.4742 13.4548 13.9839 13.4968 13.7097 13.8258Z"
        fill="#757575"
      />
    </SVGComponent>
  );
};
