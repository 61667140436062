import { injectable } from '@core/di/di-utils';
import { inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { BookmarkReadQuery } from '@shared/models/bookmark/read-model';
import { StoryListDTO } from '@shared/models/story/list-model';
import { StoryPost } from '@shared/models/story/post';
import { AnswerFile } from '@shared/models/submission/answer/answer-file';
import { SubmissionListModel, SubmissionListQuery } from '@shared/models/submission/list-model';
import { PaginatedResponse, Pagination } from '@shared/types/services';
import {
  IInsightsExploreService,
  InsightArchiveStatusRequest,
  InsightsExploreStoriesListRequestFilter,
} from '@shared/types/services/insights/explore';

@injectable()
export class InsightsExploreService extends BaseService implements IInsightsExploreService {
  private $http = inject<HTTPClient>(HTTPClient.diToken);

  protected urlPrefix = '/insights/explore';

  getStories: IInsightsExploreService['getStories'] = async (filter, pagination) => {
    const {
      data: { stories, total },
    } = await this.$http.post<PaginatedResponse<{ stories: Array<StoryListDTO> }>>(
      this.getUrl('stories'),
      {
        filter,
        pagination,
      }
    );

    return { total, stories };
  };

  getFormSubmissions: IInsightsExploreService['getFormSubmissions'] = async (
    filter,
    pagination,
    include
  ) => {
    const {
      data: { submissions, total },
    } = await this.$http.post<PaginatedResponse<{ submissions: Array<SubmissionListQuery> }>>(
      this.getUrl('submissions'),
      {
        filter,
        pagination,
        include,
      }
    );

    return {
      total,
      submissions: submissions.map((story) => new SubmissionListModel(story)),
    };
  };

  bookmarkPost: IInsightsExploreService['bookmarkPost'] = async (postId) => {
    const {
      data: { bookmarks },
    } = await this.$http.post<{ bookmarks: Array<BookmarkReadQuery<StoryPost['id']>> }>(
      `/posts/${postId}/bookmarks`,
      undefined,
      {
        params: {
          feature: 'INSIGHTS_EXPLORE',
        },
      }
    );

    return bookmarks;
  };

  bookmarkSubmission: IInsightsExploreService['bookmarkSubmission'] = async (
    submissionId,
    fileId
  ) => {
    const {
      data: { bookmarks },
    } = await this.$http.post<{ bookmarks: Array<BookmarkReadQuery<AnswerFile['id']>> }>(
      `/form-submissions/${submissionId}/files/${fileId}/bookmarks`,
      undefined,
      {
        params: {
          feature: 'INSIGHTS_EXPLORE',
        },
      }
    );

    return bookmarks;
  };

  removePostBookmark: IInsightsExploreService['removePostBookmark'] = async (
    postId,
    bookmarkId
  ) => {
    const {
      data: { bookmarks },
    } = await this.$http.delete<{ bookmarks: Array<BookmarkReadQuery<StoryPost['id']>> }>(
      `/posts/${postId}/bookmarks/${bookmarkId}`
    );

    return bookmarks;
  };

  removeSubmissionBookmark: IInsightsExploreService['removeSubmissionBookmark'] = async (
    submissionId,
    fileId,
    bookmarkId
  ) => {
    const {
      data: { bookmarks },
    } = await this.$http.delete<{ bookmarks: Array<BookmarkReadQuery<AnswerFile['id']>> }>(
      `/form-submissions/${submissionId}/files/${fileId}/bookmarks/${bookmarkId}`
    );

    return bookmarks;
  };

  getStoriesCount: IInsightsExploreService['getStoriesCount'] = async (
    filter: InsightsExploreStoriesListRequestFilter,
    pagination: Pagination
  ) => {
    const { data } = await this.$http.post<{ total: number }>(this.getUrl('stories/media-count'), {
      filter,
      pagination,
    });

    return data;
  };

  getSubmissionsCount: IInsightsExploreService['getSubmissionsCount'] = async (
    filter: InsightsExploreStoriesListRequestFilter,
    pagination: Pagination
  ) => {
    const { data } = await this.$http.post<{ total: number }>(
      this.getUrl('submissions/media-count'),
      {
        filter,
        pagination,
      }
    );

    return data;
  };

  storiesArchive: IInsightsExploreService['storiesArchive'] = async (body) => {
    const { data } = await this.$http.post<{ correlationId: string }>(
      this.getUrl('stories/archive'),
      body
    );

    return data;
  };

  submissionsArchive: IInsightsExploreService['submissionsArchive'] = async (body) => {
    const { data } = await this.$http.post<{ correlationId: string }>(
      this.getUrl('submissions/archive'),
      body
    );

    return data;
  };

  getDownloadArchiveStatus = async (correlationId: string) => {
    const { data } = await this.$http.get<InsightArchiveStatusRequest>(
      this.getUrl('archive-status'),
      {
        params: {
          correlationId,
        },
      }
    );

    return data;
  };
}
