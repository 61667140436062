import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Button, ButtonSHAPE, ButtonProps } from 'src/shared/components/button';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Icon } from '@shared/components/Icon';

import { styles } from './BackButton.styles';

type Props = Omit<ButtonProps, 'href'> &
  AppWithStyles<typeof styles> & {
    location?: Location;
    className?: string;
  };

const BackButtonComponent = ({ onClick, location, text, className, classes, size }: Props) => {
  const history = useHistory();

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
      return;
    }

    if (location) {
      history.push(location);
      return;
    }

    history.goBack();
  };

  return (
    <Button
      size={size}
      text={text}
      kind="minimal"
      shape={ButtonSHAPE.square}
      className={cx(className, classes.root)}
      onClick={handleClick}
      startEnhancer={
        <Icon size={16} icon="RegularArrowLeft" color="colorSecondary" className={classes.icon} />
      }
    />
  );
};

export const BackButton = appWithStyles(styles)(BackButtonComponent);
