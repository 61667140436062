import { styled } from 'baseui';
import { StyledLink } from 'baseui/link';
import { useHistory } from 'react-router-dom';

const NonLink = styled('div', () => ({}));

export const RouterLink = (props: any) => {
  const { to, children, style, onClick, ...rest } = props;
  const history = useHistory();

  const doClick = (e: any) => {
    if (onClick) {
      onClick();
    }
    if (!e.currentTarget.target || e.currentTarget.target === '_self') {
      e.preventDefault();
      history.push(to);
    }
  };

  const appliedStyle = {
    fontSize: 'inherit',
    textDecoration: 'none',
    ...style,
  };

  return !to ? (
    <NonLink {...rest} $style={appliedStyle} children={children} />
  ) : (
    <StyledLink {...rest} onClick={doClick} href={to} $style={appliedStyle} children={children} />
  );
};
