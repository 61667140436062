import { palette } from '@core/theme/units/palette';
import { radius } from '@core/theme/units/radius';
import { createStyles } from '@core/theme/utils/styles';

export const MODAL_MARGIN = 16;

export const styles = () =>
  createStyles({
    imageWrapper: {
      borderRadius: radius.containers.primary,
      backgroundColor: palette.grey[100],
    },
    image: {
      objectFit: 'contain',
    },
  });
