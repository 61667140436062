import { SVGComponent, SVGProps } from '@shared/components/SVGComponent';

type CommentIconProps = Partial<SVGProps> & {};

export const CommentIcon = (props: CommentIconProps) => {
  return (
    <SVGComponent width={16} height={16} {...props}>
      <path
        d="M14.4563 5.2875C14.1032 4.44844 13.597 3.69531 12.9517 3.04844C12.3109 2.40531 11.5501 1.8941 10.7126 1.54375C9.85321 1.18281 8.94071 1 8.00009 1H7.96884C7.02196 1.00469 6.10478 1.19219 5.24228 1.56094C4.4119 1.91488 3.65831 2.427 3.02353 3.06875C2.38446 3.71406 1.8829 4.46406 1.53603 5.3C1.17665 6.16563 0.995401 7.08594 1.00009 8.03281C1.00539 9.11792 1.26211 10.187 1.75009 11.1562V13.5312C1.75009 13.7219 1.82581 13.9047 1.96061 14.0395C2.0954 14.1743 2.27821 14.25 2.46884 14.25H4.8454C5.81461 14.738 6.88373 14.9947 7.96884 15H8.00165C8.93759 15 9.8454 14.8188 10.7001 14.4641C11.5334 14.1179 12.2913 13.6126 12.9313 12.9766C13.5767 12.3375 14.0845 11.5906 14.4392 10.7578C14.8079 9.89531 14.9954 8.97813 15.0001 8.03125C15.0048 7.07969 14.8204 6.15625 14.4563 5.2875ZM12.0954 12.1313C11.0001 13.2156 9.54696 13.8125 8.00009 13.8125H7.97353C7.03134 13.8078 6.0954 13.5734 5.26884 13.1328L5.13759 13.0625H2.93759V10.8625L2.86728 10.7312C2.42665 9.90469 2.19228 8.96875 2.18759 8.02656C2.18134 6.46875 2.77665 5.00625 3.86884 3.90469C4.95946 2.80312 6.41728 2.19375 7.97509 2.1875H8.00165C8.7829 2.1875 9.54071 2.33906 10.2548 2.63906C10.9517 2.93125 11.5767 3.35156 12.1142 3.88906C12.6501 4.425 13.072 5.05156 13.3642 5.74844C13.6673 6.47031 13.8188 7.23594 13.8157 8.02656C13.8063 9.58281 13.1954 11.0406 12.0954 12.1313Z"
        fill="currentColor"
      />
    </SVGComponent>
  );
};
