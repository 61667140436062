import { injectable } from '@core/di/di-utils';
import { inject } from '@core/di/di-utils';
import { HTTPClient } from '@core/http-client';
import { BaseService } from '@core/services/base-service';

import { EventListModel, EventListQuery } from '@shared/models/event/list-model';
import { EventReadModel, EventReadQuery } from '@shared/models/event/read-model';
import { EventTypeListModel, EventTypeListQuery } from '@shared/models/event-type/list-model';
import { PaginatedResponse } from '@shared/types/services';
import { IEventsService } from '@shared/types/services/events';

@injectable()
export class EventsService extends BaseService implements IEventsService {
  private $http = inject<HTTPClient>(HTTPClient.diToken);

  protected urlPrefix = '/calendar';

  getEvent: IEventsService['getEvent'] = async (id) => {
    const { data } = await this.$http.get<EventReadQuery>(this.getUrl(`events/${id}`));

    return new EventReadModel(data);
  };

  getEvents: IEventsService['getEvents'] = async (filters, pagination) => {
    const { data } = await this.$http.post<Array<EventListQuery>>(this.getUrl('events/search'), {
      ...filters,
      ...pagination,
    });

    return data.map((event) => new EventListModel(event));
  };

  getEventTypes: IEventsService['getEventTypes'] = async (params) => {
    const {
      data: { total, items },
    } = await this.$http.get<PaginatedResponse<{ items: Array<EventTypeListQuery> }>>(
      this.getUrl('event-types'),
      {
        params,
      }
    );

    return {
      total,
      items: items.map((eventType) => new EventTypeListModel(eventType)),
    };
  };

  createEventType: IEventsService['createEventType'] = async (data) => {
    await this.$http.post(this.getUrl('event-types'), data);
  };

  editEventType: IEventsService['editEventType'] = async (id: string, pageType: Id) => {
    await this.$http.patch(this.getUrl(`event-types/${id}`), { pageType });
  };

  deleteEventType: IEventsService['deleteEventType'] = async (id) => {
    await this.$http.delete(this.getUrl(`event-types/${id}`));
  };

  createEvent: IEventsService['createEvent'] = async (event) => {
    const { data } = await this.$http.post<EventReadQuery>(this.getUrl('events'), event);

    return new EventReadModel(data);
  };

  editEvent: IEventsService['editEvent'] = async (event, id) => {
    const { data } = await this.$http.put<EventReadQuery>(this.getUrl(`events/${id}`), event);

    return new EventReadModel(data);
  };

  accept: IEventsService['accept'] = async (id) => {
    await this.$http.post(this.getUrl(`events/${id}/accept`));
  };

  decline: IEventsService['decline'] = async (id) => {
    await this.$http.post(this.getUrl(`events/${id}/decline`));
  };

  deleteEvent: IEventsService['deleteEvent'] = async (id) => {
    await this.$http.delete(this.getUrl(`events/${id}`));
  };

  sendScheduleXlsx: IEventsService['sendScheduleXlsx'] = async (params) => {
    const { data } = await this.$http.post<{ errors: string[] }>(
      this.getUrl('events/export'),
      params
    );

    return data;
  };
}
