import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './default-value.styles';

type Props = AppWithStyles<typeof styles> & {
  value: string;
};

const DefaultValueComponent = ({ classes, value }: Props) => {
  return <div className={classes.root}>{value}</div>;
};

export const DefaultValue = appWithStyles(styles)(DefaultValueComponent);
