import { useMemo, useState, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { Select } from 'src/shared/components/select';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Flex } from '@shared/components/Flex';
import { Message } from '@shared/components/Message';
import { Spinner } from '@shared/components/Spinner';
import { ROUTES } from '@shared/constants/routes';
import { ListIcon } from '@shared/icons/list';
import { CommentOwnerType } from '@shared/models/comment/comment-owner';
import { SubmissionListQuery } from '@shared/models/submission/list-model';
import { getQueries } from '@shared/utils/queries';

import { Activity } from './components/activity';
import { Answer } from './components/answer';
import { DeleteSubmissionModal } from './components/delete-submission-modal';
import { MainInfo } from './components/main-info';
import { WrapperHeader } from './components/wrapper-header';
import { SubmissionViewModel } from './submission.vm';

import { Button } from '../button';

import { styles } from './submission.styles';

type Props = AppWithStyles<typeof styles> & {
  id: SubmissionListQuery['id'];
  onClose?: () => void;
};

const SubmissionComponent = ({ classes, id, onClose }: Props) => {
  const ref = useRef<HTMLDivElement>();

  const history = useHistory();

  const $vm = useMemo(() => new SubmissionViewModel(id), [id]);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const { commentParentId, commentId, type } = getQueries<{
    commentParentId?: string;
    commentId?: string;
    type?: CommentOwnerType;
  }>();

  const isSubmission = type === CommentOwnerType.submission;
  const isSubmissionAnswer = type === CommentOwnerType.submissionAnswer;

  const scrollToElement = (top: number) => {
    ref.current.scrollTo({
      top,
      behavior: 'smooth',
    });
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteSubmission = async () => {
    await $vm.deleteSubmission();
    history.push(ROUTES.insightsActivity);
  };

  const renderContent = () => {
    if ($vm.loading) {
      return (
        <Spinner
          withWrapper
          wrapperProps={{
            classes: {
              root: classes.loading,
            },
          }}
        />
      );
    }

    if ($vm.error) {
      return (
        <Message
          icon={<ListIcon classes={{ root: classes.emptyStateIcon }} />}
          heading="Failed to load data"
          subheading="Please wait some time and refresh the page"
          classes={{
            root: classes.emptyState,
            heading: classes.emptyStateHeading,
          }}
        />
      );
    }

    return (
      <div ref={ref} className={classes.mainInfo}>
        <Flex>
          <div className={classes.answersWrapper}>
            <MainInfo
              classes={{ root: classes.formMetadata }}
              submission={$vm.submission}
              onUpdateSubmission={$vm.getData}
              status={$vm.currentStatus}
              currentUser={$vm.currentUser}
            />
            {$vm.submission.assignment.tasks.map((task, i) => {
              const answer = $vm.submission.answers.find((answer) => answer.task.id === task.id);

              return (
                <Answer
                  key={task.id}
                  scrollTo={scrollToElement}
                  isOpen={
                    isSubmissionAnswer && answer?.comments?.includes(commentParentId || commentId)
                  }
                  openCommentId={isSubmissionAnswer && commentId}
                  openCommentParentId={isSubmissionAnswer && commentParentId}
                  order={i + 1}
                  task={task}
                  data={answer}
                  classes={{ root: classes.answer }}
                />
              );
            })}
          </div>
          <Activity
            scrollTo={scrollToElement}
            openCommentId={isSubmission ? commentId : undefined}
            openCommentParentId={isSubmission ? commentParentId : undefined}
            submissionId={$vm.submission?.id}
            log={$vm.activityLog}
            classes={{ root: classes.activityLog }}
          />
        </Flex>
      </div>
    );
  };

  return (
    <WrapperHeader
      loading={$vm.loading}
      actions={
        Boolean($vm.submission) &&
        !$vm.loading && (
          <Flex alignItems="center" classes={{ root: classes.statusSelectWrapper }}>
            <h5 className={classes.statusLabel}>Status:</h5>
            <Select
              size="compact"
              creatable={false}
              searchable={false}
              isLoading={$vm.statusSubmitting}
              disabled={$vm.statusSubmitting}
              clearable={false}
              overrides={{
                Popover: {
                  props: {
                    ignoreBoundary: true,
                  },
                },
              }}
              migratingToValue={$vm.currentStatus}
              options={$vm.statuses}
              classes={{ root: classes.statusSelect }}
              onChange={$vm.handleStatusChange}
            />
            <Button kind="delete" size="compact" onClick={handleOpenDeleteModal}>
              Delete
            </Button>
          </Flex>
        )
      }
      title={$vm.submission?.assignment?.name}
      onClose={onClose}
      classes={{
        root: classes.root,
        headerTitleWrapper: classes.headerTitleWrapper,
      }}
    >
      <Flex classes={{ root: classes.sectionsWrapper }}>{renderContent()}</Flex>

      <DeleteSubmissionModal
        isOpen={openDeleteModal}
        onClose={handleCloseDeleteModal}
        handleDelete={handleDeleteSubmission}
      />
    </WrapperHeader>
  );
};

export const Submission = appWithStyles(styles)(appObserver(SubmissionComponent));
