import { BaseModel } from '@core/base-model';

import { UserReadQuery } from '@shared/models/user/read-model';

export interface GroupUserListQuery {
  isManager: boolean;
  user: UserReadQuery;
}

export class GroupUserListModel extends BaseModel<GroupUserListQuery> {
  private isManager: boolean;
  private user: UserReadQuery;

  constructor(data: GroupUserListQuery) {
    super();

    this.update(data);
  }

  get asJson(): GroupUserListQuery {
    return {
      isManager: this.isManager,
      user: this.user,
    };
  }
}
