import { Form } from 'mobx-react-form';

import { showNotification } from '@shared/components/app-notification';

interface ValidationError {
  body: string;
  field: string;
  messages?: Array<string>;
  type: string;
}

const retrieveValidationErrors = (errors: Array<ValidationError>) => {
  return errors
    .filter(({ messages }) => messages?.length)
    .map(({ field, messages }) => {
      return {
        field,
        message: messages[0],
      };
    });
};

export const showErrors = (
  errors: Array<ValidationError>,
  config: { form?: Form<any>; notificationText: string }
) => {
  if (!errors) {
    showNotification(config.notificationText, { type: 'error' });

    return;
  }

  retrieveValidationErrors(errors).forEach((err) => {
    if (!config.form) {
      showNotification(err.message, { type: 'error' });

      return;
    }

    const field = config.form.fields.get(err.field);

    if (field) {
      field.errorAsync = err.message;

      field.showErrors();
    } else {
      showNotification(err.message, { type: 'error' });
    }
  });
};
