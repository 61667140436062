import { useEffect, lazy } from 'react';

import { useStyletron } from 'baseui';
import { Location } from 'history';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { ThemeContextProvider } from 'src/shared/hocs/theme-context';

import { inject } from '@core/di/di-utils';

import { Confirmation, Spinner, SuspenseBoundary } from '@shared/components';
import { DI_TOKENS } from '@shared/constants/di';
import { useCurrentContext } from '@shared/hooks';
import { IAuthService } from '@shared/types/services/auth';
import { IUsersService } from '@shared/types/services/users';
import { INotificationsViewModel } from '@shared/types/view-models/notifications';
import { IReactionsViewModel } from '@shared/types/view-models/reactions';
import { getCurrentTimezone } from '@shared/utils/date';
import { showErrors } from '@shared/utils/errors';
import { SHARED_ROUTES } from '@shared/utils/getRoute';
import { getRoute, getRoutePath, ROUTES } from '@shared/utils/getRoute';
import { isPublicPath } from '@shared/utils/isNonAuthPath';

import { styles } from './app-router.styles';

const ForgotPassword = lazy(() => import('@modules/auth/forgot-password'));
const InvitePage = lazy(() => import('@modules/auth/invite-page'));
const LoginPage = lazy(() => import('@modules/auth/login-page'));
const ResetPasswordPage = lazy(() => import('@modules/auth/reset-password-page'));
const InternalAdminTool = lazy(() => import('@modules/admin'));
const WebAppRoutes = lazy(() => import('@modules/tenant/routing'));
const Insights = lazy(() => import('@modules/tenant/insights'));

const getLoginRedirect = (location: Location) => {
  const { error }: queryString.ParsedQuery = queryString.parse(location.search);

  return {
    pathname: getRoute('LOGIN') as 'string',
    search: error ? queryString.stringify({ error }) : undefined,
  };
};

const InnerRouter = () => {
  const authService = inject<IAuthService>(DI_TOKENS.authService);
  const userService = inject<IUsersService>(DI_TOKENS.usersService);
  const notificationsViewModel = inject<INotificationsViewModel>(DI_TOKENS.notificationsViewModel);
  const reactionsViewModel = inject<IReactionsViewModel>(DI_TOKENS.reactionsViewModel);

  const location = useLocation();
  const history = useHistory();
  const { loading: contextLoading } = useCurrentContext();

  const getReactionsList = async () => {
    try {
      await reactionsViewModel.getList();
    } catch (e) {
      console.error(e);
      if (e?.response?.status !== 401) {
        showErrors(e?.response?.data?.errors, {
          notificationText: 'Something went wrong while fetching reactions',
        });
      }
    }
  };

  useEffect(() => {
    if (authService.tokens.access) {
      getReactionsList();
      notificationsViewModel.subscribe();
      userService.sendUserTimeZone(getCurrentTimezone());
    }

    if (!authService.tokens.access && notificationsViewModel.isConnected) {
      notificationsViewModel.unsubscribe();
    }
  }, [authService.tokens.access]);

  useEffect(() => {
    if (isPublicPath(location.pathname)) return;

    const params: queryString.ParsedQuery = queryString.parse(location.search);

    const setCurrentUser = async (urlParams: queryString.ParsedQuery) => {
      authService.setTokens({
        access: urlParams.accessToken as string,
        refresh: urlParams.refreshToken as string,
      });
      const { asJson } = await userService.getCurrentUser();
      authService.setCurrentUser(asJson);
      history.push(location.pathname);
    };
    // this is needed for redirect from admin panel to tenant page with access token in url-params
    if (params.accessToken) {
      setCurrentUser(params);
    } else if (
      !params.accessToken &&
      !authService.tokens.access &&
      !authService.tokens.refresh &&
      SHARED_ROUTES.some((route) => !location.pathname.includes(route))
    ) {
      history.push(getLoginRedirect(location));
    }
  }, [location.pathname]);

  const getPublicRoutes = () => {
    return (
      <>
        <Route exact path={getRoutePath('LOGIN')} component={LoginPage} />
        <Route exact path={getRoutePath('FORGOT_PASSWORD')} component={ForgotPassword} />
        <Route exact path={getRoutePath('RESET_PASSWORD')} component={ResetPasswordPage} />
        <Route exact path={getRoutePath('INVITE')} component={InvitePage} />
        <Route path={ROUTES.insights.root} component={Insights} />
      </>
    );
  };

  const getPrivateRoutes = () => {
    return authService.currentTenant?.isAdminTenant ? <InternalAdminTool /> : <WebAppRoutes />;
  };

  return (
    <ThemeContextProvider>
      {contextLoading && <Spinner withWrapper minHeight="100%" />}
      {!contextLoading && (
        <>
          <Switch>
            {!authService.loggedIn && getPublicRoutes()}
            {authService.loggedIn && getPrivateRoutes()}
          </Switch>
          <Confirmation />
        </>
      )}
    </ThemeContextProvider>
  );
};

export const AppRouter = () => {
  const [css] = useStyletron();

  return (
    <SuspenseBoundary
      loadingProps={{
        withWrapper: true,
        wrapperProps: {
          classes: { root: css(styles.loading) },
        },
      }}
    >
      <InnerRouter />
    </SuspenseBoundary>
  );
};
