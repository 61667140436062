import { palette } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/styles';

const userInfoMargin = 12;
const buttonSize = 24;
const buttonMargin = 16;

export const AVATAR_SIZE = 24;

export const styles = () =>
  createStyles({
    root: {},
    userInfo: {
      width: `calc(100% - ${buttonSize + buttonMargin}px)`,
    },
    fullNameWrapper: {
      marginLeft: `${userInfoMargin}px`,
      maxWidth: `calc(100% - ${userInfoMargin + AVATAR_SIZE}px)`,
    },
    fullName: {
      color: palette.common.black,
      fontSize: '14px',
      fontWeight: 600,
    },
    createdAt: {
      fontSize: '12px',
      color: '#666666',
    },
    dropdownMenu: {
      width: `${buttonSize}px`,
      height: `${buttonSize}px`,
    },
    dropdownMenuIcon: {
      '& > path': {
        fill: palette.grey[500],
      },
    },
    deleteMenuItem: {
      color: palette.error.main,
    },
    confirmDeleteButton: {
      backgroundColor: palette.error.main,
    },
  });
